import { GeneralContext } from '../../context/GeneralContext';
import React, { useContext, useEffect } from 'react';
import RequestList from '@/pages/chat/RequestList';
import { MenuScale } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';

const DashboardPage = () => {
    const windowWidth = useContext(GeneralContext).width;
    const windowHeight = useContext(GeneralContext).height;
    const context = useContext(GeneralContext);
    const setOpenModal = context.setOpenModal;
    const openModal = context.openModal;
    const navigate = useNavigate();

    useEffect(() => {
        if (windowWidth > 768) {
            setOpenModal('');
        }
    }, [windowWidth]);

    const handleMenuClick = () => {
        context.setMobileSidebar(true);
    };

    return (
        <div
            className="bg-white dark:bg-black text-black dark:text-white"
            style={{ height: windowHeight, width: windowWidth }}
        >
            <div className="p-5 pt-8 pb-7 flex flex-row w-100 justify-between">
                <div
                    className="contents cursor-pointer"
                    onClick={handleMenuClick}
                >
                    <MenuScale color={context.dark ? 'white' : '#141718'} />
                </div>
                <div className="font-medium">Dashboard</div>
                <div style={{ width: '24px' }}></div>
            </div>
            <RequestList />
        </div>
    );
};

export default DashboardPage;
