import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import axiosWithRetry from '@/util/axiosRetry';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const CallReport = () => {
    const queryClient = useQueryClient();
    const [startDate, setStartDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    );
    const [endDate, setEndDate] = useState(new Date());
    const fetchCallData = async () => {
        const response = await axiosWithRetry.get('/phone/get_list_calls', {
            params: {
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            },
        });
        return response.data;
    };

    const callDataMutation = useMutation({
        mutationFn: fetchCallData,

        onSuccess: response => {
            queryClient.setQueryData(['calls'], response);
        },
        onError: (error, variables, context) => {
            console.error('Error returning calls', error);
            return error;
        },
    });

    const { data: callData, isLoading } = useQuery({
        queryKey: ['calls'],
        queryFn: fetchCallData,
    });

    const handleSubmit = e => {
        e.preventDefault();
        callDataMutation.mutate();
    };

    return (
        <Container>
            <Title>Call Report</Title>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label>Start Date:</Label>
                    <StyledDatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        maxDate={new Date()}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>End Date:</Label>
                    <StyledDatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        maxDate={new Date()}
                        minDate={startDate}
                    />
                </FormGroup>
                <Button type="submit">Generate Report</Button>
            </Form>
            {isLoading || callDataMutation.isPending ? (
                <LoadingText>Loading...</LoadingText>
            ) : callData ? (
                <Results>
                    <ResultTitle>Report Results</ResultTitle>
                    <ResultItem>
                        Number of Calls: {callData.call_count}
                    </ResultItem>
                    <ResultItem>
                        Total Minutes: {callData.total_minutes?.toFixed(2)}
                    </ResultItem>
                </Results>
            ) : null}
        </Container>
    );
};

export default CallReport;

const Container = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    color: #333;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledDatePicker = styled(DatePicker)`
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #333; // Dark grey background
    color: white; // White text
    width: 100%; // Full width of the container

    &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }

    // Styles for the calendar icon (if visible)

    .react-datepicker__calendar-icon {
        fill: white;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #444; // Dark grey background for the form
    padding: 20px;
    border-radius: 8px;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
`;

const Button = styled.button`
    background-color: #007bff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const Results = styled.div`
    margin-top: 20px;
    background-color: #333; // Dark grey background for results
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const ResultTitle = styled.h2`
    margin-bottom: 10px;
`;

const ResultItem = styled.p`
    margin: 5px 0;
    font-size: 18px;
`;

const LoadingText = styled.p`
    text-align: center;
    font-style: italic;
    color: #333;
`;
