import { Check } from 'iconoir-react';
import React from 'react';
import styled from 'styled-components';

const Checkbox = ({ check, setCheck = () => {} }) => {
    return (
        <CheckBox
            type="checkbox"
            checked={check}
            onClick={() => setCheck(c => !c)}
        >
            {check && <Check height={28} width={28} color="white" />}
        </CheckBox>
    );
};
export default Checkbox;

const CheckBox = styled.div`
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${props => (props.checked ? '#EB0A74' : '#f4f3ef')};
`;
