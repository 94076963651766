import React from 'react';

export const TermsOfService = () => {
    return (
        <>
            <span>
                {`
                    We're committed to ensuring a safe, respectful, and
                    productive use of our AI services. To ensure this, we've
                    established the following community guidelines:`}
            </span>
            <ol style={{ display: 'grid', gap: '10px' }}>
                <li>
                    <strong>
                        <span>Responsible Use:</span>
                    </strong>{' '}
                    Use Subi.ai responsibly. Misuse of the AI, including
                    overloading the system or attempting to disrupt its
                    functionality, is not allowed.
                </li>
                <li>
                    <strong>
                        <span>No Illegal Activity:</span>
                    </strong>{' '}
                    Using Subi.ai for any illegal activities or to promote
                    illegal content is strictly prohibited.
                </li>
                <li>
                    <strong>
                        <span>Respect Privacy: </span>
                    </strong>
                    {`
                    Do not use Subi.ai to violate others' privacy. This
                    includes sharing private information without consent.`}
                </li>
                <li>
                    No Hateful or Violent Content: Subi.ai should not be used to
                    generate content that promotes violence, discrimination, or
                    hatred against individuals or groups based on attributes
                    such as race, religion, disability, age, nationality, or
                    gender.
                </li>
                <li>
                    No Fraudulent or Deceptive Behavior: Any activity intended
                    to deceive or defraud others is strictly prohibited.
                </li>
                <li>
                    No Unauthorized Practice of Law or Financial Advice: Subi.ai
                    should not be used to provide legal or financial advice
                    unless appropriately authorized and qualified to do so.
                </li>
                <li>
                    No Health Misinformation: Subi.ai should not be used to
                    spread misinformation about health conditions or treatments.
                </li>
                <li>
                    Respect Intellectual Property: Do not use Subi.ai to
                    infringe upon the intellectual property rights of others.
                </li>
                <li>
                    No Spamming: Do not use Subi.ai to send unsolicited or spam
                    messages.
                </li>
                <li>
                    No Cyberbullying or Harassment: Subi.ai should not be used
                    to bully, harass, or intimidate any individual or group.
                </li>
                <li>
                    No Promotion of Self-Harm or Violence: Subi.ai should not be
                    used to promote self-harm, suicide, or any form of violence.
                </li>
                <li>
                    No Promotion of Drugs or Illegal Substances: Subi.ai should
                    not be used to promote the use of drugs or other illegal
                    substances.
                </li>
                <li>
                    No Exploitation of Vulnerable Individuals or Groups: Subi.ai
                    should not be used to exploit vulnerable individuals or
                    groups.
                </li>
                <li>
                    No Promotion of Dangerous Activities: Subi.ai should not be
                    used to promote dangerous activities that could lead to harm
                    or injury.
                </li>
                <li>
                    No Misrepresentation: Do not misrepresent yourself or others
                    while using Subi.ai.
                </li>
                <li>
                    No Unauthorized Access: Do not attempt to gain unauthorized
                    access to Subi.ai systems or data.
                </li>
                <li>
                    No Data Mining: Do not use Subi.ai to mine data without
                    proper authorization.
                </li>
                <li>
                    No Disruption of Services: Do not use Subi.ai in a way that
                    disrupts the services for other users.
                </li>
                <li>
                    {`No Reverse Engineering: Do not attempt to reverse engineer
                    or decompile Subi.ai's software.`}
                </li>
                <li>
                    No Sale of Services: Do not sell access to Subi.ai or any
                    information obtained from Subi.ai.
                </li>
                <li>
                    No Impersonation: Do not impersonate other users or entities
                    while using Subi.ai.
                </li>
                <li>
                    No Promotion of Terrorism: Subi.ai should not be used to
                    promote terrorism or terrorist activities.
                </li>
                <li>
                    No Promotion of Hate Groups: Subi.ai should not be used to
                    promote hate groups or hate speech.
                </li>

                <li>
                    No Promotion of Non-Consensual Intimate Imagery: Subi.ai
                    should not be used to promote or distribute non-consensual
                    intimate imagery.
                </li>
                <li>
                    No Promotion of Animal Cruelty: Subi.ai should not be used
                    to promote animal cruelty.
                </li>
            </ol>
            <span>
                Remember, these guidelines are not exhaustive and we reserve the
                right to take action against any user who violates these
                guidelines. We also reserve the right to modify these guidelines
                as necessary. Thank you for helping us maintain a safe,
                respectful, and productive environment!
            </span>
        </>
    );
};
