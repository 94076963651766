import styled from 'styled-components';
import { styled as muiStyled } from '@mui/system';
import { TextField } from '@mui/material';

export const ChatInput = muiStyled(TextField)(({ theme }) => ({
    maxHeight: '263px',
    flexGrow: 1,
    borderRadius: '12px',
    border: '0px',
    cursor: 'pointer',
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&::placeholder': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    '& .MuiInputBase-root ::-webkit-scrollbar': {
        width: '6px',
        backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root ::-webkit-scrollbar-thumb': {
        backgroundColor: '#7F8689',
        borderRadius: '12px',
    },
    '& .MuiInputBase-root ::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
        border: '0px',

        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
    },
}));

export const Wrapper = styled.div`
    //height: calc(100vh - 80px);
    overflow: hidden;
    //margin-top: 80px;
    //margin: 20px;
    box-sizing: border-box;

    border-radius: 10px;
    @media (max-width: 768px) {
        margin: 0px;
        border-radius: 0px;
    }
`;

export const HistoryWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-y: ${({ conversationId }) => (conversationId ? 'scroll' : 'auto')};
    z-index: 1;
    height: 100%;
    flex-grow: 1;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    scroll-behavior: smooth;
    margin-top: ${({ conversationId, isMobile }) =>
        !conversationId && isMobile ? '70px' : '0px'};
    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0px;
    }
`;

export const GeneratingButton = styled.button`
    color: ${props => (props.dark ? '#fff' : 'rgba(20, 23, 24, 0.7)')};
    background: rgba(255, 255, 255, 0.2);
    /* 15 Medium */
    font-family: Indivisible;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 19.5px */
    padding: 6px 10px;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid rgba(20, 23, 24, 0.7);
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;

    &:hover {
        background: #6c7275;
        color: #ffffff;

        svg {
            fill: #ffffff;
        }
    }
`;

export const ChatUtilBar = styled.div`
    display: flex;
    position: sticky;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    bottom: 0px;
    margin-top: 20px;
    @media (max-width: 768px) {
        //bottom: 36px;
        right: 8px;
    }
`;

export const ScrollToBottom = styled.div`
    width: 31px;
    height: 31px;
    border-radius: 100px;
    background: #141718b2;
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 9999;
    cursor: pointer;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
    //@media (max-width: 768px) {
    //    width: 35px;
    //    height: 35px;
    //}
`;

export const LoadingWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
