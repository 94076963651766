import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import { ModalTitle } from '../../styles/StyledComponents';
import axiosWithRetry from '../../util/axiosRetry';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const VerifyUser = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = React.useState('');
    const queryClient = useQueryClient();

    const verifyUserMutation = useMutation({
        mutationFn: token =>
            axiosWithRetry
                .post(`/auth/verify`, { token: token })
                .then(res => res.data),

        onSuccess: data => {
            console.debug('verify response', data);

            setTimeout(() => navigate('/'), 3000);
        },
        onError: error => {
            const er = error?.response?.data?.detail;
            if (er === 'VERIFY_USER_BAD_TOKEN') {
                setError('Token expired or invalid. Please try again.');
            }
            if (er === 'VERIFY_USER_ALREADY_VERIFIED') {
                setError('Your account is already verified. Please login.');
            }
            setTimeout(() => navigate('/'), 3000);
        },
    });

    useEffect(() => {
        const { token } = queryString.parse(location.search);
        if (token) {
            verifyUserMutation.mutate(token);
        }
    }, [location.search]);
    return (
        <Wrapper>
            <ModalContentWrapper>
                <ModalTitle>
                    {error !== '' && <>{error}</>}
                    {error === '' && <>Verifying your account...</>}
                </ModalTitle>
            </ModalContentWrapper>
        </Wrapper>
    );
};

export default VerifyUser;

const ModalContentWrapper = styled.div`
    padding: 50px 50px 50px 50px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background: #fefefe;
    border-radius: 10px;

    @media (max-width: 768px) {
        width: calc(100% - 80px);
        padding: 50px 20px 40px 20px;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 62px);
`;
