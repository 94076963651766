import axiosWithRetry from '../util/axiosRetry';
import mixpanel from 'mixpanel-browser';
import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { fetchSubscription, fetchUser } from '@/util/api';
import { GeneralContext } from '@/context/GeneralContext';
import { useContext } from 'react';

export const useUser = () => {
    const queryClient = useQueryClient();
    const context = useContext(GeneralContext);
    const {
        data: user,
        isLoading,
        ...rest
    } = useQuery({
        queryKey: ['user'],
        queryFn: fetchUser,
        staleTime: 1000 * 60 * 60 * 24,
        placeholderData: keepPreviousData,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        initialData: () => {
            const userStr = localStorage.getItem('user');

            if (!userStr || userStr === 'undefined') return null;

            let storedUser = JSON.parse(userStr);

            return storedUser;
        },
    });

    const _login = async ({ e, email, password, idToken }) => {
        if (idToken !== undefined) {
            const response = await axiosWithRetry({
                method: 'post',
                url: '/login/google',
                data: { token: idToken },
                headers: { 'Content-Type': 'application/json' },
            });
            return response;
        } else {
            e.preventDefault();
            const formData = new FormData();
            formData.append('username', email);
            formData.append('password', password);
            const response = await axiosWithRetry({
                method: 'post',
                url: '/login',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response;
        }
    };

    const loginUser = useMutation({
        mutationFn: _login,
        onSuccess: response => {
            // on successful login, set the user data
            console.debug('Login Response', response);

            const newUser = response.data;

            storeNewUser({ newUser });

            mixpanel.track('Log In Completed', {
                type: 'Password',
            });
        },
        onError: (error, variables, context) => {
            console.error('Login Response', error);
            return error;
        },
    });

    const logoutUser = useMutation({
        mutationFn: () => axiosWithRetry.post(`/auth/logout`),
        onSuccess: data => {
            localStorage.removeItem('user');
            mixpanel.reset();

            queryClient.clear();
            // queryClient.removeQueries(['user']);
            window.location.reload();
        },
    });

    const _register = async ({
        email,
        password,
        googleToken,
        display_name = '',
    }) => {
        if (googleToken === undefined) {
            const response = await axiosWithRetry.post('/user/register', {
                email: email,
                password: password,
                display_name: display_name,
            });
            return response;
        } else {
            const response = await axiosWithRetry({
                method: 'post',
                url: '/register/google',
                data: { token: googleToken },
                headers: { 'Content-Type': 'application/json' },
            });

            return response;
        }
    };

    const register = useMutation({
        mutationFn: _register,
        onSuccess: response => {
            const newUser = response.data;
            storeNewUser({ newUser });
        },
    });

    const _onboard = async ({
        email = '',
        password = '',
        googleToken = undefined,
    }) => {
        if (googleToken === undefined) {
            const response = await axiosWithRetry.post('/register/onboard', {
                email: email,
                password: password,
            });
            return response;
        } else {
            const response = await axiosWithRetry({
                method: 'post',
                url: '/register/google_onboard',
                data: { token: googleToken },
                headers: { 'Content-Type': 'application/json' },
            });

            return response;
        }
    };

    const onboard = useMutation({
        mutationFn: _onboard,
        onSuccess: response => {
            const newUser = response.data;
            storeNewUser({ newUser });
        },
    });

    const storeNewUser = async ({ newUser }) => {
        localStorage.removeItem('user');

        queryClient.setQueryData(['user'], newUser);
        localStorage.setItem('user', JSON.stringify(newUser));

        mixpanel.identify(newUser.id);

        mixpanel.people.set({
            $name: newUser.display_name,
            $email: newUser.email,
            $avatar: newUser.avatar_path,
            is_verified: newUser.is_verified,
            $distinct_id: newUser.id,
        });
    };

    const { data: subscription } = useQuery({
        queryKey: ['subscription'],
        queryFn: fetchSubscription,
        enabled: user !== null,
        placeholderData: keepPreviousData,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    return {
        user,
        isLoading,
        loginUser,
        logoutUser,
        register,
        subscription,
        onboard,
    };
};

export default useUser;
// Custom hook to use the UserContext

export const useUserStateOnly = () => {
    const { data: user, isLoading } = useQuery({
        queryKey: ['user'],
        queryFn: fetchUser,
        placeholderData: keepPreviousData,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });

    return { user, isLoading };
};
