import React, { useContext } from 'react';
import styled from 'styled-components';
import { GeneralContext } from '../context/GeneralContext';
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicy = () => {
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    return (
        <>
            {context.width >= 768 && (
                <a
                    onClick={() => navigate('/')}
                    className="cursor-pointer absolute top-8 left-5"
                >
                    <Logo dark={context.dark} />
                </a>
            )}
            <div
                className="md:px-36 md:pt-32 p-5 "
                style={{
                    // color: 'black',
                    maxWidth: context.width,
                    textAlign: 'justify',
                    maxHeight: context.height,
                    overflowY: 'auto',
                    gap: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {context.width < 768 && (
                    <div className="flex flex-row justify-center">
                        <LogoSmall dark={context.dark} />
                    </div>
                )}
                <ol style={{ display: 'grid', gap: '10px' }}>
                    <MainHeader>SubiAI Privacy Policy </MainHeader>
                    <li>
                        <span>
                            SubiAI, Inc (the <b>“Company”</b> or <b>“We”</b>)
                            respects your privacy. This Policy discloses the
                            privacy practices governing the knowing collection,
                            use, and disclosure of information collected via the
                            website www.subi.ai (the
                            <b> “Site”</b>) and for the productivity Services (
                            <b>“Services”</b>) we deliver to customers
                            (collectively, (collectively, with the Site, our
                            “Services”). By accessing and using any of our
                            Services, you agree to the privacy practices as
                            described in this Privacy Policy. If you disagree
                            with anything in this Privacy Policy, please do not
                            use the Services.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>
                            <span>Some key terms</span>
                        </SmallHeader>
                    </li>
                    <li>
                        <span>
                            While there are many different definitions of
                            personal information used in different
                            jurisdictions, as used herein, the term{' '}
                            <b>Personal Information</b> means information about
                            a specific individual, such as full name, address,
                            telephone or mobile number, or email address, when
                            used to identify a particular individual.
                        </span>
                    </li>

                    <li>
                        <span>
                            The term <b>Non-Personal Information</b> means
                            information that does not personally identify you
                            and cannot presently be used to specifically
                            identify you.
                        </span>
                    </li>

                    <li>
                        <span>
                            The term <b>Information</b> means both Personal
                            Information and Non-Personal Information.
                        </span>
                    </li>

                    <li>
                        <span>
                            {' '}
                            <b>User</b> means all users of our Services.{' '}
                        </span>
                    </li>

                    <li>
                        <SmallHeader>
                            Who does this Privacy Policy apply to?
                        </SmallHeader>
                    </li>
                    <li>
                        <span>
                            {' '}
                            This Privacy Policy applies to users of the Services
                            and those who are viewing our website. Collectively
                            throughout this policy we may use the term “you” to
                            reference each type of user who interacts with us.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Information Collected</SmallHeader>
                    </li>
                    <li>
                        <span>
                            {' '}
                            <u>Non Personal</u>. We collect and store
                            Non-Personal Information about the Services,
                            including, for example, site referral information,
                            browsing patterns, technical information (e.g.,
                            browser type and version, downloads, and operating
                            system), and general usage information through
                            standard Internet protocols and logging. Like many
                            digital companies, we use common technologies such
                            as cookies to better understand users’ interactions
                            with the Services on an anonymized basis. You may
                            refuse the use of cookies by selecting the
                            appropriate settings in your browser. We may collect
                            standard internet log information and details of
                            visitor behavior patterns.
                        </span>
                    </li>
                    <li>
                        <span>
                            {' '}
                            <u>Personal Information</u>. We collect such
                            Personal Information which you provide to us with
                            voluntarily by inputting such information. For
                            example, if you do choose to set up an account, you
                            will need to input your name and email. We will
                            retain such Personal Information for purpose of
                            responding to your email or inquiry, informing you
                            about updates and changes to the Site, and otherwise
                            contacting you about our Services.
                        </span>
                    </li>
                    <li>
                        <span>
                            {' '}
                            <u>Personal Information via the Services</u>. For
                            User of the Services, you may input personal
                            information. We collect and retain the Personal
                            Information you provide in setting up your account
                            for the Services, including, an email address, first
                            name and last name and your company name. If you
                            sign up for paid Services, we receive a portion of
                            your payment information from our payment processor
                            (such as the last four digits, the country of
                            issuance and the expiration date of the payment
                            card) and we ask you to select your jurisdiction.
                        </span>
                        <span>
                            In addition, in using our Services, we directly and
                            indirectly collect other Personal Information
                            contained in your User Content. We record some of it
                            automatically when you use our Services. We host and
                            process such User Content in performing the
                            Services. Your User Content stays yours, and we will
                            only use it for the limited right that enable us to
                            provide and improve the Services. As a User Client,
                            collection and use of your User Content will be
                            covered in our agreement with you (whether by our
                            Terms of Service, separate contract or a data
                            addendum) and only used in accordance with those
                            permissions. We will not otherwise share or disclose
                            your User Content.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>
                            Additional Information about What type of Personal
                            Information We
                        </SmallHeader>
                        <ol
                            style={{
                                listStyleType: 'lower-alpha',
                                marginLeft: '20px',
                                maxWidth: '800px',
                            }}
                        >
                            <li>
                                Name, company name, and email address for
                                customers (or prospective customers) of our
                                Services. When using the website you may
                                voluntarily provide inputs as offered in an
                                online contact us form.
                            </li>
                            <li>
                                User Content, or the conversational data
                                associated with our User Clients who interact
                                with our Services. Conversational data may
                                include personal information including name,
                                email, company name, and generally details of
                                customers conversations.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>
                            The Services does not require you to enter any
                            information to view the website, however, in order
                            to access or otherwise utilize the Services you will
                            be asked to create an account. If you create an
                            account, certain limited information which generally
                            includes personal information will be required to
                            deliver the services to you. This information shall
                            be governed by this policy
                        </span>
                    </li>
                    <li>
                        <span>
                            Please note, that this Privacy Policy does not apply
                            to the privacy practices of third parties that
                            appear on or through the Services. We are not
                            responsible for the content or privacy practices of
                            third party providers, platforms or other users.{' '}
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Where We Collect Information</SmallHeader>
                    </li>
                    <li>
                        <ol
                            style={{
                                listStyleType: 'lower-alpha',
                                marginLeft: '20px',
                                maxWidth: '800px',
                            }}
                        >
                            <li>
                                <b>Registration Information</b>. In order to
                                utilize the Services you will need to set up an
                                account with us and we require certain
                                identifying information that will be linked to
                                your account, including your name and an email
                                address.
                            </li>
                            <li>
                                <b>Email Addresses</b>. We do not send spam,
                                sell or rent your email address information to
                                third parties. We do not disclose, sell, share,
                                trade or give away a user’s personal information
                                to third parties, except as needed for
                                completing payment and billing transactions
                                through the Services of payment processing
                                vendors, if applicable.
                            </li>
                            <li>
                                <b>Logging Statistics</b>. Like most website
                                operators, our servers automatically collect
                                certain types of non-personally-identifying,
                                technical information, such as the browser type,
                                language preference, referring site, and the
                                date and time of each visitor request.
                                <ul
                                    style={{
                                        listStyleType: 'disc',
                                        paddingLeft: '40px',
                                        maxWidth: '800px',
                                    }}
                                >
                                    <li>
                                        What website you came from to get here
                                    </li>
                                    <li>How long you stay for</li>
                                    <li>What kind of device you’re using</li>
                                </ul>
                                We use this information to better understand how
                                our visitors use our website, and to maintain
                                Services.
                            </li>
                            <li>
                                <b>Data Logging and Aggregation</b>. In addition
                                to the other uses described in this Policy, you
                                agree that we may extract and use information
                                from the information you disclose for the
                                purposes of logging and aggregating data in a
                                non-identifiable method. This aggregated data
                                may be used internally to improve services,
                                develop new models or without limitation, to
                                develop, analyze, combine, or publish the
                                aggregated data for commercial purposes.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <SmallHeader>The cookies we use</SmallHeader>
                        <span>
                            Like most websites, We use cookies for the operation
                            of our website. We use analytics cookies to help us
                            understand how visitors use our Services so we can
                            continue to improve it. We use a cookie to remember
                            your preferences. You can access and change your
                            preferences at any time by changing the settings in
                            your browser or declining to accept cookies when
                            first entering the Site. Essential cookies enable
                            the core functionality of our website such as
                            security, authentication, and accessibility, so if
                            you change your browser settings to disable these
                            cookies, it may affect your ability to access and
                            use the site.
                        </span>
                    </li>

                    <li>
                        <span>
                            The Company may also use a third party analytics
                            services, like Google Analytics, to analyze how
                            visitors interact with our Services so that we can
                            continue to develop and improve them. Google
                            Analytics uses cookies to collect information about
                            visitors to our site (data related to the
                            device/browser, IP address and on-site activity).
                            They measure and report on user interactions, such
                            as the volume and nature of visits to our site. This
                            Site may also use third party services to assist us
                            with marketing to previous visitors to our Site.
                            These re-marketing services use cookies or web
                            beacons to serve ads based on someone’s prior web
                            browsing history to then show ads and other
                            reminders that you visited. It requires collection
                            of information about your internet browsing, such as
                            your ISP, the browser you used to visit our Site and
                            whether you have Flash installed. Any such data
                            collected will be used in accordance with this
                            privacy policy.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Sharing Tools</SmallHeader>
                        <span>
                            This Site may use sharing tools that allow you to
                            share content through social networks such as
                            Facebook or Twitter. When you use one of these
                            buttons, the social networking site may place a
                            cookie on your computer. This would be a third-party
                            cookie set by the social networking site. Once you
                            enable this function with the social networking
                            site, this third party site may collect information
                            about you via cookies. If you have any questions
                            about the use of these third-party cookies, you
                            should check the relevant social networking site’s
                            cookie policy.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Questionnaires and Surveys</SmallHeader>
                        <span>
                            From time to time, we may ask you to complete
                            questionnaires or surveys, which we use to obtain
                            additional demographic information about our users,
                            to measure and improve the quality of our Services
                            and services and to better assess your interests and
                            needs. If we ask you to complete a survey or
                            questionnaire, we collect and retain that
                            Information and we will explain how we intend to use
                            the information that you provide to us. You always
                            have the option of not responding to any
                            questionnaire or survey.{' '}
                        </span>
                    </li>
                    <li>
                        <SmallHeader>
                            Use and Disclosure of Non Personal Information
                        </SmallHeader>
                        <span>
                            We use Non Personal Information to better understand
                            the use of the Services, to improve and revise the
                            Services and to track product interest and/or
                            downloads.
                        </span>
                    </li>

                    <li>
                        {' '}
                        <span>
                            We may share Non-Personal Information about our
                            Services with our business partners or the general
                            public. For example, we may share information
                            regarding the daily number of unique users to our
                            Site with potential corporate partners or others.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>
                            Use and Disclosure of Information (including
                            Personal Information)
                        </SmallHeader>
                    </li>

                    <li>
                        <span>
                            We may also share the Information we collect for the
                            following purposes:
                        </span>
                    </li>

                    <li>
                        <ol
                            style={{
                                paddingLeft: '40px',
                                display: 'grid',
                                gap: '10px',
                                maxWidth: '800px',
                            }}
                        >
                            <li>
                                Information may be disclosed to anyone who is
                                engaged to help us make the Services available
                                and functional, like technical agents, service
                                providers, other subcontractors, and our
                                affiliates and consultants. These functions
                                include, for example, web site hosting,
                                maintenance, data analysis, customer support,
                                email delivery services, infrastructure
                                services, technical agents, analytics and
                                metrics services. They will be engaged by
                                contract to ensure that they only use the
                                Information on our behalf and as permitted under
                                this Privacy Policy. We may also share with
                                Information our employees and contractors that
                                need to know that information in order to
                                process it on our behalf or to provide the
                                Services. Some of those employees, and
                                contractors may be located outside of your home
                                country; by using the Services you consent to
                                the transfer of such information to them.
                            </li>
                            <li>
                                We may disclose Information during due diligence
                                or in preparation for or after a sale, merger,
                                consolidation, change in control, transfer of
                                substantial assets, reorganization or
                                liquidation.
                            </li>
                            <li>
                                In the event that another company acquires all
                                or substantially all of the assets related to
                                the Site or Services or other business of the
                                Company, we reserve the right to include all
                                Information among the assets transferred to the
                                acquiring company.
                            </li>
                            <li>
                                Affiliates. We share personal information with
                                our affiliates when it is reasonably necessary
                                or desirable, such as to help provide services
                                to you or analyze and improve the services we or
                                they provide.
                            </li>
                            <li>
                                We may disclose the Information if required by
                                law or where we believe such action is necessary
                                in order to protect or defend our interests or
                                the interests of our users or business partners.
                                We may disclose Information to the extent
                                required by law, or if we have a good-faith
                                belief that we need to disclose it in order to
                                comply with official investigations or legal
                                proceedings (whether initiated by
                                governmental/law enforcement officials, or
                                private parties). If we have to disclose
                                Information to governmental/law enforcement
                                officials, we may not be able to ensure that
                                those officials will maintain the privacy and
                                security of any Information.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <SmallHeader>Children</SmallHeader>
                    </li>

                    <li>
                        <span>
                            Our Services are not directed towards children under
                            13 years of age and we do not knowingly collect any
                            Personal Information from children under age 13
                            through the Services.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Linked Internet Web Sites</SmallHeader>
                    </li>

                    <li>
                        <span>
                            The Site may contain hyperlinks that take you to a
                            third party website, document, file, or other
                            materials. These third party hyperlinked materials
                            may contain privacy provisions that are different
                            from those provided herein. We are not responsible
                            for the collection, use, or disclosure of any
                            Information collected through these third party
                            sites and services, and we expressly disclaim any
                            and all liability related to such collection, use,
                            or disclosure.{' '}
                        </span>
                    </li>

                    <li>
                        <SmallHeader>
                            PRIVACY NOTICE-CALIFORNIA CCPA
                        </SmallHeader>
                    </li>

                    <li>
                        <span>
                            This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS
                            supplements the information contained in this
                            Privacy Policy and applies to visitors and users of
                            this website who reside in the State of California (
                            <b>“consumers”</b> or <b>“you”</b>). For purposes of
                            this notice, we use the term “Personal Information”
                            as it is defined and used under the CCPA.
                            Information We Collect: the Personal Information we
                            collect from You is what you voluntarily submit to
                            us via our contact form (i.e. your email address) or
                            account set up information, and for User Clients, we
                            will also retain all User Content provided when you
                            use our Services. We use Personal Information that
                            you directly provide to respond to your request for
                            information or to enable your account set up, and in
                            the case of User Clients with respect to User
                            Content, to perform the Services. Sharing Personal
                            Information: We do not share with or sell Personal
                            Information to any third parties, except as set
                            forth in this Privacy Policy. Your Rights and
                            Choices: The CCPA provides consumers (California
                            residents) with specific rights (i.e. access, opt
                            out and deletion) regarding their Personal
                            Information. If at any time you wish to amend or
                            delete the Personal Information we hold about you,
                            you may contact us as set forth in the “Contact
                            Information” section below
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Legal Basis for Processing</SmallHeader>
                    </li>

                    <li>
                        <span>
                            Our legal bases for processing your Personal
                            Information include:
                        </span>
                    </li>

                    <li>
                        <ol
                            style={{
                                paddingLeft: '40px',
                                listStyleType: 'disc',
                                maxWidth: '800px',
                            }}
                        >
                            <li>
                                Performance of a contract with you when we
                                provide and maintain our Services. When we
                                process Account Information, Content, and
                                Technical Information solely to provide our
                                Services to you, this information is necessary
                                to be able to provide our Services. If you do
                                not provide this information, we may not be able
                                to provide our Services to you.
                            </li>
                            <li>
                                Our legitimate interests in protecting our
                                Services from abuse, fraud, or security risks,
                                or in developing, improving, or promoting our
                                Services, including when we train our models.
                                This may include the processing of Account
                                Information, Content, Social Information, and
                                Technical Information. Read&nbsp;
                                <a
                                    href="https://help.openai.com/en/articles/5722486-how-your-data-is-used-to-improve-model-performance"
                                    style={{ color: 'black' }}
                                >
                                    our instructions
                                </a>{' '}
                                on how you can opt out of our use of your
                                information to train our models.
                            </li>
                            <li>
                                Your consent when we ask for your consent to
                                process your Personal Information for a specific
                                purpose that we communicate to you. You have the
                                right to withdraw your consent at any time.
                            </li>
                            <li>
                                Compliance with our legal obligations when we
                                use your Personal Information to comply with
                                applicable law or when we protect our or our
                                affiliates’, users’, or third parties’ rights,
                                safety, and property.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <SmallHeader>Data Transfers.</SmallHeader>
                    </li>

                    <li>
                        <span>
                            Where required, we will use appropriate safeguards
                            for transferring Personal Information outside of
                            certain countries. We will only transfer Personal
                            Information pursuant to a legally valid transfer
                            mechanism.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>General</SmallHeader>
                    </li>

                    <li>
                        <span>
                            We reserve the right to change this Privacy Policy
                            at any time and will do so by posting changes to
                            this Privacy Policy.{' '}
                        </span>
                    </li>

                    <li>
                        <span>
                            The Services are hosted in the United States and is
                            intended for and directed to users in the United
                            States. If you are located outside of the United
                            States, please be advised that we process and store
                            information in the United States and your use of the
                            Site or Services constitutes your consent to and
                            understanding of this processing.
                        </span>
                    </li>

                    <li>
                        <span>
                            Information that we collect is stored on servers
                            that we manage, using reasonable administrative,
                            technical and physical safeguards designed to
                            protect against unauthorized access or disclosure of
                            data. Please be aware that no data transmission over
                            the Internet is guaranteed to be 100% secure.
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Contact Information</SmallHeader>
                    </li>

                    <li>
                        <span>
                            If you have any questions about the Services, or
                            this Policy, or if you would like us to delete the
                            Information, please contact us at&nbsp;
                            <a href="mailto:privacy@subi.ai">privacy@subi.ai</a>
                            .
                        </span>
                    </li>

                    <li>
                        <SmallHeader>Location</SmallHeader>
                    </li>

                    <li>
                        <span>
                            The Services are operated by SubiAI, Inc. in the
                            United States located at 130 W. Union St., Pasadena,
                            CA 91103.{' '}
                        </span>
                    </li>

                    <li>
                        <SmallHeader>DATED: May 1, 2024</SmallHeader>
                    </li>
                </ol>
            </div>
        </>
    );
};

const MainHeader = styled.div`
    font-weight: 600;
    font-size: 28px;
    text-align: left;
`;

const SmallHeader = styled.div`
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
`;

const Logo = ({ dark }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="86"
            height="25"
            viewBox="0 0 86 25"
            fill="none"
        >
            <path
                d="M2.20128 14.7011C2.09136 14.5938 1.91609 14.5938 1.80618 14.7011C0.641668 15.8405 0 17.3556 0 18.9691C0 20.5827 0.641668 22.0978 1.80618 23.2371C3.00633 24.4122 4.5808 24.9998 6.15823 24.9998C7.73566 24.9998 9.31013 24.4122 10.5103 23.2371C10.5638 23.1834 10.5964 23.1089 10.5964 23.0343C10.5964 22.9597 10.5667 22.8852 10.5103 22.8315L2.20128 14.7011Z"
                fill="#EB0A74"
            />
            <path
                d="M1.80618 1.76006C0.641668 2.90237 0 4.41749 0 6.03104C0 7.64458 0.641668 9.15971 1.80618 10.299C1.85965 10.3527 1.93392 10.3796 2.00224 10.3796C2.07057 10.3796 2.14483 10.3527 2.19831 10.299L10.5073 2.16867C10.5608 2.11498 10.5935 2.04042 10.5935 1.96586C10.5935 1.89129 10.5638 1.81673 10.5073 1.76304C8.10997 -0.587184 4.20649 -0.587184 1.80618 1.76006Z"
                fill="#EB0A74"
            />
            <path
                d="M23.3971 14.7011L15.0881 22.8315C15.0346 22.8852 15.002 22.9597 15.002 23.0343C15.002 23.1089 15.0317 23.1834 15.0881 23.2371C16.2883 24.4122 17.8627 24.9968 19.4402 24.9968C21.0176 24.9968 22.5921 24.4093 23.7922 23.2371C24.9567 22.0978 25.5984 20.5827 25.5984 18.9691C25.5984 17.3556 24.9567 15.8405 23.7922 14.7011C23.6823 14.5938 23.507 14.5938 23.3971 14.7011Z"
                fill="#EB0A74"
            />
            <path
                d="M23.3971 10.2989C23.4506 10.3525 23.5219 10.3794 23.5932 10.3794C23.6645 10.3794 23.7358 10.3525 23.7892 10.2989C24.9537 9.15954 25.5954 7.64441 25.5954 6.03087C25.5954 4.41732 24.9537 2.9022 23.7892 1.76288C21.3889 -0.58437 17.4854 -0.58437 15.0881 1.76288C15.0346 1.81656 15.002 1.89113 15.002 1.96569C15.002 2.04025 15.0317 2.11481 15.0881 2.1685L23.3971 10.2989Z"
                fill="#EB0A74"
            />
            <path
                d="M47.7835 13.7938C47.5815 13.4448 47.2844 13.1048 46.9041 12.7767C46.5298 12.4576 46.1971 12.2041 45.906 12.0162C45.6119 11.8253 45.193 11.6195 44.6642 11.4048C44.1384 11.193 43.7196 11.032 43.4136 10.9306C43.1106 10.8292 42.665 10.686 42.0738 10.5011C40.6687 10.0686 39.7121 9.66299 39.2279 9.3021C38.7704 8.95911 38.5476 8.52665 38.5476 7.97488C38.5476 7.4589 38.7437 7.0503 39.1506 6.72818C39.5636 6.39712 40.1488 6.2301 40.8915 6.2301C42.5135 6.2301 43.6661 7.00854 44.4088 8.60717C44.4296 8.65489 44.4711 8.69068 44.5216 8.70858C44.5721 8.72349 44.6286 8.71753 44.6732 8.69068L47.8666 6.83854C47.9528 6.78783 47.9855 6.68046 47.9409 6.59099C47.2695 5.20411 46.3159 4.10356 45.1069 3.32214C43.8978 2.54072 42.4778 2.14404 40.8915 2.14404C39.112 2.14404 37.5673 2.68388 36.2988 3.74864C35.0214 4.82235 34.3738 6.28081 34.3738 8.07927C34.3738 8.98894 34.5372 9.7972 34.861 10.4802C35.1848 11.1692 35.6631 11.7478 36.2839 12.1981C36.887 12.6366 37.5019 12.9915 38.1079 13.2539C38.708 13.5134 39.4507 13.7759 40.3181 14.0354C41.851 14.4708 42.9294 14.8764 43.5176 15.2433C44.0701 15.5863 44.3375 16.0545 44.3375 16.6719C44.3375 17.2058 44.1176 17.6353 43.6661 17.9872C43.2086 18.3451 42.46 18.5271 41.444 18.5271C39.3081 18.5271 37.9178 17.5995 37.19 15.6937C37.1692 15.6429 37.1276 15.6012 37.0771 15.5833C37.0266 15.5654 36.9672 15.5714 36.9197 15.5982L33.6727 17.504C33.5955 17.5488 33.5598 17.6472 33.5895 17.7307C34.1332 19.2369 35.0838 20.4448 36.4146 21.3187C37.7425 22.1926 39.3972 22.634 41.3341 22.634C43.4403 22.634 45.1811 22.0912 46.5031 21.0175C47.8369 19.9348 48.5142 18.4525 48.5142 16.6153C48.5142 16.0486 48.443 15.5147 48.3004 15.0285C48.1578 14.5454 47.9855 14.1278 47.7864 13.7848L47.7835 13.7938Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M62.9875 8.06445H59.4375C59.3336 8.06445 59.2504 8.14796 59.2504 8.25235V15.7116C59.2504 16.7973 58.9919 17.6175 58.478 18.1484C57.9671 18.6793 57.2719 18.9477 56.4134 18.9477C55.641 18.9477 55.0201 18.721 54.5627 18.2707C54.1081 17.8233 53.8764 17.1582 53.8764 16.2932V8.25235C53.8764 8.14796 53.7932 8.06445 53.6893 8.06445H50.1393C50.0353 8.06445 49.9521 8.14796 49.9521 8.25235V16.7347C49.9521 18.5629 50.4661 20.0214 51.4791 21.0653C52.4921 22.1121 53.7932 22.643 55.3439 22.643C57.0432 22.643 58.3562 22.1181 59.2563 21.0772V22.0674C59.2563 22.1718 59.3395 22.2553 59.4435 22.2553H62.9935C63.0974 22.2553 63.1806 22.1718 63.1806 22.0674V8.25235C63.1806 8.14796 63.0974 8.06445 62.9935 8.06445H62.9875Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M73.6433 7.67652C71.8639 7.67652 70.4291 8.24619 69.3745 9.3706V2.91641C69.3745 2.81203 69.2913 2.72852 69.1873 2.72852H65.6373C65.5334 2.72852 65.4502 2.81203 65.4502 2.91641V22.0672C65.4502 22.1716 65.5334 22.2551 65.6373 22.2551H69.1873C69.2913 22.2551 69.3745 22.1716 69.3745 22.0672V20.9488C70.4291 22.0732 71.8639 22.6428 73.6433 22.6428C75.5416 22.6428 77.1844 21.9121 78.5271 20.4686C79.8669 19.031 80.5442 17.2445 80.5442 15.1597C80.5442 13.0749 79.864 11.2884 78.5271 9.85078C77.1844 8.40724 75.5416 7.67652 73.6433 7.67652ZM72.9809 11.4524C74.0236 11.4524 74.8999 11.8014 75.5862 12.4873C76.2694 13.1763 76.617 14.074 76.617 15.1567C76.617 16.2394 76.2694 17.1401 75.5862 17.8261C74.9029 18.515 74.0266 18.861 72.9809 18.861C71.9352 18.861 71.0648 18.512 70.3904 17.8261C69.7131 17.1401 69.3715 16.2394 69.3715 15.1537C69.3715 14.0681 69.7131 13.1703 70.3904 12.4814C71.0648 11.7954 71.9382 11.4464 72.9809 11.4464V11.4524Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M82.2614 8.06445H85.8113C85.9153 8.06445 85.9985 8.14796 85.9985 8.25235V22.0674C85.9985 22.1718 85.9153 22.2553 85.8113 22.2553H82.2614C82.1574 22.2553 82.0742 22.1718 82.0742 22.0674V8.25235C82.0742 8.14796 82.1574 8.06445 82.2614 8.06445Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M85.8114 2.76416H82.2584C82.1551 2.76416 82.0713 2.84829 82.0713 2.95206V5.41562C82.0713 5.5194 82.1551 5.60352 82.2584 5.60352H85.8114C85.9147 5.60352 85.9985 5.5194 85.9985 5.41562V2.95206C85.9985 2.84829 85.9147 2.76416 85.8114 2.76416Z"
                fill={dark ? 'white ' : '#141718'}
            />
        </svg>
    );
};

const LogoSmall = ({ dark }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69"
            height="20"
            viewBox="0 0 69 20"
            fill="none"
        >
            <path
                d="M1.76102 11.761C1.67309 11.6751 1.53287 11.6751 1.44494 11.761C0.513334 12.6724 0 13.8845 0 15.1753C0 16.4662 0.513334 17.6783 1.44494 18.5897C2.40507 19.5298 3.66464 19.9999 4.92658 19.9999C6.18853 19.9999 7.4481 19.5298 8.40823 18.5897C8.451 18.5468 8.47715 18.4871 8.47715 18.4275C8.47715 18.3678 8.45338 18.3082 8.40823 18.2652L1.76102 11.761Z"
                fill="#EB0A74"
            />
            <path
                d="M1.44519 1.40805C0.513578 2.3219 0.000244141 3.53399 0.000244141 4.82483C0.000244141 6.11567 0.513578 7.32776 1.44519 8.23922C1.48796 8.28217 1.54738 8.30365 1.60204 8.30365C1.6567 8.30365 1.71611 8.28217 1.75889 8.23922L8.40609 1.73493C8.44887 1.69199 8.47501 1.63234 8.47501 1.57269C8.47501 1.51303 8.45125 1.45338 8.40609 1.41044C6.48822 -0.469747 3.36544 -0.469747 1.44519 1.40805Z"
                fill="#EB0A74"
            />
            <path
                d="M18.7177 11.761L12.0705 18.2652C12.0277 18.3082 12.0016 18.3678 12.0016 18.4275C12.0016 18.4871 12.0254 18.5468 12.0705 18.5897C13.0306 19.5298 14.2902 19.9975 15.5521 19.9975C16.8141 19.9975 18.0737 19.5275 19.0338 18.5897C19.9654 17.6783 20.4787 16.4662 20.4787 15.1753C20.4787 13.8845 19.9654 12.6724 19.0338 11.761C18.9459 11.6751 18.8056 11.6751 18.7177 11.761Z"
                fill="#EB0A74"
            />
            <path
                d="M18.7177 8.23933C18.7605 8.28228 18.8175 8.30376 18.8746 8.30376C18.9316 8.30376 18.9886 8.28228 19.0314 8.23933C19.963 7.32787 20.4764 6.11578 20.4764 4.82494C20.4764 3.5341 19.963 2.322 19.0314 1.41055C17.1112 -0.467252 13.9884 -0.467252 12.0705 1.41055C12.0277 1.45349 12.0016 1.51314 12.0016 1.57279C12.0016 1.63245 12.0254 1.6921 12.0705 1.73504L18.7177 8.23933Z"
                fill="#EB0A74"
            />
            <path
                d="M38.2268 11.0349C38.0652 10.7557 37.8276 10.4837 37.5234 10.2212C37.2239 9.96594 36.9578 9.76313 36.7248 9.61281C36.4896 9.46011 36.1545 9.29547 35.7315 9.12368C35.3108 8.95427 34.9757 8.82543 34.7309 8.7443C34.4885 8.66318 34.132 8.54865 33.6591 8.40072C32.535 8.05474 31.7697 7.73024 31.3824 7.44153C31.0164 7.16714 30.8381 6.82117 30.8381 6.37976C30.8381 5.96697 30.995 5.64009 31.3206 5.3824C31.6509 5.11755 32.1191 4.98393 32.7132 4.98393C34.0108 4.98393 34.9329 5.60669 35.5271 6.88559C35.5437 6.92377 35.577 6.9524 35.6174 6.96672C35.6578 6.97865 35.7029 6.97388 35.7386 6.9524L38.2934 5.47068C38.3623 5.43012 38.3884 5.34422 38.3528 5.27264C37.8157 4.16314 37.0528 3.2827 36.0856 2.65757C35.1183 2.03243 33.9823 1.71509 32.7132 1.71509C31.2897 1.71509 30.0539 2.14696 29.0391 2.99877C28.0172 3.85773 27.4991 5.0245 27.4991 6.46327C27.4991 7.191 27.6298 7.83761 27.8888 8.38401C28.1479 8.93518 28.5305 9.39807 29.0272 9.75836C29.5097 10.1091 30.0016 10.393 30.4864 10.603C30.9665 10.8106 31.5606 11.0206 32.2546 11.2281C33.4809 11.5765 34.3436 11.901 34.8141 12.1945C35.2561 12.4689 35.47 12.8435 35.47 13.3374C35.47 13.7645 35.2942 14.1081 34.9329 14.3896C34.5669 14.6759 33.9681 14.8215 33.1553 14.8215C31.4465 14.8215 30.3343 14.0794 29.7521 12.5548C29.7354 12.5142 29.7022 12.4808 29.6617 12.4665C29.6213 12.4522 29.5738 12.4569 29.5358 12.4784L26.9382 14.0031C26.8764 14.0389 26.8479 14.1176 26.8717 14.1844C27.3066 15.3894 28.0671 16.3557 29.1318 17.0548C30.1941 17.7539 31.5178 18.107 33.0673 18.107C34.7523 18.107 36.145 17.6728 37.2025 16.8138C38.2696 15.9477 38.8115 14.7618 38.8115 13.2921C38.8115 12.8387 38.7544 12.4116 38.6403 12.0227C38.5263 11.6362 38.3884 11.3021 38.2292 11.0277L38.2268 11.0349Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M50.39 6.45142H47.5501C47.4669 6.45142 47.4003 6.51822 47.4003 6.60174V12.5692C47.4003 13.4377 47.1936 14.0938 46.7824 14.5185C46.3737 14.9433 45.8175 15.158 45.1307 15.158C44.5128 15.158 44.0161 14.9767 43.6501 14.6164C43.2865 14.2585 43.1012 13.7264 43.1012 13.0344V6.60174C43.1012 6.51822 43.0346 6.45142 42.9514 6.45142H40.1115C40.0283 6.45142 39.9617 6.51822 39.9617 6.60174V13.3876C39.9617 14.8502 40.3729 16.017 41.1833 16.8521C41.9937 17.6896 43.0346 18.1143 44.2752 18.1143C45.6346 18.1143 46.685 17.6943 47.4051 16.8616V17.6538C47.4051 17.7373 47.4716 17.8041 47.5548 17.8041H50.3948C50.478 17.8041 50.5445 17.7373 50.5445 17.6538V6.60174C50.5445 6.51822 50.478 6.45142 50.3948 6.45142H50.39Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M58.9147 6.14102C57.4911 6.14102 56.3433 6.59675 55.4996 7.49628V2.33294C55.4996 2.24943 55.433 2.18262 55.3499 2.18262H52.5099C52.4267 2.18262 52.3602 2.24943 52.3602 2.33294V17.6536C52.3602 17.7371 52.4267 17.8039 52.5099 17.8039H55.3499C55.433 17.8039 55.4996 17.7371 55.4996 17.6536V16.7588C56.3433 17.6583 57.4911 18.1141 58.9147 18.1141C60.4333 18.1141 61.7475 17.5295 62.8217 16.3747C63.8936 15.2246 64.4354 13.7954 64.4354 12.1275C64.4354 10.4597 63.8912 9.03049 62.8217 7.88043C61.7475 6.7256 60.4333 6.14102 58.9147 6.14102ZM58.3847 9.16172C59.2189 9.16172 59.92 9.44089 60.4689 9.98967C61.0156 10.5408 61.2936 11.259 61.2936 12.1252C61.2936 12.9913 61.0156 13.7119 60.4689 14.2607C59.9223 14.8118 59.2213 15.0886 58.3847 15.0886C57.5482 15.0886 56.8518 14.8094 56.3124 14.2607C55.7705 13.7119 55.4972 12.9913 55.4972 12.1228C55.4972 11.2543 55.7705 10.5361 56.3124 9.9849C56.8518 9.43612 57.5505 9.15695 58.3847 9.15695V9.16172Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M65.809 6.45142H68.649C68.7322 6.45142 68.7987 6.51822 68.7987 6.60174V17.6538C68.7987 17.7373 68.7322 17.8041 68.649 17.8041H65.809C65.7258 17.8041 65.6593 17.7373 65.6593 17.6538V6.60174C65.6593 6.51822 65.7258 6.45142 65.809 6.45142Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M68.649 2.21143H65.8066C65.724 2.21143 65.6569 2.27873 65.6569 2.36175V4.3326C65.6569 4.41562 65.724 4.48292 65.8066 4.48292H68.649C68.7317 4.48292 68.7987 4.41562 68.7987 4.3326V2.36175C68.7987 2.27873 68.7317 2.21143 68.649 2.21143Z"
                fill={dark ? 'white ' : '#141718'}
            />
        </svg>
    );
};
