import * as React from 'react';
import { useContext } from 'react';
import ChatInput from '@/pages/chat/ChatInput';
import ChatUpload from '@/pages/chat/ChatUpload';
import { GeneralContext } from '@/context/GeneralContext';
import useUser from '@/context/useUser';

const ChatInputMenu = ({
    activeInput,
    setActiveInput,
    inputMessageText,
    setInputMessageText,
    sendMessage,
    active,
}) => {
    const context = useContext(GeneralContext);
    const { subscriptions } = useUser();
    const renderSwitch = param => {
        switch (param) {
            case 'text':
                return (
                    <ChatInput
                        setActiveInput={setActiveInput}
                        inputMessageText={inputMessageText}
                        setInputMessageText={setInputMessageText}
                        sendMessage={sendMessage}
                        active={active}
                    />
                );
            case 'files':
                return (
                    <ChatUpload
                        setActiveInput={setActiveInput}
                        activeInput={activeInput}
                    />
                );
            default:
                return 'foo';
        }
    };

    return (
        <div className="flex items-center flex-col p-5 gap-5 md:rounded-b-xl select-none">
            {renderSwitch(activeInput)}
            {(subscriptions?.subscription_tier === 'trial' ||
                subscriptions === null) && (
                <div className="font-medium text-black dark:text-white">
                    {'Your free trial is ending soon!\xa0\xa0\xa0'}
                    <a
                        onClick={() => context.setOpenModal('paywall')}
                        className="no-underline cursor-pointer inline-flex flex-row gap-2 items-center hover:text-accentHover dark:hover:text-accentLightHover dark:text-accentLight"
                    >
                        View Plans
                        <div style={{ paddingBottom: '3px' }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                            >
                                <path
                                    d="M13.8536 4.35355C14.0488 4.15829 14.0488 3.84171 13.8536 3.64645L10.6716 0.464466C10.4763 0.269204 10.1597 0.269204 9.96447 0.464466C9.7692 0.659728 9.7692 0.976311 9.96447 1.17157L12.7929 4L9.96447 6.82843C9.7692 7.02369 9.7692 7.34027 9.96447 7.53553C10.1597 7.7308 10.4763 7.7308 10.6716 7.53553L13.8536 4.35355ZM0.5 4.5H13.5V3.5H0.5V4.5Z"
                                    fill="#EB0A74"
                                />
                            </svg>
                        </div>
                    </a>
                </div>
            )}
        </div>
    );
};

export default ChatInputMenu;
