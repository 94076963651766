import React from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children, fallback, is }) => {
    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <Sentry.ErrorBoundary
                    fallback={({ error, componentStack, resetError }) =>
                        fallback(error, componentStack, reset, resetError)
                    }
                >
                    {children}
                </Sentry.ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    );
};

export const AppFallback = (
    error: Error,
    componentStack: string,
    reset: () => void,
    resetError: () => void,
) => {
    console.log(error);
    const storedTheme = localStorage.getItem('theme');
    let theme = 'dark';
    if (storedTheme) {
        theme = storedTheme;
    } else {
        const prefersDark = window.matchMedia(
            '(prefers-color-scheme: dark)',
        ).matches;
        if (prefersDark) {
            theme = 'dark';
        } else {
            theme = 'light';
        }
    }
    return (
        <div
            className={`flex flex-col bg-${theme === 'dark' ? 'black' : 'white'} text-${theme === 'dark' ? 'white' : 'black'} items-center justify-center w-screen h-screen gap-10`}
        >
            <ErrorSVG />
            <div className="flex flex-col gap-4 items-center">
                <div className="text-lg text-black dark:text-white">
                    {error.message}
                </div>
                <button
                    style={{ lineHeight: '100%' }}
                    className="rounded-2xl text-accent border-solid border-accent border px-2.5 py-1.5 font-medium"
                    onClick={() => {
                        reset();
                        resetError();
                    }}
                >
                    Reload
                </button>
            </div>
        </div>
    );
};

export const MessageErrorBoundary = ({ children, fallback, isHuman }) => {
    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <Sentry.ErrorBoundary
                    fallback={({ error, componentStack, resetError }) => (
                        <div
                            className={`sticky flex flex-col z-0 max-w-[80%] rounded-sm      ${
                                isHuman ? 'self-end' : 'self-start'
                            } dark:text-white text-black text-base`}
                        >
                            There was an error displaying this message
                        </div>
                    )}
                >
                    {children}
                </Sentry.ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    );
};
export default ErrorBoundary;

const ErrorSVG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="84"
        viewBox="0 0 85 84"
        fill="none"
    >
        <g clipPath="url(#clip0_3078_2218)">
            <path
                d="M39.205 20.1C39.205 9.54029 30.6447 0.979969 20.085 0.979969C9.52528 0.979969 0.964966 9.54029 0.964966 20.1C0.964966 30.6597 9.52528 39.22 20.085 39.22C30.6447 39.22 39.205 30.6597 39.205 20.1Z"
                fill="#EA0973"
            />
            <path
                d="M84.1149 20.1C84.1149 9.54029 75.5546 0.979969 64.9949 0.979969C54.4353 0.979969 45.8749 9.54029 45.8749 20.1C45.8749 30.6597 54.4353 39.22 64.9949 39.22C75.5546 39.22 84.1149 30.6597 84.1149 20.1Z"
                fill="#822357"
            />
            <path
                d="M39.205 63.9C39.205 53.3403 30.6447 44.78 20.085 44.78C9.52528 44.78 0.964966 53.3403 0.964966 63.9C0.964966 74.4597 9.52528 83.02 20.085 83.02C30.6447 83.02 39.205 74.4597 39.205 63.9Z"
                fill="#822357"
            />
            <path
                d="M84.1149 63.9C84.1149 53.3403 75.5546 44.78 64.9949 44.78C54.4353 44.78 45.8749 53.3403 45.8749 63.9C45.8749 74.4597 54.4353 83.02 64.9949 83.02C75.5546 83.02 84.1149 74.4597 84.1149 63.9Z"
                fill="#EA0973"
            />
        </g>
        <defs>
            <clipPath id="clip0_3078_2218">
                <rect
                    width="84"
                    height="84"
                    fill="white"
                    transform="matrix(0 -1 1 0 0.5 84)"
                />
            </clipPath>
        </defs>
    </svg>
);
