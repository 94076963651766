import * as Styles from '../../styles/ChatStyles';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { NavArrowDown } from 'iconoir-react';
import Message, { LoadingMessage } from './Message';
import { GeneralContext } from '../../context/GeneralContext';
import FeedbackModal from '../modals/FeedbackModal';
import axiosWithRetry from '../../util/axiosRetry';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import FileList from '../../components/FileList';
import { useUser, useUserStateOnly } from '../../context/useUser';
import { getChatIdFromUrl } from '../../util/utils';
import ChatInputMenu from '@/pages/chat/ChatInputMenu';
import { MessageErrorBoundary } from '@/util/ErrorBoundary';

type MenuOption = 'text' | 'files' | 'services' | 'portfolio' | 'contact';

const ChatHistory = ({
    tool,
    tool_name,
    sendMessage,
    messages,
    files,
    generating,
    setGenerating,
    details,
    setDetails,
    thinking,
    setThinking,
    guestOwner,
}) => {
    const historyWrapperRef = useRef(null);
    const { user } = useUserStateOnly();
    const [showButton, setShowButton] = useState(false);
    const [inputMessageText, setInputMessageText] = useState('');
    const context = useContext(GeneralContext);
    const isMobile = context.width < 769;
    const location = useLocation();

    const isRequest = location.pathname.includes('/request/');

    const conversationId = getChatIdFromUrl();
    const [activeInput, setActiveInput] = useState<MenuOption>('text');
    const [debouncedShowButton, setDebouncedShowButton] = useDebounce(
        showButton,
        150,
    );
    const handleSend = msg => {
        setTimeout(() => {
            scrollToBottom();
        }, 10);

        sendMessage(msg);

        // TODO: Let's do this optimistically, but let's store it in case the sendMessage fails
        // May be nice to store draft messages per conversation regardless, in local storage, so
        // nothing is lost. Right now we don't watch for failed sendMessage so have to set to "".
        setInputMessageText('');
    };

    let qr = generateQuickReplies();

    function generateQuickReplies() {
        let quickReplies =
            messages?.pages?.[0]
                .at(-1)
                ?.meta_data?.quick_replies?.filter((qr, i) => {
                    return (
                        qr !== '' &&
                        !(i === 0 && qr.charAt(qr.length - 1) === ':')
                    );
                }) ?? [];
        if (conversationId === false) {
            quickReplies.push('Add Subi to my phone');
            quickReplies.push('Add Subi to my email');
            quickReplies.push('Add Subi to my calendar');
            quickReplies.push('Got feedback?');
            // quickReplies.push('Add a contact');
            // quickReplies.push('Learn my preferences');
            // if (context.width < 768) {
            //     quickReplies.push('Take me to my dashboard.');
            // }
        }
        return quickReplies;
    }

    useEffect(() => {
        if (debouncedShowButton === false) {
            scrollToBottom();
        }
    }, [messages, thinking, generating]);

    // ============================================================
    //Scroll magic

    function scrollToBottom(click = false) {
        if (historyWrapperRef.current) {
            const scrollableElement = historyWrapperRef.current;
            if (click === false) {
                scrollableElement.style.scrollBehavior = 'auto';
            }
            scrollableElement.scrollTop = scrollableElement.scrollHeight;
            if (click === true) {
                scrollableElement.style.scrollBehavior = 'smooth';
            }
        }
    }

    const handleScroll = e => {
        if (historyWrapperRef.current) {
            const container = historyWrapperRef.current;
            if (
                container.scrollHeight - container.scrollTop <=
                container.clientHeight + 30
            ) {
                setShowButton(false);
                return false;
            } else {
                setShowButton(true);
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        const container = historyWrapperRef.current;

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    return (
        <>
            <Styles.HistoryWrapper ref={historyWrapperRef}>
                <>
                    {isRequest && details ? (
                        <ChatDetail files={files} />
                    ) : (
                        <>
                            {conversationId === false ? (
                                <Intro guestOwner={guestOwner} />
                            ) : (
                                messages && (
                                    <MessageList
                                        messages={messages}
                                        thinking={thinking}
                                        tool={tool}
                                        tool_name={tool_name}
                                    />
                                )
                            )}
                            <SuggestedMessages
                                sendMessage={sendMessage}
                                quickReplies={qr}
                            />
                            {conversationId && activeInput === 'text' && (
                                <ChatUtilBar
                                    setThinking={setThinking}
                                    setGenerating={setGenerating}
                                    conversationId={conversationId}
                                    thinking={thinking}
                                    generating={generating}
                                    showButton={debouncedShowButton}
                                    scrollToBottom={scrollToBottom}
                                />
                            )}
                        </>
                    )}
                </>
            </Styles.HistoryWrapper>
            {!(details && isRequest) && (
                <ChatInputMenu
                    activeInput={activeInput}
                    setActiveInput={setActiveInput}
                    setInputMessageText={setInputMessageText}
                    inputMessageText={inputMessageText}
                    sendMessage={handleSend}
                    active={generating || thinking}
                />
            )}
        </>
    );
};

export default ChatHistory;

const ChatDetail = ({ files }) => {
    const context = useContext(GeneralContext);
    return (
        <div
            className="flex flex-col gap-6 "
            style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                paddingTop: '5px',
            }}
        >
            {files && files.length > 0 && (
                <div className="flex flex-col gap-2.5">
                    <div
                        style={{
                            fontSize: '13px',
                            color: context.dark ? 'white' : '#141718',
                            opacity: 0.4,
                        }}
                    >
                        {`FILES (${files.length})`}
                    </div>
                    <FileList files={files} />
                </div>
            )}
            <button
                style={{
                    width: '142px',
                    paddingTop: '7px',
                    paddingBottom: '5px',
                    color:
                        context.dark === true
                            ? 'rgba(255, 255, 255, 0.50)'
                            : 'rgba(20, 23, 24, 0.60)',
                    background:
                        context.dark === false && 'rgba(20, 23, 24, 0.10)',
                }}
                className="bg-blackInput text-white px-3 font-medium rounded-3xl"
                onClick={() => context.setDeleteOpen(getChatIdFromUrl())}
            >
                Delete Request
            </button>
        </div>
    );
};
const Intro = ({ guestOwner }) => {
    const { user } = useUser();

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    let introMessage;

    // user.display_name starts with guest- and ends with "@subi-guest.ai"
    if (guestOwner && guestOwner.display_name) {
        // don't add an "'s" if the user.owner_display_name ends in an "s"
        const owner_display_name_possessive = guestOwner.display_name.endsWith(
            's',
        )
            ? `${guestOwner.display_name}'`
            : `${guestOwner.display_name}'s`;

        introMessage = `Welcome! I'm ${owner_display_name_possessive} Subi. How can I help you today?`;
    } else {
        introMessage = ``; // "I am your Subi. Let's get started."
        // introMessage = `How can I help, ${user.display_name}?`;
    }

    const [data, setData] = useState({
        'Make a phone call': 'done',
        'Send an email': 'pending',
        'Update your calendar': 'pending',
        // 'Add your contacts': 'pending',
        // 'Learn your preferences': 'pending',
    });

    return (
        <div className="flex flex-col gap-2 p-3 text-black dark:text-white text-[24px] font-semibold">
            <div
                className={`leading-[130%] text-[28px] tracking-[-0.28px] ${
                    isMounted ? 'opacity-100' : 'opacity-0'
                } transition-opacity duration-500 ease-in-out select-none
               `}
            >
                {introMessage}
            </div>

            {/*{Object.keys(data).map(*/}
            {/*    label =>*/}
            {/*        data[label] !== 'skipped' && (*/}
            {/*            <div key={label} style={{ marginBottom: '8px' }}>*/}
            {/*                <label>*/}
            {/*                    <i*/}
            {/*                        style={{*/}
            {/*                            display: 'inline-flex',*/}
            {/*                            gap: '10px',*/}
            {/*                            alignItems: 'center',*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        /!*<Checkbox check={data[label] === 'done'} />*!/*/}
            {/*                        <s>*/}
            {/*                            <div className="pt-1.5">{label}</div>*/}
            {/*                        </s>*/}
            {/*                    </i>*/}
            {/*                </label>*/}
            {/*            </div>*/}
            {/*        ),*/}
            {/*)}*/}
        </div>
    );
};
const ChatUtilBar = ({
    setThinking,
    setGenerating,
    conversationId,
    generating,
    thinking,
    scrollToBottom,
    showButton,
}) => {
    const context = useContext(GeneralContext);

    const stopConversation = async e => {
        e.stopPropagation();

        console.log('Sending stop request');

        axiosWithRetry
            .post(`/conversation/${conversationId}/stop`, {
                conversation_id: conversationId,
            })
            .then((response: any) => {
                setGenerating(false);
                setThinking(false);
                console.debug('Stop response', response);
                // TODO: remove any partially generated message from the chat history
            })
            .catch(e => {
                console.error('There was an error stopping chat', e);
            });
    };

    return (
        <Styles.ChatUtilBar
            style={{
                justifyContent: !showButton && 'center',
            }}
        >
            {showButton === true && <div style={{ width: '42px' }} />}
            <div style={{ zIndex: '999999' }}>
                {(generating === true || thinking === true) && (
                    <Styles.GeneratingButton
                        dark={context.dark}
                        onClick={stopConversation}
                        id="stop-working-botton"
                    >
                        <div style={{ paddingTop: '2px' }}>Stop Working</div>
                    </Styles.GeneratingButton>
                )}
            </div>
            {showButton === true && (
                <Styles.ScrollToBottom
                    style={{
                        background: context.dark && '#3C4041',
                    }}
                    onClick={() => {
                        scrollToBottom(true);
                    }}
                >
                    <NavArrowDown width="17px" height="17px" />
                </Styles.ScrollToBottom>
            )}
        </Styles.ChatUtilBar>
    );
};

const MessageList = ({ messages, tool_name, thinking, tool }) => {
    const [displayFeedback, setDisplayFeedback] = useState(true);
    const [currentMsg, setCurrentMsg] = useState('');

    let allMessages = messages;
    if (messages && messages.pages) {
        allMessages = messages.pages.reduce(
            (acc, page) => [...acc, ...page],
            [],
        );
    }
    return (
        <>
            {displayFeedback === true && currentMsg !== '' && (
                <FeedbackModal
                    setDisplayFeedback={setDisplayFeedback}
                    setMsgId={setCurrentMsg}
                    msgId={currentMsg}
                />
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                }}
            >
                {allMessages
                    .sort(
                        (a, b) => moment.utc(a.sent_at) - moment.utc(b.sent_at),
                    )
                    .map((msg, i) => (
                        <MessageErrorBoundary
                            key={i}
                            isHuman={msg.type === 'human'}
                        >
                            <Message setCurrentMsg={setCurrentMsg} msg={msg} />
                        </MessageErrorBoundary>
                    ))}
                {thinking === true && <LoadingMessage />}
            </div>
        </>
    );
};

const SuggestedMessages = ({ sendMessage, quickReplies }) => {
    const { user } = useUserStateOnly();
    const navigate = useNavigate();
    const context = useContext(GeneralContext);

    const handleClick = message => {
        if (message === 'Take me to my dashboard.') {
            navigate('/dashboard');
        } else {
            sendMessage(message);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                marginTop: 'auto',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '12px',
                paddingTop: quickReplies.length > 0 && '40px',
                transition: 'opacity 0.3s',
            }}
            className={`${
                quickReplies.length > 0 ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-500 ease-in-out`}
        >
            {quickReplies.map(
                (message, index) =>
                    message !== 'Quick replies:' && (
                        <div
                            style={{
                                padding: '14px',
                                paddingBottom: '11px',
                            }}
                            key={index}
                            onClick={() => handleClick(message)}
                            className="cursor-pointer active:bg-accentHover active:text-white dark:border-accentLight dark:border-opacity-40 dark:text-accentLight font-bold text-accent hover:text-accentHover rounded-xl border border-accent border-opacity-20 hover:border-accentHover hover:border-opacity-100"
                        >
                            {message}
                        </div>
                    ),
            )}
        </div>
    );
};

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        let handler;
        if (value === true) {
            // Only debounce when the value is true
            handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
        } else {
            setDebouncedValue(value); // Instantly set to false
        }
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return [debouncedValue, setDebouncedValue];
}
