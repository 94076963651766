import React, { useContext } from 'react';
import styled from 'styled-components';
import { correctUrlForEnvironment } from '@/util/utils';
import { GeneralContext } from '@/context/GeneralContext';
import { Download } from 'iconoir-react';

const PdfIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
        >
            <g opacity="0.5">
                <path
                    d="M15.6721 6.0819C15.1801 6.08142 14.7083 5.88574 14.3604 5.53781C14.0125 5.18989 13.8168 4.71813 13.8163 4.22609V1.64941H5.5237C4.88138 1.64941 4.26536 1.90458 3.81117 2.35878C3.35697 2.81297 3.10181 3.42897 3.10181 4.0713V18.671C3.10276 19.3127 3.35834 19.9278 3.81244 20.3812C4.26653 20.8347 4.882 21.0893 5.5237 21.0893H15.4786C16.1203 21.0893 16.7358 20.8347 17.1899 20.3812C17.644 19.9278 17.8996 19.3127 17.9005 18.671V6.0819H15.6721Z"
                    fill="white"
                />
            </g>
            <path
                d="M17.9005 6.08238H15.6721C15.18 6.08191 14.7083 5.88622 14.3604 5.5383C14.0124 5.19037 13.8168 4.71862 13.8163 4.22658V1.6499L17.9005 6.08238Z"
                fill="#141718"
                fillOpacity="0.7"
            />
            <path
                d="M14.8558 15.2254H5.56418C5.47467 15.2254 5.38883 15.1898 5.32553 15.1265C5.26224 15.0632 5.22668 14.9774 5.22668 14.8879C5.22692 14.7985 5.26258 14.7129 5.32585 14.6498C5.38912 14.5867 5.47483 14.5513 5.56418 14.5513H14.8558C14.9451 14.5513 15.0307 14.5867 15.0938 14.6498C15.1569 14.713 15.1924 14.7986 15.1924 14.8879C15.1925 14.9321 15.1839 14.976 15.167 15.017C15.1502 15.0579 15.1254 15.0951 15.0941 15.1264C15.0628 15.1578 15.0257 15.1827 14.9848 15.1997C14.9439 15.2166 14.9001 15.2254 14.8558 15.2254Z"
                fill="#141718"
                fillOpacity="0.7"
            />
            <path
                d="M14.8558 11.4236H5.56418C5.47483 11.4236 5.38912 11.3882 5.32585 11.3251C5.26258 11.262 5.22692 11.1764 5.22668 11.087C5.22668 10.9975 5.26224 10.9116 5.32553 10.8483C5.38883 10.7851 5.47467 10.7495 5.56418 10.7495H14.8558C14.9001 10.7495 14.9439 10.7582 14.9848 10.7752C15.0257 10.7922 15.0628 10.8171 15.0941 10.8484C15.1254 10.8798 15.1502 10.917 15.167 10.9579C15.1839 10.9989 15.1925 11.0427 15.1924 11.087C15.1924 11.1763 15.1569 11.2619 15.0938 11.325C15.0307 11.3881 14.9451 11.4236 14.8558 11.4236Z"
                fill="#141718"
                fillOpacity="0.7"
            />
            <path
                d="M14.8558 13.325H5.56418C5.47467 13.325 5.38883 13.2894 5.32553 13.2261C5.26224 13.1628 5.22668 13.077 5.22668 12.9875C5.22692 12.8981 5.26258 12.8125 5.32585 12.7494C5.38912 12.6863 5.47483 12.6509 5.56418 12.6509H14.8558C14.9451 12.6509 15.0307 12.6863 15.0938 12.7495C15.1569 12.8126 15.1924 12.8982 15.1924 12.9875C15.1925 13.0318 15.1839 13.0756 15.167 13.1166C15.1502 13.1575 15.1254 13.1947 15.0941 13.2261C15.0628 13.2574 15.0257 13.2823 14.9848 13.2993C14.9439 13.3162 14.9001 13.325 14.8558 13.325Z"
                fill="#141718"
                fillOpacity="0.7"
            />
            <path
                d="M11.191 9.52322H5.56418C5.47483 9.52322 5.38912 9.48779 5.32585 9.42469C5.26258 9.36159 5.22692 9.27597 5.22668 9.18662C5.22668 9.09711 5.26224 9.01127 5.32553 8.94798C5.38883 8.88468 5.47467 8.84912 5.56418 8.84912H11.191C11.2803 8.84936 11.366 8.88502 11.4291 8.94828C11.4922 9.01155 11.5276 9.09727 11.5276 9.18662C11.5273 9.27582 11.4918 9.36129 11.4287 9.42436C11.3657 9.48744 11.2802 9.52298 11.191 9.52322Z"
                fill="#141718"
                fillOpacity="0.7"
            />
            <path
                d="M11.191 7.62289H5.56418C5.47483 7.62289 5.38912 7.58744 5.32585 7.52434C5.26258 7.46124 5.22692 7.37564 5.22668 7.28629C5.22668 7.19678 5.26224 7.11092 5.32553 7.04762C5.38883 6.98433 5.47467 6.94879 5.56418 6.94879H11.191C11.2803 6.94903 11.366 6.98469 11.4291 7.04795C11.4922 7.11122 11.5276 7.19694 11.5276 7.28629C11.5273 7.37549 11.4918 7.46096 11.4287 7.52403C11.3657 7.58711 11.2802 7.62265 11.191 7.62289Z"
                fill="#141718"
                fillOpacity="0.7"
            />
            <path
                d="M19.6564 17.0054H8.4028C7.60452 17.0054 6.9574 17.6525 6.9574 18.4508V21.806C6.9574 22.6042 7.60452 23.2513 8.4028 23.2513H19.6564C20.4547 23.2513 21.1018 22.6042 21.1018 21.806V18.4508C21.1018 17.6525 20.4547 17.0054 19.6564 17.0054Z"
                fill="#141718"
                fillOpacity="0.7"
            />
            <path
                d="M10.6492 20.854V21.5083H9.97241V20.854H10.6492Z"
                fill="white"
            />
            <path
                d="M11.6447 20.559V21.5085H10.9904V18.8022H12.0506C12.3719 18.8022 12.6176 18.8823 12.7859 19.0416C12.871 19.1253 12.9375 19.2261 12.9809 19.3373C13.0244 19.4485 13.0438 19.5677 13.0379 19.6869C13.0411 19.8443 13.0023 19.9996 12.9254 20.1369C12.8468 20.2712 12.7303 20.3792 12.5906 20.4474C12.422 20.5271 12.2369 20.5657 12.0506 20.5599L11.6447 20.559ZM12.3719 19.6869C12.3719 19.4469 12.2405 19.3269 11.9777 19.3269H11.6447V20.0316H11.9777C12.2405 20.034 12.3719 19.9191 12.3719 19.6869Z"
                fill="white"
            />
            <path
                d="M15.6235 20.8556C15.5108 21.0602 15.3406 21.2273 15.1339 21.3362C14.9013 21.4552 14.6427 21.5143 14.3815 21.508H13.36V18.8018H14.3815C14.6429 18.7952 14.9019 18.853 15.1357 18.9701C15.3418 19.0766 15.5116 19.242 15.6235 19.4453C15.7406 19.6611 15.7995 19.9036 15.7945 20.1491C15.7994 20.3954 15.7405 20.6387 15.6235 20.8556ZM14.9197 20.7196C14.9928 20.6446 15.0495 20.555 15.0861 20.4568C15.1227 20.3585 15.1383 20.2537 15.1321 20.1491C15.1384 20.0445 15.1227 19.9398 15.0862 19.8417C15.0496 19.7436 14.9929 19.6543 14.9197 19.5794C14.756 19.4357 14.5422 19.3626 14.3248 19.376H14.0143V20.9221H14.3248C14.542 20.9353 14.7557 20.8626 14.9197 20.7196Z"
                fill="white"
            />
            <path
                d="M17.9302 18.8022V19.3233H16.8205V19.9164H17.6773V20.4141H16.8205V21.5085H16.1653V18.8022H17.9302Z"
                fill="white"
            />
            <path
                d="M14.6912 6.94879H13.1C12.8226 6.94879 12.5978 7.17363 12.5978 7.45099V9.04218C12.5978 9.31954 12.8226 9.54438 13.1 9.54438H14.6912C14.9685 9.54438 15.1934 9.31954 15.1934 9.04218V7.45099C15.1934 7.17363 14.9685 6.94879 14.6912 6.94879Z"
                fill="#141718"
                fillOpacity="0.7"
            />
        </svg>
    );
};

const FileList = ({ files }) => {
    const context = useContext(GeneralContext);
    const downloadFile = async url => {
        console.log('File URL', url);

        // get the filename and extension from url
        const filePath = url.split('/').pop();

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.download = filePath;

        link.target = '_blank';

        // Set the href to the file's URL
        link.href = url;

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    };

    return (
        <>
            {files.map((f, i) => {
                return (
                    <>
                        <div className="flex flex-row gap-2.5 items-center">
                            <FileWrapper
                                dark={context.dark}
                                style={{
                                    borderBottom: files.length === 1 && '0px',
                                }}
                                key={i}
                            >
                                <PdfIcon />
                                <FileBody key={f.file_path}>
                                    <FileTitle dark={context.dark}>
                                        {f.file_path}
                                    </FileTitle>
                                    <FileSize dark={context.dark}>
                                        {formatFileSize(f.size)}
                                    </FileSize>
                                </FileBody>
                            </FileWrapper>
                            <DownloadButton
                                dark={context.dark}
                                onClick={() => {
                                    downloadFile(
                                        correctUrlForEnvironment(f.url),
                                    );
                                }}
                            >
                                <Download width={21} height={21} />
                            </DownloadButton>
                        </div>
                    </>
                );
            })}{' '}
        </>
    );
};

export default FileList;

const DownloadButton = styled.div`
    display: flex;
    width: 35px;
    cursor: pointer;
    height: 35px;
    //padding: 10.5px;
    justify-content: center;
    align-items: center;
    border-radius: 43.75px;
    background: ${props =>
        props.dark
            ? 'rgba(255, 255, 255, 0.10)'
            : 'var(--black-10, rgba(20, 23, 24, 0.1))'};
`;
const FileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(20, 23, 24, 0.1);
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    gap: 14px;
    width: fit-content;

    //&:hover {
    //    background-color: #f4f3ef;
    //    border-bottom: 1px solid #f4f3ef;
    //}

    background: ${props =>
        props.dark ? 'rgba(255, 255, 255, 0.20)' : '#f3f3f3'};
`;

const FileTitle = styled.div`
    font-size: 16px;

    color: ${props => (props.dark ? 'white' : '#141718')};
`;
const FileBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const FileSize = styled.div`
    width: 36px;
    line-height: 16px;
    font-size: 14px;
    /* identical to box height, or 145% */

    /* Neutral/04/75% */

    /* identical to box height, or 145% */

    /* Neutral/04/75% */

    color: ${props =>
        props.dark ? 'rgba(255, 255, 255, 0.50)' : 'rgba(20, 23, 24, 0.60)'};
`;

function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    if (sizeInKB > 1024) {
        // If size is greater than 1024KB then convert it to MB.
        const sizeInMB = sizeInKB / 1024;
        return sizeInMB.toFixed(2) + 'MB';
    } else {
        return sizeInKB.toFixed(2) + 'KB';
    }
}
