import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { axiosDefault } from '../util/axiosRetry';

export const useAdminRequestList = () => {
    const getMoreRequests = async ({ pageParam }) => {
        console.log('fetching admin requests');
        const response = await axiosDefault.get('/admin/requests', {
            params: {
                page: pageParam,
            },
        });
        return response.data;
    };

    const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } =
        useInfiniteQuery({
            queryKey: ['admin', 'requests'],
            placeholderData: keepPreviousData,
            queryFn: getMoreRequests,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
            initialPageParam: 0,
            getNextPageParam: (lastPage, pages) => {
                return pages ? pages.length : 0;
            },
        });
    return { data, fetchNextPage, isFetching, isFetchingNextPage };
};
