import styled from 'styled-components';
import styleConfig from '../styles/styleConfig';
import React from 'react';
import Lottie from 'lottie-react';
import buttonLoaderWhite from '@/assets/buttonLoaderWhite.json';
import buttonLoader from '@/assets/buttonLoader.json';

export const StyledButton = styled.button`
    color: ${({ color }) => styleConfig.buttons[color].text};
    font-family: Indivisible;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    padding: ${({ size }) =>
        size === 'large'
            ? '12px 24px'
            : size === 'medium'
              ? '7px 24px'
              : '7px 15px'};
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid
        ${({ color }) => styleConfig.buttons[color].border || 'transparent'};
    width: ${props => (props.width ? props.width : 'max-content')};
    background-color: ${({ color }) => styleConfig.buttons[color].fill};

    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: center;

    &:hover {
        & svg {
            fill: ${({ color }) => styleConfig.buttons[color].hover.text};
        }

        background-color: ${({ color }) =>
            styleConfig.buttons[color].hover.fill};
        color: ${({ color }) => styleConfig.buttons[color].hover.text};
    }

    &:disabled {
        background-color: ${({ color }) =>
            styleConfig.buttons[color].disabled?.fill};
        cursor: auto;
    }
`;

export const ButtonLoaderWhite = ({ width = '42px' }) => {
    return (
        <Lottie style={{ width: width }} animationData={buttonLoaderWhite} />
    );
};
export const ButtonLoader = () => {
    return <Lottie style={{ width: '42px' }} animationData={buttonLoader} />;
};

// export const ButtonLoader = styled.div`
//     border: 4px solid #141718; /* Light grey */
//     border-top: 4px solid #f3f3f3; /* Blue */
//     border-radius: 50%;
//     width: 15px;
//
//     height: 15px;
//     animation: spin 1s linear infinite;
//
//     @keyframes spin {
//         0% {
//             transform: rotate(0deg);
//         }
//         100% {
//             transform: rotate(360deg);
//         }
//     }
// `;
export const Button = ({ loading, children, ...props }) => {
    return (
        <StyledButton {...props}>
            {loading ? <ButtonLoader /> : children}
        </StyledButton>
    );
};

export const ModalTitle = styled.div`
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 28px */
    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

export const Input = styled.input`
    color: ${props => (props.dark ? '#fff' : '#141718')};
    font-family: Indivisible, sans-serif;
    padding: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 128%; /* 25.2px */

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    border-radius: 12px;
    border: ${props =>
        props.error
            ? `1px solid ${styleConfig.colors.dangerAction.fill}`
            : props.dark
              ? '1px solid #141718'
              : '1px solid #f4f3ef'};

    width: 100%;
    background-color: ${props =>
        props.error
            ? 'rgba(233, 95, 47, 0.10)'
            : props.dark
              ? 'rgba(255, 255, 255, 0.10)'
              : '#F3F3F3'};

    ::placeholder {
        color: ${props => (props.dark ? '#fff' : '#141718')};
        opacity: 0.3;
    }

    &:hover {
        background: ${props =>
            !props.error
                ? props.dark
                    ? 'rgba(255, 255, 255, 0.15)'
                    : '#ececec'
                : ''};
    }

    &:focus {
        outline: none;
        background: ${props =>
            !props.error
                ? props.dark
                    ? 'rgba(255, 255, 255, 0.15)'
                    : '#ececec'
                : ''};
    }
`;

export const SuccessText = styled.div`
    color: green;
    width: 100%;
    padding-top: 5px;
    text-align: center;
`;

export const ErrorText = styled.div`
    color: ${styleConfig.colors.dangerLabel};
    width: 100%;
`;
