import React, { useContext, useEffect, useMemo, useState } from 'react';
import { GeneralContext } from '@/context/GeneralContext';
import { useNavigate, useParams } from 'react-router-dom';
import { NavArrowLeft, NavArrowRight, Trash, Xmark } from 'iconoir-react';
import { useResponses } from '@/hooks/useResponses';
import { useQueryClient } from '@tanstack/react-query';
import axiosWithRetry from '@/util/axiosRetry';
import useUser from '@/context/useUser';
import mixpanel from 'mixpanel-browser';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loadingAnimation.json';

function ResponseItem() {
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { user } = useUser();
    const { all, attention } = useResponses(user?.voice_config?.phone_number);
    // const responseId = 2;
    const { responseId } = useParams();
    const [loading, setLoading] = useState(false);
    const response = useMemo(() => {
        let result;

        if (all === undefined || attention === undefined) {
            return (
                <div
                    style={{ height: context.height, width: context.width }}
                    className={`flex-grow z-10 h-full flex
                    relative flex-col bg-black items-center justify-center px-5 `}
                >
                    <Lottie
                        style={{ width: '75px' }}
                        animationData={loadingAnimation}
                    />
                </div>
            );
        }

        const allItems = all.pages.flat();
        const attentionItems = attention.pages.flat();

        // Check in all
        const allIndex = allItems.findIndex(item => item.id === responseId);
        if (allIndex !== -1) {
            let handleLeft;
            if (allIndex !== 0) {
                handleLeft = () =>
                    navigate('/responses/' + allItems[allIndex - 1].id);
            }

            let handleRight;
            if (allIndex + 1 !== allItems.length) {
                handleRight = () =>
                    navigate('/responses/' + allItems[allIndex + 1].id);
            }

            result = {
                source: 'all',
                index: allIndex,
                totalLength: allItems.length,
                handleLeft: handleLeft,
                handleRight: handleRight,
                handleBack: () => navigate('/responses/all'),
            };
        } else {
            // Check in attention
            const attentionIndex = attentionItems.findIndex(
                item => item.id === responseId,
            );

            if (attentionIndex !== -1) {
                let handleLeft;
                if (attentionIndex !== 0) {
                    handleLeft = () =>
                        navigate(
                            '/responses/' +
                                attentionItems[attentionIndex - 1].id,
                        );
                }

                let handleRight;
                if (attentionIndex + 1 !== attentionItems.length) {
                    handleRight = () =>
                        navigate(
                            '/responses/' +
                                attentionItems[attentionIndex + 1].id,
                        );
                }

                result = {
                    source: 'attention',
                    index: attentionIndex,
                    totalLength: attentionItems.length,
                    handleLeft: handleLeft,
                    handleRight: handleRight,
                    handleBack: () => navigate('/responses/attention'),
                };
            }
        }

        return result;
    }, [all, attention, responseId]);

    useEffect(() => {
        if (response) {
            if (response.source) {
                mixpanel.track('Response visited', { source: response.source });
            }
        }
    }, [response]);
    if (all === undefined || attention === undefined || loading === true) {
        return (
            <div
                style={{ height: context.height, width: context.width }}
                className={`flex-grow z-10 h-full flex
                    relative flex-col bg-black items-center justify-center px-5 `}
            >
                <Lottie
                    style={{ width: '75px' }}
                    animationData={loadingAnimation}
                />
            </div>
        );
    }
    const handleSave = async () => {
        if (!ready) {
            return;
        }

        try {
            mixpanel.track('Response updated', { source: response.source });

            await axiosWithRetry.post(
                `/phone_line/${user.phone_number}/response/${responseId}`,
                { question: curResponse.input, answer: curResponse.output },
            );

            const updateCacheData = (key, dataUpdater) => {
                queryClient.setQueryData(key, existingData => {
                    // Flatten existing data
                    const flatData = existingData.pages.flat();
                    // Update the flat data
                    const updatedData = dataUpdater(flatData);
                    // Regenerate pages after update
                    return {
                        ...existingData,
                        pages: regeneratePages(updatedData),
                    };
                });
            };

            if (response.source !== 'all') {
                updateCacheData(
                    ['responses', user?.voice_config?.phone_number, 'all'],
                    data => [attention[response.index], ...data],
                );
                updateCacheData(
                    [
                        'responses',
                        user?.voice_config?.phone_number,
                        response.source,
                    ],
                    data => data.filter((_, idx) => idx !== response.index),
                );

                if (response.handleLeft) {
                    response.handleLeft();
                } else if (response.handleRight) {
                    response.handleRight();
                } else {
                    navigate('/responses/');
                }
            }
        } catch (error) {
            console.error('Error adding fact:', error);
        }
    };

    const handleRemove = async () => {
        try {
            setLoading(true);
            await axiosWithRetry.delete(
                `/phone_line/${user.phone_number}/response/${responseId}`,
            );
            setLoading(false);
            mixpanel.track('Response removed', { source: response.source });
            const updateCacheData = (key, dataUpdater) => {
                queryClient.setQueryData(key, existingData => {
                    // Flatten existing data
                    const flatData = existingData.pages.flat();
                    // Update the flat data
                    const updatedData = dataUpdater(flatData);
                    // Regenerate pages after update
                    return {
                        ...existingData,
                        pages: regeneratePages(updatedData),
                    };
                });
            };

            updateCacheData(
                [
                    'responses',
                    user?.voice_config?.phone_number,
                    response.source,
                ],
                data => data.filter((_, idx) => idx !== response.index),
            );

            if (response.handleLeft) {
                response.handleLeft();
            } else if (response.handleRight) {
                response.handleRight();
            } else {
                navigate('/responses/');
            }
        } catch (error) {
            console.error('Error removing fact:', error);
        }
    };

    const handleChange = e => {
        if (response) {
            const i = response.index;

            const updateCacheData = (key, dataUpdater) => {
                queryClient.setQueryData(key, existingData => {
                    // Flatten existing data
                    //console.log('HERE', existingData);
                    const flatData = existingData.pages.flat();
                    // Update the flat data
                    const updatedData = dataUpdater(flatData);
                    // Regenerate pages after update
                    return {
                        ...existingData,
                        pages: regeneratePages(updatedData),
                    };
                });
            };

            updateCacheData(
                [
                    'responses',
                    user?.voice_config?.phone_number,
                    response.source,
                ],
                data => [
                    ...data.slice(0, i),
                    { ...data[i], output: e.target.value },
                    ...data.slice(i + 1),
                ],
            );
        }
    };
    const curResponse =
        response?.source === 'all'
            ? all.pages.flat()[response.index]
            : attention.pages.flat()[response.index];

    const ready = curResponse.value !== '';
    const handleFocusOut = e => {
        if (response.source === 'all') {
            handleSave();
        }
    };
    return (
        <div
            className={'bg-black flex flex-col '}
            style={{ height: context.height, width: context.width }}
        >
            <div className="flex flex-row py-3.5 px-6 justify-between">
                <Xmark
                    opacity={0.5}
                    color={'white'}
                    style={{ cursor: 'pointer' }}
                    onClick={response.handleBack}
                />
                <div className={'flex flex-row gap-2.5 items-center'}>
                    {response?.handleLeft ? (
                        <NavArrowLeft
                            opacity={0.5}
                            strokeWidth={2}
                            style={{ cursor: 'pointer' }}
                            onClick={response?.handleLeft}
                        />
                    ) : (
                        <div style={{ height: '24px', width: '24px' }} />
                    )}
                    <div
                        className={
                            'text-white font-medium text-l pt-0.5 select-none'
                        }
                    >
                        {response &&
                            response?.index +
                                1 +
                                ' of ' +
                                response?.totalLength}
                    </div>
                    {response?.handleRight ? (
                        <NavArrowRight
                            opacity={0.5}
                            strokeWidth={2}
                            style={{ cursor: 'pointer' }}
                            onClick={response?.handleRight}
                        />
                    ) : (
                        <div style={{ height: '24px', width: '24px' }} />
                    )}
                </div>
                <div style={{ width: '24px' }}>
                    {response.source === 'all' && (
                        <Trash
                            style={{ cursor: 'pointer' }}
                            height={20}
                            width={20}
                            opacity={0.5}
                            onClick={handleRemove}
                        />
                    )}
                </div>
            </div>

            <div
                className={
                    'flex flex-col flex-grow justify-between items-center gap-2'
                }
            >
                <div className="w-full items-center flex flex-col px-6 gap-2">
                    {curResponse.context && (
                        <div className="bg-white bg-opacity-10 rounded-xl pt-4 pb-3 justify-center px-5 flex flex-col gap-3">
                            <div className="font-semibold text-center">
                                {' '}
                                Subi generated this response{' '}
                                {curResponse.context}
                            </div>
                            <div className="flex flex-row justify-center gap-3">
                                <div
                                    onClick={handleRemove}
                                    className="font-medium px-2.5 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-xl cursor-pointer py-0.5"
                                >
                                    {response.source === 'approval'
                                        ? 'Reject'
                                        : 'Remove'}
                                </div>
                                <div
                                    onClick={handleSave}
                                    className={`font-medium px-2.5 rounded-xl  py-0.5 ${ready ? 'cursor-pointer bg-accent hover:bg-accentHover cursor-pointer' : 'opacity-50 cursor-auto'}`}
                                >
                                    {response.source === 'approval'
                                        ? 'Approve'
                                        : 'Save'}
                                </div>
                            </div>
                        </div>
                    )}
                    {/*<div*/}
                    {/*    style={{ fontSize: '15px' }}*/}
                    {/*    className="px-2.5 text-center pb-2 opacity-80 text-white "*/}
                    {/*>*/}
                    {/*    Add a new request and response below.*/}
                    {/*</div>*/}
                    <div
                        className={
                            'px-5 py-5 gap-4 flex flex-col bg-white bg-opacity-10 rounded-xl w-full'
                        }
                    >
                        <div className={'flex flex-col gap-1.5'}>
                            <div className="text-white text-opacity-80 text-sm">
                                Request
                            </div>
                            {curResponse.input}
                        </div>
                        <div className={'flex flex-col gap-1.5'}>
                            <div className="text-white text-opacity-80 text-sm">
                                Response
                            </div>
                            <textarea
                                value={curResponse.output}
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                style={{ height: '300px' }}
                                className="resize-none rounded-xl bg-white bg-opacity-10 text-white w-full p-2 bg-gray-100 focus:outline-none custom-textarea"
                            ></textarea>
                        </div>
                    </div>
                </div>

                {/*<div className={'flex flex-row gap-2.5 pb-10'}>*/}
                {/*    <div*/}
                {/*        className={*/}
                {/*            'text-white px-2.5 py-0.5 bg-white bg-opacity-10 text-opacity-50 rounded-xl font-medium hover:bg-opacity-20 cursor-pointer'*/}
                {/*        }*/}
                {/*        onClick={handleCancelResponse}*/}
                {/*    >*/}
                {/*        Cancel*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className={*/}
                {/*            'text-white px-2.5 py-0.5 bg-accent rounded-xl font-medium hover:bg-accentHover cursor-pointer'*/}
                {/*        }*/}
                {/*        onClick={handleSaveResponse}*/}
                {/*    >*/}
                {/*        Save*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default ResponseItem;

const flattenPages = pages => pages.flat();

const regeneratePages = flatData => {
    const pageSize = 5; // Example page size, adapt as needed
    const pages = [];
    for (let i = 0; i < flatData.length; i += pageSize) {
        pages.push(flatData.slice(i, i + pageSize));
    }
    return pages;
};
