import React, { useContext } from 'react';
import styled from 'styled-components';
import { Modal } from '@mui/material';
import FileList from '../../components/FileList';
import { GeneralContext } from '../../context/GeneralContext';
import { Xmark } from 'iconoir-react';

const ModalWrapper = styled.div`
    width: 492px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => (props.dark ? '#141718' : 'white')};
    color: ${props => (props.dark ? 'white' : '#141718')};
    boxshadow: 24;
    border-radius: 10px;
    @media (max-width: 768px) {
        position: fixed;
        height: 100%;
        width: 100%;
    }
`;
const ModalContentWrapper = styled.div`
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: scroll;
    height: calc(100% - 86px);
    @media (max-width: 768px) {
        padding: 20px;
    }
`;
const ModalTitleWrapper = styled.div`
    display: flex;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 16px 25px;
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    height: 62px;
    @media (max-width: 768px) {
        border-bottom: none;
    }
`;

const ModalTitle = styled.div`
    display: flex;
    z-index: 1;
    align-items: center;
    gap: 5px;
    @media (max-width: 768px) {
        font-size: 16px;
        font-weight: 500;
    }
`;

const FileListModalMobile = ({ files }) => {
    const context = useContext(GeneralContext);
    const openModal = context.openModal;
    const setOpenModal = context.setOpenModal;
    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)', // Replace this with the desired background color and opacity
                },
            }}
            open={openModal === 'files'}
            onClose={() => setOpenModal('none')}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <ModalWrapper dark={context.dark}>
                <ModalTitleWrapper>
                    <Xmark
                        color={context.dark ? 'white' : 'black'}
                        height={24}
                        width={24}
                        strokeWidth={1.5}
                        onClick={() => setOpenModal('none')}
                        style={{ cursor: 'pointer' }}
                    />
                    <ModalTitle>Files ({files.length})</ModalTitle>
                    <div style={{ width: '24px' }}></div>
                </ModalTitleWrapper>
                {/*TODO: height at 100 percent*/}
                <ModalContentWrapper>
                    <FileList files={files} />
                </ModalContentWrapper>
            </ModalWrapper>
        </Modal>
    );
};

export default FileListModalMobile;
