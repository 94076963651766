import React, { useContext, useEffect, useMemo } from 'react';
import { Xmark } from 'iconoir-react';
import { useNavigate, useParams } from 'react-router-dom';
import { GeneralContext } from '@/context/GeneralContext';
import useUser from '@/context/useUser';
import Moment from 'react-moment';
import mixpanel from 'mixpanel-browser';
import { useCallHistory } from '@/hooks/useCallHistory';
import { DropDown } from '@/pages/modals/Onboarding';

export const CallHistoryItem = () => {
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    const { user } = useUser();
    useEffect(() => {
        mixpanel.track('Call history item visited', {});
    }, []);
    const { calls, callsLoading } = useCallHistory(
        user?.voice_config?.phone_number,
    );

    const { callId } = useParams();

    const response = useMemo(() => {
        let result;
        if (callsLoading) {
            return;
        }

        // Check in all
        if (calls.pages.flat().find(item => item.id === callId)) {
            result = calls.pages.flat().find(item => item.id === callId);
            console.log(result, 'RESULT');
        }
        return result;
    }, [callId, calls, callsLoading]);
    if (response === undefined) {
        return;
    }
    return (
        <>
            <div
                className={`flex py-3.5 px-5 justify-between items-center bg-black`}
            >
                <div
                    className="cursor-pointer"
                    onClick={() => navigate('/call/')}
                >
                    <Xmark strokeWidth={2} opacity={0.5} />
                </div>
                <div className="font-medium text-xl">Call History</div>

                <div style={{ width: '24px', height: '24px' }} />
            </div>
            <div
                style={{ height: context.height - 44 }}
                className={'p-5  bg-black overflow-scroll flex flex-col gap-3'}
            >
                <div className="bg-white bg-opacity-10 py-5 px-5 flex flex-col justify-between rounded-xl gap-1  ">
                    {response?.from_number && (
                        <div
                            className="font-medium text-lg text-white"
                            style={{ lineHeight: '100%' }}
                        >
                            {response?.from_number}
                        </div>
                    )}
                    <div className="text-sm leading-none">
                        {response?.updated_at && (
                            <div
                                className="font-medium opacity-50"
                                style={{ lineHeight: '100%' }}
                            >
                                <Moment format="MM/DD/YYYY hh:mm:ss">
                                    {response?.updated_at}
                                </Moment>
                            </div>
                        )}{' '}
                    </div>
                    {response?.duration && (
                        <div
                            className="font-medium text-sm opacity-50"
                            style={{ lineHeight: '100%' }}
                        >
                            {response?.duration}
                        </div>
                    )}
                </div>

                {response?.transcript && (
                    <DropDown title={'Transcript'}>
                        {response.transcript.split('\n').map((line, index) => (
                            <div key={index}>{line}</div>
                        ))}
                    </DropDown>
                )}
            </div>
        </>
    );
};
