import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { getChatIdFromUrl } from '../util/utils.jsx';
import axiosDefault from '../util/axiosRetry.jsx';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { GeneralContext } from '@/context/GeneralContext';

const fetchConversation = async ({ queryKey }) => {
    const [, , chatId] = queryKey;
    const response = await axiosDefault.get(`/conversation/${chatId}`);
    return response.data;
};

const fetchFiles = async ({ queryKey, pageParam }) => {
    const [, , chatId] = queryKey;
    const response = await axiosDefault.get(`/conversation/${chatId}/files`);
    return response.data;
};

const postMessage = async (optimisticMessage, id) => {
    console.log('sending message', optimisticMessage);
    const response = await axiosDefault.post(
        `/conversation/message/`,
        optimisticMessage,
    );

    return { response, optimisticMessage, id };
};

const _createConversation = async message => {
    const response = await axiosDefault.post(`/conversation/`, message);
    return response;
};

export const useConversation = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const _context = useContext(GeneralContext);
    const fetchMessages = async ({ queryKey, pageParam }) => {
        const [, , chatId] = queryKey;
        const response = await axiosDefault.get(
            `/conversation/${chatId}/messages?${pageParam}&admin=${_context.admin}`,
        );
        return response.data;
    };

    const { data, isLoading, ...rest } = useQuery({
        queryKey: ['conversations', 'detail', getChatIdFromUrl()],
        queryFn: fetchConversation,
        enabled: getChatIdFromUrl() !== false,
        staleTime: Infinity,
    });
    const { data: messageData, isLoading: isLoadingMessages } =
        useInfiniteQuery({
            queryKey: ['conversations', 'messages', getChatIdFromUrl()],
            throwOnError: true,
            queryFn: fetchMessages,
            initialPageParam: 0,
            getNextPageParam: (lastPage, pages) => {
                return pages ? pages.length : 0;
            },
            staleTime: Infinity,
            enabled: getChatIdFromUrl() !== false,
        });

    const { data: fileData, isLoading: isLoadingFiles } = useInfiniteQuery({
        queryKey: ['conversations', 'files', getChatIdFromUrl()],

        queryFn: fetchFiles,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
            return pages ? pages.length : 0;
        },
        staleTime: Infinity,
        enabled: getChatIdFromUrl() !== false,
    });

    const addMessageMutation = useMutation({
        mutationFn: postMessage,
        onMutate: async data => {
            addMessageToMessages(queryClient, data);
        },
        onError: (err, newTodo, context) => {
            console.log('ERROR', err);
            queryClient.setQueryData(
                ['conversations', 'messages', context.id],
                context.previousMessages,
            );
        },
        onSuccess: output => {
            const newMessage = output.response?.data;
            const conversation_id = newMessage.conversation_id;
            if (conversation_id) {
                const currentMessages = queryClient.getQueryData([
                    'conversations',
                    'messages',
                    conversation_id,
                ]);
                let newPages = [[]];
                if (currentMessages.pages) {
                    newPages = currentMessages.pages.map(page => {
                        if (page.some(msg => msg.id === newMessage.id)) {
                            return page.map(msg =>
                                msg.id === newMessage.id
                                    ? { ...newMessage }
                                    : msg,
                            );
                        } else {
                            return page;
                        }
                    });

                    queryClient.setQueryData(
                        ['conversations', 'messages', conversation_id],
                        oldData => {
                            return {
                                ...oldData,
                                pages: newPages,
                            };
                        },
                    );
                }
            }
        },
        // onSettled: () => {
        //   queryClient.invalidateQueries({ queryKey: ['todos'] })
        // },
    });

    // ============================================================
    // Create a new conversation

    function optimisticConversationUpdate(message) {
        navigate(`/chat/${message.conversation_id}`, {
            replace: true,
        });
        queryClient.setQueryData(
            ['conversations', 'messages', message.conversation_id],
            {
                pages: [[message]],
                pageParams: [0],
            },
        );
        queryClient.setQueryData(
            ['conversations', 'detail', message.conversation_id],
            {
                conversation: {
                    id: message.conversation_id,
                    goal: message.message_content,
                    agent_type: 'multi_func_ex',
                    creator: message.sender,
                    participants: [],
                },
                files: [],
            },
        );

        const id = message.id;
        return { id };
    }

    const createConversationMutation = useMutation({
        mutationFn: _createConversation,
        onMutate: message => optimisticConversationUpdate(message),
        onError: (err, newTodo, context) => {
            console.log('createConversationMutation', err);
            queryClient.removeQueries({
                queryKey: ['conversations', 'messages', context.id],
            });
            queryClient.removeQueries({
                queryKey: ['conversations', 'detail', context.id],
            });
        },
        onSuccess: data => {
            console.debug('Create conversation success');
        },
    });

    return {
        data,
        messageData,
        fileData,
        isLoading,
        createConversationMutation,
        addMessageMutation,
    };
};

export default useConversation;

function sortConversations(conversationData) {
    return conversationData.sort((a, b) => {
        // Parse timestamps to Date objects for comparison
        const aTime = new Date(a.last_message_sent_at);
        const bTime = new Date(b.last_message_sent_at);

        // Sort descending (most recent first)
        if (aTime > bTime) return -1;
        if (aTime < bTime) return 1;

        return 0; // equal timestamps
    });
}

export function addMessageToMessages(queryClient, newMessage) {
    const id = newMessage.conversation_id;

    const previousMessages = queryClient.getQueryData([
        'conversations',
        'messages',
        id,
    ]);

    let found = false;
    let newPages = [[]];
    if (previousMessages?.pages) {
        newPages = previousMessages.pages.map(page => {
            if (page.some(msg => msg.id === newMessage.id)) {
                found = true;
                return page.map(msg =>
                    msg.id === newMessage.id ? newMessage : msg,
                );
            } else {
                return page;
            }
        });
    }

    // If the message is not found, add it to the first page
    if (!found) {
        newPages[0].push(newMessage);
    }

    queryClient.setQueryData(['conversations', 'messages', id], oldData => {
        return {
            ...oldData,
            pages: newPages,
        };
    });

    return { previousMessages, id };
}

interface QueryDataType {
    pages: any; // replace any with the actual type of your pages
    // include here any other properties that may exist in oldData
}
