import React, { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosDefault } from '@/util/axiosRetry';
import { GeneralContext } from '@/context/GeneralContext';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loadingAnimation.json';
import ChatMain from '@/pages/chat/ChatMain';
import { useUserStateOnly } from '@/context/useUser.jsx';

const Guest = () => {
    const navigate = useNavigate();
    const context = useContext(GeneralContext);
    const { guestLink } = useParams();
    const { user } = useUserStateOnly();
    const queryClient = useQueryClient();

    const {
        data: guestUserData,
        isLoading,
        isSuccess,
    } = useQuery({
        queryKey: ['guest', guestLink],
        queryFn: fetchGuest,
    });
    useEffect(() => {
        queryClient.setQueryData(['user'], guestUserData);
    }, [guestUserData]);
    if (isSuccess && user) {
        return (
            <ChatMain
                guestOwner={{
                    user_id: guestUserData.owner_user_id,
                    display_name: guestUserData.owner_display_name,
                }}
            />
        );
    }
    return (
        <div
            style={{ height: context.height, width: context.width }}
            className="flex items-center justify-center"
        >
            <Lottie
                style={{ width: '75px' }}
                animationData={loadingAnimation}
            />
        </div>
    );
};

async function fetchGuest() {
    const response = await axiosDefault.get(`/guest/${getGuestIdFromUrl()}`);
    // window.localStorage.setItem('user', JSON.stringify(response.data));
    return response.data;
}

function getGuestIdFromUrl() {
    let pathParts = window?.location?.pathname?.split('/');
    console.log('getGuestIdFromUrl', pathParts);
    return pathParts[1];
}

export default Guest;
