import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import axiosWithRetry from '../../util/axiosRetry';
import { useGoogleLogin } from '@react-oauth/google';
import { GeneralContext } from '../../context/GeneralContext';
import { useUser } from '../../context/useUser';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
    ButtonLoader,
    ButtonLoaderWhite,
    ErrorText,
    Input,
    SuccessText,
} from '@/styles/StyledComponents';
import { TermsOfService } from '../../components/TermsOfService';
import { BaseModal } from '../../components/Modal';
import { GoogleLogo } from '../../assets/svg/GoogleLogo';
import {
    checkUserEmptyOrGuest,
    createOptimisticMessage,
    validateEmail,
    validatePassword,
} from '../../util/utils';
import { useVerifyToken } from '../../hooks/useVerifyToken';
import colors from '@/styles/colors.js';
import Checkbox from '@/components/Checkbox';
import useConversation from '@/hooks/useConversation';
import queryClient from '@/util/queryClient';
import { EyeClosed, EyeSolid } from 'iconoir-react';

const LoginModal = () => {
    const { user, loginUser, register } = useUser();
    const { createConversationMutation } = useConversation();

    const context = useContext(GeneralContext);
    const openModal = context.openModal;
    const setOpenModal = context.setOpenModal;
    const navigate = useNavigate();
    const [page, setPage] = useState(context.register ? 2 : 1);
    const [name, setName] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [resetPasswordEmail, setResetPasswordEmail] = useState('');
    const [altError, setAltError] = useState('');
    const [_altError, set_AltError] = useState('');

    const [success, setSuccess] = useState('');
    const [checkGuidelines, setCheckGuidelines] = useState(false);
    const [checkTerms, setCheckTerms] = useState(false);
    const request_verify_token = useVerifyToken();
    const [google, setGoogle] = useState(false);
    const [revealPassword, setRevealPassword] = useState(false);

    const googleLogin = useGoogleLogin({
        flow: 'implicit',
        onSuccess: async tokenResponse => {
            console.log('response', tokenResponse);
            await mutateLoginUser({ idToken: tokenResponse.access_token });
        },
        onFailure: response => {
            console.log('Login failed', response);
            setGoogle(false);
        },
    });

    const googleRegister = useGoogleLogin({
        flow: 'implicit',
        onSuccess: tokenResponse => {
            const token = tokenResponse.access_token;
            mutateRegisterUser({ token });
        },
        onFailure: response => {
            console.log('Google register error', response);
        },
    });

    const forgotPassword = async () => {
        setError('');
        setIsLoading(true);
        if (
            !validateEmail(resetPasswordEmail, () => {
                setError('Please enter a valid email address.');
            })
        ) {
            setIsLoading(false);
            return;
        }
        try {
            console.log('Sending password reset request to server...');

            const response = await axiosWithRetry.post(
                '/auth/forgot-password',
                {
                    email: resetPasswordEmail,
                },
            );
            console.debug('forgot password response', response.data);
            setIsLoading(false);
            setError('');
            setPage(4);
        } catch (error) {
            console.error('Error resetting password:', error);
            setIsLoading(false);

            setError('No account found matching that email address.');
        }
    };

    const requestRegister = async email => {
        setError('');
        set_AltError('');
        setAltError('');
        if (name === '') {
            set_AltError('Please enter your name');
            return;
        }
        if (
            validateEmail(email, () => {
                setError('Please enter a valid email address.');
            }) === false
        ) {
            return;
        }
        if (
            (await validatePassword(password, password, setAltError, () => {
                return true;
            })) === false
        ) {
            return;
        }
        mutateRegisterUser({ email, password, display_name: name });
    };

    const requestLogin = async e => {
        if (
            (await validatePassword(password, password, setAltError, () => {
                return true;
            })) === true
        ) {
            mutateLoginUser({ e, email, password });
        }
    };

    const mutateLoginUser = credentials => {
        // console.log('credentials', credentials);
        loginUser.mutate(credentials, {
            onSuccess: response => {
                if (response.data.is_verified) {
                    navigate('/');
                    setOpenModal('');
                } else {
                    //TODO: Bring up email verification screen
                    navigate('/');
                    setError('');
                    setOpenModal('');
                    // setPage(5);
                }
            },
            onError: (error, variables, context) => {
                console.log('ERROR', error);
                if (error.message === 'Request failed with status code 400') {
                    setError(
                        "We couldn't find an account with those credentials.",
                    );
                } else {
                    setError('Unexpected error occurred.');
                }
            },
        });
    };

    const mutateRegisterUser = credentials => {
        console.log('mutateRegisterUser', credentials);
        register.mutate(credentials, {
            onSuccess: () => {
                request_verify_token.mutate(
                    { email },
                    {
                        onSuccess: () => {
                            setError('');
                            setAltError('');
                            setOpenModal('');
                            const u = queryClient.getQueryData(['user']);
                            const optimisticMessage = createOptimisticMessage(
                                uuid(),
                                u,
                                'Get started',
                                undefined,
                            );
                            createConversationMutation.mutate(
                                optimisticMessage,
                            );
                        },
                    },
                );
            },
            onError: error => {
                console.log('mutateRegisterUser', error);
                if (
                    error.response.data.detail ===
                    'REGISTER_USER_ALREADY_EXISTS'
                ) {
                    setError('An account already exists with this email');
                    setPage(2);
                } else {
                    setError('There was an error registering your account');
                    console.log(
                        'mutateRegisterUser err',
                        error.response.data.detail,
                    );
                    // setError(error.response.data.detail);
                    setPage(2);
                }
            },
        });
    };

    const changePage = page => {
        setPage(page);
        setError('');
        setAltError('');
        setEmail('');
        setPassword('');
        setSuccess('');
        setResetPasswordEmail('');
    };

    return (
        <BaseModal
            width={'560px'}
            backgroundColor={context.dark ? colors.black : colors.white}
            open={openModal === 'Log in'}
            onBack={
                page === 3
                    ? () => {
                          setPage(1);
                      }
                    : page === 2 &&
                        user !== undefined &&
                        context.width < 768 &&
                        location.pathname !== '/su' &&
                        checkUserEmptyOrGuest(user)
                      ? () => {
                            context.setOpenModal('');
                        }
                      : undefined
            }
            user={user}
            onClose={() => {
                if (user) {
                    context.setOpenModal('');
                    return;
                }
                if (isLoading === false) {
                    setCheckGuidelines(false);
                    setCheckTerms(false);
                    setOpenModal('');
                    changePage(1);
                }
            }}
        >
            <ModalTitle dark={context.dark} page={page}>
                {returnModalTitle(page)}
            </ModalTitle>
            <ModalContentWrapper>
                {page === 1 && (
                    <>
                        <ModalContentTop>
                            <div className="flex flex-col gap-5">
                                <Form>
                                    <div className="flex flex-col w-full gap-0.5 text-sm">
                                        <SubsectionSubtext dark={context.dark}>
                                            Email address
                                        </SubsectionSubtext>
                                        {error !== '' && (
                                            <ErrorText>{error}</ErrorText>
                                        )}
                                    </div>

                                    <Input
                                        dark={context.dark}
                                        error={error !== ''}
                                        placeholder={'hello@me.com'}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                requestLogin(e);
                                            }
                                        }}
                                    />
                                </Form>
                                <Form>
                                    <div className="flex flex-col w-full gap-0.5 text-sm">
                                        <SubsectionSubtext dark={context.dark}>
                                            Password{' '}
                                        </SubsectionSubtext>
                                        {altError !== '' && (
                                            <ErrorText>{altError}</ErrorText>
                                        )}
                                        <div
                                            className="text-sm font-normal cursor-pointer text-accent hover:text-accentHover dark:text-accentLight dark:hover:text-accentLightHover"
                                            onClick={() => changePage(3)}
                                        >
                                            Forgot password?
                                        </div>
                                    </div>
                                    <div className="relative flex flex-row items-center w-full">
                                        <Input
                                            dark={context.dark}
                                            altError={error !== ''}
                                            type={
                                                revealPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            value={password}
                                            onChange={e =>
                                                setPassword(e.target.value)
                                            }
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    requestLogin(e);
                                                }
                                            }}
                                        />
                                        <div
                                            className="absolute right-3 text-black dark:text-white cursor-pointer opacity-20 hover:opacity-50"
                                            onClick={() =>
                                                setRevealPassword(p => !p)
                                            }
                                        >
                                            {revealPassword ? (
                                                <EyeSolid />
                                            ) : (
                                                <EyeClosed />
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>

                            <Form>
                                <button
                                    onClick={requestLogin}
                                    disabled={loginUser.isPending}
                                    className="flex font-medium py-2 px-3 hover:bg-accentHover justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-white bg-accent shadow"
                                >
                                    {loginUser.isPending && google === false ? (
                                        <div
                                            style={{
                                                height: 28,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <ButtonLoaderWhite />
                                        </div>
                                    ) : (
                                        'Sign in'
                                    )}
                                </button>
                                <SubsectionSubtext
                                    dark={context.dark}
                                    style={{ textAlign: 'center' }}
                                    className="flex flex-row items-center gap-4"
                                >
                                    <div className="h-px w-full bg-black opacity-10 dark:bg-white dark:opactiy-20" />
                                    <div className="text-lg">or</div>
                                    <div className="h-px w-full bg-black opacity-10 dark:bg-white dark:opactiy-20" />
                                </SubsectionSubtext>
                                <button
                                    onClick={() => {
                                        setGoogle(true);
                                        googleLogin();
                                    }}
                                    className="bg-white flex font-medium py-2 px-3 justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-black shadow hover:text-opacity-70"
                                >
                                    {loginUser.isPending && google === true ? (
                                        <div
                                            style={{
                                                height: 28,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <ButtonLoader />
                                        </div>
                                    ) : (
                                        <div className="flex flex-row gap-2.5 items-center">
                                            <GoogleLogo />
                                            <div className="pt-px">
                                                Sign in with Google
                                            </div>
                                        </div>
                                    )}
                                </button>
                            </Form>
                            <div className="flex flex-col gap-10">
                                <div className="opacity-70 text-sm text-center dark:text-white">
                                    By continuing, you agree to{' '}
                                    <div
                                        className="font-bold cursor-pointer inline"
                                        onClick={() => {
                                            setOpenModal('');
                                            navigate('/privacy');
                                        }}
                                    >
                                        Subi’s Privacy Policy
                                    </div>
                                    {' and '}
                                    <div
                                        className="font-bold cursor-pointer inline"
                                        onClick={() => {
                                            setOpenModal('');
                                            navigate('/terms');
                                        }}
                                    >
                                        Terms of Service.
                                    </div>
                                </div>
                                {/*<div className="text-lg text-black dark:text-white text-center">*/}
                                {/*    Not a member?*/}
                                {/*    <div*/}
                                {/*        onClick={() => {*/}
                                {/*            setPage(2);*/}
                                {/*        }}*/}
                                {/*        className="cursor-pointer font-bold text-accent hover:text-accentHover dark:text-accentLight dark:hover:text-accentLightHover"*/}
                                {/*    >*/}
                                {/*        Join Subi*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </ModalContentTop>
                        {/*<ModalContentBottom>*/}
                        {/*    {context.register && (*/}
                        {/*        <SubsectionTitle dark={context.dark}>*/}
                        {/*            {'Not a member?'}*/}
                        {/*            <SubsectionLink*/}
                        {/*                onClick={() => changePage(2)}*/}
                        {/*            >*/}
                        {/*                Join Subi*/}
                        {/*            </SubsectionLink>*/}
                        {/*        </SubsectionTitle>*/}
                        {/*    )}*/}
                        {/*</ModalContentBottom>*/}
                    </>
                )}
                {page === 2 && (
                    <>
                        <ModalContentTop style={{ gap: '20px' }}>
                            <div className="flex flex-col gap-4">
                                <Form>
                                    <div className="flex flex-col w-full gap-0.5 text-sm">
                                        <SubsectionSubtext dark={context.dark}>
                                            Full name
                                        </SubsectionSubtext>
                                        {_altError !== '' && (
                                            <ErrorText>{_altError}</ErrorText>
                                        )}
                                    </div>
                                    <Input
                                        dark={context.dark}
                                        error={_altError !== ''}
                                        // placeholder={'hello@me.com'}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                requestLogin(e);
                                            }
                                        }}
                                    />
                                </Form>
                                <Form>
                                    <div className="flex flex-col w-full gap-0.5 text-sm">
                                        <SubsectionSubtext dark={context.dark}>
                                            Email address
                                        </SubsectionSubtext>
                                        {error !== '' && (
                                            <ErrorText>{error}</ErrorText>
                                        )}
                                    </div>
                                    <Input
                                        dark={context.dark}
                                        error={error !== ''}
                                        placeholder={'hello@me.com'}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                requestRegister(email);
                                            }
                                        }}
                                    />
                                </Form>

                                <Form>
                                    <div className="flex flex-col w-full gap-0.5 text-sm">
                                        <SubsectionSubtext dark={context.dark}>
                                            Password
                                        </SubsectionSubtext>
                                        {altError !== '' ? (
                                            <ErrorText>{altError}</ErrorText>
                                        ) : (
                                            <div className="opacity-50 text-black dark:text-white">
                                                {
                                                    'Must be at least 8 characters.'
                                                }
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative flex flex-row items-center w-full">
                                        <Input
                                            dark={context.dark}
                                            altError={error !== ''}
                                            type={
                                                revealPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            value={password}
                                            onChange={e =>
                                                setPassword(e.target.value)
                                            }
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    requestRegister(email);
                                                }
                                            }}
                                        />
                                        <div
                                            className="absolute right-3 text-black dark:text-white cursor-pointer opacity-20 hover:opacity-50"
                                            onClick={() =>
                                                setRevealPassword(p => !p)
                                            }
                                        >
                                            {revealPassword ? (
                                                <EyeSolid />
                                            ) : (
                                                <EyeClosed />
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <Form>
                                <button
                                    onClick={() => requestRegister(email)}
                                    disabled={register.isPending}
                                    className="flex font-medium py-2 px-3 justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-white bg-accent hover:bg-accentHover shadow"
                                >
                                    {register.isPending ? (
                                        <div
                                            style={{
                                                height: 28,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <ButtonLoaderWhite />
                                        </div>
                                    ) : (
                                        <>Join Subi</>
                                    )}
                                </button>
                                <SubsectionSubtext
                                    dark={context.dark}
                                    style={{ textAlign: 'center' }}
                                    className="flex flex-row items-center gap-4"
                                >
                                    <div className="h-px w-full bg-black opacity-10 dark:bg-white dark:opactiy-20" />
                                    <div className="text-lg">or</div>
                                    <div className="h-px w-full bg-black opacity-10 dark:bg-white dark:opactiy-20" />
                                </SubsectionSubtext>
                                <button
                                    onClick={() => {
                                        setGoogle(true);
                                        googleRegister();
                                    }}
                                    className="bg-white flex font-medium py-2 px-3 justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-black shadow hover:text-opacity-70"
                                >
                                    <div className="flex flex-row gap-2.5 items-center">
                                        {register.isPending &&
                                        google === true ? (
                                            <div
                                                style={{
                                                    height: 28,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <ButtonLoader />
                                            </div>
                                        ) : (
                                            <>
                                                <GoogleLogo />
                                                <div className="pt-0.5">
                                                    Continue with Google
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </button>
                            </Form>

                            <div className="flex flex-col gap-9">
                                <div className="opacity-70 text-sm text-center dark:text-white">
                                    By continuing, you agree to{' '}
                                    <div
                                        className="font-bold cursor-pointer inline"
                                        onClick={() => {
                                            setOpenModal('');
                                            navigate('/privacy');
                                        }}
                                    >
                                        Subi’s Privacy Policy
                                    </div>
                                    {' and '}
                                    <div
                                        className="font-bold cursor-pointer inline"
                                        onClick={() => {
                                            setOpenModal('');
                                            navigate('/terms');
                                        }}
                                    >
                                        Terms of Service.
                                    </div>
                                </div>
                                <div className="text-lg text-black dark:text-white text-center">
                                    Have an account?
                                    <div
                                        onClick={() => {
                                            setPage(1);
                                        }}
                                        className="cursor-pointer font-bold text-accent hover:text-accentHover dark:text-accentLight dark:hover:text-accentLightHover"
                                    >
                                        Log in
                                    </div>
                                </div>
                            </div>
                        </ModalContentTop>
                        {/*<ModalContentBottom>*/}
                        {/*    <SubsectionTitle dark={context.dark}>*/}
                        {/*        Already have an account?{' '}*/}
                        {/*        <SubsectionLink onClick={() => changePage(1)}>*/}
                        {/*            Log in*/}
                        {/*        </SubsectionLink>*/}
                        {/*    </SubsectionTitle>*/}
                        {/*</ModalContentBottom>*/}
                    </>
                )}
                {page === 3 && (
                    <>
                        <ModalContentTop>
                            <SubsectionSubtitle dark={context.dark}>
                                {
                                    "No worries! We'll email you instructions to reset it."
                                }
                            </SubsectionSubtitle>
                            <Form>
                                <SubsectionSubtext dark={context.dark}>
                                    Email Address
                                    {error !== '' && (
                                        <ErrorText>{error}</ErrorText>
                                    )}
                                </SubsectionSubtext>
                                <Input
                                    dark={context.dark}
                                    error={error !== ''}
                                    placeholder={'hello@me.com'}
                                    value={resetPasswordEmail}
                                    onChange={e =>
                                        setResetPasswordEmail(e.target.value)
                                    }
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            forgotPassword();
                                        }
                                    }}
                                />
                            </Form>

                            <button
                                onClick={forgotPassword}
                                className="flex font-medium py-2 px-3 justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-white bg-accent hover:bg-accentHover shadow"
                            >
                                Continue
                            </button>
                        </ModalContentTop>
                        <ModalContentBottom>
                            <SubsectionTitle dark={context.dark}>
                                <div
                                    className="block text-sm font-bold cursor-pointer text-accent hover:text-accentHover dark:text-accentLight dark:hover:text-accentLightHover"
                                    onClick={() => changePage(1)}
                                >
                                    Back to log in
                                </div>
                            </SubsectionTitle>
                        </ModalContentBottom>
                    </>
                )}
                {page === 4 && (
                    <>
                        <SubsectionTitle dark={context.dark}>
                            We sent a reset link to{' '}
                            <UserEmail>{resetPasswordEmail}</UserEmail>
                        </SubsectionTitle>
                        {isLoading && (
                            <SubsectionTitle style={{ color: 'blue' }}>
                                Loading...
                            </SubsectionTitle>
                        )}
                        <SubsectionTitle dark={context.dark}>
                            {"Didn't receive an email?"}{' '}
                            <div
                                className="block text-sm font-bold cursor-pointer text-accent hover:text-accentHover dark:text-accentLight dark:hover:text-accentLightHover"
                                onClick={forgotPassword}
                            >
                                Try again
                            </div>
                        </SubsectionTitle>
                    </>
                )}
                {page === 5 && (
                    <>
                        <ModalContentTop>
                            <SubsectionTitle dark={context.dark}>
                                We sent a verification link to{' '}
                                <UserEmail>{email}</UserEmail>
                            </SubsectionTitle>
                        </ModalContentTop>
                        <ModalContentBottom>
                            <SubsectionTitle dark={context.dark}>
                                {"Didn't receive an email?"}{' '}
                                <div
                                    className="inline text-sm font-bold cursor-pointer text-accent hover:text-accentHover dark:text-accentLight dark:hover:text-accentLightHover"
                                    onClick={() => {
                                        request_verify_token.mutate(
                                            { email },
                                            {
                                                onSuccess: () => {
                                                    setSuccess(
                                                        'Verification email sent!',
                                                    );
                                                },
                                                onError: error => {
                                                    if (
                                                        error.response.data
                                                            .detail ===
                                                        'REGISTER_USER_ALREADY_EXISTS'
                                                    ) {
                                                        setError(
                                                            'User already registered with this email',
                                                        );
                                                    } else {
                                                        setError(
                                                            error.response.data
                                                                .detail,
                                                        );
                                                    }
                                                },
                                            },
                                        );
                                    }}
                                >
                                    Try again
                                </div>
                            </SubsectionTitle>
                            {error && <ErrorText>{error}</ErrorText>}
                            {success && <SuccessText>{success}</SuccessText>}
                        </ModalContentBottom>
                    </>
                )}
                {page === 6 && (
                    <>
                        <ModalContentTop>
                            <TermsBox
                                style={{
                                    color: context.isDark ? 'white' : 'black',
                                }}
                            >
                                <TermsOfService />
                            </TermsBox>
                            <CheckList>
                                <CheckWrapper>
                                    <Checkbox
                                        check={checkGuidelines}
                                        setCheck={setCheckGuidelines}
                                    />

                                    <CheckLabel dark={context.dark}>
                                        I agree to the{' '}
                                        <b>Community Guidelines</b>
                                    </CheckLabel>
                                </CheckWrapper>
                                <CheckWrapper>
                                    <Checkbox
                                        check={checkTerms}
                                        setCheck={setCheckTerms}
                                    />
                                    <CheckLabel dark={context.dark}>
                                        I agree to the <b>Terms of Service</b>
                                    </CheckLabel>
                                </CheckWrapper>
                            </CheckList>
                        </ModalContentTop>
                        <ModalContentBottom>
                            <button
                                disabled={register.isPending}
                                className="flex font-medium py-2 px-3 justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-white bg-accent hover:bg-accentHover shadow"
                            >
                                {register.isPending ? (
                                    <div
                                        style={{
                                            height: 28,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ButtonLoaderWhite />
                                    </div>
                                ) : (
                                    'Continue'
                                )}
                            </button>
                            {error && <ErrorText>{error}</ErrorText>}
                            {success && <SuccessText>{success}</SuccessText>}
                        </ModalContentBottom>
                    </>
                )}
            </ModalContentWrapper>
        </BaseModal>
    );
};

export default LoginModal;

const returnModalTitle = page => {
    const context = useContext(GeneralContext);
    switch (page) {
        case 3:
            return 'Forgot your password?';
        case 4:
            return 'Reset Password';
        case 5:
            return 'Please verify your email address';
        case 6:
            return 'Commit to keeping the Subi.ai community safe and positive';
        default:
            return (
                <div className="flex flex-col gap-5">
                    <div className="flex content-center justify-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="172"
                            height="50"
                            viewBox="0 0 172 50"
                            fill="none"
                        >
                            <path
                                d="M4.4042 29.4028C4.18437 29.188 3.83383 29.188 3.614 29.4028C1.28498 31.6814 0.00164795 34.7116 0.00164795 37.9387C0.00164795 41.1658 1.28498 44.1961 3.614 46.4747C6.01431 48.825 9.16324 50.0001 12.3181 50.0001C15.473 50.0001 18.6219 48.825 21.0222 46.4747C21.1292 46.3674 21.1945 46.2182 21.1945 46.0691C21.1945 45.92 21.1351 45.7709 21.0222 45.6635L4.4042 29.4028Z"
                                fill="#EB0A74"
                            />
                            <path
                                d="M3.61418 3.52012C1.28517 5.80474 0.00183105 8.83498 0.00183105 12.0621C0.00183105 15.2892 1.28517 18.3194 3.61418 20.5981C3.72113 20.7054 3.86966 20.7591 4.00631 20.7591C4.14297 20.7591 4.2915 20.7054 4.39844 20.5981L21.0165 4.33734C21.1234 4.22997 21.1888 4.08084 21.1888 3.93171C21.1888 3.78259 21.1293 3.63346 21.0165 3.52609C16.2218 -1.17437 8.41481 -1.17437 3.61418 3.52012Z"
                                fill="#EB0A74"
                            />
                            <path
                                d="M46.7959 29.4028L30.1779 45.6635C30.071 45.7709 30.0056 45.92 30.0056 46.0691C30.0056 46.2182 30.065 46.3674 30.1779 46.4747C32.5782 48.825 35.7272 49.9941 38.882 49.9941C42.0369 49.9941 45.1858 48.819 47.5861 46.4747C49.9151 44.1961 51.1985 41.1658 51.1985 37.9387C51.1985 34.7116 49.9151 31.6814 47.5861 29.4028C47.3663 29.188 47.0158 29.188 46.7959 29.4028Z"
                                fill="#EB0A74"
                            />
                            <path
                                d="M46.7959 20.5982C46.9029 20.7056 47.0455 20.7593 47.1881 20.7593C47.3306 20.7593 47.4732 20.7056 47.5802 20.5982C49.9092 18.3196 51.1925 15.2893 51.1925 12.0622C51.1925 8.83514 49.9092 5.80489 47.5802 3.52624C42.7796 -1.16825 34.9726 -1.16825 30.1779 3.52624C30.071 3.63361 30.0056 3.78274 30.0056 3.93186C30.0056 4.08099 30.065 4.23012 30.1779 4.33749L46.7959 20.5982Z"
                                fill="#EB0A74"
                            />
                            <path
                                d="M95.5686 27.5876C95.1646 26.8897 94.5705 26.2096 93.81 25.5535C93.0614 24.9152 92.3959 24.4082 91.8137 24.0324C91.2255 23.6506 90.3878 23.239 89.3302 22.8096C88.2786 22.386 87.4408 22.0639 86.8289 21.8611C86.2229 21.6583 85.3316 21.372 84.1493 21.0022C81.339 20.1372 79.4259 19.326 78.4575 18.6042C77.5425 17.9182 77.0969 17.0533 77.0969 15.9498C77.0969 14.9178 77.489 14.1006 78.303 13.4564C79.1289 12.7942 80.2993 12.4602 81.7846 12.4602C85.0286 12.4602 87.3339 14.0171 88.8192 17.2143C88.8608 17.3098 88.944 17.3814 89.045 17.4172C89.146 17.447 89.2589 17.4351 89.348 17.3814L95.735 13.6771C95.9073 13.5757 95.9726 13.3609 95.8835 13.182C94.5408 10.4082 92.6336 8.20712 90.2155 6.64428C87.7973 5.08144 84.9573 4.28809 81.7846 4.28809C78.2258 4.28809 75.1363 5.36776 72.5993 7.49728C70.0445 9.6447 68.7493 12.5616 68.7493 16.1585C68.7493 17.9779 69.0761 19.5944 69.7237 20.9604C70.3713 22.3383 71.3278 23.4955 72.5696 24.3963C73.7757 25.2731 75.0055 25.983 76.2176 26.5079C77.4177 27.0269 78.9031 27.5518 80.638 28.0707C83.7037 28.9416 85.8604 29.7529 87.0368 30.4866C88.1419 31.1726 88.6766 32.1091 88.6766 33.3438C88.6766 34.4116 88.237 35.2706 87.3339 35.9744C86.4189 36.6902 84.9217 37.0541 82.8897 37.0541C78.6179 37.0541 75.8373 35.199 74.3817 31.3873C74.3401 31.2859 74.2569 31.2024 74.1559 31.1666C74.0549 31.1308 73.9361 31.1427 73.841 31.1964L67.3471 35.0081C67.1926 35.0976 67.1213 35.2944 67.1808 35.4614C68.268 38.4738 70.1693 40.8896 72.831 42.6374C75.4868 44.3851 78.7961 45.268 82.6699 45.268C86.8823 45.268 90.364 44.1823 93.0079 42.0349C95.6756 39.8696 97.0302 36.905 97.0302 33.2305C97.0302 32.0971 96.8876 31.0294 96.6024 30.0571C96.3172 29.0908 95.9726 28.2557 95.5746 27.5697L95.5686 27.5876Z"
                                fill={
                                    context.dark ? colors.white : colors.black
                                }
                            />
                            <path
                                d="M125.977 16.1287H118.877C118.669 16.1287 118.502 16.2957 118.502 16.5045V31.423C118.502 33.5943 117.985 35.2347 116.958 36.2965C115.936 37.3583 114.545 37.8951 112.828 37.8951C111.284 37.8951 110.042 37.4418 109.127 36.5411C108.218 35.6463 107.754 34.3161 107.754 32.5862V16.5045C107.754 16.2957 107.588 16.1287 107.38 16.1287H100.28C100.072 16.1287 99.9058 16.2957 99.9058 16.5045V33.4691C99.9058 37.1256 100.934 40.0425 102.96 42.1303C104.986 44.224 107.588 45.2858 110.689 45.2858C114.088 45.2858 116.714 44.236 118.514 42.1542V44.1346C118.514 44.3433 118.681 44.5104 118.889 44.5104H125.988C126.196 44.5104 126.363 44.3433 126.363 44.1346V16.5045C126.363 16.2957 126.196 16.1287 125.988 16.1287H125.977Z"
                                fill={
                                    context.dark ? colors.white : colors.black
                                }
                            />
                            <path
                                d="M147.288 15.353C143.729 15.353 140.86 16.4924 138.751 18.7412V5.83283C138.751 5.62405 138.584 5.45703 138.376 5.45703H131.276C131.068 5.45703 130.902 5.62405 130.902 5.83283V44.1344C130.902 44.3432 131.068 44.5102 131.276 44.5102H138.376C138.584 44.5102 138.751 44.3432 138.751 44.1344V41.8975C140.86 44.1463 143.729 45.2857 147.288 45.2857C151.085 45.2857 154.37 43.8242 157.056 40.9371C159.735 38.062 161.09 34.4889 161.09 30.3194C161.09 26.1498 159.729 22.5767 157.056 19.7016C154.37 16.8145 151.085 15.353 147.288 15.353ZM145.963 22.9048C148.049 22.9048 149.801 23.6027 151.174 24.9747C152.54 26.3526 153.236 28.1481 153.236 30.3134C153.236 32.4787 152.54 34.2802 151.174 35.6521C149.807 37.03 148.055 37.722 145.963 37.722C143.872 37.722 142.131 37.0241 140.782 35.6521C139.428 34.2802 138.745 32.4787 138.745 30.3074C138.745 28.1361 139.428 26.3407 140.782 24.9627C142.131 23.5908 143.878 22.8929 145.963 22.8929V22.9048Z"
                                fill={
                                    context.dark ? colors.white : colors.black
                                }
                            />
                            <path
                                d="M164.524 16.1287H171.624C171.832 16.1287 171.998 16.2957 171.998 16.5045V44.1346C171.998 44.3433 171.832 44.5104 171.624 44.5104H164.524C164.316 44.5104 164.15 44.3433 164.15 44.1346V16.5045C164.15 16.2957 164.316 16.1287 164.524 16.1287Z"
                                fill={
                                    context.dark ? colors.white : colors.black
                                }
                            />
                            <path
                                d="M171.624 5.52881H164.518C164.312 5.52881 164.144 5.69706 164.144 5.90461V10.8317C164.144 11.0393 164.312 11.2075 164.518 11.2075H171.624C171.831 11.2075 171.998 11.0393 171.998 10.8317V5.90461C171.998 5.69706 171.831 5.52881 171.624 5.52881Z"
                                fill={
                                    context.dark ? colors.white : colors.black
                                }
                            />
                        </svg>
                    </div>
                </div>
            );
    }
};

const TermsBox = styled.div`
    display: flex;
    max-width: 480px;
    height: 36vh;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: #f4f3ef;
    overflow-y: scroll;
    @media (max-width: 768px) {
        max-width: none;
    }
`;
const CheckWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
`;
const CheckLabel = styled.div`
    color: ${props => (props.dark ? 'white' : '#141718')};

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.18px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;
const CheckList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
`;

const SubsectionSubtext = styled.div`
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 21px */
    width: 100%;
    color: ${props => (props.dark ? 'white' : '#141718')};
`;

const SubsectionTitle = styled.div`
    color: ${props => (props.dark ? 'white' : '#141718')};
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
`;

const SubsectionSubtitle = styled.div`
    color: ${props => (props.dark ? 'white' : '#141718')};
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const ModalContentWrapper = styled.div`
    padding: 0px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    gap: 15px;
    @media (max-width: 768px) {
        padding: 0px 20px 40px 20px;
        justify-content: space-between;
    }
`;
const ModalContentTop = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
`;
const ModalContentBottom = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (max-width: 768px) {
        gap: 40px;
    }
    width: 100%;
`;

const ModalTitle = styled.div`
    color: ${props => (props.dark ? 'white' : '#141718')};
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.56px;
    padding: 20px 40px 40px 40px;
    @media (max-width: 768px) {
        padding: 10px 20px 40px 20px;
        padding-bottom: ${props =>
            (props.page === 3 || props.page === 2 || props.page === 5) &&
            '35px'};
    }

    @media (max-width: 330px) {
        font-size: 24px;
    }
    padding-bottom: ${props =>
        (props.page === 3 || props.page === 2 || props.page === 5) && '35px'};
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

const UserEmail = styled.div`
    font-weight: 700;
    display: inline;
    @media (max-width: 768px) {
        display: block;

`;
