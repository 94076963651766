// @ts-ignore
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import styled from 'styled-components';
import { GeneralContext } from '../../context/GeneralContext';
import { useNavigate } from 'react-router-dom';
import { FilterListCircle, Plus, Search, Trash } from 'iconoir-react';
import { formatTimestamp, getAiFromConvo } from '../../util/utils.jsx';
import { useAdminRequestList } from '@/hooks/useAdminRequestList';
import { fetchSuggestedChats } from '@/util/api';
import {
    keepPreviousData,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { UserChats } from './ConversationList';
import { UserActivity } from './UserActivity';

import { useUnreadChats } from '../../hooks/useUnreadChats';
import Popover from '@mui/material/Popover';

const RequestList = () => {
    const context = useContext(GeneralContext);
    const { data: unread } = useUnreadChats();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState('Show all');
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const onTabClick = tab => {
        setActiveTab(tab);
        setFilter('Show all');
    };
    const [inputText, setInputText] = useState('');
    const [debouncedInputText, setDebouncedInputText] = useState('');
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useState(0);
    const [isFocused, setIsFocused] = useState(false);
    const AdminTabs = [
        { title: 'My requests', length: 0 },
        { title: 'All requests', length: 0 },
    ];

    const DefaultTabs = [
        { title: 'Personal', length: unread ? unread.length : 0 },
        {
            title: 'External',
            length: 0,
            // activeRequests
            // ? Object.values(activeRequests).reduce(
            //       (sum, list) => sum + list.length,
            //       0,
            //   )
            // : 0,
        },
    ];

    useEffect(() => {
        if (inputText === '') {
            queryClient.removeQueries({ queryKey: ['suggestedChats'] });
        }
        const debounceTimer = setTimeout(() => {
            setDebouncedInputText(inputText);
        }, 500);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [inputText]);

    const { data: suggestedChats = undefined, isLoading } = useQuery({
        queryKey: ['suggestedChats', debouncedInputText],
        queryFn: () => fetchSuggestedChats(inputText),
        placeholderData: keepPreviousData,
        enabled: debouncedInputText.length >= 3,
    });
    useEffect(() => {
        setActiveTab(0);
    }, [context.admin]);
    const activity = activeTab === 1 && context.admin === false;

    return (
        <>
            <div className="relative w-full h-full">
                <Tabs
                    options={context.admin ? AdminTabs : DefaultTabs}
                    active={activeTab}
                    onTabClick={onTabClick}
                />
                {!activity && (
                    <div className="flex gap-5 px-5 pt-5 self-stretch items-center pl-5">
                        <div className="cursor-pointer text-black dark:text-white hover:text-opacity-70 dark:hover:text-opacity-80">
                            <FilterListCircle onClick={handleClick} />
                            <FilterPopover
                                anchorEl={anchorEl}
                                filters={
                                    context.admin
                                        ? []
                                        : activeTab === 0
                                          ? ['Unread', 'Show all']
                                          : [
                                                'Needs attention',
                                                'In progress',
                                                'Ready for review',
                                                'Complete',
                                                'Show all',
                                            ]
                                }
                                setAnchorEl={setAnchorEl}
                                setFilter={setFilter}
                                filter={filter}
                            />
                        </div>
                        <div className="h-12 text-black dark:text-white dark:text-opacity-30 gap-2.5 flex items-center relative hover:text-opacity-50 text-opacity-30 w-full ">
                            {!isFocused && inputText === '' && (
                                <div className={`absolute left-2`}>
                                    <Search strokeWidth={2} />
                                </div>
                            )}
                            <input
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                className={`border-black focus:border-opacity-100 focus:outline-none bg-white dark:bg-black opacity-50 focus:opacity-100 text-black dark:text-white leading-none w-full rounded-xl border-2 dark:border-white hover:border-opacity-50 border-opacity-5 dark:border-opacity-10 p-3 ${!isFocused && inputText === '' && 'pl-9'}`}
                                type="text"
                                value={inputText}
                                onChange={e => setInputText(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                <div
                    className="pl-5 pr-2 md:pl-2.5 md:pr-1.5"
                    style={{
                        overflow: 'auto',
                        height:
                            (context.width < 768
                                ? context.height - 198
                                : context.height - 179) +
                            68 * +activity,
                    }}
                    id="chats-wrapper"
                >
                    <Requests
                        activeTab={activeTab}
                        search={inputText}
                        searchChats={suggestedChats}
                        unread={unread}
                        filter={filter}
                    />
                    {context.width >= 768 && <NewChatButton />}
                </div>
            </div>
            {context.width < 768 && <NewChatButton />}
        </>
    );
};

const NewChatButton = () => {
    const navigate = useNavigate();

    const handleNewChat = () => {
        navigate('/');
    };

    return (
        <div className="absolute bottom-2.5 w-full flex right-2.5 justify-end">
            <button
                onClick={handleNewChat}
                className="p-1 bg-accent hover:bg-accentHover rounded-full shadow-lg text-white text-lg leading-relaxed"
            >
                <Plus height={40} width={40} color="white" />
            </button>
        </div>
    );
};

export default RequestList;

const FilterPopover = ({
    anchorEl,
    setAnchorEl,
    setFilter,
    filter,
    filters,
}) => {
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (f: string) => {
        setFilter(f);
        handleClose();
    };

    return (
        <Popover
            disableAutoFocus={true}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    marginTop: '15px',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.14);',
                },
            }}
        >
            <div className="flex flex-col p-2 font-medium bg-white dark:bg-black text-black dark:text-white">
                {filters.map(f => (
                    <div
                        key={f}
                        className={`${filter === f && 'text-accent'} flex flex-row gap-2.5 text-base  font-medium leading-relaxed py-2 px-3 cursor-pointer no-underline hover:bg-lightGray dark:hover:bg-darkGray rounded-lg`}
                        onClick={() => {
                            handleClick(f);
                        }}
                    >
                        {f}
                    </div>
                ))}
            </div>
        </Popover>
    );
};

const Requests = ({ activeTab, search, searchChats, unread, filter }) => {
    const context = useContext(GeneralContext);

    if (search !== '' && searchChats) {
        return (
            <div className="flex flex-col gap-2.5 pt-6">
                {searchChats.map(element => (
                    <SearchChatPreview element={element} />
                ))}
            </div>
        );
    }

    if (context.admin) {
        return <AdminRequestList />;
    } else {
        if (activeTab === 1) {
            return <UserActivity search={search} filter={filter} />;
        } else if (activeTab === 0) {
            return (
                <UserChats search={search} unread={unread} filter={filter} />
            );
        }
    }

    return <></>;
};

const AdminRequestList = () => {
    const { data: adminRequests, fetchNextPage } = useAdminRequestList();
    const allConversations = adminRequests?.pages.flat();

    return (
        <>
            {adminRequests && (
                <InfiniteScroll
                    dataLength={
                        allConversations.length > 0
                            ? allConversations.length
                            : 0
                    } //total conversation count
                    next={fetchNextPage} //function to fetch the next page
                    hasMore={true} //whether there's a next page
                    scrollableTarget={'chats-wrapper'}
                    loader={<h4>Loading...</h4>} //element to be shown while loading
                    endMessage={<></>}
                >
                    <div className="flex flex-col gap-2.5 pt-4  ">
                        {allConversations.map(element => (
                            <AdminPreview element={element} key={element.id} />
                        ))}
                        {/*{Object.entries(conversationBuckets).map(*/}
                        {/*    ([status, conversations]) => (*/}
                        {/*        <div*/}
                        {/*            className="flex flex-col gap-2.5 pt-6"*/}
                        {/*            key={status}*/}
                        {/*        >*/}
                        {/*            <div className="font-indivisible overflow-hidden text-black text-sm text-opacity-40 text-base font-normal leading-none uppercase">{`${status}(${conversations.length})`}</div>*/}
                        {/*            {conversations.map(element => (*/}
                        {/*                <RequestPreview element={element} />*/}
                        {/*            ))}*/}
                        {/*        </div>*/}
                        {/*    ),*/}
                        {/*)}*/}
                    </div>
                </InfiniteScroll>
            )}
        </>
    );
};

const AdminPreview = ({ element, status }) => {
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    const handleChatClick = chat => {
        context.setOpenModal('');
        navigate(`/chat/${chat?.conversation_id}`);
    };

    // check if any of the messages in context.unreadMessages has a conversation_id = element?.conversation?.id

    return (
        <div
            style={{
                width: context.width >= 768 && '329px',
            }}
            className="flex flex-col gap-2.5 bg-lightGray hover:bg-lightGrayHover text-black dark:text-white dark:bg-white dark:bg-opacity-10 px-4 py-5 rounded-xl justify-between cursor-pointer"
            onClick={() => handleChatClick(element)}
            key={element.conversation?.id}
        >
            <div className="flex flex-row ">
                <div
                    style={{
                        width:
                            context.width >= 768
                                ? '225px'
                                : context.width - 115,
                    }}
                    className="flex-row flex-grow-1 select-none "
                >
                    <ChatContentTop>
                        <ChatContentTopLeft>
                            {parseNotifcaiton(status)}
                            {element.summary}
                        </ChatContentTopLeft>
                    </ChatContentTop>
                </div>

                <StyledTime>{formatTimestamp(element.created_at)}</StyledTime>
            </div>
            <div
                style={{
                    fontSize: '15px',
                    color: context.dark ? 'white' : '#141718',
                }}
                className="overflow-hidden flex flex-row items-center justify-between text-ellipsis font-normal leading-none whitespace-nowrap"
            >
                <div className="flex flex-row gap-2 items-center">
                    <ChatIcon
                        style={{
                            backgroundImage: element.user.avatar_path
                                ? `url('${element.user.avatar_path}')`
                                : '',
                        }}
                    />
                    <div> {'for ' + element.user.display_name}</div>
                </div>
                <div
                    className="z-9 bg-secondaryAccent text-white rounded-xl p-1.5 select-none cursor-pointer"
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`/chat/${element.job_conversation_id}`);
                    }}
                >
                    Job
                </div>
            </div>
            <Icon className="icon">
                <Trash
                    onClick={e => {
                        e.stopPropagation();
                        context.setDeleteOpen(element.conversation?.id);
                    }}
                    width={18}
                    height={18}
                    color={'#E94235'}
                    fill={'none'}
                />
            </Icon>
        </div>
    );
};

const parseNotifcaiton = status => {
    if (status === 'ready_for_review' || status === 'needs_user_input') {
        return <UnreadDot />;
    }
    if (status === 'approved') {
        return <UnreadDot style={{ backgroundColor: '#3EA515' }} />;
    }
    return;
};

const parseDescription = ({ status, description }) => {
    if (status === 'ready_for_review') {
        return 'Input needed';
    }
    if (status === 'approved') {
        return 'Finalizing';
    }
    return description;
};

function findObjectByConversationId(obj, conversationId) {
    return Object.values(obj)
        .flatMap(list => list)
        .find(item => item.id === conversationId);
}

export const RequestPreview = ({
    element,
    type,
    status = '',
    unreadChats = [],
}) => {
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    const handleChatClick = chat => {
        context.setOpenModal('');
        type === 'conversation'
            ? navigate(`/chat/${chat?.conversation.id}`)
            : navigate(`/chat/${chat?.conversation_id}`);
    };
    const unread =
        type === 'conversation'
            ? unreadChats.find(
                  item => item.conversation.id === element.conversation.id,
              ) && <UnreadDot />
            : parseNotifcaiton(status);

    // check if any of the messages in context.unreadMessages has a conversation_id = element?.conversation?.id
    const title =
        type === 'conversation' ? element.conversation?.goal : element.summary;
    const time =
        type === 'conversation'
            ? element.last_message_timestamp
                ? formatTimestamp(element.last_message_timestamp)
                : element.conversation.updated_at
                  ? formatTimestamp(element.conversation.updated_at)
                  : formatTimestamp(element.conversation.created_at)
            : formatTimestamp(element.updated_at);
    const description =
        type === 'conversation'
            ? element.msg_content
            : parseDescription({
                  status: status,
                  description: element.description,
              });
    return (
        <div
            style={{
                width: context.width >= 768 && '330px',
            }}
            className="flex flex-col gap-2.5 bg-lightGray hover:bg-lightGrayHover text-black dark:text-white dark:bg-white hover:dark:bg-white hover:dark:bg-opacity-15 dark:bg-opacity-10 px-4 py-5 rounded-xl justify-between cursor-pointer"
            onClick={() => handleChatClick(element)}
            key={element.conversation?.id}
        >
            <div className="flex flex-row ">
                <div
                    style={{
                        width:
                            context.width >= 768
                                ? '225px'
                                : context.width - 135,
                    }}
                    className="flex-row flex-grow-1 select-none "
                >
                    <ChatContentTop>
                        <ChatContentTopLeft>
                            {unread}
                            {title}
                        </ChatContentTopLeft>
                    </ChatContentTop>
                </div>
                <StyledTime className="text-black dark:text-white">
                    {time}
                </StyledTime>
            </div>
            <div
                style={{
                    fontSize: '15px',
                    opacity: status === 'approved' && 0.7,
                }}
                className="overflow-hidden text-ellipsis font-normal leading-none whitespace-nowrap"
            >
                {description}
            </div>
            <Icon className="icon">
                <Trash
                    onClick={e => {
                        e.stopPropagation();
                        context.setDeleteOpen(element.conversation?.id);
                    }}
                    width={18}
                    height={18}
                    color={'#E94235'}
                    fill={'none'}
                />
            </Icon>
        </div>
    );
};

const StyledTime = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    /* 13 Header */
    font-family: Indivisible;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    opacity: 0.5;
    width: 71px;
    justify-content: right;
    display: flex;
    align-items: top;
    padding-top: 1px;
`;

const SearchChatPreview = ({ element }) => {
    const aiUser = getAiFromConvo(element.conversation);
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    const handleChatClick = chat => {
        context.setOpenModal('');
        navigate(`/chat/${chat?.conversation_id}`);
    };
    // check if any of the messages in context.unreadMessages has a conversation_id = element?.conversation?.id

    return (
        <div
            className="flex gap-4 bg-lightGray hover:bg-lightGrayHover cursor-pointer dark:text-white text-black dark:bg-white dark:bg-opacity-10 px-4 py-5 rounded-xl items-center"
            onClick={() => handleChatClick(element)}
            key={element.conversation_id}
        >
            <div className="flex-row flex-grow-1">
                <ChatContentTop
                    mobileWidth={context.width < 768 ? context.width : 330}
                    style={{ width: context.width >= 768 && '253px' }}
                >
                    {' '}
                    <ChatContentTopLeft>
                        {/*{isUnread && <UnreadDot />}*/}
                        {aiUser?.display_name}
                    </ChatContentTopLeft>
                </ChatContentTop>
                <div className="overflow-hidden text-ellipsis text-sm font-normal leading-none">
                    {element.content}
                </div>
            </div>
            <ChatIcon
                style={{
                    backgroundImage: aiUser?.avatar_path
                        ? `url('${aiUser.avatar_path}')`
                        : '',
                }}
            />
            <Icon className="icon">
                <Trash
                    onClick={e => {
                        e.stopPropagation();
                        context.setDeleteOpen(element.conversation_id);
                    }}
                    width={18}
                    height={18}
                    color={'#E94235'}
                    fill={'none'}
                />
            </Icon>
        </div>
    );
};

const Tabs = ({ options, active, onTabClick }) => {
    const handleClick = tab => {
        onTabClick(tab);
    };
    return (
        <div className="flex flex-row font-medium text-center">
            {options.map((option, i) => (
                <div
                    key={option.title}
                    onClick={() => handleClick(i)}
                    style={{
                        lineHeight: '15px',
                        fontSize: '15px',
                        width: `calc(100%/${options.length})`,
                    }}
                    className={`flex flex-row gap-2 p-2.5 cursor-pointer justify-center items-center border-b-2 dark:text-white dark:border-white ${
                        active === i
                            ? 'border-secondaryAccent text-secondaryAccent'
                            : 'border-black border-opacity-10 dark:border-opacity-10 dark:hover:border-opacity-50 text-opacity-50 text-black dark:text-opacity-60 hover:text-opacity-80 hover:border-opacity-30'
                    }`}
                >
                    <div style={{ paddingTop: '4px' }}> {option.title}</div>
                    {option.length > 0 && (
                        <div
                            style={{
                                background: '#EB0A74',
                                borderRadius: '100px',
                                fontSize: '12px',
                                padding: '4px',
                                paddingTop: '2.5px',
                                paddingBottom: '1.5px',
                                color: 'white',
                                userSelect: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '16px',
                                fontWeight: 600,
                            }}
                        >
                            {option.length}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const ChatIcon = styled.div`
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-size: cover;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    z-index: 0;

    justify-content: center;
    align-items: center;
    font-weight: bold;
`;

const ChatContentTop = styled.div`
    display: flex;
    justify-content: space-between;

    width: ${props => `${props.mobileWidth - 137}px`};
`;

const ChatContentTopLeft = styled.div`
    align-items: center;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    line-height: 100%;
    text-overflow: ellipsis;
`;

const Icon = styled.div`
    display: none;
    position: absolute;
    height: 67px;
    width: 175px;
    right: 0px;
    flex-direction: row;
    align-items: center;
    padding-right: 12px;
    justify-content: right;

    background: linear-gradient(270deg, #f8f8f5 20.31%, gba(248, 248, 0) 100%);
`;

const UnreadDot = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #eb0a74;
    margin-right: 8px;
    display: inline-block;
    margin-bottom: 2px;
`;
