import { useContext, useEffect } from 'react';
import { GeneralContext } from '@/context/GeneralContext';
import loginbg from '@/assets/loginbg.png';

const LoginPage = ({ register }: { register: boolean }) => {
    const context = useContext(GeneralContext);

    useEffect(() => {
        if (register) {
            context.setRegister(true);
        }

        context.setOpenModal('Log in');
    }, []);

    return (
        <div
            id={'test'}
            style={{
                backgroundImage: `url(${loginbg})`,
                height: context.height,
                backgroundSize: 'cover',
                width: '100%',
            }}
        ></div>
    );
};

export default LoginPage;
