import axiosWithRetry from '../util/axiosRetry';

export const askPermission = async vapid_key => {
    if ('Notification' in window) {
        return new Promise(function (resolve, reject) {
            const permissionResult = Notification.requestPermission(
                function (result) {
                    resolve(result);
                },
            );

            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        }).then(async function (permissionResult) {
            if (permissionResult === 'granted') {
                await subscribeToPushNotifications(vapid_key);
            }
        });
    } else {
        console.log('This browser does not support desktop notification');
    }
};

export const subscribeToPushNotifications = async vapid_key => {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapid_key,
            });
            console.debug('Push subscription:', subscription);
            await sendSubscriptionToServer(subscription);
        } catch (error) {
            console.error('Error subscribing to push notifications:', error);
        }
    } else {
        console.error('Service workers not supported in this browser.');
    }
};

const sendSubscriptionToServer = async subscription => {
    try {
        await axiosWithRetry.post('/notify/subscribe', subscription);
    } catch (error) {
        console.error('Error sending subscription to server:', error);
    }
};

export const sendNotificationToUser = async (userId, data) => {
    try {
        const response = await axiosWithRetry.post(`/notify`, data);
    } catch (error) {
        console.error('Error sending notification:', error);
    }
};
