import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { axiosDefault } from '@/util/axiosRetry';

export const useUnreadChats = () => {
    async function fetchUnread() {
        const response = await axiosDefault.get('/user/conversations', {
            params: {
                unread: true,
            },
        });
        return response.data;
    }

    const { data } = useQuery({
        queryKey: ['unread'],
        queryFn: fetchUnread,
        staleTime: 1000,
        placeholderData: keepPreviousData,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    return { data };
};
