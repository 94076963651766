import { axiosDefault } from '../util/axiosRetry';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { GeneralContext } from '../context/GeneralContext';

export const useActivityList = () => {
    const context = useContext(GeneralContext);
    const getMoreChats = async ({ pageParam }) => {
        const response = await axiosDefault.get('/user/activity', {
            params: {
                page: pageParam,
            },
        });

        return response.data;
    };

    const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } =
        useInfiniteQuery({
            queryKey: ['activity'],
            placeholderData: keepPreviousData,
            queryFn: getMoreChats,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
            initialPageParam: 0,
            enabled: context.admin === false,
            getNextPageParam: (lastPage, pages) => {
                return pages ? pages.length : 0;
            },
        });
    return { data, fetchNextPage, isFetching, isFetchingNextPage };
};
