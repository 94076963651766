// @ts-ignore
import React from 'react';
import { RequestPreview } from './RequestList';
import { useActivityList } from '../../hooks/useActivityList';

export const UserActivity = ({ search, unread, filter }) => {
    const { data } = useActivityList();
    const output = filter === 'Unread' ? unread : data?.pages.flat();
    return (
        <>
            {output && search === '' && (
                <div
                    className="flex flex-col gap-2.5 pt-6"
                    key="Ready for review"
                >
                    {/*<div className="font-indivisible overflow-hidden text-black text-sm text-opacity-40 text-base font-normal leading-none uppercase">{`Ready for Review (${_data.length})`}</div>*/}
                    {output?.map((element, index) => (
                        <RequestPreview
                            key={element.conversation_id || index}
                            element={element}
                            type={'conversation'}
                            unreadChats={unread}
                        />
                    ))}
                </div>
            )}
            {/*{data && search === '' && (*/}
            {/*    <InfiniteScroll*/}
            {/*        dataLength={dataLength} //total conversation count*/}
            {/*        next={fetchNextPage} //function to fetch the next page*/}
            {/*        hasMore={true} //whether there's a next page*/}
            {/*        scrollableTarget={'chats-wrapper'}*/}
            {/*        loader={<h4>Loading...</h4>} //element to be shown while loading*/}
            {/*        endMessage={*/}
            {/*            <p style={{ textAlign: 'center' }}>*/}
            {/*                <b>Yay! You have seen it all</b>*/}
            {/*            </p>*/}
            {/*        }*/}
            {/*    >*/}
            {/*        {Object.entries(conversationBuckets).map(*/}
            {/*            ([status, conversations]) => (*/}
            {/*                <div*/}
            {/*                    className="flex flex-col gap-2.5 pt-6"*/}
            {/*                    key={status}*/}
            {/*                >*/}
            {/*                    /!*<div className="font-indivisible overflow-hidden text-black text-sm text-opacity-40 text-base font-normal leading-none uppercase">{`${status}(${conversations.length})`}</div>*!/*/}
            {/*                    {conversations.map(element => (*/}
            {/*                        <ChatPreview element={element} />*/}
            {/*                    ))}*/}
            {/*                </div>*/}
            {/*            ),*/}
            {/*        )}*/}
            {/*    </InfiniteScroll>*/}
            {/*)}*/}
        </>
    );
};
