import React, { useContext } from 'react';
import Markdown from 'markdown-to-jsx';
import { Wrench } from 'iconoir-react';
import { CodeBlock } from 'react-code-blocks';
import Lottie from 'lottie-react';
import thinkingAnimation from '@/assets/thinkingAnimation.json';
import styled from 'styled-components';
import { GeneralContext } from '@/context/GeneralContext';
import GoogleAuthButton from '@/components/GoogleAuthButton';
import '@/styles/markdownStyles.css';

const MessageContent = ({ markdownRef, msg }) => {
    const context = useContext(GeneralContext);

    const markdownOverrides = {
        code: { component: myCode },
        img: { component: myImg },
        a: { component: myA },
        pre: { component: myPre },
    };

    //tool call
    /*
    Tool call message. This is the object in msg.message:
        {
          "data": {
            "id": "run-61c2bbcd-6ca0-4fe9-9d25-7e6fc3ad53d2",
            "name": null,
            "type": "ai",
            "content": "Let's get started by integrating your Microsoft Office 365 account. I'll generate an authorization link for you to connect your MS Office account.\n\nPlease click the button below to authorize the integration:\n\n",
            "example": false,
            "tool_calls": [
              {
                "id": "call_hh6eupq4iqJKEqixLE3o3wZ7",
                "args": {
                  "account_name": "default",
                  "service_name": "ms_office"
                },
                "name": "oauth2_authentication"
              }
            ],
            "additional_kwargs": {
              "tool_calls": [
                {
                  "id": "call_hh6eupq4iqJKEqixLE3o3wZ7",
                  "type": "function",
                  "index": 0,
                  "function": {
                    "name": "oauth2_authentication",
                    "arguments": "{\"service_name\":\"ms_office\",\"account_name\":\"default\"}"
                  }
                }
              ],
              "sent_at_override": "2024-05-15T05:01:06.590044+00:00"
            },
            "response_metadata": {
              "finish_reason": "tool_calls"
            },
            "invalid_tool_calls": []
          },
          "type": "ai"
        }

     */
    if (msg.message.type === 'ai' && msg.message.data.tool_calls) {
        // list all the tool calls
        return (
            <>
                {msg.message.data.content && (
                    <div ref={markdownRef} className="markdown">
                        <Markdown
                            options={{
                                forceBlock: true,
                                overrides: markdownOverrides,
                            }}
                        >
                            {msg.message.data.content}
                        </Markdown>
                    </div>
                )}
                {msg.message.data.tool_calls &&
                    msg.message.data.tool_calls.map(tool_call => (
                        <div
                            className="flex py-4 px-3 gap-4 "
                            key={tool_call.id}
                        >
                            <Wrench
                                style={{ flexShrink: 0 }}
                                height={24}
                                width={24}
                            />
                            <div>
                                <div>{tool_call.name}</div>
                                {context.admin && (
                                    <>
                                        Arguments:
                                        <CodeBlock
                                            language="json"
                                            wrapLongLines={true}
                                            showLineNumbers={false}
                                            text={JSON.stringify(
                                                tool_call.args,
                                                null,
                                                4,
                                            )}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
            </>
        );
    }

    // tool response
    /*

        {
          "data": {
            "id": null,
            "name": null,
            "type": "tool",
            "content": "https://login.microsoft.com/Calendardfghdfghdfgh",
            "tool_call_id": "call_hh6eupq4iqJKEqixLE3o3wZ7",
            "additional_kwargs": {
              "name": "oauth2_authentication",
              "sent_at_override": "2024-05-15T05:01:06.590044+00:00"
            },
            "response_metadata": {}
          },
          "type": "tool"
        }

     */
    if (msg.type === 'tool') {
        let toolName = msg.message.data.additional_kwargs.name;
        let toolResponse = JSON.stringify(msg.message.data.content, null, 4);

        return (
            <>
                <div className="flex py-4 px-3 gap-4 ">
                    <Wrench style={{ flexShrink: 0 }} height={24} width={24} />
                    <div>
                        <div>{toolName}</div>
                        {context.admin && (
                            <>
                                {/*{toolInputFormatted !== null && (*/}
                                {/*    <>*/}
                                {/*        Original Arguments:*/}
                                {/*        <CodeBlock*/}
                                {/*            language="json"*/}
                                {/*            wrapLongLines={true}*/}
                                {/*            showLineNumbers={false}*/}
                                {/*            text={toolInputFormatted}*/}
                                {/*        />*/}
                                {/*    </>*/}
                                {/*)}*/}
                                {toolResponse && (
                                    <>
                                        Response:
                                        <CodeBlock
                                            language="json"
                                            wrapLongLines={true}
                                            showLineNumbers={false}
                                            text={toolResponse}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    let content = trimString(msg.message.data.content);

    return (
        <div ref={markdownRef} className="markdown">
            <Markdown options={{ overrides: markdownOverrides }}>
                {content[0]}
            </Markdown>
            {content[1] === true && (
                <Lottie
                    style={{ width: '30px' }}
                    animationData={thinkingAnimation}
                />
            )}
        </div>
    );
};

export default MessageContent;

const myPre = props => {
    const { children, className, ...rest } = props;

    return <pre style={{ whiteSpace: 'break-spaces' }}>{children}</pre>;
};

const myCode = props => {
    const { children, className, ...rest } = props;
    let match = /lang-(\w+)/.exec(className || '');
    return match ? (
        <UserSelect>
            <CodeBlock
                text={String(children).replace(/\n$/, '')}
                language={String(match[1])}
                showLineNumbers={false}
                wrapLongLines={true}
                // theme={solarizedlight}
            />
        </UserSelect>
    ) : (
        <>{children}</>
    );
};

const UserSelect = styled.div`
    span[style] {
        user-select: auto !important;
    }
`;

const trimString = str => {
    if (!str) {
        return ['', false];
    }

    let openBracketCount = 0;
    let clipStartIndex = 0;
    for (let i = 0; i < str.length; i++) {
        if (str[i] === '[') {
            if (openBracketCount === 0) {
                clipStartIndex = i;
                openBracketCount++;
            }
        } else if (
            openBracketCount > 0 &&
            str[i] === ']' &&
            str[i + 1] !== '('
        ) {
            openBracketCount--;
        } else if (str[i] === ')' && openBracketCount > 0) {
            clipStartIndex = i + 1;
            openBracketCount = 0;
        }
    }

    if (openBracketCount === 0) {
        return [str, false];
    } else {
        return [str.slice(0, clipStartIndex), true];
    }
};

const myImg = props => {
    return (
        <img
            {...props}
            style={{
                width: '100%',
            }}
        />
    );
};

const myA = props => {
    function isUrlValid(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }

    const isGoogleOAuthUrl = url => {
        const oauthUrlPattern =
            /^https:\/\/accounts\.google\.com\/o\/oauth2\/v2\/auth/;
        return oauthUrlPattern.test(url);
    };

    if (isGoogleOAuthUrl(props.href)) {
        return <GoogleAuthButton props={props} />;
    }

    // open links from other domains in new tab
    if (isUrlValid(props.href) === false) {
        return <>{props.children}</>;
    }
    const domain = new URL(props.href).hostname;
    // domain contains localhost
    if (domain === 'subi.ai' || domain === 'localhost') {
        return <a {...props} />;
    }
    // if not, open in new tab
    return <a {...props} target="_blank" rel="noopener noreferrer" />;
};
