const environment = import.meta.env.VITE_ENV || 'development';
const _localhost = import.meta.env.VITE_SUBI_PUBLIC_ADDR || 'localhost';
const apiUrl =
    environment === 'production'
        ? `https://api.subi.ai`
        : environment === 'staging'
          ? `https://staging-api.subi.ai`
          : `https://${_localhost}:8000`;

const config = {
    environment: environment,
    appVersion: import.meta.env.RAILWAY_SNAPSHOT_ID,
    apiUrl: apiUrl,
    vapidPublicKey:
        import.meta.env.VITE_VAPID_PUBLIC_KEY ||
        'BO0qsAqtkMThOwmzbFO-fcQThl1LMA10l6OdxILainnWgZJnZQQaH6S94QK8744gPhcT6nqStdwItmiEYuO2q_Q',
    googleClientId:
        '404505569347-f03483f4cnhau6ujm9od9jv789qkig78.apps.googleusercontent.com',
    stripe: 'pk_test_51OXt3YKvqwA8qoBX0gbmdctVTcKJ1r0x2SQhAJFddNWwrTdPfCb0Zn8xkqgjboLsa5eAv6cOzJUztzkn3eSn71zG00DFQKVQN9',
    mixpanelToken: 'a724c618533e3619f3cbc8b577d7c90f',
    homeAgentsPageSize: 27,
    agentCreateID: 'ba8fb12f-1c3e-4e81-a391-2fc19d6d53ff',
    localhost: _localhost,
};

export default config;
