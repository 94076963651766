import { useEffect, useState } from 'react';
import config from '../util/config';
import { useQueryClient } from '@tanstack/react-query';
import { getChatIdFromUrl } from '../util/utils';

function useEventSource(user) {
    const queryClient = useQueryClient();
    const [isOffline, setIsOffline] = useState(true);

    function handleEventSource() {}

    useEffect(() => {
        if (!user) {
            return;
        }

        // Instead of conversations, the last unread message
        const eventSource = new EventSource(
            `${config.apiUrl}/user/unread-messages/events`,
            { withCredentials: true },
        );

        function handleUnreadEventBroadcast(wrapper) {
            let unreadMessage = JSON.parse(wrapper.data);
            console.log('Unread message event', unreadMessage);
            if ('service_name' in unreadMessage) {
                handleAuthEvent(unreadMessage);
            }
            // Handle update where conversation is already active
            const activeConversations = queryClient.getQueryData([
                'conversations',
                'active',
            ]);
            console.log('Active conversations', activeConversations);
            if (
                activeConversations?.some(
                    c => c.conversation?.id === unreadMessage.conversation_id,
                )
            ) {
                console.log(
                    'Message conversation found in active conversation cache',
                    unreadMessage,
                );
                const updatedConversations = activeConversations.map(c => {
                    if (c.conversation?.id === unreadMessage.conversation_id) {
                        return {
                            ...c,
                            conversation: {
                                ...c.conversation,
                                status: 'running',
                            },
                        };
                    }
                    return c;
                });
                const conversationIndex = updatedConversations.findIndex(
                    c => c.conversation?.id === unreadMessage.conversation_id,
                );
                const conversation = updatedConversations.splice(
                    conversationIndex,
                    1,
                );
                if (conversation !== undefined) {
                    updatedConversations.unshift(conversation[0]);
                }
                queryClient.setQueryData(
                    ['conversations', 'active'],
                    updatedConversations,
                );
            } else {
                const inactiveConversations = queryClient.getQueryData([
                    'conversations',
                    'inactive',
                ]);

                // Remove from inactive cache
                if (
                    inactiveConversations?.pages.some(p =>
                        p.some(
                            item =>
                                item.conversation.id ===
                                unreadMessage.conversation_id,
                        ),
                    )
                ) {
                    console.log(
                        'Message conversation found in inactive conversation cache',
                        unreadMessage,
                    );
                    const updatedInactiveConversations =
                        inactiveConversations.pages.map(page => {
                            const index = page.findIndex(
                                item =>
                                    item.conversation.id ===
                                    unreadMessage.conversation_id,
                            );
                            if (index !== -1) {
                                const updatedPage = [...page];
                                updatedPage.splice(index, 1);
                                return updatedPage;
                            }
                            return page;
                        });
                    queryClient.setQueryData(['conversations', 'inactive'], {
                        ...inactiveConversations,
                        pages: updatedInactiveConversations,
                    });
                } else {
                    console.debug(
                        'Unread message conversation not found in cache',
                        unreadMessage,
                    );
                }

                // Add to active cache
                activeConversations?.unshift(unreadMessage);

                queryClient.setQueryData(
                    ['conversations', 'active'],
                    activeConversations,
                );
            }
            //Fetch new conversation metadata
            queryClient.invalidateQueries({
                queryKey: [
                    'conversations',
                    'detail',
                    unreadMessage.conversation_id,
                ],
            });

            //If chat is currently not open fetch new messages
            if (getChatIdFromUrl() !== unreadMessage.conversation_id) {
                queryClient.invalidateQueries({
                    queryKey: [
                        'conversations',
                        'messages',
                        unreadMessage.conversation_id,
                    ],
                });
            }
        }

        function handleUnreadEventSourceConnect() {
            console.debug('Unread event source connected');
            setIsOffline(false);
        }

        //Todo: Show errors on frontend,
        function handleUnreadErrorBroadcast(event) {
            console.log('Unread event source error:', event);
            setIsOffline(true);

            if (event.target.readyState === EventSource.CLOSED) {
                console.log(
                    'Unread event source - Connection closed, will try to reconnect',
                );
            } else if (event.target.readyState === EventSource.CONNECTING) {
                console.log('Unread event source - Attempting to reconnect');
            }
        }

        // EventSource handlers
        eventSource.onopen = handleUnreadEventSourceConnect;
        eventSource.onmessage = handleUnreadEventBroadcast;
        eventSource.onerror = handleUnreadErrorBroadcast;

        // Create an EventSource that connects to the SSE endpoint for the conversation
        function handleAuthEvent(message) {
            console.log('here');
            const integrations = queryClient.getQueryData(['integrations']);

            queryClient.setQueryData(
                ['integrations'],
                [...integrations, { service_name: message.service_name }],
            );
        }

        return () => {
            console.debug('Cleaning up');

            eventSource.close();
        };
    }, [user]);
    return isOffline;
}

export default useEventSource;
