import React, { useContext, useEffect } from 'react';
import { NavArrowLeft, Plus } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';
import { GeneralContext } from '@/context/GeneralContext';
import useUser from '@/context/useUser';
import AddResponseModal from '@/pages/modals/AddReponseModal';
import { useResponses } from '@/hooks/useResponses';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loadingAnimation.json';
import mixpanel from 'mixpanel-browser';
import InfiniteScroll from 'react-infinite-scroll-component';

export const ResponsesList = ({ needsAttention = false }) => {
    const navigate = useNavigate();
    const context = useContext(GeneralContext);
    const { user } = useUser();
    const {
        all,
        attention,
        fetchNextPageAttention,
        fetchNextPageAll,
        hasNextPageAttention,
        hasNextPageAll,
    } = useResponses(user?.voice_config?.phone_number);
    useEffect(() => {
        mixpanel.track('Response list visited', {
            needsAttention: needsAttention,
        });
    }, []);
    return (
        <>
            {context.openModal === 'response' && <AddResponseModal />}

            <div className={`flex py-3.5 px-5 justify-between items-center`}>
                <div onClick={() => navigate('/responses/')}>
                    <NavArrowLeft strokeWidth={2} opacity={0.5} />
                </div>
                <div className="font-medium text-xl">
                    {needsAttention ? 'Needs Attention' : 'All Responses'}
                </div>
                {!needsAttention ? (
                    <div
                        onClick={() => context.setOpenModal('response')}
                        style={{ height: '24px', width: '24px' }}
                        className="rounded-full bg-white bg-opacity-30 hover:bg-opacity-40 cursor-pointer items-center justify-center flex"
                    >
                        <Plus color={'white'} height={20} width={20} />
                    </div>
                ) : (
                    <div style={{ width: '24px', height: '24px' }} />
                )}
            </div>
            <div
                style={{ fontSize: '15px' }}
                className="px-2.5 text-center pb-2 opacity-80 "
            >
                {needsAttention
                    ? 'Please provide answers to the following'
                    : 'Subi will use the following responses to answer callers'}
            </div>
            <div
                style={{ height: context.height - 107 }}
                className={
                    'p-2.5 rounded-xl mx-5 bg-white bg-opacity-10 flex flex-col gap-3 overflow-y-scroll'
                }
                id={'chats-wrapper'}
            >
                {(needsAttention ? attention : all) === undefined ? (
                    <div className={'items-center justify-center flex h-full'}>
                        <Lottie
                            style={{ width: '75px' }}
                            animationData={loadingAnimation}
                        />
                    </div>
                ) : (
                    <InfiniteScroll
                        dataLength={(needsAttention
                            ? attention
                            : all
                        )?.pages.flat()} //total conversation count
                        next={
                            needsAttention
                                ? fetchNextPageAttention
                                : fetchNextPageAll
                        } //function to fetch the next page
                        hasMore={
                            needsAttention
                                ? !!hasNextPageAttention
                                : !!hasNextPageAll
                        } //whether there's a next page
                        scrollableTarget={'chats-wrapper'}
                        loader={<></>} //element to be shown while loading
                        endMessage={<></>}
                    >
                        <div className="flex flex-col gap-2.5 pt-4  ">
                            {(needsAttention ? attention : all)?.pages
                                .flat()
                                .map(c => (
                                    <div
                                        key={c.id}
                                        onClick={() =>
                                            navigate('/responses/' + c.id)
                                        }
                                        className="cursor-pointer bg-white bg-opacity-10 p-3 items-center px-3 flex flex-row justify-between rounded-xl"
                                    >
                                        <div className="flex flex-col">
                                            <div className="font-medium text-md ">
                                                {c.input}
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <div className="opacity-50">
                                                    {c.output}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </InfiniteScroll>
                )}
            </div>
        </>
    );
};
