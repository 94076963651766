import axiosWithRetry from '../util/axiosRetry';
import { useMutation } from '@tanstack/react-query';

const _request_verify_token = async ({ email }) => {
    await axiosWithRetry.post('/auth/request-verify-token', {
        email: email,
    });
};

export const useVerifyToken = () => {
    const request_verify_token = useMutation({
        mutationFn: _request_verify_token,
        onSuccess: data => {},
    });
    return request_verify_token;
};
