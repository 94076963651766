import React, { useContext } from 'react';
import styled from 'styled-components';
import { GeneralContext } from '@/context/GeneralContext';
import { Modal } from '@mui/material';
import styleConfig from '../styles/styleConfig';
import { NavArrowLeft, Xmark } from 'iconoir-react';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';

export const ModalTitleWrapper = ({
    onClose = undefined,
    onBack = undefined,
    color = 'black',
    opacity = 0.7,
    paywall = false,
}) => {
    const windowHeight = useContext(GeneralContext);
    const setOpenModal = windowHeight.setOpenModal;
    const location = useLocation();

    return (
        <TitleWrapper>
            {location.pathname !== '/login' && location.pathname !== '/su' && (
                <>
                    {onBack !== undefined && (
                        <NavArrowLeft
                            id="back-modal-button"
                            onClick={() => {
                                // setOpenModal('');
                                onBack();
                            }}
                            height={24}
                            width={24}
                            strokeWidth={2}
                        />
                    )}
                    {onClose !== undefined && (
                        <Xmark
                            height={24}
                            width={24}
                            color={paywall === true ? 'white' : color}
                            strokeWidth={1.5}
                            id="close-modal-button"
                            onClick={() => {
                                setOpenModal('');
                                onClose();
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                </>
            )}
            {paywall === true && (
                <div className="text-base text-white font-medium select-none ">
                    {onBack === undefined && 'Subscribe'}
                </div>
            )}
            <div style={{ width: '24px' }} />
        </TitleWrapper>
    );
};

export const BaseModal = ({
    children,
    open,
    width = '600px',
    backgroundColor = 'white',
    backgroundImage = undefined,
    onClose = () => {},
    onBack = undefined,
    user = null,
    paywall = false,
}) => {
    const context = useContext(GeneralContext);
    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)',
                },
            }}
            open={open}
            onClose={() => {
                if (context.openModal === 'Log in') {
                    if (
                        location.pathname === '/su' ||
                        location.pathname === '/login'
                    ) {
                        return;
                    }
                    if (user !== null) {
                        context.setOpenModal('');
                    }
                } else {
                    context.setOpenModal('');
                }
            }}
        >
            <Box
                sx={{
                    outline: 'none',
                    width: context.width > 768 ? width : '100%',
                    height: context.width > 768 ? 'auto' : '100%',
                    overflowY: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundImage: backgroundImage,
                    backgroundColor: backgroundColor
                        ? backgroundColor
                        : 'white',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 80%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: context.width > 768 ? '10px' : '0px',
                }}
            >
                <ModalTitleWrapper
                    onBack={onBack}
                    opacity={backgroundColor === styleConfig.colors.text && 1}
                    color={
                        backgroundColor === styleConfig.colors.text && 'white'
                    }
                    onClose={onClose}
                    paywall={paywall}
                />
                {children}
            </Box>
        </Modal>
    );
};

const TitleWrapper = styled.div`
    display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    padding: 15px;
    padding-bottom: 0px;
    box-sizing: border-box;
    color: #141718;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    height: 39px;
    @media (max-width: 768px) {
        padding-bottom: 20px;
        padding-left: 14px;
        padding-top: 20px;
        height: 64px;
    }
`;

export const ModalWrapper = styled.div`
    width: ${props => (props.width ? props.width : '600px')};

    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : 'white'};
    background-image: ${props =>
        props.backgroundImage && `url(${props.backgroundImage})`};
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
`;
