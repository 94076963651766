import styled from 'styled-components';

export const MobileChatTitleWrapper = styled.div`
    padding: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 20px;
    color: ${props => (props.detail ? 'white' : 'black')};
    display: flex;
    flex-direction: column;
    background: ${props => props.detail && 'black'};
`;

export const TitleGrid = styled.div`
    display: grid;
    align-items: center;
    align-self: stretch;
    grid-template-columns: 1fr auto 1fr;
`;

export const MobileChatTitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Indivisible, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-wrap: nowrap;
    @media (max-width: 768px) {
        max-width: 240px;
    }
    color: ${props => (props.dark ? 'white' : 'black')};
`;

export const ChatTitleWrapper = styled.div`
    border-radius: 15px 15px 0px 0px;
    background: #fefefe;
    display: flex;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    box-sizing: border-box;
    gap: 10px;
    border-bottom: 1px solid #e5e5e5;
`;

export const ChatTitle = styled.div`
    display: flex;
    align-items: center;
`;
export const ChatTitleText = styled.div`
    font-weight: 600;
    font-size: 18px;
    z-index: 1;
    margin: 0;
`;

export const ChatTitleLeft = styled.div`
    display: flex;
    height: 31px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const ChatTitleRight = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

export const FilesWrapper = styled.div`
    width: 260px;
    display: flex;
    flex-direction: column;
    //overflow-Y: scroll;
    height: 100%;
    max-height: 70vh;
    padding: 15px;
`;

// box-shadow: 0 0 0 0.3px #6C7275;

export const NewChatPretext = styled.div`
    color: #141718;
    font-family: Indivisible, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    letter-spacing: -0.48px;
    opacity: 0.6;

    @media (max-width: 768px) {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
        width: 25px;
        min-width: 25px;
    }
`;

export const NewChatMobileWrapper = styled.div`
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    background: rgb(254, 254, 254);
    border-bottom: 1px solid rgba(20, 23, 24, 0.1);
`;

export const NewChatInput = styled.input`
    color: #141718;
    width: 100%;

    font-family: Indivisible, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    letter-spacing: -0.48px;
    border: none;

    // &::placeholder {
    // color: #125AE4;
    //}

    &:focus {
        outline: none;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
    }
`;

export const MobilePopoverWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    padding: 10px;
    gap: 15px;
    background: rgb(254, 254, 254);
`;

export const Suggestions = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0px;
        gap: 20px;
    }
`;

export const SuggestionsColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 256px;
`;

export const SuggestionsColumnTitle = styled.div`
    color: rgba(20, 23, 24, 0.7);
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 15px */
    letter-spacing: -0.3px;
    padding: 10px;
    padding-bottom: 15px;
`;

export const AgentWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px;

    align-items: center;

    &:hover {
        border-radius: 5px;
        background: rgba(244, 243, 239, 0.6);
    }
`;
export const AgentIcon = styled.div`
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-size: cover;

    border-radius: 50%;
    display: flex;
    z-index: 0;

    justify-content: center;
    align-items: center;
    font-weight: bold;
`;

export const AgentDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const AgentTitle = styled.div`
    color: #141718;
    font-family: Indivisible, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
`;

export const AgentSubtext = styled.div`
    color: #141718;
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 15px */
    letter-spacing: -0.3px;
`;

export const NewAgentTitle = styled.div`
    color: #135ae4;
    font-family: Indivisible, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
`;
