import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import axiosWithRetry from '../../util/axiosRetry';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import { ModalTitle } from '../../styles/StyledComponents';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const VerifyEmail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = React.useState('');
    const { token } = queryString.parse(location.search);
    const queryClient = useQueryClient();

    const verifyEmailMutation = useMutation({
        mutationFn: token =>
            axiosWithRetry
                .post(`/user/verify_email?token=${token}`)
                .then(res => res.data),
        onSuccess: data => {
            navigate('/');
        },
        onError: () => {
            setError(
                'There was an error verifying your email. Please make sure you are logged in.',
            );
        },
    });

    useEffect(() => {
        if (token) {
            verifyEmailMutation.mutate(token);
        }
    }, [token, verifyEmailMutation]);

    return (
        <Wrapper>
            <ModalContentWrapper>
                <ModalTitle>
                    {' '}
                    {error !== '' && <>{error}</>}
                    {error === '' && <>Verifying your new email...</>}
                </ModalTitle>
            </ModalContentWrapper>
        </Wrapper>
    );
};

export default VerifyEmail;

const ModalContentWrapper = styled.div`
    padding: 50px 50px 50px 50px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background: #fefefe;
    border-radius: 10px;

    @media (max-width: 768px) {
        width: calc(100% - 80px);
        padding: 50px 20px 40px 20px;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 62px);
`;
