import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Modal, TextField } from '@mui/material';
import axiosWithRetry from '../../util/axiosRetry';
import { Button } from '../../styles/StyledComponents';
import { Xmark } from 'iconoir-react';
import GeneralContext from '@/context/GeneralContext';

const FeedbackModal = ({ setDisplayFeedback, msgId, setMsgId }) => {
    const [feedback, setFeedback] = useState('');
    const context = useContext(GeneralContext);
    const submitExample = async () => {
        axiosWithRetry
            .post(`/conversation/message/${msgId}/example`, {
                example: feedback,
            })
            .then(response => {
                console.debug('Reinforcement response', response);
                setMsgId('');
            })
            .catch(e => {
                console.error('There was an error posting message feedback', e);
            });
    };

    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)',
                },
            }}
            open={msgId !== ''}
            onClose={() => setMsgId('')}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <ModalWrapper>
                <ModalTitleWrapper>
                    <Xmark
                        id="close-feedback-modal-button"
                        height={24}
                        width={24}
                        strokeWidth={1.5}
                        onClick={() => setMsgId('')}
                        style={{ color: 'black', cursor: 'pointer' }}
                    />
                    <ModalTitle>Help the Agent</ModalTitle>
                    <div style={{ width: '24px' }} />
                </ModalTitleWrapper>
                {/*TODO: height at 100 percent*/}
                <ModalContentWrapper>
                    <SubsectionTitle dark={context.dark}>
                        What should the agent improve?
                    </SubsectionTitle>
                    <SubsectionSubtitle>
                        {
                            "Frame your feedback as a thought you'd like the agent to have as it responds to you in the future. The agent will consider this in the future when responding to similar messages."
                        }
                        <br />
                        <br />
                        Example:{' '}
                        <i>
                            {
                                '"Thought: I should cite authoritative sources as often as possible..."'
                            }
                        </i>
                    </SubsectionSubtitle>
                    <StyledInput
                        value={feedback}
                        onInput={e => {
                            setFeedback(e.target.value);
                        }}
                        multiline={true}
                        rows={10}
                    />
                    <Button
                        color={'black'}
                        width={'305px'}
                        size={'medium'}
                        onClick={submitExample}
                    >
                        Submit feedback
                    </Button>
                    <div
                        style={{
                            display: 'flex',
                            flexDirectionirection: 'row',
                            gap: '15px',
                        }}
                    >
                        <Button
                            color={'white'}
                            size={'medium'}
                            onClick={() => setMsgId('')}
                        >
                            Skip
                        </Button>
                        <Button
                            color={'white'}
                            size={'medium'}
                            onClick={() => {
                                setDisplayFeedback(false);
                                setMsgId('');
                            }}
                        >
                            {"Don't show this again"}
                        </Button>
                    </div>
                </ModalContentWrapper>
            </ModalWrapper>
        </Modal>
    );
};

export default FeedbackModal;

const SubsectionTitle = styled.div`
    color: #141718;

    font-family: Indivisible, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.36px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SubsectionSubtitle = styled.div`
    color: #141718;
    margin-bottom: 5px;
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    letter-spacing: -0.3px;
    opacity: 0.8;
`;

const ModalWrapper = styled.div`
    width: 750px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 10px;
    @media (max-width: 768px) {
        position: fixed;
        height: 100%;
        width: 100%;
        border-radius: 0px;
    }
`;
const ModalContentWrapper = styled.div`
    padding: 30px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    height: 80%;
    @media (max-width: 768px) {
        padding: 20px;
    }
`;
const ModalTitleWrapper = styled.div`
    display: flex;
    z-index: 1;
    justify-content: space-between;

    align-items: center;
    padding: 16px 20px 16px 25px;
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;
    color: #141718;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    height: 62px;
    @media (max-width: 768px) {
        border-bottom: none;
        flex-direction: row-reverse;
    }
`;

const ModalTitle = styled.div`
    display: flex;
    z-index: 1;
    align-items: center;
    gap: 5px;
    @media (max-width: 768px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 213.333% */
        letter-spacing: -0.3px;
    }
`;

export const StyledInput = styled(TextField)`
    outline: none;
    width: 100%;
    //height:400px;
    background-color: #F4F3EF;
    border-radius: 20px;
    border: none;
    z-index: 1;
    padding: 22px 14px;

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiInputBase-root-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

,
. MuiInputBase-root-webkit-scrollbar-thumb {
    background-color: #7F8689;
    border-radius: 12px;
},


`;
