import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { axiosDefault } from '@/util/axiosRetry';

export const useResponses = phone_number => {
    const {
        data: all,
        hasNextPage: hasNextPageAll,
        fetchNextPage: fetchNextPageAll,
    } = useInfiniteQuery({
        queryKey: ['responses', phone_number, 'all'],
        queryFn: async k => {
            const response = await axiosDefault.get(
                `/phone_line/${k.queryKey[1]}/responses?page=${k.pageParam}`,
            );
            return response.data;
        },
        // initialData: allraw,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => {
            return pages ? pages.length + 1 : 1;
        },
    });
    const {
        data: attention,
        fetchNextPage: fetchNextPageAttention,
        hasNextPage: hasNextPageAttention,
    } = useInfiniteQuery({
        queryKey: ['responses', phone_number, 'attention'],
        queryFn: async k => {
            const response = await axiosDefault.get(
                `/phone_line/${k.queryKey[1]}/responses?filter=needs_attention&page=${k.pageParam}`,
            );
            return response.data;
        },
        // initialData: attentionraw,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => {
            return pages ? pages.length + 1 : 1;
        },
    });

    const { data: approval, approvalLoading } = useQuery({
        queryKey: ['responses', 'approval'],
        queryFn: () => {},
        initialData: approvalraw,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });

    return {
        all,
        attention,
        approval,
        hasNextPageAll,
        hasNextPageAttention,
        approvalLoading,
        fetchNextPageAll,
        fetchNextPageAttention,
    };
};

const allraw = [
    {
        id: 'c8e3ef8d-bbdb-4463-8577-06e9d246221e',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What night is it?',
        output: "I'm not sure",
    },
    {
        id: '123',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
    {
        id: '1234',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
    {
        id: '12345',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
    {
        id: '123456',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
    {
        id: '1234567',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
    {
        id: '12345678',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
    {
        id: '123456789',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
    {
        id: '666',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
    },
];

const attentionraw = {
    pageParams: [1],
    pages: [
        [
            {
                id: 'c8e3ef8d-bbdb-4463-8577-06e9d246121e',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                output: "I'm not sure",
                context: 'for a new request on a call 10:30pm on Friday.',
            },
            {
                id: '12345683',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                output: "I'm not sure",
                context: 'for a new request on a call 11:30pm on Friday.',
            },
            {
                id: '1234683',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                output: "I'm not sure",
                context: 'for a new request on a call 11:30pm on Thurs.',
            },
            {
                id: '14683',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                output: "I'm not sure",
                context: 'for a new request on a call 11:30pm on Saturday.',
            },
            {
                id: '1683',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                context: 'for a new request on a call 2:30pm on Saturday.',
            },
            {
                id: '183',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                output: "I'm not sure",
                context: 'for a new request on a call 2:30pm on Sunday.',
            },
            {
                id: '13',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                output: "I'm not sure",
                context: 'for a new request on a call 2:30pm on Tuesday.',
            },
            {
                id: '1332',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                context: 'for a new request on a call 1:30pm on Tuesday.',
            },
            {
                id: '2',
                created_at: '2024-08-16T21:44:47.415421Z',
                updated_at: '2024-08-16T22:10:04.677731Z',
                from_number: '234234234',
                to_number: 'None',
                duration: null,
                transcript: null,
                sentiment: null,
                input: 'What day is it?',
                context: 'for a new request on a call 2:30pm on Friday.',
            },
        ],
    ],
};

const approvalraw = [
    {
        id: 'c8e3ef8d-bbdb-4463-8577-01e9d246221e',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
        context: 'for a new request on a call 10:30pm on Friday.',
    },
    {
        id: '12345683',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
        context: 'for a new request on a call 11:30pm on Friday.',
    },
    {
        id: '1234683',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
        context: 'for a new request on a call 11:30pm on Thurs.',
    },
    {
        id: '14683',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
        context: 'for a new request on a call 11:30pm on Saturday.',
    },
    {
        id: '1683',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        context: 'for a new request on a call 2:30pm on Saturday.',
    },
    {
        id: '183',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
        context: 'for a new request on a call 2:30pm on Sunday.',
    },
    {
        id: '666',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        output: "I'm not sure",
        context: 'for a new request on a call 2:30pm on Tuesday.',
    },
    {
        id: '1332',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        context: 'for a new request on a call 1:30pm on Tuesday.',
    },
    {
        id: '2',
        created_at: '2024-08-16T21:44:47.415421Z',
        updated_at: '2024-08-16T22:10:04.677731Z',
        from_number: '234234234',
        to_number: 'None',
        duration: null,
        transcript: null,
        sentiment: null,
        input: 'What day is it?',
        context: 'for a new request on a call 2:30pm on Friday.',
    },
];
