import { useQueryClient } from '@tanstack/react-query';
import { getChatIdFromUrl } from '../util/utils';
import { addMessageToMessages } from './useConversation';
import { useEffect, useState } from 'react';
import config from '../util/config';
import axiosWithRetry from '../util/axiosRetry';
import { useUser } from '@/context/useUser';

function useConversationEventSource(location) {
    const queryClient = useQueryClient();
    const [generating, setGenerating] = useState(false);
    const [thinking, setThinking] = useState(null);
    const [tool, setTool] = useState(false);
    const [toolName, setToolName] = useState('');
    const [playUrl, setPlayUrl] = useState(null);
    const [playedFile, setPlayedFile] = useState('');
    const { user } = useUser();

    useEffect(() => {
        // The url that we take from the back is the url of files that already were saved in the Files on private s3
        // We need to get the public url - backend send it and we see it in the chat, but where I can get it?

        if (!playedFile) {
            return;
        }

        console.log(
            'RECENT file: ',
            playedFile,
            'And: ',
            playedFile.includes('.mp3'),
        );
        const fileUrl = {
            private_url: playedFile,
        };

        axiosWithRetry
            .post(`/sign_s3_file`, fileUrl)
            .then(response => {
                console.info('The signed url: ', response.data);
                setPlayUrl(response.data);
            })
            .catch(e => {
                console.error('Error: ', e);
            });
    }, [playedFile]);

    function handleFileBroadcast(convo_event) {
        const id = getChatIdFromUrl();
        if (convo_event.payload) {
            queryClient.setQueryData(
                ['conversations', 'files', id],
                oldData => {
                    return {
                        ...oldData,
                        pages: [...convo_event.payload],
                    };
                },
            );

            const sortedFiles = convo_event.payload.sort(
                (a, b) => new Date(b.last_modified) - new Date(a.last_modified),
            );
            const mostRecentFile = sortedFiles[0];

            if (
                mostRecentFile &&
                mostRecentFile.url.includes('.mp3') &&
                playedFile !== mostRecentFile.url
            ) {
                setPlayedFile(mostRecentFile.url);
            }
        }
    }

    function handleMessageBroadcast(message) {
        // console.log('Message broadcast:', message);

        if (getChatIdFromUrl() === message.conversation_id) {
            addMessageToMessages(queryClient, message);
        }
    }

    function handleQuickReplyBroadcast(convo_event) {
        if (convo_event.payload) {
            const id = getChatIdFromUrl();
            const previousMessages = queryClient.getQueryData([
                'conversations',
                'messages',
                id,
            ]);
            let found = false;
            const newPages = previousMessages?.pages.map((page, i) => {
                if (i === 0 && page.some(msg => msg.type === 'ai')) {
                    return page
                        .reverse()
                        .map((msg, i) => {
                            if (msg.type === 'ai' && found === false) {
                                found = true;

                                return {
                                    ...msg,
                                    meta_data: {
                                        ...msg.meta_data,
                                        quick_replies:
                                            convo_event.payload.replies,
                                    },
                                };
                            } else {
                                return msg;
                            }
                        })
                        .reverse();
                } else {
                    return page;
                }
            });

            if (found) {
                queryClient.setQueryData(
                    ['conversations', 'messages', id],
                    oldData => {
                        return {
                            ...oldData,
                            pages: newPages,
                        };
                    },
                );
            }
        }
    }

    function handleEventBroadcast(wrapper) {
        const convo_event = JSON.parse(wrapper.data);

        if (convo_event.event_type !== 'token') {
            console.log(
                'Event:',
                convo_event?.event_type,
                'message: ',
                convo_event?.message,
                'payload: ',
                convo_event?.payload,
                'full event: ',
                convo_event,
            );
        }

        // case switch on convo_event.event_type
        switch (convo_event.event_type) {
            case 'thinking_start':
            case 'llm_start':
            case 'chat_model_start':
            case 'chain_start':
                // Thinking
                // setThinker(getAiFromConvo(conversation));
                // reset quick replies and tools
                setTool(false);
                setToolName(null);
                setThinking(true);
                setGenerating(false);
                break;
            case 'files':
                handleFileBroadcast(convo_event);
                break;
            case 'message':
            case 'token':
                setThinking(false);
                setGenerating(true);
                handleMessageBroadcast(convo_event.message);
                break;
            case 'tool_start':
            case 'tool_working':
            case 'agent_action':
                // tools - tool name in payload
                // convo_event?.message?.content = "Tool or action...";
                setTool(true);
                setToolName(convo_event.payload.tool);
                // Let's leave this for the message, token, done, error, thinking events
                // setThinking(false);
                // setGenerating(true);
                // AI-247: could be a good place for https://docs.google.com/spreadsheets/d/1DUqqYocCuzOlwi5C5XyeUn1j7H7v-EN7tmZckjgFTtI/edit#gid=1641519238&range=89:89
                //console.log(convo_event)
                break;
            case 'error':
            case 'llm_error':
            case 'tool_error':
            case 'chain_error':
                // AI-185: this isn't working
                setTool(false);
                setToolName(null);
                setThinking(false);
                setGenerating(false);
                break;
            case 'tool_end':
                // convo_event?.message?.content = "Tool or action...";
                setTool(false);
                setToolName(null);

                // We're still generating if we're now waiting for the tool to run; tool_end really means "tool called", then to tool runs, then we often send tool response back to the model, then we finally get our final answer
                break;
            case 'chain_end':
            case 'llm_end':
                break;
            case 'done':
                setTool(false);
                setToolName(null);
                setThinking(false);
                setGenerating(false);
                break;
            case 'quick_replies':
                console.log('EVENT QUICK REPLIES', convo_event);
                handleQuickReplyBroadcast(convo_event);
                break;
        }
    }

    function handleEventSourceConnect() {
        console.log('Convo event source connected'); //, event);
    }

    //Todo: Show errors on frontend,
    function handleEventSourceError(event) {
        setThinking(false);
        setGenerating(false);
        setTool(false);

        let message;

        if (event?.target?.readyState === EventSource.CLOSED) {
            message = 'Connection closed';
        } else if (event?.target?.readyState === EventSource.CONNECTING) {
            message = 'Reconnecting';
        } else {
            message = 'Unknown error occurred';
        }

        console.error(
            'Event Source Error',
            message,
            event?.target?.readyState,
            event,
        );
    }

    useEffect(() => {
        const id = getChatIdFromUrl();

        // const readChat = async chatId => {
        //     await axiosWithRetry.post(`/conversation/${chatId}/current`);
        // };
        // readChat(id);
        if (!id) {
            console.debug('no conversation');
            return;
        }

        console.log('Connecting to event source...');

        const eventSource = new EventSource(
            `${config.apiUrl}/conversation/${id}/events`,
            {
                withCredentials: true,
            },
        );

        // EventSource handlers
        eventSource.onopen = handleEventSourceConnect;
        eventSource.onmessage = handleEventBroadcast;
        eventSource.onerror = handleEventSourceError;

        return () => {
            console.debug('Cleaning up', eventSource);
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [location]);

    return {
        generating,
        setGenerating,
        thinking,
        setThinking,
        tool,
        setTool,
        toolName,
        setToolName,
        playUrl,
    };
}

export default useConversationEventSource;
