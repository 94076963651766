import React, { useContext, useEffect, useState } from 'react';
import { useUser } from '@/context/useUser';
import { useConversation } from '@/hooks/useConversation';
import { v4 as uuid } from 'uuid';
import * as Styles from '../../styles/ChatStyles';
import { GeneralContext } from '@/context/GeneralContext';
import ChatHistory from './ChatHistory';
import Lottie from 'lottie-react';
import {
    createOptimisticMessage,
    getChatIdFromUrl,
    isValidUUID,
} from '@/util/utils';
import loadingAnimation from '../../assets/loadingAnimation.json';
import { useLocation } from 'react-router-dom';
import useConversationEventSoure from '../../hooks/useConversationEventSource';
import ChatHeaderMobile from '@/pages/chat/ChatHeaderMobile';
import useActiveConversationList from '@/hooks/useConversationList';
import { readConversation } from '@/util/api';
import { useUnreadChats } from '@/hooks/useUnreadChats';
import { useQueryClient } from '@tanstack/react-query';
import { useActivityList } from '../../hooks/useActivityList';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ChatMain = ({ guestOwner = undefined }) => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const context = useContext(GeneralContext);
    const {
        generating,
        setGenerating,
        thinking,
        setThinking,
        tool,
        toolName,
        playUrl,
    } = useConversationEventSoure(location);
    useEffect(() => {
        setGenerating(false);
        setThinking(false);
    }, [location]);
    const path = window?.location?.pathname?.split('/newchat');

    const { user } = useUser();
    const {
        data,
        fileData,
        messageData,
        isLoading,
        createConversationMutation,
        addMessageMutation,
    } = useConversation();
    const conversationId = getChatIdFromUrl();

    useActiveConversationList();
    useActivityList();

    // useInactiveConversationList();

    const [details, setDetails] = useState(true);
    const isRequest = location.pathname.includes('/request/');
    const { data: unread } = useUnreadChats();
    useEffect(() => {
        isRequest === false &&
            conversationId &&
            readConversation(conversationId);
        if (unread?.find(item => item.conversation.id === conversationId)) {
            queryClient.setQueryData(['unread'], oldData =>
                oldData.filter(item => item.conversation.id !== conversationId),
            );
        }
    }, [location]);
    const sendMessage = message_content => {
        // construct record
        // console.log("Message: ", message_content);

        if (message_content.trim() === '') {
            console.log('Message is empty');
            return false; // Ignore empty messages
        }

        const conversationId = getChatIdFromUrl();

        const optimisticMessage = createOptimisticMessage(
            conversationId,
            user,
            message_content,
            guestOwner,
        );

        if (conversationId) {
            addMessageMutation.mutate(optimisticMessage);
        } else {
            const agentId = path[1].replace('/', '');
            if (agentId !== '' && isValidUUID(agentId)) {
                createConversationMutation.mutate({
                    ...optimisticMessage,
                    conversation_id: uuid(),
                    agent_id: agentId,
                });
            } else {
                createConversationMutation.mutate({
                    ...optimisticMessage,
                    conversation_id: uuid(),
                });
            }
        }
    };

    return (
        <>
            {/*<Header />*/}
            <Styles.Wrapper
                // style={{
                //     height:
                //         context.width < 769
                //             ? context.height
                //             : context.height - 40,
                //     width:
                //         context.width < 769
                //             ? context.width
                //             : context.width - 393,
                // }}
                style={{
                    height: context.height,
                    width: context.width,
                }}
                className={`flex-grow z-10 h-full flex
                    relative flex-col bg-white dark:bg-black `}
            >
                <ChatHeaderMobile
                    chatData={data}
                    details={details}
                    setDetails={setDetails}
                    files={fileData && fileData.pages.flat()}
                />
                {!(messageData === undefined && conversationId !== false) ? (
                    <ChatHistory
                        tool={tool}
                        tool_name={toolName}
                        sendMessage={sendMessage}
                        messages={messageData}
                        files={fileData && fileData.pages.flat()}
                        details={details}
                        setDetails={setDetails}
                        generating={generating}
                        setGenerating={setGenerating}
                        setThinking={setThinking}
                        thinking={thinking}
                        guestOwner={guestOwner}
                    />
                ) : (
                    <Styles.LoadingWrapper>
                        <Lottie
                            style={{ width: '75px' }}
                            animationData={loadingAnimation}
                        />
                    </Styles.LoadingWrapper>
                )}
            </Styles.Wrapper>
        </>
    );
};

export default ChatMain;
