// private, export the styleConfig
const colors = {
    danger: '#E95F2F',
    accent: '#125AE4',
    text: '#141718',
    textAlt: '#FEFEFE',
};

const styleConfig = {
    colors: {
        dangerLabel: colors.danger,
        dangerAction: {
            fill: colors.danger,
            text: colors.textAlt,
        },
        cancelAction: {
            fill: colors.text,
            text: colors.textAlt,
        },
        text: colors.text,
        textAlt: colors.textAlt,
        textAccent: colors.accent,
    },
    buttons: {
        white: {
            fill: '#FFF',
            text: '#1c1f20',
            border: '#1c1f20',
            hover: {
                fill: '#1c1f20',
                text: '#FFF',
            },
        },
        black: {
            fill: '#141718',
            text: '#fefefe',
            hover: {
                fill: '#343839',
                text: '#fefefe',
            },
            disabled: {
                fill: 'rgba(20, 23, 24, 0.3)',
            },
        },
        blue: {
            fill: '#125AE4',
            text: '#fefefe',
            hover: {
                fill: '#206AF9',
                text: '#fefefe',
            },
        },
        red: {
            fill: '#FEFEFE',
            text: '#E95F2F',
            hover: {
                fill: '#d35f33',
                text: '#E95F2F',
            },
        },
    },
};

// export these
export default styleConfig;
