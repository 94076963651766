import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@mui/material';
import { EditPencil, NavArrowDown, Plus, XmarkCircle } from 'iconoir-react';
import { Input } from '@/styles/StyledComponents';
import { GeneralContext } from '@/context/GeneralContext';
import { v4 as uuid } from 'uuid';
import { axiosDefault } from '@/util/axiosRetry';
import queryClient from '@/util/queryClient';
import { useQueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import useUser from '@/context/useUser';
import ClearFactsModal from '@/pages/modals/ClearFactsModal';

const Facts = ({ phoneLine, userFacts, setUserFacts, user }) => {
    const [error, setError] = useState('');
    const context = useContext(GeneralContext);
    const [fact, setFact] = useState('');

    const handleInputChange = event => {
        const newString = event.target.value;
        setFact(newString);
    };

    const handleFocusOut = event => {
        console.log('fact:', fact);
        if (fact !== '') {
            const f = { fact: fact, id: uuid() };
            setUserFacts([f, ...userFacts]);
            setFact('');
            addFact(f);
        }
    };

    const addFact = fact => {
        mixpanel.track('Fact added', {});
        try {
            console.log('fact: ', fact);
            axiosDefault.post(`/phone_line/${user.phone_number}/facts`, fact);
        } catch (error) {
            console.error('Error adding fact:', error);
        }
    };

    const examples =
        phoneLine?.category &&
        sampleCases.some(sample => sample.name.includes(phoneLine?.category))
            ? sampleCases.find(sample =>
                  sample.name.includes(phoneLine?.category),
              ).examples
            : 'hours, services, pricing';

    return (
        <div className="flex flex-col gap-2.5 mr-2">
            <div>
                {'Provide relevant details your agent may need. For example, ' +
                    examples +
                    '.'}
            </div>
            <div className="relative">
                <Input
                    dark={context.dark}
                    error={error !== ''}
                    style={{
                        border: 'rgba(255,255,255,0.1)',
                        paddingRight: '35px',
                    }}
                    placeholder={
                        userFacts.length === 0
                            ? '✏️️ ️Add your first fact'
                            : '✏️️ ️Add another fact'
                    }
                    value={fact}
                    // onBlur={handleFocusOut}
                    onChange={handleInputChange}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            handleFocusOut();
                        }
                    }}
                />
                {fact !== '' && (
                    <div className="absolute top-0 right-2 justify-center flex h-full items-center">
                        <div
                            style={{ height: '22px', width: '22px' }}
                            className="rounded-full bg-accent hover:bg-accentHover cursor-pointer"
                            onClick={handleFocusOut}
                        >
                            <Plus color={'white'} height={22} width={22} />
                        </div>
                    </div>
                )}
            </div>
            {userFacts.map((f, i) => (
                <Fact
                    f={f}
                    i={i}
                    key={i}
                    setUserFacts={setUserFacts}
                    userFacts={userFacts}
                    user={user}
                />
            ))}

            {/*<div className="pt-5">*/}
            {/*    Save time by uploading documents to Subi’s knowledge*/}
            {/*    base.*/}
            {/*</div>*/}
            {/*<ChatUpload onboarding={true} />*/}
        </div>
    );
};
export default Facts;

export const Contacts = () => {
    const [contacts, setContacts] = useState('');
    const [expanded, setExpanded] = useState(false);

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <div className="flex-1 flex flex-col">
            <Accordion
                square={true}
                expanded={expanded}
                onChange={handleChange}
                sx={{
                    borderRadius: '10px',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: 'white',
                }}
            >
                <AccordionSummary
                    aria-controls="voice-options-content"
                    id="voice-options-header"
                    expandIcon={<NavArrowDown color="rgba(255,255,255,0.5)" />}
                    sx={{ paddingLeft: '10px', paddingRight: '10px' }}
                >
                    <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                        Add your contacts
                    </Typography>
                </AccordionSummary>

                <AccordionDetails
                    sx={{ paddingLeft: '10px', paddingRight: '10px' }}
                >
                    <div className="flex flex-col gap-2.5">
                        <div>
                            Paste names and phone numbers for any callers Subi
                            should know, with a comma separating each different
                            contact.
                        </div>
                        <textarea
                            value={contacts}
                            onChange={e => {
                                setContacts(e.target.value);
                            }}
                            rows={8}
                            placeholder="First Last +1 555-555-5555, &#10;First Last +1 555-555-5555"
                            className="bg-white bg-opacity-10 rounded-xl resize-none outline-none p-2.5"
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export const Accordian = ({ title, children, isLoading }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <div className="flex-1 flex flex-col">
            <Accordion
                square={true}
                expanded={expanded}
                onChange={handleChange}
                sx={{
                    borderRadius: '10px',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: 'white',
                }}
            >
                <AccordionSummary
                    aria-controls="voice-options-content"
                    id="voice-options-header"
                    expandIcon={<NavArrowDown color="rgba(255,255,255,0.5)" />}
                    sx={{ paddingLeft: '10px', paddingRight: '10px' }}
                >
                    <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                        {title}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails
                    sx={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        gap: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {!isLoading && children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export const ServiceDetails = ({ phoneLine }) => {
    const { user } = useUser();
    const [name, setName] = useState(
        phoneLine?.name.startsWith('phone_line_agent') ? '' : phoneLine?.name,
    );
    const [openMessage, setOpenMessage] = useState(false);
    const [URL, setURL] = useState(phoneLine?.knowledge_url);
    const [clearFacts, setClearFacts] = useState('');
    const [category, setCategory] = useState(phoneLine?.category);
    const [active, setActive] = useState(
        phoneLine?.category &&
            !sampleCases.some(sample =>
                sample.name.includes(phoneLine?.category),
            )
            ? true
            : false,
    );
    const [showCopiedText, setShowCopiedText] = useState('');
    const [custom, setCustom] = useState(
        phoneLine?.category &&
            !sampleCases.some(sample =>
                sample.name.includes(phoneLine?.category),
            )
            ? phoneLine?.category
            : '',
    );
    const [isScraping, setIsScraping] = useState(
        phoneLine?.knowledge_url ? true : false,
    );
    useEffect(() => {
        if (active === true) {
            setCategory(custom);
        }
    }, [custom]);

    const addDomain = async (c = undefined) => {
        try {
            const response = await axiosDefault.post(
                `/phone_line/${phoneLine.id}`,
                {
                    name: name,
                    category: c !== undefined ? c : category,
                    knowledge_url: URL !== null && URL !== undefined ? URL : '',
                },
            );
            queryClient.setQueryData(['phoneline'], p => {
                return {
                    ...p,
                    agent: {
                        ...p.agent,
                        name: name,
                        category: c === undefined ? category : c,
                        knowledge_url: URL,
                    },
                };
            });
            setShowCopiedText('Service details updated');
            if (URL !== '') {
                setIsScraping(true);
            } else {
                setIsScraping(false);
            }
            setTimeout(() => {
                setShowCopiedText('');
            }, 2000);
        } catch (error) {
            console.error('Error adding fact:', error);
        }
    };

    const handleFocusOut = (e, c = undefined) => {
        const phoneline = queryClient.getQueryData(['phoneline'])?.agent;
        const onboarding = user.email.includes('@subi-phone.ai');
        if (phoneline.knowledge_url !== URL) {
            setIsScraping(false);
            mixpanel.track('URL updated', { onboarding: onboarding });
        } else if (phoneline.name !== name) {
            mixpanel.track('Account name updated', { onboarding: onboarding });
        } else if (
            phoneline.category !== category ||
            (c !== undefined && phoneLine.category !== c)
        ) {
            mixpanel.track('Account category updated', {
                onboarding: onboarding,
                category: category,
            });
        } else {
            return;
        }

        if (
            name !== '' &&
            name !== null &&
            name !== undefined &&
            (c !== undefined ||
                (category !== null &&
                    category !== '' &&
                    category !== undefined))
        ) {
            addDomain(c);
        }
    };

    const handleNameChange = e => {
        setName(e.target.value);
        // queryClient.setQueryData(['phoneline'], {
        //     ...phoneline,
        //     name: e.target.value,
        //     category: category,
        //     knowledge_url: URL,
        // });
    };

    const handleFocus = e => {
        if (!location.pathname.includes('/join') && clearFacts === '') {
            setOpenMessage(true);
        }
    };
    const clearFactsCallback = c => {
        setClearFacts(c);
        setOpenMessage(false);
    };
    return (
        <>
            {openMessage && (
                <ClearFactsModal
                    open={openMessage}
                    clearFactsCallback={clearFactsCallback}
                    setOpen={setOpenMessage}
                ></ClearFactsModal>
            )}
            {showCopiedText !== '' && (
                <div
                    style={{
                        width: '70vw',
                        opacity: showCopiedText !== '' ? 100 : 0,
                        zIndex: 9999999999,
                        bottom:
                            showCopiedText === 'Link copied!' ? '30px' : '73px',
                    }}
                    className={`bg-lightGray text-black transition-opacity duration-300 z-50 absolute rounded-xl items-center shadow-lg py-3 px-2.5 flex mx-5 font-medium gap-2.5 select-none`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM7.53044 11.9697C7.23755 11.6768 6.76268 11.6768 6.46978 11.9697C6.17689 12.2626 6.17689 12.7374 6.46978 13.0303L9.46978 16.0303C9.76268 16.3232 10.2376 16.3232 10.5304 16.0303L17.5304 9.03033C17.8233 8.73744 17.8233 8.26256 17.5304 7.96967C17.2375 7.67678 16.7627 7.67678 16.4698 7.96967L10.0001 14.4393L7.53044 11.9697Z"
                            fill="#3EA515"
                        />
                    </svg>

                    <div>{showCopiedText}</div>
                </div>
            )}
            <div className="mr-2 flex flex-col gap-5">
                <div className="flex flex-col gap-2.5">
                    <div>
                        What is the name of the service or person that this Subi
                        is for? <span className="text-accent">{' *'}</span>
                    </div>
                    <input
                        value={name}
                        onChange={handleNameChange}
                        onBlur={handleFocusOut}
                        className={`bg-white bg-opacity-10 rounded-xl resize-none outline-none p-2.5`}
                    />
                </div>

                <div className="flex flex-col gap-2.5">
                    <div>
                        Subi can train itself on the web! Share a URL of your
                        business or personal website.
                    </div>
                    <input
                        onSelect={handleFocus}
                        value={URL}
                        onChange={e => {
                            setURL(e.target.value);
                        }}
                        onBlur={handleFocusOut}
                        placeholder="www.subi.ai"
                        className="bg-white bg-opacity-10 rounded-xl resize-none outline-none p-2.5"
                    />
                    {isScraping === true &&
                        location.pathname.includes('/join') && (
                            <div className={'text-accent font-medium'}>
                                Subi is compiling information from your website.
                                Head to Response Library after setup to approve.
                            </div>
                        )}
                </div>
                <div className="flex flex-col gap-4">
                    <div className="text-white">
                        Choose Subi's Role
                        <span className="text-accent">{' *'}</span>
                    </div>
                    <div className="flex flex-wrap gap-1 items-center">
                        {sampleCases.map((c, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={e => {
                                        setCategory(c.name);
                                        setActive(false);
                                        handleFocusOut(e, c.name);
                                    }}
                                    className={`font-medium p-2.5 rounded-3xl bg-white text-black ${category !== c.name && 'text-white bg-opacity-10'} cursor-pointer`}
                                >
                                    {c.name}
                                </div>
                            );
                        })}
                        <input
                            value={custom}
                            style={{ width: '175px' }}
                            onChange={e => setCustom(e.target.value)}
                            onClick={() => {
                                setCategory(custom);
                                setActive(true);
                            }}
                            onBlur={handleFocusOut}
                            placeholder="✏️️  Something else"
                            className={`font-medium p-2.5 rounded-3xl bg-white outline-none text-black ${!active && 'text-white bg-opacity-10'} cursor-pointer`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export const Personality = ({ phoneLine, user }) => {
    const queryClient = useQueryClient();
    const handleClick = (e, c) => {
        let new_p = [];
        if (phoneLine.tone_of_voice) {
            if (
                typeof phoneLine.tone_of_voice === 'string' ||
                phoneLine.tone_of_voice instanceof String
            ) {
                new_p = [phoneLine.tone_of_voice];
            } else {
                new_p = [...phoneLine.tone_of_voice];
            }

            if (phoneLine.tone_of_voice?.includes(c)) {
                new_p.splice(new_p.indexOf(c), 1);
            } else {
                new_p.push(c);
            }
        } else {
            new_p.push(c);
        }
        const onboarding = user.email.includes('@subi-phone.ai');

        queryClient.setQueryData(['phoneline'], p => {
            return {
                ...p,
                agent: {
                    ...p.agent,
                    tone_of_voice: new_p,
                },
            };
        });
        if (new_p.length > 0) {
            mixpanel.track('Personality updated', {
                onboarding: onboarding,
                personality: new_p,
            });

            try {
                axiosDefault.post(`/phone_line/${user.phone_number}/tone`, {
                    tone_of_voice: new_p,
                });
            } catch (error) {
                console.error('Error adding fact:', error);
            }
        }
    };

    return (
        <div className="flex flex-col gap-2.5">
            <div>Choose a personality for your Subi.</div>
            <div className="flex flex-wrap gap-1">
                {personalities.map((c, index) => {
                    return (
                        <div
                            key={index}
                            onClick={e => handleClick(e, c)}
                            className={`font-medium p-2.5 rounded-3xl bg-white text-black ${!phoneLine?.tone_of_voice?.includes(c) && 'text-white bg-opacity-10'} cursor-pointer`}
                        >
                            {c}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const Fact = ({ f, i, setUserFacts, userFacts, user }) => {
    const [editing, setEditing] = useState(false);

    const textRef = useRef(null);
    const editRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const removeFact = id => {
        try {
            axiosDefault.delete(`/phone_line/${user.phone_number}/fact/${id}`);
        } catch (error) {
            console.error('Error adding fact:', error);
        }
    };

    const updateFact = () => {
        try {
            axiosDefault.post(`/phone_line/${user.phone_number}/fact/${f.id}`, {
                fact: f.fact,
            });
        } catch (error) {
            console.error('Error adding fact:', error);
        }
    };
    const updateArrayAtPosition = (array, position, newValue) => {
        const ar = array.map((item, index) =>
            index === position ? { ...item, fact: newValue } : item,
        );
        console.log(ar);
        return ar;
    };

    const handleChange2 = (event, i) => {
        console.log(event.target.value);
        // setText(event.target.value);
        setUserFacts(facts =>
            updateArrayAtPosition(facts, i, event.target.value),
        );
    };
    const handleDelete = i => {
        setEditing(false);
        console.log('i:', i);
        const saved = [...userFacts];
        let removed = saved.splice(i, 1);

        // get first removed
        removed = removed[0];

        setUserFacts(saved);
        console.log('removed', removed);
        removeFact(removed.id);
    };

    useEffect(() => {
        if (textRef.current) {
            setDimensions({
                width: textRef.current.offsetWidth,
                height: textRef.current.offsetHeight,
            });
        }
    }, [f]);

    const handleFocusOut = () => {
        updateFact();
        setEditing(false);
        if (f.fact === '') {
            handleDelete(i);
        }
    };
    useEffect(() => {
        if (editing && editRef.current) {
            console.log(focus);
            editRef.current.focus();
        }
    }, [editing]);
    return (
        <div
            key={f.id}
            className={
                'flex flex-row gap-2.5 p-2.5 bg-white break-all bg-opacity-10 rounded-xl justify-between items-center'
            }
        >
            {editing === true ? (
                <textarea
                    ref={editRef}
                    onBlur={handleFocusOut}
                    style={{
                        width: '100%',
                        height: `${dimensions.height}px`,
                    }}
                    value={f.fact}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            handleFocusOut();
                        }
                    }}
                    onChange={e => handleChange2(e, i)}
                    rows="1"
                    className="auto-height-textarea outline-none resize-none overflow-hidden bg-opacity-0 bg-white w-100 text-white"
                />
            ) : (
                // <span
                //     role="textbox"
                //     contentEditable
                //     onChange={() => console.log('blah')}
                //     className="outline-none bg-opacity-0 bg-white w-100 text-white"
                // >
                //     {f.fact}
                // </span>
                <div ref={textRef}>{f.fact}</div>
            )}
            <div className="flex flex-row gap-2">
                <div
                    className={'cursor-pointer'}
                    onClick={() => {
                        if (editing === false) {
                            setEditing(true);
                            textRef.current.focus();
                        }
                        // setText(f.fact);
                        // const textarea = textareaRef.current;
                        // if (textarea) {
                        //     textarea.style.height = 'auto';
                        //     textarea.style.height = `${textarea.scrollHeight}px`;
                        // }
                    }}
                >
                    <EditPencil
                        height={20}
                        width={20}
                        opacity={editing === false && 0.5}
                    />
                </div>
                <div
                    className={'cursor-pointer'}
                    onClick={() => handleDelete(i)}
                >
                    <XmarkCircle height={20} width={20} color={'#F56B24'} />
                </div>
            </div>
        </div>
    );
};

export const Intro = ({ phoneLine }) => {
    const { user } = useUser();
    const [showCopiedText, setShowCopiedText] = useState('');
    const [intro, setIntro] = useState(
        phoneLine?.startup_message ? phoneLine?.startup_message : '',
    );
    const queryClient = useQueryClient();

    const addIntro = async (c = undefined) => {
        const onboarding = user.email.includes('@subi-phone.ai');

        try {
            const response = await axiosDefault.post(
                `/phone_line/${phoneLine.id}/intro`,
                { intro: intro },
            );
            mixpanel.track('Greeting updated', { onboarding: onboarding });
            setShowCopiedText('Greeting updated');
            console.log(response, 'RESPONSE');
            setTimeout(() => {
                setShowCopiedText('');
            }, 2000);
            queryClient.setQueryData(['phoneline'], p => {
                return { ...p, agent: response.data.agent };
            });
        } catch (error) {
            console.error('Error adding fact:', error);
        }
    };

    const handleFocusOut = e => {
        const phoneline = queryClient.getQueryData(['phoneline']);
        queryClient.setQueryData(['phoneline'], {
            ...phoneline,
            agent: {
                ...phoneline.agent,
                startup_message: e.target.value,
            },
        });

        if (intro !== '' && intro !== null && intro !== undefined) {
            addIntro();
        }
    };

    const handleNameChange = e => {
        setIntro(e.target.value);
        const phoneline = queryClient.getQueryData(['phoneline']);
        queryClient.setQueryData(['phoneline'], {
            ...phoneline,
            agent: {
                ...phoneline.agent,
                startup_message: e.target.value,
            },
        });
    };

    return (
        <>
            {showCopiedText !== '' && (
                <div
                    style={{
                        width: '70vw',
                        opacity: showCopiedText !== '' ? 100 : 0,
                        zIndex: 9999999999,
                        bottom:
                            showCopiedText === 'Link copied!' ? '30px' : '73px',
                    }}
                    className={`bg-lightGray text-black transition-opacity duration-300 z-50 absolute rounded-xl items-center shadow-lg py-3 px-2.5 flex mx-5 font-medium gap-2.5 select-none`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM7.53044 11.9697C7.23755 11.6768 6.76268 11.6768 6.46978 11.9697C6.17689 12.2626 6.17689 12.7374 6.46978 13.0303L9.46978 16.0303C9.76268 16.3232 10.2376 16.3232 10.5304 16.0303L17.5304 9.03033C17.8233 8.73744 17.8233 8.26256 17.5304 7.96967C17.2375 7.67678 16.7627 7.67678 16.4698 7.96967L10.0001 14.4393L7.53044 11.9697Z"
                            fill="#3EA515"
                        />
                    </svg>

                    <div>{showCopiedText}</div>
                </div>
            )}
            <div className="flex flex-col gap-2.5">
                <div>How would you like your Subi to greet callers?</div>
                <input
                    placeholder={
                        'Thank you for calling ' +
                        phoneLine?.name +
                        ', how can I help you?'
                    }
                    value={intro}
                    onChange={handleNameChange}
                    onBlur={handleFocusOut}
                    className="bg-white bg-opacity-10 rounded-xl resize-none outline-none p-2.5"
                />
            </div>
        </>
    );
};

const personalities = [
    'Warm',
    'Professional',
    'Casual',
    'Outgoing',
    'Witty',
    'Conversational',
    'Direct',
    'Assertive',
    'Accommodating',
];

const sampleCases = [
    {
        name: '📞 Receptionist',
        examples: 'hours of operation, services offered, contact information',
    },
    {
        name: '📝 Personal Assistant',
        examples: 'task reminders, scheduling preferences, important contacts',
    },
    {
        name: '🤝 Customer Support',
        examples: 'troubleshooting steps, FAQs, escalation protocols',
    },
    {
        name: '✋ Dating Screening',
        examples:
            'screening questions, compatibility criteria, response templates',
    },
    {
        name: '📢 Event Hotline',
        examples: 'event schedules, location details, RSVP management',
    },
    {
        name: '📆 Booking Agent',
        examples: 'availability, booking procedures, payment options',
    },
    {
        name: '💼 Business Admin',
        examples: 'project deadlines, team contacts, document access',
    },
    {
        name: '🚑 Medical Info',
        examples: 'medical history, appointment schedules, emergency contacts',
    },
    {
        name: '📚 Teacher Assistant',
        examples: 'lesson plans, student lists, assignment deadlines',
    },
    {
        name: '👥 Group Hotline',
        examples: 'participant lists, group rules, meeting schedules',
    },
];
