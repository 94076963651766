import React, { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '@/context/GeneralContext';
import { SubiLogoMenu } from '@/assets/svg/SubiLogoMenu';
import {
    ButtonLoader,
    ButtonLoaderWhite,
    ErrorText,
    Input,
} from '@/styles/StyledComponents';
import VoiceSettings from '@/components/VoiceSettings';
import {
    EyeClosed,
    EyeSolid,
    NavArrowDown,
    NavArrowLeft,
    NavArrowUp,
} from 'iconoir-react';
import axiosWithRetry, { axiosDefault } from '@/util/axiosRetry';
import { useNavigate } from 'react-router-dom';
import { accounts } from '@/util/accounts';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ComingSoonModal } from '@/pages/modals/Integrations';
import { GoogleLogo } from '@/assets/svg/GoogleLogo';
import styled from 'styled-components';
import useUser from '@/context/useUser';
import { validateEmail, validatePassword } from '@/util/utils';
import useConversation from '@/hooks/useConversation';
import { useVerifyToken } from '@/hooks/useVerifyToken';
import { useGoogleLogin } from '@react-oauth/google';
import Facts, { Intro, Personality, ServiceDetails } from '@/components/Facts';
import { usePhoneLine } from '@/hooks/usePhoneLine';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loadingAnimation.json';
import { sha256 } from 'js-sha256';
import mixpanel from 'mixpanel-browser';

const Onboarding = () => {
    const queryClient = useQueryClient();
    const { user } = useUser();
    const [page, setPage] = useState<number>(1);
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [altError, setAltError] = useState<string>('');
    const { phoneLine } = usePhoneLine({ user });
    const [onboardLoading, setOnboardLoading] = useState(true);

    useEffect(() => {
        async function startOnboard() {
            const response = await axiosDefault.get(`/phone_line/onboard`);

            queryClient.setQueryData(['user'], response.data);
            setOnboardLoading(false);
        }

        const _user = queryClient.getQueryData(['user']);

        if (_user === null) {
            startOnboard();
        } else {
            setName(_user?.display_name);
            setPhone(_user?.phone_number);
            setOnboardLoading(false);
        }
    }, []);
    const updateDisplayNameMutation = useMutation({
        mutationFn: async displayName => {
            const response = await axiosDefault.post(`/user/name-phone`, {
                display_name: displayName,
                phone_number: phone,
            });

            return response.data;
        },

        onError: error => {
            console.log(error);
            if (error?.response?.status == 422) {
                if (error.response.data.errors.length > 0) {
                    if (error.response.data.errors[0].suggestion) {
                        setError(error.response.data.errors[0].suggestion);
                    }
                }
            } else {
                console.error('Error updating display name:', error);
            }
        },
        onSuccess: data => {
            console.log('Update display response', data, data.display_name);
            //TODO: use output of data as cache update

            queryClient.setQueryData(['user'], {
                ...user,
                display_name: data.display_name,
                phone_number: data.phone_number,
            });
            setError('');
            setAltError('');
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(data));

            setPage(p => p + 1);
        },
    });

    const updatePhoneNumberMutation = useMutation({
        mutationFn: async phoneNumber => {
            const response = await axiosDefault.post(`/user/phone_number`, {
                phone_number: phoneNumber,
            });

            return response.data;
        },

        onError: error => {
            console.log('updatePhoneNumber error', error);
            if (error?.response?.status == 422) {
                if (error.detail && error.detail.length > 0) {
                    if (error.detail[0].msg) {
                        setError(error.detail[0].msg);
                    }
                }
            }
            setError(
                'The phone number you provided is already associated with a Subi.',
            );
        },
        onSuccess: data => {
            console.debug('Update phone response', data);
            //TODO: use output of data as cache update

            queryClient.setQueryData(['user'], {
                ...user,
                phone_number: data.phone_number,
            });
            setError('');
            setAltError('');
            setPage(p => p + 1);

            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(data));
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });
    const handleContinue = async () => {
        if (page === 1) {
            mixpanel_continue();

            updateDisplayNameMutation.mutate(name);
        } else if (conditionsMet) {
            mixpanel_continue();

            setError('');
            setAltError('');
            if (page === 2) {
                setPage(4);
            } else {
                setPage(p => p + 1);
            }
        }
    };

    const context = useContext(GeneralContext);
    const mixpanel_continue = () => {
        mixpanel.track('Onboarding step ' + String(page) + ' completed', {});
    };
    const { data: userIntegrations } = useQuery({
        queryKey: ['integrations'],
        queryFn: async () => {
            const integrations = await axiosDefault.get(
                '/user/get_integrations',
            );
            return integrations.data;
        },
    });
    const needsAttention = [
        true,
        phoneLine?.agent.category !== null &&
            phoneLine?.agent.name !== undefined &&
            phoneLine?.agent.category !== '' &&
            phoneLine?.agent.category !== undefined &&
            'category' in phoneLine?.agent &&
            phoneLine?.agent.name !== null &&
            phoneLine?.agent.name !== '',
        true,
    ];

    let conditionsMet =
        page === 1
            ? name !== '' && phone !== ''
            : page === 2
              ? !needsAttention.includes(false)
              : page === 3
                ? userIntegrations && userIntegrations.length > 0
                : true;

    const title =
        page === 1
            ? `Let's get started`
            : page === 2
              ? 'Customize your Subi'
              : page === 3
                ? 'Add Email and Calendar'
                : 'You’re all set! Join today to unlock your Subi';
    const subtitle =
        page === 1
            ? 'You’re just a few taps away from creating your Subi!'
            : page === 2
              ? 'You can update these at any time'
              : '';

    if (onboardLoading) {
        return (
            <div
                style={{ height: 'inherit', width: 'inherit' }}
                className={'bg-black items-center justify-center flex'}
            >
                <Lottie
                    style={{ width: '75px' }}
                    animationData={loadingAnimation}
                />
            </div>
        );
    }
    return (
        <div
            style={{
                height: context.height,
                width: context.width,
            }}
            className="flex flex-col bg-black"
        >
            {/* Progress bar */}
            <ProgressBar page={page} setPage={setPage} />
            {/* Page content */}
            <div
                className="overflow-scroll px-2 mx-1"
                style={{
                    paddingTop: '85px',
                    // width: '700px', // TODO: temporary solution, Andrew could fix the width of the content
                    height:
                        context.height -
                        62 * (+page !== 4) -
                        42 * (+page === 3),
                }}
            >
                <div
                    className={`${page === 1 ? 'text-2xl' : 'text-xl'} text-white font-medium text-center ${subtitle === '' && 'pb-3'}`}
                >
                    {title}
                </div>
                {subtitle !== '' && (
                    <div className="text-center pt-1 pb-4">{subtitle}</div>
                )}
                {page === 1 ? (
                    <Page1
                        error={error}
                        phone={phone}
                        setPhone={setPhone}
                        name={name}
                        setName={setName}
                    />
                ) : page === 2 ? (
                    <Customization />
                ) : page === 3 ? (
                    <Integrations />
                ) : (
                    <Page4 phone={phone} name={name} />
                )}
            </div>
            {page !== 4 && (
                <BottomBar
                    conditionsMet={conditionsMet}
                    page={page}
                    setPage={setPage}
                    loading={
                        updateDisplayNameMutation.isPending ||
                        updatePhoneNumberMutation.isPending
                    }
                    onContinue={handleContinue}
                />
            )}
        </div>
    );
};

interface BottomBarProps {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    conditionsMet: boolean;
}

function BottomBar({
    page,
    setPage,
    conditionsMet,
    loading,
    onContinue,
}: BottomBarProps) {
    function handleSkip() {
        mixpanel.track('Onboarding integrations step skipped', {});
        setPage(p => p + 1);
    }

    return (
        <div className="absolute bottom-0 text-white pb-4 gap-2.5 justify-center pt-2.5 w-full items-center flex flex-col bg-black">
            <button
                onClick={onContinue}
                disabled={!conditionsMet}
                style={{ width: '102px' }}
                className={`py-1 px-3 flex justify-center text-white ${conditionsMet ? 'bg-accent hover:bg-accentHover' : 'cursor-default bg-white bg-opacity-10 text-opacity-50'} rounded-3xl font-medium text-lg`}
            >
                {loading ? (
                    <div
                        style={{
                            height: 28,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ButtonLoaderWhite />
                    </div>
                ) : (
                    'Continue'
                )}
            </button>
            {page === 3 && (
                <button
                    onClick={handleSkip}
                    className="py-1 px-3 text-white text-opacity-60 font-medium"
                >
                    Skip
                </button>
            )}
        </div>
    );
}

interface ProgressBarProps {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
}

function ProgressBar({ page, setPage }: ProgressBarProps) {
    const navigate = useNavigate();

    function handleBack() {
        if (page === 1) {
            navigate('/');
        } else {
            if (page === 4) {
                setPage(2);
            } else {
                setPage(p => p - 1);
            }
        }
    }

    return (
        <div className="absolute top-0 flex justify-between pt-2.5 w-full bg-black z-50 pb-7">
            <div
                className="pl-5 pt-2.5 cursor-pointer opacity-60"
                onClick={handleBack}
            >
                <NavArrowLeft color="white" />
            </div>
            <div
                style={{ borderRadius: '100px' }}
                className="flex flex-row items-center py-2.5 px-4 bg-white bg-opacity-10"
            >
                <div
                    className={`rounded-full w-6 h-6 text-center  pr-px font-medium text-white ${page >= 1 ? 'bg-accent' : 'bg-miscGray text-opacity-50'}`}
                >
                    1
                </div>
                <div
                    className={`w-10 h-0.5 ${page > 1 ? 'bg-accent' : 'bg-miscGray'}`}
                />
                <div
                    className={`rounded-full w-6 h-6 text-center  pr-px font-medium text-white ${page >= 2 ? 'bg-accent' : 'bg-miscGray text-opacity-50'}`}
                >
                    2
                </div>
                <div
                    className={`w-0.5 h-0.5 ${page > 3 ? 'bg-accent' : 'bg-miscGray'}`}
                />
                <div className={`w-1 h-0.5`} />
                <div
                    className={`w-1 h-0.5 ${page > 3 ? 'bg-accent' : 'bg-miscGray'}`}
                />
                <div className={`w-1 h-0.5 `} />
                <div
                    className={`w-1 h-0.5 ${page > 3 ? 'bg-accent' : 'bg-miscGray'}`}
                />
                <div className={`w-1 h-0.5`} />

                <div
                    className={`w-1 h-0.5 ${page > 3 ? 'bg-accent' : 'bg-miscGray'}`}
                />
                <div className={`w-1 h-0.5`} />
                <div
                    className={`w-1 h-0.5 ${page > 3 ? 'bg-accent' : 'bg-miscGray'}`}
                />
                <div className={`w-1 h-0.5`} />
                <div
                    className={`w-1 h-0.5 ${page > 3 ? 'bg-accent' : 'bg-miscGray'}`}
                />
                <div className={`w-1.5 h-0.5`} />

                <SubiLogoMenu
                    width={'25'}
                    height={'26'}
                    color={page > 3 ? '#EB0A74' : '#4F5254'}
                />
            </div>
            <div className="w-6"></div>
        </div>
    );
}

export default Onboarding;

export function Customization() {
    const [voiceOptions, setVoiceOptions] = useState([]);
    const [selectedVoiceId, setSelectedVoiceId] = useState(null);
    const [userFacts, setUserFacts] = useState([]);
    const { user } = useUser();
    const { phoneLine, isLoading } = usePhoneLine({ user });
    useEffect(() => {
        if (user) {
            fetchVoices();
            fetchFacts();
        }
    }, [user]);
    const fetchVoices = async () => {
        try {
            const response = await axiosWithRetry({
                method: 'get',
                url: '/phone/get_voices',
                headers: { 'Content-Type': 'application/json' },
            });
            setVoiceOptions(response.data.voices);
            if (response.data.current) {
                setSelectedVoiceId(response.data.current);
            }
        } catch (error) {
            console.error('Error fetching customization options:', error);
        }
    };

    const fetchFacts = async () => {
        try {
            const response = await axiosWithRetry({
                method: 'get',
                url: `/phone_line/${user.phone_number}/facts`,
                headers: { 'Content-Type': 'application/json' },
            });
            setUserFacts(response.data);
        } catch (error) {
            console.error('Error fetching customization options:', error);
        }
    };

    return (
        <div className={'flex flex-col gap-5'}>
            <DropDown
                title={
                    <>
                        {'Details'}

                        <span className="text-accent">{' *'}</span>
                    </>
                }
            >
                {!isLoading && <ServiceDetails phoneLine={phoneLine?.agent} />}
            </DropDown>
            <DropDown title={'Agent Rules & Info'}>
                {!isLoading && (
                    <Facts
                        phoneLine={phoneLine?.agent}
                        userFacts={userFacts}
                        setUserFacts={setUserFacts}
                        user={user}
                    />
                )}
            </DropDown>
            <DropDown title={'Voice'}>
                {!isLoading && (
                    <VoiceSettings
                        voiceOptions={voiceOptions}
                        selectedVoiceId={selectedVoiceId}
                        setSelectedVoiceId={setSelectedVoiceId}
                    />
                )}
            </DropDown>
            <DropDown title={'Greeting'}>
                {!isLoading && <Intro phoneLine={phoneLine?.agent} />}
            </DropDown>
            <DropDown title={<>Personality</>}>
                <Personality phoneLine={phoneLine?.agent} user={user} />
            </DropDown>

            {/*<Contacts />*/}
        </div>
    );
}

export const DropDown = ({ title, children }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="bg-white bg-opacity-10 rounded-xl p-3">
            <div
                onClick={() => setOpen(o => !o)}
                className="flex justify-between text-lg cursor-pointer select-none font-medium"
            >
                <div>{title}</div>
                {open ? (
                    <NavArrowUp opacity={0.5} />
                ) : (
                    <NavArrowDown opacity={0.5} />
                )}
            </div>
            <div
                className={`${open ? 'max-h-96 opacity-100 mt-3' : 'max-h-0 opacity-0'}  overflow-hidden transition-all duration-300 ease-in-out`}
            >
                <div className={'overflow-y-auto max-h-96 pt-3'}>
                    {' '}
                    {children}
                </div>
            </div>
        </div>
    );
};

function Page4({ phone, name }) {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [altError, setAltError] = useState('');
    const [_altError, set_AltError] = useState('');
    const { user, loginUser, register, onboard } = useUser();
    const [google, setGoogle] = useState(false);
    const [revealPassword, setRevealPassword] = useState(false);
    const { createConversationMutation } = useConversation();
    const request_verify_token = useVerifyToken();

    const context = useContext(GeneralContext);
    const navigate = useNavigate();

    const requestOnboard = async email => {
        setError('');
        set_AltError('');
        setAltError('');
        if (name === '') {
            set_AltError('Please enter your name');
            return;
        }
        if (
            validateEmail(email, () => {
                setError('Please enter a valid email address.');
            }) === false
        ) {
            return;
        }
        if (
            (await validatePassword(password, password, setAltError, () => {
                return true;
            })) === false
        ) {
            return;
        }
        onboard.mutate(
            {
                email: email,
                password: password,
            },
            {
                onSuccess: () => {
                    const hashedEmail = sha256(email);
                    const hashedPhoneNumber = sha256(phone).toString();
                    const hashedExternalId = sha256(user.id).toString();

                    // Identify the user with TikTok Pixel
                    console.log(
                        'WINDOW',
                        window.ttq,
                        hashedEmail,
                        hashedPhoneNumber,
                        hashedExternalId,
                    );
                    mixpanel.track('Sign Up Completed', {
                        registration_method: 'Email',
                    });
                    if (window.ttq) {
                        window.ttq.identify({
                            email: hashedEmail,
                            phone_number: hashedPhoneNumber,
                            external_id: hashedExternalId,
                        });

                        window.ttq.track('CompleteRegistration');
                    } else {
                        console.error('ttq object is not initialized');
                    }
                    setError('');
                    setAltError('');
                    navigate('/');
                },
                onError: e => {
                    setError('');
                    setAltError('');
                    if (
                        e.response.data.detail ===
                        'REGISTER_USER_ALREADY_EXISTS'
                    ) {
                        setError(
                            'There is already an account associated with this email',
                        );
                    }
                    if (
                        e.response.data.detail === 'REGISTER_INVALID_PASSWORD'
                    ) {
                        setAltError('Invalid password');
                    }
                },
            },
        );
        // const response = await axiosWithRetry.post('/user/onboard', {
        //     email: email,
        //     password: password,
        // });
        // console.log(response);
        // mutateRegisterUser({ email, password, display_name: name });
    };

    const googleRegister = useGoogleLogin({
        flow: 'implicit',
        onSuccess: tokenResponse => {
            const token = tokenResponse.access_token;
            onboard.mutate(
                {
                    googleToken: token,
                },
                {
                    onSuccess: () => {
                        mixpanel.track('Sign Up Completed', {
                            registration_method: 'Google',
                        });
                        navigate('/');
                    },
                },
            );
        },
    });

    const mutateRegisterUser = credentials => {
        console.log('mutateRegisterUser', credentials);
        register.mutate(credentials, {
            onSuccess: () => {
                request_verify_token.mutate(
                    { email },
                    {
                        // onSuccess: () => {
                        //     setError('');
                        //     setAltError('');
                        //     const u = queryClient.getQueryData(['user']);
                        //     const optimisticMessage = createOptimisticMessage(
                        //         uuid(),
                        //         u,
                        //         'Get started',
                        //         undefined,
                        //     );
                        //     createConversationMutation.mutate(
                        //         optimisticMessage,
                        //     );
                        // },
                    },
                );
            },
            onError: error => {
                console.log('mutateRegisterUser', error);
                if (
                    error.response.data.detail ===
                    'REGISTER_USER_ALREADY_EXISTS'
                ) {
                    setError('An account already exists with this email');
                } else {
                    setError('There was an error registering your account');
                    console.log(
                        'mutateRegisterUser err',
                        error.response.data.detail,
                    );
                    // setError(error.response.data.detail);
                }
            },
        });
    };

    return (
        <div className="flex flex-col justify-between pt-8">
            <div className="flex flex-col gap-10 px-4">
                <div className="flex flex-col gap-7">
                    <Form>
                        <button
                            onClick={() => {
                                setGoogle(true);
                                googleRegister();
                            }}
                            className="bg-white flex font-medium py-2 px-3 justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-black shadow hover:text-opacity-70"
                        >
                            <div className="flex flex-row gap-2.5 items-center">
                                {onboard.isPending && google === true ? (
                                    <div
                                        style={{
                                            height: 28,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ButtonLoader />
                                    </div>
                                ) : (
                                    <>
                                        <GoogleLogo />
                                        <div className="pt-0.5">
                                            Continue with Google
                                        </div>
                                    </>
                                )}
                            </div>
                        </button>
                        <SubsectionSubtext
                            dark={context.dark}
                            style={{ textAlign: 'center' }}
                            className="flex flex-row items-center gap-4"
                        >
                            <div className="h-px w-full bg-black opacity-10 dark:bg-white dark:opactiy-20" />
                            <div className="text-lg">or</div>
                            <div className="h-px w-full bg-black opacity-10 dark:bg-white dark:opactiy-20" />
                        </SubsectionSubtext>
                        <div className="flex flex-col w-full gap-0.5 text-sm">
                            <SubsectionSubtext dark={context.dark}>
                                Email address
                            </SubsectionSubtext>
                            {error !== '' && <ErrorText>{error}</ErrorText>}
                        </div>
                        <Input
                            dark={context.dark}
                            error={error !== ''}
                            placeholder={'hello@me.com'}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    requestOnboard(email);
                                }
                            }}
                        />
                    </Form>

                    <Form>
                        <div className="flex flex-col w-full gap-0.5 text-sm">
                            <SubsectionSubtext dark={context.dark}>
                                Password
                            </SubsectionSubtext>
                            {altError !== '' ? (
                                <ErrorText>{altError}</ErrorText>
                            ) : (
                                <div className="opacity-50 text-black dark:text-white">
                                    {'Must be at least 8 characters.'}
                                </div>
                            )}
                        </div>
                        <div className="relative flex flex-row items-center w-full">
                            <Input
                                dark={context.dark}
                                altError={error !== ''}
                                type={revealPassword ? 'text' : 'password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        requestOnboard(email);
                                    }
                                }}
                            />
                            <div
                                className="absolute right-3 text-black dark:text-white cursor-pointer opacity-20 hover:opacity-50"
                                onClick={() => setRevealPassword(p => !p)}
                            >
                                {revealPassword ? <EyeSolid /> : <EyeClosed />}
                            </div>
                        </div>
                    </Form>
                </div>
                <Form>
                    <button
                        onClick={() => requestOnboard(email)}
                        disabled={onboard.isPending}
                        className="flex font-medium py-2 px-3 justify-center items-center space-x-10 self-stretch text-lg rounded-3xl text-white bg-accent hover:bg-accentHover shadow"
                    >
                        {onboard.isPending ? (
                            <div
                                style={{
                                    height: 28,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ButtonLoaderWhite />
                            </div>
                        ) : (
                            <>Join Subi</>
                        )}
                    </button>
                </Form>

                <div className="flex flex-col gap-9">
                    <div className="opacity-70 text-sm text-center dark:text-white">
                        By continuing, you agree to{' '}
                        <div
                            className="font-bold cursor-pointer inline"
                            onClick={() => {
                                navigate('/privacy');
                            }}
                        >
                            Subi’s Privacy Policy
                        </div>
                        {' and '}
                        <div
                            className="font-bold cursor-pointer inline"
                            onClick={() => {
                                navigate('/terms');
                            }}
                        >
                            Terms of Service.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Integrations({}) {
    const [coming, setComing] = useState(undefined);
    const { data: userIntegrations } = useQuery({
        queryKey: ['integrations'],
        queryFn: async () => {
            const integrations = await axiosDefault.get(
                '/user/get_integrations',
            );
            return integrations.data;
        },
    });
    const context = useContext(GeneralContext);
    const handleAddIntegration = async account => {
        const mixpanel_integrate = () => {
            mixpanel.track('Integration attempted', {
                platform: account.service_name,
            });
        };
        try {
            mixpanel_integrate();
            const response = await axiosWithRetry({
                method: 'post',
                url: '/user/add_integration',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    service_name: account.service_name,
                    account_name: account.title, // Add the account_name parameter
                },
            });

            const oauthLink = response.data.oauth_link;
            if (oauthLink === '') {
                setComing(account);
                return;
            }
            if (context.width < 768) {
                const link = document.createElement('a');
                link.href = oauthLink;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                return;
            }

            const width = 600;
            const height = 600;
            const left = window.screen.width / 2 - width / 2;
            const top = window.screen.height / 2 - height / 2;

            const windowFeatures = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
            window.open(oauthLink, '_blank', windowFeatures);
            console.log('RESPONSE', response);
            // Update the query cache with the newly added integration
        } catch (error) {
            console.error('Error adding integration:', error);
            // Handle the error, show an error message, etc.
        }
    };

    return (
        <>
            {coming !== undefined && (
                <ComingSoonModal coming={coming} setComing={setComing} />
            )}
            <div className={'flex flex-col grow justify-between'}>
                <div className={'flex flex-col gap-4'}>
                    <div
                        style={{ fontSize: '15px' }}
                        className="px-2.5 text-center opacity-80 "
                    >
                        Connecting your accounts allows Subi to book
                        apppointments, check availability, and send and respond
                        to emails
                    </div>
                    <div
                        style={{ gap: '60px' }}
                        className="flex flex-row flex-wrap px-5 items-center justify-center "
                    >
                        {accounts(context.dark === true)
                            .filter(
                                a =>
                                    a.title === 'Gmail' ||
                                    a.title === 'Google Calendar',
                            )
                            .map(a => {
                                const active = userIntegrations?.find(
                                    item =>
                                        item.service_name === a.service_name,
                                );
                                return (
                                    a.icon_large && (
                                        <div
                                            key={a.service_name}
                                            className={`${!active && 'opacity-70 cursor-pointer'} relative`}
                                            onClick={() =>
                                                !active &&
                                                handleAddIntegration(a)
                                            }
                                        >
                                            {active && (
                                                <div className="absolute -right-2 -top-2 bg-lightGreen px-1.5 py-1.5 rounded-full">
                                                    <div className="py-px">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="12"
                                                            viewBox="0 0 14 12"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1 6.85721L4.42857 10.2858L13 1.71436"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            )}
                                            {a.icon_large}
                                        </div>
                                    )
                                );
                            })}
                    </div>
                </div>
                <div
                    style={{ fontSize: '15px' }}
                    className="px-2.5 text-center opacity-80 "
                >
                    More integrations coming soon!
                </div>
            </div>
        </>
    );
}

function Page1({ phone, setPhone, name, setName, error }) {
    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-4">
                <div className="text-white text-opacity-80 text-sm font-medium">
                    My name is
                </div>
                <Input
                    dark={true}
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col w-full gap-0.5 text-sm">
                    <div className="text-white text-opacity-80 text-sm font-medium">
                        My phone number is
                    </div>
                    {error !== '' && <ErrorText>{error}</ErrorText>}
                </div>
                <Input
                    dark={true}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                />
            </div>
        </div>
    );
}

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

const SubsectionSubtext = styled.div`
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 21px */
    width: 100%;
    color: ${props => (props.dark ? 'white' : '#141718')};
`;
