import axiosWithRetry, { axiosDefault } from './axiosRetry';

export async function fetchUser() {
    const user = localStorage.getItem('user');

    if (user === null) {
        return null;
    }

    console.log('Fetching user');

    try {
        const response = await axiosDefault.get(`/user/me`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log('Session expired or invalid authorization');

            // Redirect to login page since session expired and user needs a new cookie
            window.location.href = '/login';
        }
        throw error; // throw if not 401
    }
}

export const readConversation = async conversationId => {
    try {
        const response = await axiosWithRetry.post(
            `/conversation/${conversationId}/read`,
        );
        console.debug('Convo read success');
    } catch (error) {
        console.error('Convo read error:', error);
    }
};

export const fetchSuggestedChats = async searchText => {
    if (searchText === '') {
        return [];
    }
    const response = await axiosDefault.get(
        `/conversation/search/${searchText}`,
    );
    return response.data;
};

export async function fetchSubscription() {
    const response = await axiosDefault.get(`/payment/subscription`);
    return response.data;
}
