import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axiosWithRetry from '../../util/axiosRetry';
import { GeneralContext } from '@/context/GeneralContext';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loadingAnimation.json';

export const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        axiosWithRetry
            .get(`/payment/session-status?session_id=${sessionId}`)
            .then(data => {
                console.log('data', data);
                context.setOpenModal('confirmation');
                navigate('/');
            });
    }, []);

    if (status === 'open') {
        // return <Navigate to="/checkout" />;
        return <Navigate to="/" />;
    }

    return (
        <div
            style={{ height: context.height, width: context.width }}
            className="flex items-center justify-center"
        >
            <Lottie
                style={{ width: '75px' }}
                animationData={loadingAnimation}
            />
        </div>
    );
    // }

    return null;
};
