const colors = {
    black: '#141718',
    blackInput: 'rgba(255, 255, 255, 0.10)',
    systemBlack: '#000',
    accent: '#EB0A74',
    blueAccent: '#2F75FB',
    white: '#FFFFFF',
    darkGray: '#3C4041',
    lightGray: '#F3F3F3',
    lightGrayHover: '#ECECEC',
    lightGraySelection: '#E3E3E3',
    lightGraySelectionHover: '#DBDBDB',
    alertRed: '#DE5C18',
    secondaryAccent: '#225147',
    secondaryAccentHover: '#396F63',
    secondaryAccentMedium: '#5E9287',
    secondaryAccentMediumHover: '#79A99F',
    lightBlack: '#222526',
    green: '#3EA515',
    lightGreen: '#60C637',
    homepageButtonHover: '#1D2123',
    accentHover: '#F9298B',
    accentLight: '#FC479C',
    accentLightHover: '#FF63AC',
    miscGray: '#4F5254',
    homepageBlue: 'rgba(11, 167, 255, 1)',
    accentBlueHover: '#4484FF',
    accentBlue: '#2F75FB',
};

export default colors;
