import React, { useContext } from 'react';
import styled from 'styled-components';
import { GeneralContext } from '../context/GeneralContext';
import { useNavigate } from 'react-router-dom';

export const Terms = () => {
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    return (
        <>
            {context.width >= 768 && (
                <a
                    onClick={() => navigate('/')}
                    className="cursor-pointer absolute top-8 left-5"
                >
                    <Logo dark={context.dark} />
                </a>
            )}
            <div
                className="md:px-36 md:pt-32 p-5 "
                style={{
                    // color: 'black',
                    maxWidth: context.width,
                    textAlign: 'justify',
                    maxHeight: context.height,
                    overflowY: 'auto',
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {context.width < 768 && (
                    <div className="flex flex-row justify-center">
                        <LogoSmall dark={context.dark} />
                    </div>
                )}
                <p>
                    <MainHeader>SubiAI Terms of Service&nbsp;</MainHeader>
                </p>
                <p>
                    <span>
                        These Terms of Use (the “Terms”) apply when you use the
                        products and services on our website including software
                        applications, tools, and data (the “Services”). The
                        Terms also include our posted Privacy Policy, and Usage
                        Policy, as well as other guidelines and policies we post
                        here (or you agree to with us in writing). By using our
                        Services, you agree to these&nbsp;Terms. Our Privacy
                        Policy explains how we collect and use
                        personal&nbsp;information.
                    </span>
                </p>
                <SmallHeader>1. Registration and Access</SmallHeader>
                <p>
                    <span>
                        To use the Services, we ask that you set up an
                        account.&nbsp; You must provide truthful information to
                        register for an account.&nbsp; Do not set up an account
                        for any other person or under a false name. You are
                        responsible for all activities that occur
                        using&nbsp;your&nbsp;credentials and under your account.
                    </span>
                </p>
                <p>
                    <SmallHeader>2. Usage Requirements</SmallHeader>
                </p>
                <p>
                    <span>
                        (a)&nbsp; You must be at least 18 years old to use the
                        Services. If you are between 13 and 18, you can also use
                        the Services but only if you have your parent or legal
                        guardian’s permission to use the Services. If you use
                        the Services on behalf of an entity, you must have the
                        authority to accept the Terms on their behalf.&nbsp;
                    </span>
                </p>

                <p>
                    <span>
                        (b) Free Tier. You may not create more than one account
                        in the free tier of the Services. If we believe you are
                        not using the free tier in good faith, we may charge you
                        our standard fees, if any, or stop providing access
                        to&nbsp;the&nbsp;Services.
                    </span>
                </p>
                <p>
                    <span>
                        (c)You may access and use the Services only in
                        accordance with these Terms. You will comply with these
                        Terms and all applicable laws when using the Services.
                        We and our affiliates own and retain all rights, title,
                        and interest in and to&nbsp;the&nbsp;Services.
                    </span>
                </p>
                <p>
                    <span>
                        (d)&nbsp;Restrictions.&nbsp; You may not use the
                        Services for any illegal or harmful activity.&nbsp; That
                        includes that you may not:
                    </span>
                </p>
                <p>
                    <span>
                        &nbsp;(i) use the Services in a way that infringes,
                        misappropriates or violates any person’s rights;&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        (ii) reverse engineer, reverse assemble, reverse
                        compile, decompile, translate or otherwise attempt to
                        discover the source code, architecture, or underlying
                        components of models, algorithms, and systems of the
                        Services (except to the extent such restrictions are
                        contrary to applicable law);&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        (iii) use Output from the Services to develop models
                        that compete with these Services; (iv) hack into our
                        systems or use any automated or programmatic method to
                        extract data or Output from the Services, or to&nbsp;
                        scrape, web harvest, or web data extract;&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        (v) represent that Output from the Services was
                        human-generated when it is not;&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        (vi) buy, sell, or transfer API keys without our prior
                        consent;&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        (vii) send us any personal information of others;
                    </span>
                </p>
                <p>
                    <span>
                        &nbsp;or (viii) use the Services or any Content for any
                        illegal or harmful purpose or otherwise causes harm.
                    </span>
                </p>
                <p>
                    <span>
                        (e)&nbsp;Third Party Services. Any third party software,
                        services, or other products you use in connection with
                        the Services are subject to their own terms, and we are
                        not responsible for third&nbsp;party&nbsp;products.
                    </span>
                </p>
                <p>
                    <SmallHeader>
                        3. Intellectual Property Rights; Content
                    </SmallHeader>
                </p>
                <p>
                    <span>
                        (a)&nbsp; Content. You may provide instructions,
                        content, custom actions and configurations in the
                        Services (“Input”) and receive output generated and
                        returned by the Services based on the Input (“Output”).
                        Input and Output are collectively “Content.” As between
                        the parties and to the extent permitted by applicable
                        law, you own all Input. Subject to your compliance with
                        these Terms, SubiAI grants you a non-exclusive license
                        to use the Output. SubiAI may also use any and all
                        Content including to provide and maintain the Services,
                        to develop further our Services, share such Content with
                        third parties, and to comply with applicable law and
                        enforce our policies. You are solely responsible for
                        your use of any Output, including for ensuring that it
                        does not violate any applicable law
                        or&nbsp;these&nbsp;Terms.
                    </span>
                </p>
                <p>
                    <span>
                        (b)&nbsp;Similarity of Content. Due to the nature of
                        machine learning, Output may not be unique across users
                        and the Services may generate the same or similar output
                        for SubiAI or a third party. For example, you may
                        provide input to a model such as “What color is the
                        sky?” and receive output such as “The sky is blue.”
                        Other users may also ask similar questions and receive
                        the same response. Responses that are requested by and
                        generated for other users are not
                        considered&nbsp;your&nbsp;Content.
                    </span>
                </p>
                <p>
                    <span>
                        (c)&nbsp;Use of Content to Improve Services. We may use
                        Content that you provide or generate to develop or
                        improve our Services. We may use Content from services
                        other than our Services (“Non-API Content”) to help
                        develop and improve our Services.
                    </span>
                </p>
                <p>
                    <span>
                        (d)&nbsp;Development. We are constantly working to make
                        our Services more accurate, reliable, and safe. Given
                        the probabilistic nature of machine learning, use of our
                        Services may in some situations result in incorrect
                        Output that does not accurately reflect real people,
                        places, or facts. You should evaluate the accuracy of
                        any Output as appropriate for your use case.
                    </span>
                </p>
                <p>
                    <span>(e) The Services. </span>
                    We own all right, title, and interest in and to the
                    Services. You only receive rights to use the Services as
                    explicitly granted in this Agreement.
                </p>
                <p>
                    <SmallHeader>4. Actions Builders</SmallHeader>
                </p>
                <p>
                    If you enable the Service to interact with an API, website,
                    or service (an “Action”), you are responsible for your
                    Actions. If you enable the Services to interact with an
                    Action owned or controlled by a third party, you are
                    responsible for ensuring that any collection, use, or
                    transmission of any information from any user of your GPT to
                    the third party is authorized by the user.
                </p>

                <p>
                    <SmallHeader>5. Security and Data Protection</SmallHeader>
                </p>
                <p>
                    <span>
                        (a)&nbsp;Security. We will deploy and maintain
                        reasonable and appropriate procedures designed to
                        protect the Services against unauthorized access and
                        minimize security risks.&nbsp; You must also implement
                        measures designed to help secure your access to and use
                        of the Services. If you discover any vulnerabilities or
                        breaches related to your use of the Services, promptly
                        contact SubiAI.
                    </span>
                </p>
                <p>
                    <span>
                        (c)&nbsp;Processing of Personal Data. If you provide
                        Input to use the Services to process personal data, you
                        must provide legally adequate privacy notices and obtain
                        necessary consents for the processing of such data, and
                        you represent to us that you are processing such data in
                        accordance with applicable law.&nbsp;&nbsp;
                    </span>
                </p>
                <p>
                    <SmallHeader>6. Term and Termination</SmallHeader>
                </p>
                <p>
                    <span>
                        (a)&nbsp;Termination; Suspension. These Terms take
                        effect when you first use the Services and remain in
                        effect until terminated. You may terminate these Terms
                        at any time for any reason by discontinuing the use of
                        the Services and all Content. We may terminate or
                        suspend your use of the Services&nbsp; for any reason in
                        writing, including when you&nbsp; breach and of these
                        Terms, if there are changes in relationships with third
                        party technology providers or in applicable law,
                        and&nbsp; or to comply with law
                        or&nbsp;government&nbsp;requests, or if your use poses a
                        security risk to us or any third party, or if we suspect
                        that your use is fraudulent or could subject us or any
                        third party to liability.
                    </span>
                </p>
                <p>
                    <span>
                        (b)&nbsp;Effect on Termination. Upon termination, you
                        will stop using the Services. The sections of these
                        Terms which by their nature should survive termination
                        or expiration should survive, including but not limited
                        to Sections 3&nbsp;and&nbsp;5-9.
                    </span>
                </p>
                <p>
                    <SmallHeader>
                        7. Indemnification; Disclaimer of Warranties;
                        Limitations on Liability
                    </SmallHeader>
                </p>
                <p>
                    <span>
                        (a)&nbsp;Indemnity. You will defend, indemnify, and hold
                        harmless us, our employees, directors, affiliates, and
                        our representatives, from and against any claims,
                        losses, and expenses (including attorneys’ fees) arising
                        from or relating to your use of the Services and your
                        Content,&nbsp; or your breach of these Terms or any
                        violation of&nbsp;applicable&nbsp;law.
                    </span>
                </p>
                <h2>
                    <span>(b)&nbsp;Disclaimer. Beta Services</span>
                </h2>
                <p>
                    <span>
                        The Services are offered on an early access, preview,
                        beta basis, and as such, Services are offered “as-is”
                        without any warranties of any kind, solely to allow
                        testing and evaluation and are excluded from any
                        indemnification obligations We may have to you. We make
                        no representations or warranties for the Services,
                        including any warranty that the Services will be
                        generally available, uninterrupted or error-free, or
                        that Content will be secure or not lost or damaged.
                        Except to the extent prohibited by law, SubiAI expressly
                        disclaims all warranties for the Services, including any
                        implied warranties of merchantability, satisfactory
                        quality, fitness for a particular purpose,
                        non-infringement, or quiet enjoyment, and any warranties
                        arising out of any course of dealing or usage of trade.
                    </span>
                </p>
                <p>
                    <span>
                        EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR
                        AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS,
                        IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE
                        SERVICES, AND DISCLAIM ALL WARRANTIES INCLUDING BUT NOT
                        LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, SATISFACTORY QUALITY,
                        NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY
                        WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE
                        USAGE. WE DO NOT WARRANT THAT THE SERVICES WILL BE
                        UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY
                        CONTENT WILL BE SECURE OR NOT LOST&nbsp;OR&nbsp;ALTERED.
                    </span>
                </p>
                <p>
                    <span>
                        (c)&nbsp;NEITHER WE NOR ANY OF OUR AFFILIATES OR
                        LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                        SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
                        DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR
                        OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY
                        UNDER THESE TERMS SHALL NOT EXCEED ​​THE GREATER OF THE
                        AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE
                        CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR
                        ONE HUNDRED DOLLARS ($100). THE LIMITATIONS IN THIS
                        SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED
                        BY&nbsp;APPLICABLE&nbsp;LAW.
                    </span>
                </p>
                <p>
                    <SmallHeader>8. Dispute Resolution</SmallHeader>
                </p>
                <p>
                    <span>
                        (a) Informal Dispute Resolution.&nbsp;We would like to
                        understand and try to address your concerns prior to
                        formal legal action. Before filing a claim against
                        SubiAI, you agree to try to resolve the dispute
                        informally by sending us notice
                        at&nbsp;_privacy@Subi.ai, that includes your name, a
                        description of the dispute, and the relief you seek. If
                        we are unable to resolve a dispute within 60 days, you
                        may bring a formal proceeding.
                    </span>
                </p>
                <p>
                    <span>
                        (b) Mandatory Arbitration. These Terms shall be governed
                        by and construed in accordance with the laws of the
                        state of California, exclusive of its choice of law or
                        conflicts of law rules. You agree that the exclusive
                        venue for all litigation regarding or arising out of
                        these Terms shall be in Los Angeles County, California
                        and you agree to submit to the jurisdiction of the
                        courts in Los Angeles County, California for such
                        litigation. Excluding claims for equitable relief, if
                        the total amount in dispute is less than $50,000, either
                        party may elect to resolve the claim through binding
                        arbitration by initiating arbitration through an
                        established provider that is agreed by the parties. The
                        arbitration must be conducted under the following rules:
                        (a) at the choice of the party seeking relief, the
                        arbitration shall be conducted by telephone, online, or
                        solely on written submissions; (b) no party or witness
                        will make any personal appearance unless the parties
                        agree otherwise; and (c) the winning party may have the
                        arbitrator's award entered as a judgment in any court of
                        competent jurisdiction. Any claims (in court or in
                        arbitration) must be brought in the initiating party's
                        individual capacity and not as a plaintiff or class
                        member in any class action or other similar proceeding.
                        This Agreement does not allow class actions or
                        collective arbitrations even if the arbitration
                        procedures or rules would.
                    </span>
                </p>
                <p>
                    <span>
                        (c)&nbsp;Severability. If any part of this Section&nbsp;
                        is found to be illegal or unenforceable, the remainder
                        will remain in effect, except that if a finding of
                        partial illegality or unenforceability would allow Mass
                        Filing or class or representative arbitration, this
                        Section 8 will be unenforceable in its entirety. Nothing
                        in this section will be deemed to waive or otherwise
                        limit the right to seek public injunctive relief or any
                        other non-waivable right, pending a ruling on the
                        substance of such claim from the&nbsp;arbitrator.
                    </span>
                </p>
                <p>
                    <SmallHeader>9. General Terms</SmallHeader>
                </p>
                <p>
                    <span>
                        (a)&nbsp;Relationship of the Parties. These Terms do not
                        create a partnership, joint venture or agency
                        relationship between you and SubiAI or any of SubiAI’s
                        affiliates. SubiAI and you are independent contractors
                        and neither party will have the power to bind the other
                        or to incur obligations on the other’s behalf without
                        the other party’s prior&nbsp;written&nbsp;consent.
                    </span>
                </p>
                <p>
                    <span>
                        (b)&nbsp;Use of Brands. You may not use SubiAI’s or any
                        of its affiliates’ names, logos, or trademarks, without
                        our prior&nbsp;written&nbsp;consent.
                    </span>
                </p>
                <p>
                    <span>
                        (c) Feedback. We appreciate feedback, comments, ideas,
                        proposals and suggestions for improvements. If you
                        provide any of these things, we may use it without
                        restriction or compensation&nbsp;to&nbsp;you.
                    </span>
                </p>
                <p>
                    <span>
                        (d)&nbsp;Copyright Complaints.&nbsp;If you believe that
                        your intellectual property rights have been infringed
                        anywhere in relation to the Services, please send notice
                        to the address below. We may delete or disable Content
                        alleged to be infringing and may terminate accounts of
                        repeat&nbsp;infringers.
                    </span>
                </p>
                <br />
                <p>
                    <span>SubiAI, Inc.</span>
                </p>
                <p>
                    <span>130 W. Union St.</span>
                </p>
                <p>
                    <span>Pasadena, CA 91103</span>
                </p>
                <br />
                <p>
                    <span>Attn: General Counsel / Copyright Agent</span>
                </p>
                <p>
                    <span>
                        Written claims concerning copyright infringement must
                        include the following&nbsp;information:
                    </span>
                </p>
                <ul>
                    <li>
                        <span>
                            A physical or electronic signature of the person
                            authorized to act on behalf of the owner of the
                            copyright&nbsp;interest;
                        </span>
                    </li>
                    <li>
                        <span>
                            A description of the copyrighted work that you claim
                            has been infringed&nbsp;upon;
                        </span>
                    </li>
                    <li>
                        <span>
                            A description of where the material that you claim
                            is infringing is located on the&nbsp;site;
                        </span>
                    </li>
                    <li>
                        <span>
                            Your address, telephone number, and
                            e-mail&nbsp;address;
                        </span>
                    </li>
                    <li>
                        <span>
                            A statement by you that you have a good-faith belief
                            that the disputed use is not authorized by the
                            copyright owner, its agent, or the law;&nbsp;and
                        </span>
                    </li>
                    <li>
                        <span>
                            A statement by you, made under penalty of perjury,
                            that the above information in your notice is
                            accurate and that you are the copyright owner or
                            authorized to act on the copyright
                            owner’s&nbsp;behalf.
                        </span>
                    </li>
                </ul>
                <p>
                    <span>
                        (e)&nbsp;Assignment and Delegation.&nbsp;You may not
                        assign or delegate any rights or obligations under these
                        Terms, including in connection with a change of control.
                        Any purported assignment and delegation shall be null
                        and void. We may assign these Terms in connection with a
                        merger, acquisition or sale of all or substantially all
                        of our assets, or to any affiliate or as part of
                        a&nbsp;corporate&nbsp;reorganization.
                    </span>
                </p>

                <p className="ql-align-justify">
                    <span>
                        (f)&nbsp;Modifications. We may amend these Terms from
                        time to time by posting a revised version on the
                        website. Company reservice the right to change, update
                        or discontinue the Services at any time, including to
                        require You to sign additional agreements and/or pay for
                        access to the Services.&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        (g)&nbsp;Notices.&nbsp;All notices to Us will be in
                        writing. Service will be deemed given on the date of
                        receipt if delivered by email or on the date sent via
                        courier if delivered by post. SubiAI accepts service of
                        process at this address: SubiAI,Inc., 130 W. Union St.,
                        Pasadena, CA 91103.&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        (h)&nbsp;Waiver and Severability.&nbsp;If you do not
                        comply with these Terms, and SubiAI does not take action
                        right away, this does not mean SubiAI is giving up any
                        rights. EIf any part of these Terms is determined to be
                        invalid or unenforceable by a court of competent
                        jurisdiction, that term will be enforced to the maximum
                        extent permissible and it will not affect the
                        enforceability of any&nbsp;other&nbsp;terms.
                    </span>
                </p>
                <p>
                    <span>
                        (i)&nbsp;Export Controls. The Services may not be used
                        in or for the benefit of, exported, or re-exported (a)
                        into any U.S. embargoed countries (collectively, the
                        “Embargoed Countries”) or (b) to anyone on the U.S.
                        Treasury Department’s list of Specially Designated
                        Nationals, any other restricted party lists (existing
                        now or in the future) identified by the Office of
                        Foreign Asset Control, or the U.S. Department of
                        Commerce Denied Persons List or Entity List, or any
                        other restricted party lists (collectively, “Restricted
                        Party Lists”). You represent and warrant that you are
                        not located in any Embargoed Countries and not on any
                        such restricted party lists. You must comply with all
                        applicable laws related to Embargoed Countries or
                        Restricted Party Lists, including any requirements or
                        obligations to know your end&nbsp;users&nbsp;directly.
                    </span>
                </p>
                <p>
                    <span>
                        (j)&nbsp;Equitable Remedies.&nbsp;You acknowledge that
                        if you violate or breach these Terms, it may cause
                        irreparable harm to SubiAI and its affiliates, and
                        SubiAI shall have the right to seek injunctive relief
                        against you in addition to any
                        other&nbsp;legal&nbsp;remedies.
                    </span>
                </p>
                <p>
                    <span>
                        (k)&nbsp;Entire Agreement.&nbsp;These Terms and any
                        policies incorporated in these Terms contain the entire
                        agreement between you and SubiAI regarding the use of
                        the Services and, other than any Service specific terms
                        of use or any applicable enterprise agreements,
                        supersedes any prior or contemporaneous agreements,
                        communications, or understandings between you and SubiAI
                        on&nbsp;that&nbsp;subject.
                    </span>
                </p>
                <p>
                    <span>
                        (l)&nbsp;Jurisdiction, Venue and Choice of Law. These
                        Terms will be governed by the laws of the State of
                        California, excluding California’s conflicts of law
                        rules or principles. Except as provided in the “Dispute
                        Resolution” section, all claims arising out of or
                        relating to these Terms will be brought exclusively in
                        the federal or state courts of Los Angeles County,
                        California,&nbsp;USA.
                    </span>
                </p>
                <p>
                    <br />
                </p>
                <p>
                    <span>
                        The Services are provided by SubiAI, Inc. (“We” or
                        “SubiAI”), 130 W. 130 Street, Pasadena, CA 91103.
                    </span>
                </p>
                <p>
                    <br />
                </p>
                <p>
                    <br />
                </p>
            </div>
        </>
    );
};

const MainHeader = styled.div`
    font-weight: 600;
    font-size: 28px;
    text-align: left;
`;

const SmallHeader = styled.div`
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
`;

const Logo = ({ dark }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="86"
            height="25"
            viewBox="0 0 86 25"
            fill="none"
        >
            <path
                d="M2.20128 14.7011C2.09136 14.5938 1.91609 14.5938 1.80618 14.7011C0.641668 15.8405 0 17.3556 0 18.9691C0 20.5827 0.641668 22.0978 1.80618 23.2371C3.00633 24.4122 4.5808 24.9998 6.15823 24.9998C7.73566 24.9998 9.31013 24.4122 10.5103 23.2371C10.5638 23.1834 10.5964 23.1089 10.5964 23.0343C10.5964 22.9597 10.5667 22.8852 10.5103 22.8315L2.20128 14.7011Z"
                fill="#EB0A74"
            />
            <path
                d="M1.80618 1.76006C0.641668 2.90237 0 4.41749 0 6.03104C0 7.64458 0.641668 9.15971 1.80618 10.299C1.85965 10.3527 1.93392 10.3796 2.00224 10.3796C2.07057 10.3796 2.14483 10.3527 2.19831 10.299L10.5073 2.16867C10.5608 2.11498 10.5935 2.04042 10.5935 1.96586C10.5935 1.89129 10.5638 1.81673 10.5073 1.76304C8.10997 -0.587184 4.20649 -0.587184 1.80618 1.76006Z"
                fill="#EB0A74"
            />
            <path
                d="M23.3971 14.7011L15.0881 22.8315C15.0346 22.8852 15.002 22.9597 15.002 23.0343C15.002 23.1089 15.0317 23.1834 15.0881 23.2371C16.2883 24.4122 17.8627 24.9968 19.4402 24.9968C21.0176 24.9968 22.5921 24.4093 23.7922 23.2371C24.9567 22.0978 25.5984 20.5827 25.5984 18.9691C25.5984 17.3556 24.9567 15.8405 23.7922 14.7011C23.6823 14.5938 23.507 14.5938 23.3971 14.7011Z"
                fill="#EB0A74"
            />
            <path
                d="M23.3971 10.2989C23.4506 10.3525 23.5219 10.3794 23.5932 10.3794C23.6645 10.3794 23.7358 10.3525 23.7892 10.2989C24.9537 9.15954 25.5954 7.64441 25.5954 6.03087C25.5954 4.41732 24.9537 2.9022 23.7892 1.76288C21.3889 -0.58437 17.4854 -0.58437 15.0881 1.76288C15.0346 1.81656 15.002 1.89113 15.002 1.96569C15.002 2.04025 15.0317 2.11481 15.0881 2.1685L23.3971 10.2989Z"
                fill="#EB0A74"
            />
            <path
                d="M47.7835 13.7938C47.5815 13.4448 47.2844 13.1048 46.9041 12.7767C46.5298 12.4576 46.1971 12.2041 45.906 12.0162C45.6119 11.8253 45.193 11.6195 44.6642 11.4048C44.1384 11.193 43.7196 11.032 43.4136 10.9306C43.1106 10.8292 42.665 10.686 42.0738 10.5011C40.6687 10.0686 39.7121 9.66299 39.2279 9.3021C38.7704 8.95911 38.5476 8.52665 38.5476 7.97488C38.5476 7.4589 38.7437 7.0503 39.1506 6.72818C39.5636 6.39712 40.1488 6.2301 40.8915 6.2301C42.5135 6.2301 43.6661 7.00854 44.4088 8.60717C44.4296 8.65489 44.4711 8.69068 44.5216 8.70858C44.5721 8.72349 44.6286 8.71753 44.6732 8.69068L47.8666 6.83854C47.9528 6.78783 47.9855 6.68046 47.9409 6.59099C47.2695 5.20411 46.3159 4.10356 45.1069 3.32214C43.8978 2.54072 42.4778 2.14404 40.8915 2.14404C39.112 2.14404 37.5673 2.68388 36.2988 3.74864C35.0214 4.82235 34.3738 6.28081 34.3738 8.07927C34.3738 8.98894 34.5372 9.7972 34.861 10.4802C35.1848 11.1692 35.6631 11.7478 36.2839 12.1981C36.887 12.6366 37.5019 12.9915 38.1079 13.2539C38.708 13.5134 39.4507 13.7759 40.3181 14.0354C41.851 14.4708 42.9294 14.8764 43.5176 15.2433C44.0701 15.5863 44.3375 16.0545 44.3375 16.6719C44.3375 17.2058 44.1176 17.6353 43.6661 17.9872C43.2086 18.3451 42.46 18.5271 41.444 18.5271C39.3081 18.5271 37.9178 17.5995 37.19 15.6937C37.1692 15.6429 37.1276 15.6012 37.0771 15.5833C37.0266 15.5654 36.9672 15.5714 36.9197 15.5982L33.6727 17.504C33.5955 17.5488 33.5598 17.6472 33.5895 17.7307C34.1332 19.2369 35.0838 20.4448 36.4146 21.3187C37.7425 22.1926 39.3972 22.634 41.3341 22.634C43.4403 22.634 45.1811 22.0912 46.5031 21.0175C47.8369 19.9348 48.5142 18.4525 48.5142 16.6153C48.5142 16.0486 48.443 15.5147 48.3004 15.0285C48.1578 14.5454 47.9855 14.1278 47.7864 13.7848L47.7835 13.7938Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M62.9875 8.06445H59.4375C59.3336 8.06445 59.2504 8.14796 59.2504 8.25235V15.7116C59.2504 16.7973 58.9919 17.6175 58.478 18.1484C57.9671 18.6793 57.2719 18.9477 56.4134 18.9477C55.641 18.9477 55.0201 18.721 54.5627 18.2707C54.1081 17.8233 53.8764 17.1582 53.8764 16.2932V8.25235C53.8764 8.14796 53.7932 8.06445 53.6893 8.06445H50.1393C50.0353 8.06445 49.9521 8.14796 49.9521 8.25235V16.7347C49.9521 18.5629 50.4661 20.0214 51.4791 21.0653C52.4921 22.1121 53.7932 22.643 55.3439 22.643C57.0432 22.643 58.3562 22.1181 59.2563 21.0772V22.0674C59.2563 22.1718 59.3395 22.2553 59.4435 22.2553H62.9935C63.0974 22.2553 63.1806 22.1718 63.1806 22.0674V8.25235C63.1806 8.14796 63.0974 8.06445 62.9935 8.06445H62.9875Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M73.6433 7.67652C71.8639 7.67652 70.4291 8.24619 69.3745 9.3706V2.91641C69.3745 2.81203 69.2913 2.72852 69.1873 2.72852H65.6373C65.5334 2.72852 65.4502 2.81203 65.4502 2.91641V22.0672C65.4502 22.1716 65.5334 22.2551 65.6373 22.2551H69.1873C69.2913 22.2551 69.3745 22.1716 69.3745 22.0672V20.9488C70.4291 22.0732 71.8639 22.6428 73.6433 22.6428C75.5416 22.6428 77.1844 21.9121 78.5271 20.4686C79.8669 19.031 80.5442 17.2445 80.5442 15.1597C80.5442 13.0749 79.864 11.2884 78.5271 9.85078C77.1844 8.40724 75.5416 7.67652 73.6433 7.67652ZM72.9809 11.4524C74.0236 11.4524 74.8999 11.8014 75.5862 12.4873C76.2694 13.1763 76.617 14.074 76.617 15.1567C76.617 16.2394 76.2694 17.1401 75.5862 17.8261C74.9029 18.515 74.0266 18.861 72.9809 18.861C71.9352 18.861 71.0648 18.512 70.3904 17.8261C69.7131 17.1401 69.3715 16.2394 69.3715 15.1537C69.3715 14.0681 69.7131 13.1703 70.3904 12.4814C71.0648 11.7954 71.9382 11.4464 72.9809 11.4464V11.4524Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M82.2614 8.06445H85.8113C85.9153 8.06445 85.9985 8.14796 85.9985 8.25235V22.0674C85.9985 22.1718 85.9153 22.2553 85.8113 22.2553H82.2614C82.1574 22.2553 82.0742 22.1718 82.0742 22.0674V8.25235C82.0742 8.14796 82.1574 8.06445 82.2614 8.06445Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M85.8114 2.76416H82.2584C82.1551 2.76416 82.0713 2.84829 82.0713 2.95206V5.41562C82.0713 5.5194 82.1551 5.60352 82.2584 5.60352H85.8114C85.9147 5.60352 85.9985 5.5194 85.9985 5.41562V2.95206C85.9985 2.84829 85.9147 2.76416 85.8114 2.76416Z"
                fill={dark ? 'white ' : '#141718'}
            />
        </svg>
    );
};

const LogoSmall = ({ dark }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69"
            height="20"
            viewBox="0 0 69 20"
            fill="none"
        >
            <path
                d="M1.76102 11.761C1.67309 11.6751 1.53287 11.6751 1.44494 11.761C0.513334 12.6724 0 13.8845 0 15.1753C0 16.4662 0.513334 17.6783 1.44494 18.5897C2.40507 19.5298 3.66464 19.9999 4.92658 19.9999C6.18853 19.9999 7.4481 19.5298 8.40823 18.5897C8.451 18.5468 8.47715 18.4871 8.47715 18.4275C8.47715 18.3678 8.45338 18.3082 8.40823 18.2652L1.76102 11.761Z"
                fill="#EB0A74"
            />
            <path
                d="M1.44519 1.40805C0.513578 2.3219 0.000244141 3.53399 0.000244141 4.82483C0.000244141 6.11567 0.513578 7.32776 1.44519 8.23922C1.48796 8.28217 1.54738 8.30365 1.60204 8.30365C1.6567 8.30365 1.71611 8.28217 1.75889 8.23922L8.40609 1.73493C8.44887 1.69199 8.47501 1.63234 8.47501 1.57269C8.47501 1.51303 8.45125 1.45338 8.40609 1.41044C6.48822 -0.469747 3.36544 -0.469747 1.44519 1.40805Z"
                fill="#EB0A74"
            />
            <path
                d="M18.7177 11.761L12.0705 18.2652C12.0277 18.3082 12.0016 18.3678 12.0016 18.4275C12.0016 18.4871 12.0254 18.5468 12.0705 18.5897C13.0306 19.5298 14.2902 19.9975 15.5521 19.9975C16.8141 19.9975 18.0737 19.5275 19.0338 18.5897C19.9654 17.6783 20.4787 16.4662 20.4787 15.1753C20.4787 13.8845 19.9654 12.6724 19.0338 11.761C18.9459 11.6751 18.8056 11.6751 18.7177 11.761Z"
                fill="#EB0A74"
            />
            <path
                d="M18.7177 8.23933C18.7605 8.28228 18.8175 8.30376 18.8746 8.30376C18.9316 8.30376 18.9886 8.28228 19.0314 8.23933C19.963 7.32787 20.4764 6.11578 20.4764 4.82494C20.4764 3.5341 19.963 2.322 19.0314 1.41055C17.1112 -0.467252 13.9884 -0.467252 12.0705 1.41055C12.0277 1.45349 12.0016 1.51314 12.0016 1.57279C12.0016 1.63245 12.0254 1.6921 12.0705 1.73504L18.7177 8.23933Z"
                fill="#EB0A74"
            />
            <path
                d="M38.2268 11.0349C38.0652 10.7557 37.8276 10.4837 37.5234 10.2212C37.2239 9.96594 36.9578 9.76313 36.7248 9.61281C36.4896 9.46011 36.1545 9.29547 35.7315 9.12368C35.3108 8.95427 34.9757 8.82543 34.7309 8.7443C34.4885 8.66318 34.132 8.54865 33.6591 8.40072C32.535 8.05474 31.7697 7.73024 31.3824 7.44153C31.0164 7.16714 30.8381 6.82117 30.8381 6.37976C30.8381 5.96697 30.995 5.64009 31.3206 5.3824C31.6509 5.11755 32.1191 4.98393 32.7132 4.98393C34.0108 4.98393 34.9329 5.60669 35.5271 6.88559C35.5437 6.92377 35.577 6.9524 35.6174 6.96672C35.6578 6.97865 35.7029 6.97388 35.7386 6.9524L38.2934 5.47068C38.3623 5.43012 38.3884 5.34422 38.3528 5.27264C37.8157 4.16314 37.0528 3.2827 36.0856 2.65757C35.1183 2.03243 33.9823 1.71509 32.7132 1.71509C31.2897 1.71509 30.0539 2.14696 29.0391 2.99877C28.0172 3.85773 27.4991 5.0245 27.4991 6.46327C27.4991 7.191 27.6298 7.83761 27.8888 8.38401C28.1479 8.93518 28.5305 9.39807 29.0272 9.75836C29.5097 10.1091 30.0016 10.393 30.4864 10.603C30.9665 10.8106 31.5606 11.0206 32.2546 11.2281C33.4809 11.5765 34.3436 11.901 34.8141 12.1945C35.2561 12.4689 35.47 12.8435 35.47 13.3374C35.47 13.7645 35.2942 14.1081 34.9329 14.3896C34.5669 14.6759 33.9681 14.8215 33.1553 14.8215C31.4465 14.8215 30.3343 14.0794 29.7521 12.5548C29.7354 12.5142 29.7022 12.4808 29.6617 12.4665C29.6213 12.4522 29.5738 12.4569 29.5358 12.4784L26.9382 14.0031C26.8764 14.0389 26.8479 14.1176 26.8717 14.1844C27.3066 15.3894 28.0671 16.3557 29.1318 17.0548C30.1941 17.7539 31.5178 18.107 33.0673 18.107C34.7523 18.107 36.145 17.6728 37.2025 16.8138C38.2696 15.9477 38.8115 14.7618 38.8115 13.2921C38.8115 12.8387 38.7544 12.4116 38.6403 12.0227C38.5263 11.6362 38.3884 11.3021 38.2292 11.0277L38.2268 11.0349Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M50.39 6.45142H47.5501C47.4669 6.45142 47.4003 6.51822 47.4003 6.60174V12.5692C47.4003 13.4377 47.1936 14.0938 46.7824 14.5185C46.3737 14.9433 45.8175 15.158 45.1307 15.158C44.5128 15.158 44.0161 14.9767 43.6501 14.6164C43.2865 14.2585 43.1012 13.7264 43.1012 13.0344V6.60174C43.1012 6.51822 43.0346 6.45142 42.9514 6.45142H40.1115C40.0283 6.45142 39.9617 6.51822 39.9617 6.60174V13.3876C39.9617 14.8502 40.3729 16.017 41.1833 16.8521C41.9937 17.6896 43.0346 18.1143 44.2752 18.1143C45.6346 18.1143 46.685 17.6943 47.4051 16.8616V17.6538C47.4051 17.7373 47.4716 17.8041 47.5548 17.8041H50.3948C50.478 17.8041 50.5445 17.7373 50.5445 17.6538V6.60174C50.5445 6.51822 50.478 6.45142 50.3948 6.45142H50.39Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M58.9147 6.14102C57.4911 6.14102 56.3433 6.59675 55.4996 7.49628V2.33294C55.4996 2.24943 55.433 2.18262 55.3499 2.18262H52.5099C52.4267 2.18262 52.3602 2.24943 52.3602 2.33294V17.6536C52.3602 17.7371 52.4267 17.8039 52.5099 17.8039H55.3499C55.433 17.8039 55.4996 17.7371 55.4996 17.6536V16.7588C56.3433 17.6583 57.4911 18.1141 58.9147 18.1141C60.4333 18.1141 61.7475 17.5295 62.8217 16.3747C63.8936 15.2246 64.4354 13.7954 64.4354 12.1275C64.4354 10.4597 63.8912 9.03049 62.8217 7.88043C61.7475 6.7256 60.4333 6.14102 58.9147 6.14102ZM58.3847 9.16172C59.2189 9.16172 59.92 9.44089 60.4689 9.98967C61.0156 10.5408 61.2936 11.259 61.2936 12.1252C61.2936 12.9913 61.0156 13.7119 60.4689 14.2607C59.9223 14.8118 59.2213 15.0886 58.3847 15.0886C57.5482 15.0886 56.8518 14.8094 56.3124 14.2607C55.7705 13.7119 55.4972 12.9913 55.4972 12.1228C55.4972 11.2543 55.7705 10.5361 56.3124 9.9849C56.8518 9.43612 57.5505 9.15695 58.3847 9.15695V9.16172Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M65.809 6.45142H68.649C68.7322 6.45142 68.7987 6.51822 68.7987 6.60174V17.6538C68.7987 17.7373 68.7322 17.8041 68.649 17.8041H65.809C65.7258 17.8041 65.6593 17.7373 65.6593 17.6538V6.60174C65.6593 6.51822 65.7258 6.45142 65.809 6.45142Z"
                fill={dark ? 'white ' : '#141718'}
            />
            <path
                d="M68.649 2.21143H65.8066C65.724 2.21143 65.6569 2.27873 65.6569 2.36175V4.3326C65.6569 4.41562 65.724 4.48292 65.8066 4.48292H68.649C68.7317 4.48292 68.7987 4.41562 68.7987 4.3326V2.36175C68.7987 2.27873 68.7317 2.21143 68.649 2.21143Z"
                fill={dark ? 'white ' : '#141718'}
            />
        </svg>
    );
};
