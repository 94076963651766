import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Avatar, FormControl } from '@mui/material';
import { Pause, Play } from 'iconoir-react';
import axiosWithRetry from '@/util/axiosRetry';
import mixpanel from 'mixpanel-browser';
import useUser from '@/context/useUser';

const VoiceSettings = forwardRef(
    ({ voiceOptions, selectedVoiceId, setSelectedVoiceId }, ref) => {
        const [playingVoiceId, setPlayingVoiceId] = useState(null);
        const audioRef = useRef(new Audio());
        const { user } = useUser();
        const updateVoice = async id => {
            const onboarding = user.email.includes('@subi-phone.ai');
            mixpanel.track('Voice updated', {
                onboarding: onboarding,
                voice_id: id,
            });

            try {
                await axiosWithRetry({
                    method: 'post',
                    url: `/user/update_voices/${id}`,
                    headers: { 'Content-Type': 'application/json' },
                });
                console.log('Voice updated successfully');
            } catch (error) {
                console.error('Error updating voice:', error);
            }
        };

        useImperativeHandle(ref, () => ({
            updateVoice,
            getSelectedVoice: () =>
                voiceOptions.find(voice => voice.voice_id === selectedVoiceId),
        }));

        const handleVoiceChange = id => {
            setSelectedVoiceId(id);
            updateVoice(id);
        };

        const handlePlayAudio = async (voiceId, audioUrl) => {
            if (!audioUrl) return;

            const audio = audioRef.current;

            if (playingVoiceId === voiceId) {
                // If the same audio is playing, stop it
                audio.pause();
                audio.currentTime = 0;
                setPlayingVoiceId(null);
            } else {
                // If a different audio is playing or no audio is playing
                try {
                    audio.pause();
                    audio.currentTime = 0;
                    audio.src = audioUrl;
                    await audio.play();
                    setPlayingVoiceId(voiceId);
                } catch (error) {
                    console.error('Error playing audio:', error);
                    setPlayingVoiceId(null);
                }
            }
        };

        useEffect(() => {
            const audio = audioRef.current;
            audio.onended = () => setPlayingVoiceId(null);
        }, []);

        const isValidAudioUrl = url => {
            return url && url.trim() !== '';
        };

        return (
            <FormControl component="fieldset" sx={{ width: '100%' }}>
                <div>Select a voice for your Subi.</div>
                <div
                    aria-label="voice-options"
                    name="voice-options"
                    className="flex flex-col gap-2.5 pt-3"
                >
                    {voiceOptions.map(
                        voice =>
                            voice.preview_audio_url && (
                                <div
                                    onClick={() =>
                                        handleVoiceChange(voice.voice_id)
                                    }
                                    key={voice.voice_id}
                                    className={`gap-4 mr-2 relative cursor-pointer flex items-center mb-2 bg-white bg-opacity-10 rounded-xl px-4 py-3 hover:bg-opacity-20 ${selectedVoiceId === voice.voice_id && 'bg-opacity-20'}`}
                                >
                                    {selectedVoiceId === voice.voice_id && (
                                        <div className="absolute -right-1 -top-2 bg-lightGreen px-1 py-1 rounded-full">
                                            <div className="py-px">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="10"
                                                    viewBox="0 0 12 10"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1.10059 5.68582L3.84344 8.42868L10.7006 1.57153"
                                                        stroke="white"
                                                        strokeWidth="1.6"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    )}
                                    <Avatar
                                        src={voice.avatar_url}
                                        alt={voice.voice_name}
                                        className="mr-2"
                                    />
                                    <div className="flex-grow flex flex-col">
                                        <div className="font-bold">
                                            {voice.voice_name}
                                        </div>
                                        <div>{voice.accent}</div>
                                    </div>
                                    {isValidAudioUrl(
                                        voice.preview_audio_url,
                                    ) && (
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handlePlayAudio(
                                                    voice.voice_id,
                                                    voice.preview_audio_url,
                                                );
                                            }}
                                            className="py-1.5 pl-1.5 pr-1.5 bg-white bg-opacity-10 rounded-full"
                                        >
                                            {playingVoiceId ===
                                            voice.voice_id ? (
                                                <Pause
                                                    height="18"
                                                    width="18"
                                                    fill="white"
                                                    color="white"
                                                />
                                            ) : (
                                                <Play
                                                    height="18"
                                                    width="18"
                                                    fill="white"
                                                    color="white"
                                                />
                                            )}
                                        </button>
                                    )}
                                </div>
                            ),
                    )}
                </div>
            </FormControl>
        );
    },
);

export default VoiceSettings;
