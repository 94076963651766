import React, { useContext } from 'react';
import { GeneralContext } from '@/context/GeneralContext.jsx';
import { SettingsContent } from '@/pages/modals/SettingsModal';

const SettingsPage = () => {
    const context = useContext(GeneralContext);
    return (
        <div
            style={{
                height: context.height,
                width: context.width,
            }}
            className="w-full h-full bg-white dark:bg-black text-black dark:text-white"
        >
            <SettingsContent />
        </div>
    );
};

export default SettingsPage;
