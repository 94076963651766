import React, { useContext, useState } from 'react';
import { Modal } from '@mui/material';
import { GeneralContext } from '@/context/GeneralContext';
import { Xmark } from 'iconoir-react';
import { useQueryClient } from '@tanstack/react-query';
import axiosWithRetry from '@/util/axiosRetry';
import useUser from '@/context/useUser';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { ButtonLoaderWhite } from '@/styles/StyledComponents';
import mixpanel from 'mixpanel-browser';

const AddResponseModal = () => {
    const context = useContext(GeneralContext);
    const [request, setRequest] = useState('');
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const ready = request !== '' && answer !== '';
    const { user } = useUser();
    const navigate = useNavigate();

    const handleSaveResponse = async () => {
        if (!ready) {
            return;
        }

        try {
            setLoading(true);
            mixpanel.track('Response added', {});

            const newResponse = {
                question: request,
                answer: answer,
                id: uuidv4(),
            };

            const response = await axiosWithRetry.post(
                `/phone_line/${user.phone_number}/response`,
                newResponse,
            );

            const updateCacheData = (key, dataUpdater) => {
                queryClient.setQueryData(key, existingData => {
                    // Flatten existing data
                    const flatData = existingData.pages.flat();
                    // Update the flat data
                    const updatedData = dataUpdater(flatData);
                    // Regenerate pages after update
                    return {
                        ...existingData,
                        pages: regeneratePages(updatedData),
                    };
                });
            };

            // Update cache data to include the new response at the top
            updateCacheData(
                ['responses', user?.voice_config?.phone_number, 'all'],
                data => [response.data, ...data],
            );

            context.setOpenModal('');
            navigate('/responses/all');
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    const handleCancelResponse = () => {
        context.setOpenModal('');
    };

    return (
        <Modal
            sx={{
                outline: 'none',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)',
                },
            }}
            open={context.openModal === 'response'}
            disableAutoFocus={true}
            onClose={handleCancelResponse}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div
                className={'bg-black flex flex-col '}
                style={{ height: context.height, width: context.width }}
            >
                <div className="flex flex-row py-3.5 px-6 justify-between">
                    <Xmark
                        opacity={0.5}
                        color={'white'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => context.setOpenModal('')}
                    />
                    <div className={'text-white font-medium text-xl'}>
                        New Response
                    </div>
                    <div style={{ width: '24px' }}></div>
                </div>
                <div
                    className={
                        'flex flex-col flex-grow justify-between items-center gap-2'
                    }
                >
                    <div className="w-full items-center flex flex-col px-6 gap-2">
                        <div
                            style={{ fontSize: '15px' }}
                            className="px-2.5 text-center pb-2 opacity-80 text-white "
                        >
                            Add a new request and response below.
                        </div>
                        <div
                            className={
                                'px-5 py-5 gap-4 flex flex-col bg-white bg-opacity-10 rounded-xl w-full'
                            }
                        >
                            <div className={'flex flex-col gap-1.5'}>
                                <div className="text-white text-opacity-80 text-sm">
                                    Request
                                </div>
                                <textarea
                                    value={request}
                                    onChange={e => setRequest(e.target.value)}
                                    style={{ height: '100px' }}
                                    className="resize-none rounded-xl bg-white bg-opacity-10 text-white w-full p-2 bg-gray-100 focus:outline-none custom-textarea"
                                ></textarea>
                            </div>
                            <div className={'flex flex-col gap-1.5'}>
                                <div className="text-white text-opacity-80 text-sm">
                                    Response
                                </div>
                                <textarea
                                    value={answer}
                                    onChange={e => setAnswer(e.target.value)}
                                    style={{ height: '300px' }}
                                    className="resize-none rounded-xl bg-white bg-opacity-10 text-white w-full p-2 bg-gray-100 focus:outline-none custom-textarea"
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div className={'flex flex-row gap-2.5 pb-10'}>
                        <div
                            className={
                                'text-white px-2.5 py-1 bg-white bg-opacity-10 text-opacity-50 rounded-xl font-medium hover:bg-opacity-20 cursor-pointer'
                            }
                            onClick={handleCancelResponse}
                        >
                            Cancel
                        </div>
                        <div
                            style={{ width: '57px' }}
                            className={`flex justify-center text-white px-2.5  bg-accent rounded-xl font-medium  ${ready ? 'cursor-pointer bg-accent hover:bg-accentHover cursor-pointer' : 'opacity-50 cursor-auto'} ${!loading && 'py-1'} `}
                            onClick={handleSaveResponse}
                        >
                            {loading ? (
                                <ButtonLoaderWhite width={'32px'} />
                            ) : (
                                'Save'
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AddResponseModal;

const regeneratePages = flatData => {
    const pageSize = 10; // Example page size, adapt as needed
    const pages = [];
    for (let i = 0; i < flatData.length; i += pageSize) {
        pages.push(flatData.slice(i, i + pageSize));
    }
    return pages;
};
