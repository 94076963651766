import { useEffect, useState } from 'react';

const useTheme = () => {
    const [theme, setTheme] = useState('dark');

    // useEffect(() => {
    //     const storedTheme = localStorage.getItem('theme');
    //     if (storedTheme) {
    //         setTheme(storedTheme);
    //     } else {
    //         const prefersDark = window.matchMedia(
    //             '(prefers-color-scheme: dark)',
    //         ).matches;
    //         setTheme(prefersDark ? 'dark' : 'light');
    //     }
    // }, []);

    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [theme]);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };
    return { theme, toggleTheme };
};

export default useTheme;
