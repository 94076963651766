import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { GeneralContext } from '@/context/GeneralContext';
import { useUser } from '@/context/useUser';

import {
    BrightStar,
    Copy,
    Key,
    LogOut,
    NavArrowLeft,
    Palette,
    Phone,
    ProfileCircle,
    SwitchOff,
    SwitchOn,
    Xmark,
} from 'iconoir-react';
import DeleteAccountModal from './DeleteAccountModal';
import axiosWithRetry, { axiosDefault } from '../../util/axiosRetry';
import styleConfig from '../../styles/styleConfig';
import { ButtonLoaderWhite, ModalTitle } from '@/styles/StyledComponents';
import { isValidPhoneNumber, validatePassword } from '@/util/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Customization, Integrations } from '@/pages/modals/Onboarding';
import { Modal } from '@mui/material';
import colors from '@/styles/colors.js';
import mixpanel from 'mixpanel-browser';

export const SettingsContent = ({
    setSettingsOpen = undefined,
    defaultPage = 'home',
}) => {
    const { user, logoutUser, subscription } = useUser();
    const context = useContext(GeneralContext);
    const setOpenModal = context.setOpenModal;
    const [email, setEmail] = useState(user?.email);
    const [displayName, setDisplayName] = useState(user?.display_name);
    const [phoneNumber, setPhoneNumber] = useState(user?.phone_number);

    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [deleteAccount, setDeleteAccount] = useState('');
    const [page, setPage] = useState(defaultPage);
    const [delAccount, setDelAccount] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const [oldError, setOldError] = useState('');
    const [sentEmail, setSentEmail] = useState(false);
    const [showCopied, setShowCopied] = useState(false);
    const [showCopiedText, setShowCopiedText] = useState('');
    const queryClient = useQueryClient();
    const remainingMinutes = subscription
        ? subscription.calls.allowed - subscription.calls.used
        : 0;
    const subscriptionRenews = subscription?.current_period_end_date
        ? ' · Renews ' +
          new Date(subscription.current_period_end_date).toLocaleDateString()
        : '';

    const subscriptionPlan = subscription?.subscription_tier || 'Trial';
    const subscriptionPrice = subscription?.subscription_price || '';

    useEffect(() => {
        if (user) {
            setEmail(user.email);
            setDisplayName(user.display_name);
        }
    }, [user]);

    //Reset state
    const resetState = () => {
        setEmail(user.email);
        setDisplayName(user.display_name);
        setDeleteAccount('');
        setPage('home');
        setDelAccount('');
        setPassword('');
        setNewPassword('');
        setNewPasswordConfirm('');
        setError('');
        setOldError('');
        setSentEmail(false);
    };
    useEffect(() => {
        setPhoneError('');
        setEmailError('');
    }, [page]);
    const updatePasswordMutation = useMutation({
        mutationFn: async ({ newPassword, password }) => {
            console.log('inputs', newPassword, password);
            const response = await axiosDefault.post('/user/password', null, {
                params: {
                    password: newPassword,
                    oldPassword: password,
                },
            });
            console.log('resp', response.data);
            return response.data;
        },

        onError: error => {
            const er = error?.response?.data?.detail;
            console.log('updatePasswordMutation error', er);
            setOldError('Incorrect password');

            // if (er === 'Incorrect password') {
            //     setOldError('Incorrect password');
            // } else {
            //     setError(er);
            // }
        },
        onSuccess: data => {
            resetState();
        },
        onSettled: () => {
            // queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });

    const subscriptionsPortal = () => {
        if (
            subscription?.subscription_tier === 'trial' ||
            subscription === null
        ) {
            context.setOpenModal('paywall');
        } else {
            mixpanel.track('Stripe memberships settings screen reached', {});

            axiosWithRetry
                .post(`/payment/create-portal-session`)
                .then(res => {
                    window.location.replace(res.data.url);
                })
                .catch(err => console.error(err));
        }
    };

    const updateDisplayNameMutation = useMutation({
        mutationFn: async displayName => {
            const response = await axiosDefault.post(`/user/name-phone`, {
                display_name: displayName,
            });

            return response.data;
        },

        onError: error => {
            console.error('Error updating display name:', error);
        },
        onSuccess: data => {
            console.debug('Update display response', data);
            //TODO: use output of data as cache update

            queryClient.setQueryData(['user'], {
                ...user,
                display_name: data.displayName,
            });

            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(data));
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });

    const updatePhoneNumberMutation = useMutation({
        mutationFn: async phoneNumber => {
            setPhoneError('');
            const response = await axiosDefault.post(`/user/phone_number`, {
                phone_number: phoneNumber,
            });

            return response.data;
        },

        onError: error => {
            setPhoneError(
                'The phone number you provided is already associated with an account',
            );
        },
        onSuccess: data => {
            console.debug('Update phone', data);
            queryClient.setQueryData(['user'], {
                ...user,
                phone_number: data.phone_number,
            });
            setPhoneNumber(data.phone_number);

            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(data));
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });

    const updateEmailMutation = useMutation({
        mutationFn: async email => {
            setEmailError('');
            const response = await axiosDefault.post(`/user/email`, {
                email: email,
            });
            return response.data;
        },

        onError: error => {
            console.error('Error updating display name:', error);
            setEmailError(
                'The email you provided is already associated with an account.',
            );
        },
        onSuccess: data => {
            setSentEmail(true);

            //TODO: use output of data as cache update
            queryClient.setQueryData(['user'], user => {
                console.log('USER (settings)', data);

                return {
                    ...user,
                    email: data.email,
                };
            });
            localStorage.removeItem('user');
            localStorage.setItem(
                'user',
                JSON.stringify({
                    ...user,
                    email: data.email,
                }),
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });

    const pages = useMemo(() => {
        let pages = [
            {
                title: 'Account',
                icon: <ProfileCircle height={24} width={24} />,
                onClick: () => {
                    setPage('Account');
                },
            },
            {
                title: 'Membership',
                icon: <BrightStar height={24} width={24} />,
                onClick: () => {
                    setPage('Membership');
                },
            },
            {
                title: 'Customization',
                icon: <Palette height={24} width={24} />,
                onClick: () => {
                    setPage('Customization');
                },
            },
            {
                title: 'Integrations',
                icon: <Key height={24} width={24} />,
                onClick: () => {
                    setPage('Integrations');
                },
            },

            // {
            //     title:
            //         context && context.dark === true
            //             ? 'Disable Dark Mode'
            //             : 'Enable Dark Mode',
            //     icon: <FillColor height={24} width={24} />,
            //     onClick: context && context.toggleTheme,
            // },
            {
                title: 'Log Out',
                icon: <LogOut height={24} width={24} />,
                onClick: () => {
                    logoutUser && logoutUser.mutate();
                },
            },
        ];

        if (user?.is_superuser === true) {
            pages.push({
                title:
                    context && context.admin === false
                        ? 'Enable Admin'
                        : 'Disable Admin',
                icon:
                    context.admin === false ? (
                        <SwitchOff height={24} width={24} />
                    ) : (
                        <SwitchOn height={24} width={24} />
                    ),
                onClick: () => {
                    context && context.setAdmin(a => !a);
                    queryClient && queryClient.removeQueries();
                },
            });
        }
        if (context.width > 768) {
            // if (user?.guest_link) {
            //     pages.unshift({
            //         title: (
            //             <div className="flex flex-row justify-between grow">
            //                 <div className="flex flex-col">
            //                     <div>My Subi Link</div>
            //                     <div className="opacity-70 text-sm">
            //                         {user.guest_link}
            //                     </div>
            //                 </div>
            //                 <Copy color={colors.accent} />
            //             </div>
            //         ),
            //         icon: <Link height={24} width={24} />,
            //         onClick: () => {
            //             navigator.clipboard
            //                 .writeText(user.guest_link)
            //                 .then(() => {
            //                     console.log(
            //                         'Copied to clipboard successfully!',
            //                     );
            //                     setShowCopied(true);
            //                     setShowCopiedText('Link copied!');
            //
            //                     // Remove 'Link Copied' message after 3 seconds
            //                     setTimeout(() => {
            //                         setShowCopied(false);
            //                     }, 2000);
            //                 })
            //                 .catch(function () {
            //                     console.error(
            //                         'An error occurred trying to copy to clipboard',
            //                     );
            //                 });
            //         },
            //     });
            // }

            if (user?.voice_config) {
                pages.unshift({
                    title: (
                        <div className="flex flex-row justify-between grow">
                            <div className="flex flex-col">
                                <div>My Subi Number</div>
                                <div className="opacity-70 text-sm">
                                    {user.voice_config.phone_number}
                                </div>
                            </div>
                            <Copy color={colors.accent} />
                        </div>
                    ),
                    icon: <Phone height={24} width={24} />,
                    onClick: () => {
                        mixpanel.track('Hotline shared', {
                            location: 'menu',
                        });

                        navigator.clipboard
                            .writeText(user.voice_config.phone_number)
                            .then(() => {
                                console.log(
                                    'Copied to clipboard successfully!',
                                );
                                setShowCopied(true);
                                setShowCopiedText('Link copied!');

                                // Remove 'Link Copied' message after 3 seconds
                                setTimeout(() => {
                                    setShowCopied(false);
                                }, 2000);
                            })
                            .catch(function () {
                                console.error(
                                    'An error occurred trying to copy to clipboard',
                                );
                            });
                    },
                });
            }
        }

        return pages;
    }, [context, user]);

    return (
        <>
            {delAccount !== '' && (
                <DeleteAccountModal
                    deleteOpen={delAccount}
                    setDeleteOpen={setDelAccount}
                />
            )}
            {/*<div*/}
            {/*    style={{*/}
            {/*        width: context.width - 40,*/}
            {/*        opacity: showCopied ? 100 : 0,*/}
            {/*        bottom: showCopiedText === 'Link copied!' ? '30px' : '73px',*/}
            {/*    }}*/}
            {/*    className={`transition-opacity duration-300 z-50 absolute rounded-xl items-center shadow-lg py-3 px-2.5 flex mx-5 font-medium gap-2.5 select-none`}*/}
            {/*>*/}
            {/*    <svg*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*        width="24"*/}
            {/*        height="24"*/}
            {/*        viewBox="0 0 24 24"*/}
            {/*        fill="none"*/}
            {/*    >*/}
            {/*        <path*/}
            {/*            fillRule="evenodd"*/}
            {/*            clipRule="evenodd"*/}
            {/*            d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM7.53044 11.9697C7.23755 11.6768 6.76268 11.6768 6.46978 11.9697C6.17689 12.2626 6.17689 12.7374 6.46978 13.0303L9.46978 16.0303C9.76268 16.3232 10.2376 16.3232 10.5304 16.0303L17.5304 9.03033C17.8233 8.73744 17.8233 8.26256 17.5304 7.96967C17.2375 7.67678 16.7627 7.67678 16.4698 7.96967L10.0001 14.4393L7.53044 11.9697Z"*/}
            {/*            fill="#3EA515"*/}
            {/*        />*/}
            {/*    </svg>*/}

            {/*    <div>{showCopiedText}</div>*/}
            {/*</div>*/}
            <div className="flex flex-row">
                <div className="flex flex-col w-full">
                    <ModalTitleWrapper
                        style={{
                            height: '56px',
                            paddingTop: '16px',
                            paddingBottom: '16px',
                        }}
                    >
                        <div
                            style={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                        <ModalTitle
                            style={{
                                fontSize:
                                    // context?.width < 768 &&
                                    '20px',
                            }}
                        >
                            {page === 'home' ? 'Settings' : page}
                        </ModalTitle>

                        {page === 'home' ? (
                            <>
                                {context.width < 768 ? (
                                    <div className="cursor-pointer">
                                        <Xmark
                                            onClick={() => {
                                                context.setOpenModal('');
                                            }}
                                            height={20}
                                            width={20}
                                            strokeWidth={'1px'}
                                            color={
                                                context.dark ? 'white' : 'black'
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="cursor-pointer">
                                        {setSettingsOpen !== undefined ? (
                                            <NavArrowLeft
                                                height={24}
                                                width={24}
                                                strokeWidth={2}
                                                opacity={0.5}
                                                onClick={() =>
                                                    setSettingsOpen(s => !s)
                                                }
                                            />
                                        ) : (
                                            <Xmark
                                                height={24}
                                                width={24}
                                                strokeWidth={1.5}
                                                onClick={() => setOpenModal('')}
                                                color={
                                                    context.dark
                                                        ? 'white'
                                                        : 'black'
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="cursor-pointer">
                                <NavArrowLeft
                                    opacity={0.5}
                                    height={24}
                                    width={24}
                                    strokeWidth={2}
                                    onClick={() => {
                                        context.openModal !== 'settings'
                                            ? setOpenModal('')
                                            : setPage('home');
                                    }}
                                />
                            </div>
                        )}
                    </ModalTitleWrapper>
                    {page === 'home' &&
                        pages.map((p, i) => (
                            <div
                                className={`flex items-center ${
                                    p.title === page
                                        ? 'text-accent'
                                        : `text-base`
                                } font-medium cursor-pointer space-x-2.5 hover:bg-lightGray dark:hover:bg-lightBlack py-5 px-5`}
                                key={i}
                                style={{
                                    borderBottom: context.dark
                                        ? '1px solid rgba(255, 255, 255, 0.10)'
                                        : '1px solid rgba(20, 23, 24, 0.10)',
                                }}
                                onClick={p.onClick}
                            >
                                <div className="inline-block pb-1 grow-0">
                                    {p.icon}
                                </div>
                                <div className="grow">{p.title}</div>
                            </div>
                        ))}
                    <ModalContentPadding
                        style={{
                            height: context.height - 56,
                            paddingTop: page === 'Integrations' && '0px',
                        }}
                    >
                        {page === 'Membership' && (
                            <div
                                className="flex flex-col select-none gap-6"
                                style={{ height: context.height - 104 }}
                            >
                                <div className="font-medium">My plan</div>
                                <div
                                    style={{
                                        width: '100%',
                                        maxWidth: '350px',
                                    }}
                                    className={`rounded-xl select-none text-xs font-medium bg-black dark:bg-white text-white dark:text-black flex flex-col text-center pt-1.5`}
                                >
                                    <div>{'YOUR PLAN'}</div>
                                    <div
                                        style={{
                                            marginTop: '2px',
                                            border: '2px solid var(--black-10, rgba(20, 23, 24))',
                                        }}
                                        className="flex flex-col gap-2.5 p-5 bg-white rounded-xl"
                                    >
                                        <div className="flex flex-col gap-6">
                                            <div className="flex flex-col gap-4">
                                                <div className="flex flex-row justify-between">
                                                    <div>
                                                        <div
                                                            style={{
                                                                lineHeight:
                                                                    '100%',
                                                            }}
                                                            className="text-lg font-semibold text-black "
                                                        >
                                                            {subscriptionPlan}
                                                        </div>
                                                        <div className="text-base font-normal text-black opacity-70 ">
                                                            {subscriptionPrice}
                                                        </div>
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            setOpenModal(
                                                                'paywall',
                                                            )
                                                        }
                                                        style={{
                                                            fontSize: '15px',
                                                        }}
                                                        className="cursor-pointer py-1.5 h-fit px-2.5 rounded-2xl dark:text-secondaryAccentMedium dark:hover:text-secondaryAccentMediumHover border border-secondaryAccent dark:border-secondaryAccentMedium dark:hover:border-secondaryAccentHover hover:border-secondaryAccentHover hover:text-secondaryAccentHover text-secondaryAccent"
                                                    >
                                                        Edit plan
                                                    </div>
                                                </div>
                                                <div className="text-sm text-black font-normal text-left">
                                                    <div
                                                        style={{
                                                            display:
                                                                'inline-flex',
                                                            alignItems:
                                                                'center',
                                                            gap: '4px',
                                                        }}
                                                    >
                                                        {subscription?.subscription_tier !==
                                                        'Trial' ? (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="10"
                                                                height="10"
                                                                viewBox="0 0 10 10"
                                                                fill="none"
                                                            >
                                                                <g clipPath="url(#clip0_4270_8628)">
                                                                    <path
                                                                        d="M5.83332 3.75001L5.72916 3.90626M4.16666 3.75001C3.78657 3.24403 3.18155 2.91667 2.49999 2.91667C1.3494 2.91667 0.416656 3.84941 0.416656 5.00001C0.416656 6.15059 1.3494 7.08334 2.49999 7.08334C3.18158 7.08334 3.78672 6.75601 4.16682 6.25001L4.3057 6.04167"
                                                                        stroke="#EB0A74"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M4.16666 3.75001L5.83316 6.25001C6.21324 6.75601 6.81841 7.08334 7.49999 7.08334C8.65057 7.08334 9.58332 6.15059 9.58332 5.00001C9.58332 3.84941 8.65057 2.91667 7.49999 2.91667C6.81841 2.91667 6.21324 3.24398 5.83316 3.75001"
                                                                        stroke="#EB0A74"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_4270_8628">
                                                                        <rect
                                                                            width="10"
                                                                            height="10"
                                                                            fill="white"
                                                                        />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        ) : (
                                                            subscription?.chats
                                                                ?.allowed || 0
                                                        )}
                                                        {' Chats'}
                                                    </div>
                                                    <div>
                                                        {(subscription?.emails
                                                            ?.allowed || 0) +
                                                            ' Emails'}
                                                    </div>
                                                    <div>
                                                        {subscription?.calls
                                                            ?.allowed +
                                                            ' Calls'}
                                                    </div>
                                                </div>
                                            </div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="302"
                                                height="2"
                                                viewBox="0 0 302 2"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 1H301"
                                                    stroke="#141718"
                                                    strokeOpacity="0.1"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                            <div className="text-left text-black font-medium">
                                                {remainingMinutes +
                                                    ' Free Minutes Left' +
                                                    subscriptionRenews}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    style={{ width: '185px' }}
                                    onClick={subscriptionsPortal}
                                    color={'black'}
                                >
                                    Membership Settings
                                </Button>
                            </div>
                        )}
                        {page === 'Account' && (
                            <>
                                <PasswordForm>
                                    {/*<AvatarUpload />*/}
                                    <SubsectionTitle dark={context.dark}>
                                        Display Name
                                    </SubsectionTitle>
                                    <Input
                                        value={displayName}
                                        onChange={e =>
                                            setDisplayName(e.target.value)
                                        }
                                    />
                                    <Button
                                        style={{
                                            width: '121px',
                                        }}
                                        disabled={
                                            displayName === user?.display_name
                                        }
                                        onClick={() => {
                                            updateDisplayNameMutation.mutate(
                                                displayName,
                                            );
                                        }}
                                        type="Save Changes"
                                        value="Save Changes"
                                    >
                                        {updateDisplayNameMutation.isPending ? (
                                            <ButtonLoaderWhite />
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </Button>
                                </PasswordForm>
                                <PasswordForm>
                                    {/*<AvatarUpload />*/}
                                    <div className="flex flex-col gap-1">
                                        <SubsectionTitle dark={context.dark}>
                                            Phone Number
                                        </SubsectionTitle>
                                        {phoneError && (
                                            <div className="text-alertRed">
                                                {phoneError}
                                            </div>
                                        )}
                                        <Input
                                            value={phoneNumber}
                                            onChange={e =>
                                                setPhoneNumber(e.target.value)
                                            }
                                        />
                                    </div>

                                    <Button
                                        style={{
                                            width: '121px',
                                        }}
                                        disabled={
                                            phoneNumber ===
                                                user?.phone_number ||
                                            !isValidPhoneNumber(phoneNumber)
                                        }
                                        onClick={() => {
                                            updatePhoneNumberMutation.mutate(
                                                phoneNumber,
                                            );
                                        }}
                                        type="Save Changes"
                                        value="Save Changes"
                                    >
                                        {updatePhoneNumberMutation.isPending ? (
                                            <ButtonLoaderWhite />
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </Button>
                                </PasswordForm>
                                <PasswordForm
                                // onSubmit={handleSubmit}
                                >
                                    <div className="flex flex-col gap-2.5">
                                        <SubsectionTitle dark={context.dark}>
                                            Email
                                        </SubsectionTitle>
                                        {emailError && (
                                            <div className="text-alertRed">
                                                {emailError}
                                            </div>
                                        )}
                                        <Input
                                            value={email}
                                            onChange={e =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                        {/*{sentEmail === true && (*/}
                                        {/*    <SubsectionSubtext*/}
                                        {/*        style={{*/}
                                        {/*            color: styleConfig.colors*/}
                                        {/*                .dangerLabel,*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        {*/}
                                        {/*            "Please verify your email address by clicking the link in the verification email we sent. If you don't see the email, click"*/}
                                        {/*        }*/}
                                        {/*        <a*/}
                                        {/*            onClick={() =>*/}
                                        {/*                updateEmailMutation.mutate(*/}
                                        {/*                    email,*/}
                                        {/*                )*/}
                                        {/*            }*/}
                                        {/*            style={{*/}
                                        {/*                textDecoration:*/}
                                        {/*                    'underline',*/}
                                        {/*                cursor: 'pointer',*/}
                                        {/*            }}*/}
                                        {/*        >*/}
                                        {/*            here*/}
                                        {/*        </a>*/}
                                        {/*        and we will send a new one.*/}
                                        {/*    </SubsectionSubtext>*/}
                                        {/*)}*/}
                                    </div>

                                    <Button
                                        style={{
                                            width: '121px',
                                        }}
                                        disabled={email === user?.email}
                                        onClick={() =>
                                            updateEmailMutation.mutate(email)
                                        }
                                        type="Save Changes"
                                        value="Save Changes"
                                    >
                                        {updateEmailMutation.isPending ? (
                                            <ButtonLoaderWhite />
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </Button>
                                </PasswordForm>
                                <PasswordForm>
                                    <SubsectionTitle dark={context.dark}>
                                        Password
                                    </SubsectionTitle>
                                    <Button
                                        style={{ width: 'fit-content' }}
                                        onClick={() =>
                                            setPage('Change Password')
                                        }
                                    >
                                        Change Password
                                    </Button>
                                </PasswordForm>

                                <PasswordForm>
                                    <div className="flex flex-col gap-2.5">
                                        <SubsectionTitle dark={context.dark}>
                                            Delete Account
                                        </SubsectionTitle>
                                        <SubsectionSubtext
                                            style={{ opacity: 0.7 }}
                                        >
                                            Type DELETE below to proceed. This
                                            action is permanent and can’t be
                                            undone.
                                        </SubsectionSubtext>
                                    </div>
                                    <Input
                                        value={deleteAccount}
                                        placeholder="DELETE"
                                        onChange={e =>
                                            setDeleteAccount(e.target.value)
                                        }
                                    />
                                    <Button
                                        color={'black'}
                                        size={'large'}
                                        style={{
                                            background: `${styleConfig.colors.dangerAction.fill}`,
                                            color: `${styleConfig.colors.dangerAction.text}`,
                                        }}
                                        disabled={deleteAccount !== 'DELETE'}
                                        onClick={() => setDelAccount('delete')}
                                        type="Delete Account"
                                        value="Delete Account"
                                    >
                                        Delete Account
                                    </Button>
                                </PasswordForm>
                            </>
                        )}
                        {page === 'Customization' && <Customization />}
                        {page === 'Integrations' && <Integrations />}
                        {page === 'Change Password' && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '30px',
                                }}
                            >
                                <PasswordForm style={{ gap: '15px' }}>
                                    <div className="flex flex-col gap-2.5">
                                        <SubsectionTitle dark={context.dark}>
                                            Old Password
                                        </SubsectionTitle>
                                        {oldError?.length > 0 && (
                                            <SubsectionSubtext
                                                style={{
                                                    color: styleConfig.colors
                                                        .dangerLabel,
                                                }}
                                            >
                                                {oldError}
                                            </SubsectionSubtext>
                                        )}
                                    </div>

                                    <Input
                                        type="password"
                                        style={{
                                            margin: '0px',
                                            padding: '18px',
                                        }}
                                        value={password}
                                        onChange={e =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <div className="flex flex-col gap-2.5">
                                        <SubsectionTitle dark={context.dark}>
                                            New Password{' '}
                                        </SubsectionTitle>
                                        {error?.length > 0 ? (
                                            <SubsectionSubtext
                                                style={{
                                                    color: styleConfig.colors
                                                        .dangerLabel,
                                                }}
                                            >
                                                {error}
                                            </SubsectionSubtext>
                                        ) : (
                                            <SubsectionSubtext
                                                style={{
                                                    opacity: '0.5',
                                                }}
                                            >
                                                Must be at least 8 characters.
                                            </SubsectionSubtext>
                                        )}
                                    </div>
                                    <Input
                                        type="password"
                                        style={{
                                            margin: '0px',
                                            padding: '18px',
                                        }}
                                        value={newPassword}
                                        onChange={e =>
                                            setNewPassword(e.target.value)
                                        }
                                    />
                                    <SubsectionTitle dark={context.dark}>
                                        Confirm New Password
                                    </SubsectionTitle>
                                    <Input
                                        type="password"
                                        style={{
                                            margin: '0px',
                                            padding: '18px',
                                        }}
                                        value={newPasswordConfirm}
                                        onChange={e =>
                                            setNewPasswordConfirm(
                                                e.target.value,
                                            )
                                        }
                                    />
                                </PasswordForm>
                                <Button
                                    style={{
                                        width: '121px',
                                    }}
                                    onClick={() => {
                                        setOldError('');

                                        validatePassword(
                                            newPassword,
                                            newPasswordConfirm,
                                            setError,
                                            () =>
                                                updatePasswordMutation.mutate({
                                                    newPassword,
                                                    password,
                                                }),
                                        );
                                    }}
                                    type="Save Changes"
                                    value="Save Changes"
                                >
                                    {updatePasswordMutation.isPending ? (
                                        <ButtonLoaderWhite />
                                    ) : (
                                        'Save Changes'
                                    )}
                                </Button>
                            </div>
                        )}
                    </ModalContentPadding>
                </div>
            </div>
        </>
    );
};

const SettingsModal = () => {
    const context = useContext(GeneralContext);
    const openModal = context.openModal;
    const setOpenModal = context.setOpenModal;

    return (
        <Modal
            sx={{
                outline: 0,
                '& .MuiBackdrop-root': {
                    outline: 'none',
                    backgroundColor: 'rgba(200, 200, 200, 0.3)', // Replace this with the desired background color and opacity
                },
            }}
            open={
                openModal === 'settings' ||
                openModal === 'customization' ||
                openModal === 'membership' ||
                openModal === 'integrations'
            }
            onClose={() => setOpenModal('none')}
            aria-labelledby="modal-title"
            disableAutoFocus={true}
            aria-describedby="modal-description"
        >
            <ModalWrapper dark={context.dark}>
                <SettingsContent
                    defaultPage={
                        openModal === 'customization'
                            ? 'Customization'
                            : openModal === 'integrations'
                              ? 'Integrations'
                              : openModal === 'membership'
                                ? 'Membership'
                                : 'home'
                    }
                />
            </ModalWrapper>
        </Modal>
    );
};
export default SettingsModal;

const ModalContentPadding = styled.div`
    //padding: 30px 40px 40px 40px;
    //@media (max-width: 768px) {
    padding: 20px 20px 40px 20px;
    //}
    gap: 40px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`;
const SubsectionSubtext = styled.div`
    font-family: Indivisible, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
`;

const SubsectionTitle = styled.div`
    font-family: Indivisible, sans-serif;
    //font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    //@media (max-width: 768px) {
    font-size: 16px;
    //}
`;

const ModalWrapper = styled.div`
    //width: 800px;
    //height: 600px;
    //position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => (props.dark ? '#141718' : 'white')};
    color: ${props => (props.dark ? 'white' : '#141718')};

    box-shadow: 24px;
    //border-radius: 10px;
    overflow: hidden;
    //@media (max-width: 768px) {
    position: fixed;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    //}
`;

const ModalTitleWrapper = styled.div`
    display: flex;
    z-index: 1;
    justify-content: space-between;
    //border-bottom: 1px #e8ecef solid;
    align-items: center;
    //padding: 16px 30px 16px 30px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    //height: 62px;
    //@media (max-width: 768px) {
    height: 64px;
    padding: 20px 20px 20px 20px;
    border-bottom: none;
    flex-direction: row-reverse;
    //}
`;

export const PasswordForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
`;

const Input = styled.input`
    color: black;
    font-family: Indivisible, sans-serif;
    padding: 20px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    border-radius: 12px;
    border: none;
    width: 100%;
    background-color: #f3f3f3;

    ::placeholder {
        color: #6c7275;
    }

    &:focus {
        outline: none;
    }
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    border-radius: 20px;
    background: ${colors.secondaryAccent};
    height: 31px;
    width: 131px;
    color: #fff;
    cursor: pointer;
    /* 15 Medium */
    font-family: Indivisible;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 19.5px */

    &:disabled {
        background: grey;
        cursor: default;

        &:hover {
            background: grey;
        }
    }

    &:hover {
        background: ${colors.secondaryAccentHover};
    }
`;
