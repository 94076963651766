import React from 'react';

export const GoogleLogo = ({ color = 'black' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1 10.2273C20.1 9.51825 20.0364 8.83643 19.9182 8.18188H10.5V12.0501H15.8818C15.65 13.3001 14.9455 14.3592 13.8864 15.0682V17.5773H17.1182C19.0091 15.8364 20.1 13.2728 20.1 10.2273Z"
                fill="#4285F4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5001 19.9999C13.2001 19.9999 15.4637 19.1044 17.1183 17.5772L13.8864 15.0681C12.991 15.6681 11.8455 16.0226 10.5001 16.0226C7.89554 16.0226 5.69099 14.2635 4.90463 11.8999H1.56372V14.4908C3.20918 17.759 6.59099 19.9999 10.5001 19.9999Z"
                fill="#34A853"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.90455 11.8999C4.70455 11.2999 4.59091 10.659 4.59091 9.99994C4.59091 9.34085 4.70455 8.69994 4.90455 8.09994V5.50903H1.56364C0.886364 6.85903 0.5 8.38631 0.5 9.99994C0.5 11.6136 0.886364 13.1409 1.56364 14.4909L4.90455 11.8999Z"
                fill="#FBBC05"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5001 3.97727C11.9683 3.97727 13.2864 4.48182 14.3228 5.47273L17.191 2.60455C15.4592 0.990909 13.1955 0 10.5001 0C6.59099 0 3.20918 2.24091 1.56372 5.50909L4.90463 8.1C5.69099 5.73636 7.89554 3.97727 10.5001 3.97727Z"
                fill="#EA4335"
            />
        </svg>
    );
};
