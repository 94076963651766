import React, { createContext, useEffect, useState } from 'react';
import useTheme from '@/hooks/useTheme';
import useUser from '@/context/useUser';
import useEventSource from '@/hooks/useEventSource';

export const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {
    const { theme, toggleTheme } = useTheme();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [openModal, setOpenModal] = useState('');
    const [admin, setAdmin] = useState(false);
    const [register, setRegister] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState('');
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const { user } = useUser();
    const { isOffline } = useEventSource(user);
    // const {
    //     generating,
    //     setGenerating,
    //     thinking,
    //     setThinking,
    //     tool,
    //     toolName,
    //     playUrl,
    // } = useUniversalEventSource();
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // useEffect(() => {
    //     if (windowWidth < 768) {
    //         setAdmin(false);
    //     }
    // }, [windowWidth]);
    return (
        <GeneralContext.Provider
            value={{
                height: windowHeight,
                width: windowWidth,
                dark: theme === 'dark',
                toggleTheme,
                deleteOpen,
                setDeleteOpen,
                openModal,
                setOpenModal,
                admin,
                setAdmin,
                setRegister,
                register,
                isOffline,
                mobileSidebar,
                setMobileSidebar,
                // generating,
                // setGenerating,
                // thinking,
                // setThinking,
                // tool,
                // toolName,
                // playUrl,
            }}
        >
            {children}
        </GeneralContext.Provider>
    );
};

export default GeneralProvider;
