import React, { useContext, useState } from 'react';
import { GeneralContext } from '@/context/GeneralContext';
import useUser from '@/context/useUser';
import { useNavigate } from 'react-router-dom';
import { NavArrowLeft, NavArrowRight, Plus } from 'iconoir-react';
import AddResponseModal from '@/pages/modals/AddReponseModal';
import { useResponses } from '@/hooks/useResponses';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loadingAnimation.json';
import { usePhoneLine } from '@/hooks/usePhoneLine';

function Responses() {
    const context = useContext(GeneralContext);

    const { user } = useUser();
    const navigate = useNavigate();
    const { phoneLine } = usePhoneLine({ user });

    const { all, attention, allLoading, attentionLoading } = useResponses(
        user?.voice_config?.phone_number,
    );
    if (allLoading || attentionLoading) {
        return (
            <div
                style={{ height: context.height, width: context.width }}
                className={`flex-grow z-10 h-full flex
                    relative flex-col bg-black items-center justify-center px-5 `}
            >
                <Lottie
                    style={{ width: '75px' }}
                    animationData={loadingAnimation}
                />
            </div>
        );
    }

    return (
        <>
            {context.openModal === 'response' && <AddResponseModal />}
            <div
                style={{ height: context.height, width: context.width }}
                className={`flex-grow z-10 h-full flex
                    relative flex-col bg-white dark:bg-black`}
            >
                <div
                    className={`flex py-3.5 justify-between items-center px-5`}
                >
                    <NavArrowLeft
                        opacity={0.5}
                        strokeWidth={2}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/');
                        }}
                    />
                    <div className={'text-white text-xl font-medium'}>
                        Response Library
                    </div>
                    <div
                        onClick={() => context.setOpenModal('response')}
                        style={{ height: '24px', width: '24px' }}
                        className="rounded-full bg-white bg-opacity-30 hover:bg-opacity-40 cursor-pointer items-center justify-center flex"
                    >
                        <Plus color={'white'} height={20} width={20} />
                    </div>
                </div>
                <div className="grow flex flex-col justify-between">
                    <div className="flex flex-col gap-4 px-5">
                        <div
                            style={{ fontSize: '15px' }}
                            className="px-2.5 text-center pb-2 opacity-80 "
                        >
                            Review and adjust how your Subi will <br />
                            respond to typical questions it might receive.
                        </div>
                        <div className="bg-white bg-opacity-10  rounded-xl">
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        borderBottom: '1px solid rgba(255,255,255,0.2)',*/}
                            {/*    }}*/}
                            {/*    className="justify-between pr-4 pl-4 relative items-center py-3 flex-grow flex flex-row  w-auto  text-center font-medium"*/}
                            {/*>*/}
                            {/*    <div className="h-12 text-black dark:text-white dark:text-opacity-30 gap-2.5 flex items-center relative hover:text-opacity-50 text-opacity-30 w-full ">*/}
                            {/*        {!isFocused && inputText === '' && (*/}
                            {/*            <div className={`absolute left-2`}>*/}
                            {/*                <Search strokeWidth={2} />*/}
                            {/*            </div>*/}
                            {/*        )}*/}
                            {/*        <input*/}
                            {/*            onFocus={() => setIsFocused(true)}*/}
                            {/*            onBlur={() => setIsFocused(false)}*/}
                            {/*            className={`border-black focus:outline-none outline-none bg-white bg-opacity-10 border-white-10 text-black dark:text-white leading-none w-full rounded-xl border-2  hover:border-opacity-50 border-opacity-5 dark:border-opacity-10 p-3 ${!isFocused && inputText === '' && 'pl-9'}`}*/}
                            {/*            type="text"*/}
                            {/*            placeholder="Search..."*/}
                            {/*            value={inputText}*/}
                            {/*            onChange={e => setInputText(e.target.value)}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div
                                onClick={() => {
                                    attention &&
                                        attention.pages[0] &&
                                        attention.pages[0].length > 0 &&
                                        navigate('/responses/attention');
                                }}
                                style={{
                                    borderBottom:
                                        '1px solid rgba(255,255,255,0.2)',
                                    cursor:
                                        attention &&
                                        attention.pages[0] !== undefined &&
                                        attention.pages[0].length > 0 &&
                                        'pointer',
                                    opacity:
                                        attention &&
                                        attention.pages[0] !== undefined &&
                                        attention.pages[0].length > 0
                                            ? '1'
                                            : '0.5',
                                }}
                                className={`${
                                    attention &&
                                    attention.pages[0] !== undefined &&
                                    attention.pages[0].length > 0 &&
                                    `hover:bg-opacity-10`
                                } bg-opacity-0 bg-white justify-between pr-4 pl-4 relative  items-center py-3 flex-grow flex flex-row  w-auto  text-center font-medium`}
                            >
                                <div className="text-xl">
                                    {'Needs Attention'}
                                </div>

                                <div className="flex flex-row items-center gap-2">
                                    {attention &&
                                        attention.pages.flat().length > 0 && (
                                            <NotificationCircle>
                                                {' '}
                                                {attention.pages.flat().length}
                                            </NotificationCircle>
                                        )}
                                    <NavArrowRight opacity={0.5} />
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    navigate('/responses/all');
                                }}
                                className="hover:bg-opacity-10 rounded-b-xl bg-white bg-opacity-0 justify-between px-4 relative cursor-pointer items-center py-3 flex-grow flex flex-row  w-auto  text-center font-medium"
                            >
                                <div className="text-xl">{'All'}</div>
                                <div className="flex flex-row items-center gap-2">
                                    <div className="pr-2.5 pt-0.5 opacity-50 font-medium">
                                        {all?.length}
                                    </div>
                                    <NavArrowRight opacity={0.5} />
                                </div>
                            </div>
                        </div>
                        {/*<div className="justify-between px-4 relative cursor-pointer items-center py-3 flex-grow flex flex-row  w-auto bg-white bg-opacity-10 rounded-xl text-center font-medium">*/}
                        {/*    <div className="text-xl">{'Knowledge'}</div>*/}
                        {/*    <div className="flex flex-row items-center gap-2">*/}
                        {/*        {notif && (*/}
                        {/*            <NotificationCircle>1</NotificationCircle>*/}
                        {/*        )}*/}
                        {/*        <NavArrowRight opacity={0.5} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <div className="justify-between px-4 relative items-center py-3 flex-grow flex flex-row w-auto bg-white bg-opacity-10 rounded-xl text-center font-medium">*/}
                        {/*        <div className="text-xl">{'Strict Mode'}</div>*/}
                        {/*        <ToggleSwitch />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    {Array.isArray(phoneLine?.knowledge_complete) ? (
                        phoneLine?.knowledge_complete?.length === 0 && (
                            <div className="mb-20 cursor-pointer bg-accent font-medium flex flex-col gap-2.5 px-5 py-2.5 mb-7 text-center">
                                We're almost done scraping your website.
                                <br />
                                New responses will appear under All.
                            </div>
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
}

const NotificationCircle = ({ children }) => {
    return (
        <div
            style={{
                width: '26px',
                padding: '3px 6px 0px 5px',
            }}
            className="bg-accent rounded-full font-medium"
        >
            {children}
        </div>
    );
};

const ToggleSwitch = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    return (
        <label className="flex items-center cursor-pointer">
            <div className="relative">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleToggle}
                    className="sr-only"
                />
                <div
                    className={`block w-14 h-8 rounded-full  ${
                        !isChecked
                            ? 'bg-white hover:bg-opacity-20 bg-opacity-10'
                            : 'bg-secondaryAccentMedium hover:bg-secondaryAccentMediumHover'
                    }`}
                ></div>
                <div
                    className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-300 ${
                        isChecked ? 'transform translate-x-full' : ''
                    }`}
                    style={{
                        transform: isChecked
                            ? 'translateX(100%)'
                            : 'translateX(0)',
                        transition: 'transform 0.3s ease-in-out',
                    }}
                ></div>
            </div>
        </label>
    );
};

export default Responses;
