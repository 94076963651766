import styled from 'styled-components';
import React, { useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { Modal } from '@mui/material';
import { Xmark } from 'iconoir-react';
import Lottie from 'lottie-react';
import confetti from '@/assets/lottie/confetti.json';

export const PaymentConfirmationModal = ({}) => {
    const context = useContext(GeneralContext);

    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)',
                },
            }}
            open={context.openModal === 'confirmation'}
            onClose={() => {
                context.setOpenModal('');
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <ModalWrapper className="bg-white dark:bg-black text-black dark:text-white">
                <ModalTitleWrapper>
                    <Xmark
                        height={24}
                        width={24}
                        strokeWidth={1.5}
                        onClick={() => context.setOpenModal('')}
                        color={context.dark ? 'white' : 'black'}
                    />

                    <div className="font-medium">Success!</div>
                    <div
                        style={{
                            width: '24px',
                            height: '24px',
                        }}
                    />
                </ModalTitleWrapper>
                <div
                    className={`p-5 md:pb-10 md:p-0 flex flex-col items-center justify-between gap-5 grow`}
                >
                    <div className="flex flex-col gap-2.5 text-center">
                        <Lottie
                            style={{ height: '300px' }}
                            animationData={confetti}
                        />
                        <div className="text-2xl font-bold">
                            Your Subi membership is now active.
                        </div>
                        <div className="text-opacity-70">
                            Your plan will automatically renew on this day next
                            month. You can cancel anytime in Settings.
                        </div>
                    </div>
                    <div
                        style={{
                            borderRadius: '20px',
                            width: context.width >= 768 ? '350px' : '100%',
                        }}
                        className={`bg-accent py-2.5 hover:bg-accentHover text-center font-medium w-full text-white cursor-pointer`}
                        onClick={() => {
                            context.setOpenModal('');
                        }}
                    >
                        Back to Subi
                    </div>
                </div>
            </ModalWrapper>
        </Modal>
    );
};

const ModalWrapper = styled.div`
    width: 600px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
`;

const ModalTitleWrapper = styled.div`
    display: flex;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    line-height: 100%;
    height: 64px;
    padding: 20px;
    border-bottom: none;
    flex-direction: row;
`;
