import {
    keepPreviousData,
    useInfiniteQuery,
    useQuery,
} from '@tanstack/react-query';
import { axiosDefault } from '../util/axiosRetry';
import { useContext } from 'react';
import { GeneralContext } from '@/context/GeneralContext';

export const useActiveConversationList = () => {
    const getMoreChats = async () => {
        console.debug('fetching active chats');
        const response = await axiosDefault.get('/user/conversations', {
            params: {
                active: true,
            },
        });
        console.debug('active chats response', response);
        return response.data;
    };

    const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } =
        useQuery({
            queryKey: ['conversations', 'active'],
            placeholderData: keepPreviousData,
            queryFn: getMoreChats,
            refetchOnWindowFocus: false,
        });
    return { data, fetchNextPage, isFetching, isFetchingNextPage };
};

export const useInactiveConversationList = () => {
    const context = useContext(GeneralContext);
    const getMoreChats = async ({ pageParam }) => {
        const response = await axiosDefault.get('/user/conversations', {
            params: {
                page: pageParam,
                active: false,
            },
        });

        console.debug('inactive chats response (convo list)', response);

        return response.data;
    };

    const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } =
        useInfiniteQuery({
            queryKey: ['conversations', 'inactive'],
            placeholderData: keepPreviousData,
            queryFn: getMoreChats,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
            initialPageParam: 0,
            enabled: context.admin === false,
            getNextPageParam: (lastPage, pages) => {
                return pages ? pages.length : 0;
            },
        });
    return { data, fetchNextPage, isFetching, isFetchingNextPage };
};

export default useActiveConversationList;
