import styled from 'styled-components';
import React, { useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { Modal } from '@mui/material';
import axiosWithRetry from '../../util/axiosRetry';
import { useNavigate } from 'react-router-dom';
import styleConfig from '../../styles/styleConfig';
import { Xmark } from 'iconoir-react';
import { useQueryClient } from '@tanstack/react-query';

export const DeleteConversationModal = ({ deleteOpen, setDeleteOpen }) => {
    const navigate = useNavigate();
    const context = useContext(GeneralContext);
    const queryClient = useQueryClient();
    
    const deleteConversation = async conversationId => {
        try {
            const response = await axiosWithRetry.patch(
                `/conversation/${conversationId}`,
            );
            if (response.status === 204) {
                //TODO: remove from local state
                // Remove unread from context
                // context.setUnreadMessages(
                //     context.unreadMessages.filter(
                //         chatId => chatId !== conversationId,
                //     ),
                // );
                queryClient.setQueryData(['conversations', 'active'], (oldData) => {
                    if (oldData) {
                        return  oldData.filter(conversation => conversation.conversation.id !== conversationId);
                    }
                    return oldData;
                });

                console.log('Delete successful');
            }
        } catch (error) {
            console.error(`Error deleting the conversation: ${error}`);
        }
    };

    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)', // Replace this with the desired background color and opacity
                },
            }}
            open={deleteOpen !== ''}
            onClose={() => setDeleteOpen('')}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <ModalWrapper width={context?.width}>
                <ModalTitleWrapper>
                    <Xmark
                        id="delete-conversation-modal-button"
                        height={24}
                        width={24}
                        strokeWidth={1.5}
                        onClick={() => setDeleteOpen('')}
                        style={{ color: 'black', cursor: 'pointer' }}
                    />
                </ModalTitleWrapper>
                <div className="flex flex-col gap-8 p-10 pt-7.5">
                    <div className="flex flex-col gap-4">
                        <SubsectionTitle dark={context?.dark}>
                            Are you sure you want to delete this conversation?
                        </SubsectionTitle>{' '}
                        <SubsectionSubtext dark={context?.dark}>
                            This action cannot be undone.
                        </SubsectionSubtext>
                    </div>
                    <div className="flex flex-col gap-2.5 items-center">
                        <ConfirmButton
                            onClick={() => {
                                deleteConversation(deleteOpen);
                                navigate('/');

                                setDeleteOpen('');
                            }}
                        >
                            Delete conversation
                        </ConfirmButton>
                        <CancelButton onClick={() => setDeleteOpen('')}>
                            Cancel
                        </CancelButton>
                    </div>
                </div>
            </ModalWrapper>
        </Modal>
    );
};

const ModalTitleWrapper = styled.div`
    display: flex;
    z-index: 1;

    align-items: center;
    padding: 15px 15px 0px 15px;
    box-sizing: border-box;
    color: ${styleConfig.colors.text};
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    @media (max-width: 768px) {
        border-bottom: none;
    }
`;

const ConfirmButton = styled.button`
    border: none;
    color: ${styleConfig.colors.dangerAction.text};
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: -0.32px;
    background-color: ${styleConfig.colors.dangerAction.fill};
    padding: 6px 10px;
    border-radius: 20px;
    cursor: pointer;
    width: fit-content;
`;
const CancelButton = styled.button`
    width: fit-content;

    border: none;
    color: #141718;
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: -0.32px;
    padding: 6px 10px;
    border-radius: 20px;
    cursor: pointer;
    background: #e3e3e3;
`;

const ModalWrapper = styled.div`
    width: 750px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: ${props => `${props.width - 40}px`};
    }
`;

const SubsectionSubtext = styled.p`
    color: #141718;
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SubsectionTitle = styled.div`
    color: #141718;
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 28px */
    letter-spacing: -0.56px;
    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px; /* 122.222% */
    }
`;
const ModalContentWrapper = styled.div`
    padding: 30px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
`;

const ButtonBar = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;
`;
