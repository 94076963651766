import React from 'react';

const SideBarLogo = ({ color, width = '150', height = '25' }) => {
    return (
        <svg
            style={{ cursor: 'pointer' }}
            width={width}
            height={height}
            viewBox="0 0 150 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.2013 14.7014C22.0914 14.594 21.9161 14.594 21.8062 14.7014C20.6417 15.8407 20 17.3558 20 18.9694C20 20.5829 20.6417 22.098 21.8062 23.2374C23.0063 24.4125 24.5808 25 26.1582 25C27.7357 25 29.3101 24.4125 30.5103 23.2374C30.5638 23.1837 30.5964 23.1091 30.5964 23.0346C30.5964 22.96 30.5667 22.8854 30.5103 22.8317L22.2013 14.7014Z"
                fill="#EB0A74"
            />
            <path
                d="M21.8062 1.76006C20.6417 2.90237 20 4.41749 20 6.03104C20 7.64458 20.6417 9.15971 21.8062 10.299C21.8596 10.3527 21.9339 10.3796 22.0022 10.3796C22.0706 10.3796 22.1448 10.3527 22.1983 10.299L30.5073 2.16867C30.5608 2.11498 30.5935 2.04042 30.5935 1.96586C30.5935 1.89129 30.5638 1.81673 30.5073 1.76304C28.11 -0.587184 24.2065 -0.587184 21.8062 1.76006Z"
                fill="#EB0A74"
            />
            <path
                d="M43.3971 14.7014L35.0881 22.8317C35.0346 22.8854 35.002 22.96 35.002 23.0346C35.002 23.1091 35.0317 23.1837 35.0881 23.2374C36.2883 24.4125 37.8627 24.9971 39.4402 24.9971C41.0176 24.9971 42.5921 24.4095 43.7922 23.2374C44.9567 22.098 45.5984 20.5829 45.5984 18.9694C45.5984 17.3558 44.9567 15.8407 43.7922 14.7014C43.6823 14.594 43.507 14.594 43.3971 14.7014Z"
                fill="#EB0A74"
            />
            <path
                d="M43.3971 10.2991C43.4506 10.3528 43.5219 10.3796 43.5932 10.3796C43.6645 10.3796 43.7358 10.3528 43.7892 10.2991C44.9537 9.15978 45.5954 7.64466 45.5954 6.03111C45.5954 4.41757 44.9537 2.90245 43.7892 1.76312C41.3889 -0.584126 37.4854 -0.584126 35.0881 1.76312C35.0346 1.81681 35.002 1.89137 35.002 1.96593C35.002 2.0405 35.0317 2.11506 35.0881 2.16874L43.3971 10.2991Z"
                fill="#EB0A74"
            />
            <path
                d="M67.7835 13.7938C67.5815 13.4448 67.2844 13.1048 66.9041 12.7767C66.5298 12.4576 66.1971 12.2041 65.906 12.0162C65.6119 11.8253 65.193 11.6195 64.6642 11.4048C64.1384 11.193 63.7196 11.032 63.4136 10.9306C63.1106 10.8292 62.665 10.686 62.0738 10.5011C60.6687 10.0686 59.7121 9.66299 59.2279 9.3021C58.7704 8.95911 58.5476 8.52665 58.5476 7.97488C58.5476 7.4589 58.7437 7.0503 59.1506 6.72818C59.5636 6.39712 60.1488 6.2301 60.8915 6.2301C62.5135 6.2301 63.6661 7.00854 64.4088 8.60717C64.4296 8.65489 64.4711 8.69068 64.5216 8.70858C64.5721 8.72349 64.6286 8.71753 64.6732 8.69068L67.8666 6.83854C67.9528 6.78783 67.9855 6.68046 67.9409 6.59099C67.2695 5.20411 66.3159 4.10356 65.1069 3.32214C63.8978 2.54072 62.4778 2.14404 60.8915 2.14404C59.112 2.14404 57.5673 2.68388 56.2988 3.74864C55.0214 4.82235 54.3738 6.28081 54.3738 8.07927C54.3738 8.98894 54.5372 9.7972 54.861 10.4802C55.1848 11.1692 55.6631 11.7478 56.2839 12.1981C56.887 12.6366 57.5019 12.9915 58.1079 13.2539C58.708 13.5134 59.4507 13.7759 60.3181 14.0354C61.851 14.4708 62.9294 14.8764 63.5176 15.2433C64.0701 15.5863 64.3375 16.0545 64.3375 16.6719C64.3375 17.2058 64.1176 17.6353 63.6661 17.9872C63.2086 18.3451 62.46 18.5271 61.444 18.5271C59.3081 18.5271 57.9178 17.5995 57.19 15.6937C57.1692 15.6429 57.1276 15.6012 57.0771 15.5833C57.0266 15.5654 56.9672 15.5714 56.9197 15.5982L53.6727 17.504C53.5955 17.5488 53.5598 17.6472 53.5895 17.7307C54.1332 19.2369 55.0838 20.4448 56.4146 21.3187C57.7425 22.1926 59.3972 22.634 61.3341 22.634C63.4403 22.634 65.1811 22.0912 66.5031 21.0175C67.8369 19.9348 68.5142 18.4525 68.5142 16.6153C68.5142 16.0486 68.443 15.5147 68.3004 15.0285C68.1578 14.5454 67.9855 14.1278 67.7864 13.7848L67.7835 13.7938Z"
                fill={color}
            />
            <path
                d="M82.9875 8.06433H79.4375C79.3336 8.06433 79.2504 8.14784 79.2504 8.25223V15.7115C79.2504 16.7972 78.9919 17.6174 78.478 18.1482C77.9671 18.6791 77.2719 18.9476 76.4134 18.9476C75.641 18.9476 75.0201 18.7209 74.5627 18.2705C74.1081 17.8232 73.8764 17.158 73.8764 16.2931V8.25223C73.8764 8.14784 73.7932 8.06433 73.6893 8.06433H70.1393C70.0353 8.06433 69.9521 8.14784 69.9521 8.25223V16.7345C69.9521 18.5628 70.4661 20.0213 71.4791 21.0652C72.4921 22.112 73.7932 22.6429 75.3439 22.6429C77.0432 22.6429 78.3562 22.118 79.2563 21.0771V22.0673C79.2563 22.1717 79.3395 22.2552 79.4435 22.2552H82.9935C83.0974 22.2552 83.1806 22.1717 83.1806 22.0673V8.25223C83.1806 8.14784 83.0974 8.06433 82.9935 8.06433H82.9875Z"
                fill={color}
            />
            <path
                d="M93.6433 7.67652C91.8639 7.67652 90.4291 8.24619 89.3745 9.3706V2.91641C89.3745 2.81203 89.2913 2.72852 89.1873 2.72852H85.6373C85.5334 2.72852 85.4502 2.81203 85.4502 2.91641V22.0672C85.4502 22.1716 85.5334 22.2551 85.6373 22.2551H89.1873C89.2913 22.2551 89.3745 22.1716 89.3745 22.0672V20.9488C90.4291 22.0732 91.8639 22.6428 93.6433 22.6428C95.5416 22.6428 97.1844 21.9121 98.5271 20.4686C99.8669 19.031 100.544 17.2445 100.544 15.1597C100.544 13.0749 99.864 11.2884 98.5271 9.85078C97.1844 8.40724 95.5416 7.67652 93.6433 7.67652ZM92.9809 11.4524C94.0236 11.4524 94.8999 11.8014 95.5862 12.4873C96.2694 13.1763 96.617 14.074 96.617 15.1567C96.617 16.2394 96.2694 17.1401 95.5862 17.8261C94.9029 18.515 94.0266 18.861 92.9809 18.861C91.9352 18.861 91.0648 18.512 90.3904 17.8261C89.7131 17.1401 89.3715 16.2394 89.3715 15.1537C89.3715 14.0681 89.7131 13.1703 90.3904 12.4814C91.0648 11.7954 91.9382 11.4464 92.9809 11.4464V11.4524Z"
                fill={color}
            />
            <path
                d="M102.261 8.06433H105.811C105.915 8.06433 105.998 8.14784 105.998 8.25223V22.0673C105.998 22.1717 105.915 22.2552 105.811 22.2552H102.261C102.157 22.2552 102.074 22.1717 102.074 22.0673V8.25223C102.074 8.14784 102.157 8.06433 102.261 8.06433Z"
                fill={color}
            />
            <path
                d="M105.811 2.7644H102.258C102.155 2.7644 102.071 2.84853 102.071 2.9523V5.41587C102.071 5.51964 102.155 5.60377 102.258 5.60377H105.811C105.915 5.60377 105.999 5.51964 105.999 5.41587V2.9523C105.999 2.84853 105.915 2.7644 105.811 2.7644Z"
                fill={color}
            />
        </svg>
    );
};

export default SideBarLogo;
