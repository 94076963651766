import React from 'react';

export const SubiLogoMenu = ({ color, height = '24', width = '24' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
        >
            <path
                d="M2.06386 13.7833C1.96081 13.6827 1.79648 13.6827 1.69343 13.7833C0.601632 14.8515 3.05176e-05 16.272 3.05176e-05 17.7848C3.05176e-05 19.2976 0.601632 20.7181 1.69343 21.7863C2.81864 22.8881 4.2948 23.4389 5.77373 23.4389C7.25267 23.4389 8.72882 22.8881 9.85404 21.7863C9.90417 21.736 9.93481 21.6661 9.93481 21.5962C9.93481 21.5263 9.90696 21.4564 9.85404 21.406L2.06386 13.7833Z"
                fill={color}
            />
            <path
                d="M1.6934 1.65016C0.601601 2.72114 0 4.14166 0 5.65445C0 7.16725 0.601601 8.58776 1.6934 9.65595C1.74353 9.70628 1.81316 9.73145 1.87722 9.73145C1.94128 9.73145 2.01091 9.70628 2.06104 9.65595L9.85122 2.03325C9.90136 1.98292 9.932 1.91301 9.932 1.84311C9.932 1.7732 9.90414 1.70329 9.85122 1.65296C7.60357 -0.55052 3.94383 -0.55052 1.6934 1.65016Z"
                fill={color}
            />
            <path
                d="M21.9362 13.7833L14.146 21.406C14.0959 21.4564 14.0652 21.5263 14.0652 21.5962C14.0652 21.6661 14.0931 21.736 14.146 21.7863C15.2712 22.8881 16.7474 23.4361 18.2263 23.4361C19.7052 23.4361 21.1814 22.8853 22.3066 21.7863C23.3984 20.7181 24 19.2976 24 17.7848C24 16.272 23.3984 14.8515 22.3066 13.7833C22.2035 13.6827 22.0392 13.6827 21.9362 13.7833Z"
                fill={color}
            />
            <path
                d="M21.9362 9.656C21.9863 9.70634 22.0531 9.7315 22.12 9.7315C22.1868 9.7315 22.2537 9.70634 22.3038 9.656C23.3956 8.58782 23.9972 7.1673 23.9972 5.65451C23.9972 4.14171 23.3956 2.7212 22.3038 1.65301C20.0534 -0.547668 16.3936 -0.547668 14.146 1.65301C14.0959 1.70335 14.0652 1.77325 14.0652 1.84316C14.0652 1.91307 14.0931 1.98298 14.146 2.03331L21.9362 9.656Z"
                fill={color}
            />
        </svg>
    );
};
