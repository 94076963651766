import React, { useContext, useEffect } from 'react';
import { NavArrowLeft, NavArrowRight } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';
import { GeneralContext } from '@/context/GeneralContext';
import useUser from '@/context/useUser';
import { useCallHistory } from '@/hooks/useCallHistory';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loadingAnimation.json';
import mixpanel from 'mixpanel-browser';
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';

export const CallHistoryList = () => {
    const navigate = useNavigate();
    const context = useContext(GeneralContext);
    const { user } = useUser(); // Adjust the user hook based on your implementation
    const { calls, fetchNextPage, hasNextPage, callsLoading } = useCallHistory(
        user?.voice_config?.phone_number,
    );

    useEffect(() => {
        mixpanel.track('Call history visited', {});
    }, []);

    return (
        <>
            <div
                className={`flex py-3.5 px-5 justify-between bg-black items-center`}
            >
                <div className={'cursor-pointer'} onClick={() => navigate('/')}>
                    <NavArrowLeft strokeWidth={2} opacity={0.5} />
                </div>
                <div className="font-medium text-xl">Call History</div>
                <div style={{ width: '24px', height: '24px' }} />
            </div>
            <div
                style={{ height: context.height - 44 }}
                id={'cats-wrapper'}
                className={
                    'rounded-xl px-5 py-3 bg-black flex flex-col gap-3 overflow-y-scroll'
                }
            >
                {callsLoading ? (
                    <div className={'items-center justify-center flex h-full'}>
                        <Lottie
                            style={{ width: '75px' }}
                            animationData={loadingAnimation}
                        />
                    </div>
                ) : (
                    <InfiniteScroll
                        dataLength={calls?.pages.flat().length || 0}
                        next={fetchNextPage}
                        hasMore={true}
                        scrollableTarget={'cats-wrapper'}
                        loader={<></>}
                        endMessage={<></>}
                    >
                        <div className="flex flex-col gap-2.5 pt-4  ">
                            {calls?.pages.flat().map(c => (
                                <div
                                    key={c.id}
                                    onClick={() => navigate('/call/' + c.id)}
                                    className=" bg-white cursor-pointer hover:bg-opacity-20 bg-opacity-10 py-4 flex flex-row justify-between pl-4 items-center rounded-xl gap-1"
                                >
                                    <div className={'flex flex-col grow'}>
                                        <div className="flex flex-row justify-between items-center">
                                            <div className="flex flex-row gap-2 items-center">
                                                {c.unread && (
                                                    <div
                                                        className="rounded-full bg-accent mb-0.5"
                                                        style={{
                                                            height: '6px',
                                                            width: '6px',
                                                        }}
                                                    />
                                                )}
                                                <div className="font-medium text-lg">
                                                    {c.from_number}
                                                </div>
                                            </div>
                                            <div className="font-medium">
                                                <Moment fromNow>
                                                    {c.created_at}
                                                </Moment>
                                            </div>
                                        </div>
                                        <div className="text-md font-medium leading-none flex flex-col gap-0.5 text-right">
                                            <div className="opacity-50">
                                                {c.duration}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'pr-3'}>
                                        <NavArrowRight
                                            strokeWidth={1.5}
                                            opacity={0.5}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                )}
            </div>
        </>
    );
};
