import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import { EditPencil, ThumbsDown, ThumbsUp } from 'iconoir-react';
import axiosWithRetry from '../../util/axiosRetry';
import styleConfig from '../../styles/styleConfig';
import { GeneralContext } from '@/context/GeneralContext';
import { useUserStateOnly } from '@/context/useUser';
import Lottie from 'lottie-react';
import thinkingAnimation from '@/assets/thinkingAnimation.json';
import 'moment-timezone';
import MessageContent from '@/pages/chat/MessageContent';

const Message = React.memo(function Message({ msg, setCurrentMsg }) {
    const [isEditing, setIsEditing] = useState(false);
    const [tempText, setTempText] = useState(msg?.message?.data?.content);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const context = useContext(GeneralContext);
    const [feedback, setFeedback] = useState(undefined);
    const { user } = useUserStateOnly;

    const markdownRef = useRef(null);

    const isHuman = msg.type === 'human';
    const submitFeedback = async feedback => {
        axiosWithRetry
            .post(`/conversation/message/${msg.id}/feedback`, {
                reinforcement: feedback,
            })
            .then(response => {
                console.debug('Reinforcement response', response);
            })
            .catch(e => {
                console.error('There was an error posting message feedback', e);
            });
    };

    const submitEditMessage = async () => {
        msg.message.data.content = tempText;
        setIsEditing(false);
        // editMessage(msg);
    };

    const handleInputChange = e => {
        setTempText(e.target.value);
    };

    useEffect(() => {
        if (markdownRef.current) {
            setDimensions({
                width: markdownRef.current.offsetWidth,
                height: markdownRef.current.offsetHeight,
            });
        }
    }, [msg]);

    if (context.admin === false && msg.message?.data?.content === '') {
        return;
    }
    return (
        <div
            aria-label={isHuman ? 'user-message' : 'ai-message'}
            className={`sticky flex flex-col z-0 max-w-[80%] rounded-sm text-sm ${
                isHuman ? 'self-end' : 'self-start'
            } dark:text-white text-black `}
        >
            <MessageTop isHuman={isHuman}>
                <MessageText dark={context.dark} isHuman={isHuman} id={msg.id}>
                    {context.admin && (
                        <div
                            style={{
                                color: context.dark ? 'white' : '#141718',
                                paddingBottom: '7px',
                            }}
                        >
                            {msg.sender?.display_name}
                        </div>
                    )}
                    <div>
                        {isEditing ? (
                            <UserBodyInput
                                type="text"
                                value={tempText}
                                onChange={handleInputChange}
                                style={{
                                    width: `${dimensions.width}px`,
                                    height: `${dimensions.height}px`,
                                }}
                            />
                        ) : (
                            <MessageContent
                                markdownRef={markdownRef}
                                msg={msg}
                            />
                        )}
                    </div>
                </MessageText>
            </MessageTop>
            {context.admin && (
                <MessageBottom isHuman={isHuman}>
                    <TimeStamp>
                        <Moment fromNow>{msg.sent_at}</Moment>
                        <TimeStampHover dark={context.dark}>
                            <Moment format="DD MM YYYY hh:mm:ss">
                                {msg.sent_at}
                            </Moment>
                        </TimeStampHover>
                    </TimeStamp>
                    {isHuman ? (
                        <Icon isActive={feedback === 'down'}>
                            {isEditing ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <EditButton onClick={submitEditMessage}>
                                        Save
                                    </EditButton>
                                    <EditButton
                                        onClick={() => {
                                            setTempText(
                                                msg.message?.data?.content,
                                            );
                                            setIsEditing(false);
                                            console.log('msg.id', msg.id);
                                        }}
                                    >
                                        Cancel
                                    </EditButton>
                                </div>
                            ) : (
                                <EditPencil
                                    color={'#232627'}
                                    width={23}
                                    height={24}
                                    onClick={() => {
                                        setIsEditing(!isEditing);
                                    }}
                                />
                            )}
                        </Icon>
                    ) : (
                        <>
                            <Icon isActive={feedback === 'up'}>
                                <ThumbsUp
                                    width={24}
                                    height={24}
                                    color={'#232627'}
                                    onClick={() => {
                                        if (feedback !== 'up') {
                                            setFeedback('up');
                                        }
                                        submitFeedback('human_helpful');
                                        mixpanel_feedback('positive');
                                    }}
                                />
                            </Icon>
                            <Icon isActive={feedback === 'down'}>
                                <ThumbsDown
                                    color={'#232627'}
                                    width={24}
                                    height={24}
                                    onClick={() => {
                                        setCurrentMsg(String(msg.id));
                                        if (feedback !== 'down') {
                                            setFeedback('down');
                                        }
                                        submitFeedback('human_unhelpful');
                                        mixpanel_feedback('negative');
                                    }}
                                />
                            </Icon>
                            {msg.meta_data && msg.meta_data.example_usages && (
                                <ExamplesCount>
                                    <a
                                        href={`https://api.subi.ai/admin/message/detail/${msg.id}`}
                                    >
                                        {msg.meta_data.example_usages} examples
                                        used
                                    </a>
                                </ExamplesCount>
                            )}
                        </>
                    )}
                </MessageBottom>
            )}
        </div>
    );
});

export default Message;

export const LoadingMessage = () => {
    return (
        <div
            className={`flex flex-col z-0 max-w-[80%] rounded-sm text-sm self-start md:max-w-full `}
        >
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <MessageText
                    style={{ padding: '0', background: 'transparent' }}
                    isHuman={false}
                >
                    <div>
                        <Lottie
                            aria-label="message-loading-indicator"
                            style={{ width: '75px' }}
                            animationData={thinkingAnimation}
                        />
                    </div>
                </MessageText>
            </div>
        </div>
    );
};

const ExamplesCount = styled.div`
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const MessageTop = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: ${props => (props.isHuman ? 'row-reverse' : 'row')};
`;
const MessageBottom = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: row;
    padding-top: 5px;
    text-align: ${props => (props.isHuman ? 'right' : 'left')};
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    opacity: 0.5;
    align-items: center;
    justify-content: ${props => (props.isHuman ? 'flex-end' : 'flex-start')};
`;

const Icon = styled.div`
    svg path {
        fill: ${props => props.isActive && '#232627'};
    }

    cursor: pointer;
    align-items: center;
    display: flex;

    &:hover svg path {
        fill: #232627;
    }
`;

const MessageText = styled.div`
    font-size: 16px;
    z-index: 0;
    min-width: 75px;
    min-height: 40px;
    border-radius: 12px;
    overflow-wrap: anywhere;
    line-height: 26px;
    padding: 14px;
    padding-bottom: 12px;

    background-color: ${props =>
        props.isHuman ? 'transparent' : props.dark ? '#3C4041' : '#f3f3f3'};

    border: ${props =>
        props.isHuman
            ? props.dark
                ? '1px solid  rgba(255, 255, 255, 0.20)'
                : '2px solid #f0f0f0'
            : 'none'};

    & p:last-child {
        margin-block-end: 0px;
    }

    & p:first-child {
        margin-block-start: 0px;
    }

    img {
        object-fit: cover;
    }
`;

const UserBodyInput = styled.textarea`
    font-family: sans-serif;
    resize: none;
    border: none;
    outline: none;
    font-size: 16px;
    z-index: 0;
`;

const EditButton = styled.div`
    color: ${styleConfig.colors.text};
    background-color: #d4d2cc;
    border-radius: 6px;
    padding: 0px 8px 0px 8px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 13px;

    &:hover {
        background-color: #d5d8dd;
    }
`;

const TimeStampHover = styled.div`
    visibility: hidden;
    background-color: ${props => (props.dark ? '#3C4041' : '#f3f3f3')};
    color: ${props => (props.dark ? 'white' : '#141718')};
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 11111111;
`;

const TimeStamp = styled.div`
    &:hover ${TimeStampHover} {
        visibility: visible;
    }
`;
