import { accounts } from '@/util/accounts';
import { Plus, Xmark } from 'iconoir-react';
import React, { useContext, useState } from 'react';
import { GeneralContext } from '@/context/GeneralContext';
import { Modal } from '@mui/material';
import styled from 'styled-components';
import axiosWithRetry, { axiosDefault } from '../../util/axiosRetry';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const Integrations = () => {
    const context = useContext(GeneralContext);
    const [coming, setComing] = useState(undefined);
    const queryClient = useQueryClient();
    const { data: userIntegrations } = useQuery({
        queryKey: ['integrations'],
        queryFn: async () => {
            const integrations = await axiosDefault.get(
                '/user/get_integrations',
            );
            return integrations.data;
        },
    });
    const deleteIntegration = async integrationId => {
        try {
            const response = await axiosWithRetry({
                method: 'post',
                url: `/user/delete_integration/${integrationId}`,
                headers: { 'Content-Type': 'application/json' },
            });

            const integrations = queryClient.getQueryData(['integrations']);
            const newIntegrations = integrations.filter(
                integration => integration.id !== integrationId,
            );
            queryClient.setQueryData(['integrations'], newIntegrations);
        } catch (error) {
            console.error('Error fetching integrations:', error);
            throw error;
        }
    };

    const handleRemoveIntegration = async integrationId => {
        try {
            await deleteIntegration(integrationId);
            console.log('Remove integration: ', integrationId);
        } catch (error) {
            console.error('Error removing integration:', error);
        }
    };

    const handleAddIntegration = async account => {
        try {
            const response = await axiosWithRetry({
                method: 'post',
                url: '/user/add_integration',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    service_name: account.service_name,
                    account_name: account.title, // Add the account_name parameter
                },
            });

            const oauthLink = response.data.oauth_link;
            if (oauthLink === '') {
                setComing(account);
                return;
            }
            const width = 600;
            const height = 600;
            const left = window.screen.width / 2 - width / 2;
            const top = window.screen.height / 2 - height / 2;

            const windowFeatures = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
            window.open(oauthLink, '_blank', windowFeatures);

            // Update the query cache with the newly added integration
            queryClient.setQueryData(['integrations'], oldIntegrations => {
                const newIntegration = {
                    email: response.data.email,
                    account_name: account.title,
                };
                return [...(oldIntegrations ?? []), newIntegration];
            });
        } catch (error) {
            console.error('Error adding integration:', error);
            // Handle the error, show an error message, etc.
        }
    };

    const accountsWithIntegrations = accounts(context.dark === true).map(
        account => {
            const integrations =
                userIntegrations?.filter(
                    integration =>
                        integration.service_name === account.service_name,
                ) || [];
            return {
                ...account,
                integrations,
            };
        },
    );

    return (
        <>
            {coming !== undefined && (
                <ComingSoonModal coming={coming} setComing={setComing} />
            )}
            <div
                style={{
                    height: `${context.height - 79}px`,
                    overflow: 'scroll',
                    paddingRight: '10px',
                }}
                className="flex flex-col gap-6 select-none"
            >
                <div className="font-medium">Connect Accounts</div>
                {accountsWithIntegrations.map(a => {
                    return (
                        <div key={a.title} className="flex flex-col gap-4">
                            <div className="flex flex-row justify-between align-center">
                                <div className="flex flex-row gap-2.5">
                                    {a.icon !== '' && a.icon}
                                    <div>{a.title}</div>
                                </div>
                                <div
                                    // onClick={() => setComing(a)}
                                    onClick={() => handleAddIntegration(a)}
                                    className="cursor-pointer rounded-full bg-secondary-accent shadow-md flex w-6 h-6 bg-secondaryAccent justify-center items-center gap-9"
                                >
                                    <Plus
                                        height={20}
                                        width={20}
                                        color="white"
                                    />
                                </div>
                            </div>

                            {a.integrations && a.integrations.length > 0 && (
                                <div className="flex flex-col gap-1">
                                    {a.integrations?.map(i => {
                                        return (
                                            <div
                                                key={i.id}
                                                className="flex flex-row justify-between items-center"
                                            >
                                                <div className="flex flex-col w-fit px-3 py-2 bg-lightGray dark:bg-lightBlack rounded-xl gap-0.5">
                                                    <div className="text-sm">
                                                        {i.account_name}
                                                    </div>
                                                    <div className="text-xs opacity-70">
                                                        {i.email}
                                                    </div>
                                                </div>
                                                <button
                                                    className="rounded-2xl font-medium h-fit text-sm py-1.5 px-2.5 text-secondaryAccent border-1-secondaryAccent bg-white"
                                                    onClick={() =>
                                                        handleRemoveIntegration(
                                                            i.id,
                                                        )
                                                    }
                                                >
                                                    Disconnect
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Integrations;

export const ComingSoonModal = ({ coming, setComing }) => {
    const context = useContext(GeneralContext);

    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)', // Replace this with the desired background color and opacity
                },
            }}
            open={coming !== undefined}
            onClose={() => setComing(undefined)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            disableAutoFocus={true}
        >
            <ModalWrapper
                className="text-black dark:text-white"
                width={'350px'}
                backgroundColor={context.dark && '#141718'}
            >
                <TitleWrapper>
                    <Xmark
                        height={24}
                        width={24}
                        strokeWidth={1.5}
                        onClick={() => setComing(undefined)}
                        style={{
                            color: context.dark ? 'white' : '#141718',
                            cursor: 'pointer',
                        }}
                    />
                </TitleWrapper>
                <div className="flex flex-col p-10 pt-7 items-center text-center gap-4">
                    {coming.icon}
                    <div className="text-lg font-medium">
                        {coming.title} is coming soon!
                    </div>
                    <div>
                        We’re rolling out new integrations every day, and this
                        one is currently in the works.
                    </div>
                </div>
            </ModalWrapper>
        </Modal>
    );
};

const ModalWrapper = styled.div`
    width: ${props => (props.width ? props.width : '600px')};

    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : 'white'};
    background-image: ${props =>
        props.backgroundImage && `url(${props.backgroundImage})`};
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    //@media (max-width: 768px) {
    //    width: 100%;
    //    height: 100%;
    //    border-radius: 0px;
    //}
`;

const TitleWrapper = styled.div`
    display: flex;
    z-index: 1;

    align-items: center;
    padding: 15px;
    padding-bottom: 0px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    height: 39px;
    @media (max-width: 768px) {
        padding-bottom: 20px;
        padding-left: 14px;
        padding-top: 20px;
        height: 64px;
    }
`;
