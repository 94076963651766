import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { axiosDefault } from '@/util/axiosRetry';

export const usePhoneLine = ({ user }) => {
    const {
        data: phoneLine,
        isLoading,
        ...rest
    } = useQuery({
        queryKey: ['phoneline'],
        queryFn: fetchPhoneLine,
        staleTime: 0,
        placeholderData: keepPreviousData,
        enabled: user !== undefined,
    });
    return { phoneLine, isLoading };
};

async function fetchPhoneLine() {
    const response = await axiosDefault.get(`/phone_line`);
    return response.data;
}
