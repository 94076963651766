import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '@mui/material';
import { GeneralContext } from '@/context/GeneralContext';
import { useUser } from '@/context/useUser';
import { useVerifyToken } from '@/hooks/useVerifyToken';

const ClearFactsModal = ({ open, clearFactsCallback, setOpen }) => {
    const { user } = useUser();
    const context = useContext(GeneralContext);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const request_verify_token = useVerifyToken();
    const resendVerificationEmail = async () => {
        setError('');
        setSuccess('');
        request_verify_token.mutate(
            { email: user.email },
            {
                onSuccess: () => {
                    setSuccess('Verification email sent');
                },
                onError: () => {
                    if (
                        error.response.data.detail ===
                        'REGISTER_USER_ALREADY_EXISTS'
                    ) {
                        setError('User already registered with this email');
                    } else {
                        setError(error.response.data.detail);
                    }
                },
            },
        );
    };

    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)',
                },
            }}
            open={open === true}
            onClose={() => {}}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <ModalWrapper className="bg-black text-white">
                <div className="flex flex-col items-center py-5 px-3 gap-2 text-center">
                    <div className="text-xl font-semibold">
                        Updating your domain will change Subi's knowledge base
                    </div>
                    <div className="text-l font-medium opacity-70 ">
                        Choose how to handle responses generated from your old
                        domain
                    </div>
                    <div className="flex flex-row gap-2 pt-2">
                        <div
                            className="cursor-pointer hover:bg-opacity-90 rounded-xl bg-alertRed px-2 py-0.5 font-medium"
                            onClick={() => clearFactsCallback('')}
                        >
                            Cancel
                        </div>
                        <div
                            className="cursor-pointer hover:bg-accentHover rounded-xl bg-accent px-2 py-0.5 font-medium"
                            onClick={() => clearFactsCallback('Clear')}
                        >
                            Remove
                        </div>
                        <div
                            className="cursor-pointer hover:bg-secondaryAccentHover rounded-xl bg-secondaryAccent px-2 py-0.5 font-medium"
                            onClick={() => clearFactsCallback('Save')}
                        >
                            Preserve
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        </Modal>
    );
};

export default ClearFactsModal;

const ModalWrapper = styled.div`
    width: 600px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: calc(100% - 40px);
    }
`;
