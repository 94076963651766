import styled from 'styled-components';
import React, { useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { Modal } from '@mui/material';
import axiosWithRetry from '../../util/axiosRetry';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/useUser';
import styleConfig from '../../styles/styleConfig';
import { Xmark } from 'iconoir-react';

const ModalTitleWrapper = styled.div`
    display: flex;
    z-index: 1;
    justify-content: flex-end;

    align-items: center;
    padding: 20px 30px 0px 30px;
    box-sizing: border-box;
    color: #141718;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    @media (max-width: 768px) {
        border-bottom: none;
    }
`;

const ConfirmButton = styled.button`
    border: none;
    color: ${styleConfig.colors.dangerAction.text};
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: -0.32px;
    background-color: ${styleConfig.colors.dangerAction.fill};
    padding: 12px 24px;
    border-radius: 12px;
    cursor: pointer;
    width: 250px;

    &:hover {
    }

    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0px;
        background: none;
        color: #e94235;
        font-size: 17px;
        font-style: normal;
        font-weight: 590;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.4px;
        border-top: 0.333px solid
            var(--separators-non-opaque, rgba(60, 60, 67, 0.36));
        border-right: 0.333px solid
            var(--separators-non-opaque, rgba(60, 60, 67, 0.36));
    }
`;
const CancelButton = styled.button`
    width: 250px;
    border: none;
    color: #fefefe;
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: -0.32px;
    padding: 12px 24px;
    border-radius: 12px;
    cursor: pointer;
    background: #141718;

    &:hover {
        background-color: #343839;
    }

    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0px;
        border-bottom-left-radius: 10px;
        background: none;
        color: #000;
        font-size: 17px;
        font-style: normal;
        font-weight: 590;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.4px;
        border-top: 0.333px solid
            var(--separators-non-opaque, rgba(60, 60, 67, 0.36));
    }
`;

const ModalWrapper = styled.div`
    width: 750px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: 270px;
    }
`;

const SubsectionSubtext = styled.p`
    color: #141718;
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SubsectionTitle = styled.div`
    color: #141718;
    text-align: center;
    font-family: Indivisible, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 28px */
    letter-spacing: -0.56px;
    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px; /* 122.222% */
    }
`;
const ModalContentWrapper = styled.div`
    padding: 20px 50px 20px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    align-items: center;
    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const ButtonBar = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margintop: 4;
    padding: 0px 50px 50px;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: row-reverse;
        padding: 0px;
        gap: 0.333px;
    }
`;
const DeleteAccountModal = ({ deleteOpen, setDeleteOpen }) => {
    const navigate = useNavigate();
    const { logoutUser } = useUser();
    const context = useContext(GeneralContext);

    const deleteAccount = async () => {
        try {
            console.log('Deleting account...');

            const response = await axiosWithRetry.patch(
                '/user/delete',
                null,
                {},
            );
            console.log('Response', response.data);
            context.setOpenModal('');
            setDeleteOpen('');

            logoutUser();
            navigate('/');
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    return (
        <Modal
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(200, 200, 200, 0.3)', // Replace this with the desired background color and opacity
                },
            }}
            open={deleteOpen !== ''}
            onClose={() => setDeleteOpen('')}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <ModalWrapper>
                <ModalTitleWrapper>
                    {context.width > 768 && (
                        <Xmark
                            id="delete-conversation-modal-button"
                            height={24}
                            width={24}
                            strokeWidth={1.5}
                            onClick={() => setDeleteOpen('')}
                            style={{ color: 'black', cursor: 'pointer' }}
                        />
                    )}
                </ModalTitleWrapper>
                <ModalContentWrapper>
                    <SubsectionTitle dark={context.dark}>
                        Are you sure you want to delete your account?
                    </SubsectionTitle>{' '}
                    <SubsectionSubtext dark={context.dark}>
                        This action cannot be undone.
                    </SubsectionSubtext>
                </ModalContentWrapper>
                <ButtonBar>
                    <CancelButton onClick={() => setDeleteOpen('')}>
                        Cancel
                    </CancelButton>
                    <ConfirmButton onClick={deleteAccount}>
                        Delete
                    </ConfirmButton>
                </ButtonBar>
            </ModalWrapper>
        </Modal>
    );
};

export default DeleteAccountModal;
