import { useEffect } from 'react';
import Moment from 'react-moment';
import axiosDefault from '@/util/axiosRetry';
import config from '@/util/config';
import { askPermission } from '@/util/notifications';

const useTimezone = user => {
    useEffect(() => {
        const sendTimezone = async () => {
            console.log('timezone send');
            // send all initial information to the backend that was taken from the browser
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            Moment.globalTimezone = timezone;

            console.debug('Sending timezone: ', timezone);

            try {
                const response = await axiosDefault.post('/user/timezone', {
                    timezone,
                });
            } catch (error) {
                console.error('tz error', error);
            }
        };
        if (user !== null) {
            console.log('timezone');
            sendTimezone()
                .then(r => console.debug('timezone set'))
                .catch(e => console.error('timezone error', e));

            askPermission(config.vapidPublicKey)
                .then(r => console.debug('push permission'))
                .catch(e => console.error('push permission error', e));
        }
    }, [user]);
};

export default useTimezone;
