import React, { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { BaseModal } from '../../components/Modal';
import axiosWithRetry from '../../util/axiosRetry';
import useUser from '../../context/useUser';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const tabs = [
    {
        tabName: 'Agent Passes',
        bottomText: '$60/year',

        items: {
            Trial: {
                title: 'Trial',
                id: '1',
                cost: 'Free',
                convos: '10',
                emails: '10',
                calls: '2',
                free: '10',
                month_rate: '$0',
                min_rate: '$0.45',
            },
            Basic: {
                title: 'Basic',
                id: 'price_1PjUXaKvqwA8qoBXX2YzAEW4',
                cost: '$4.99/mo',
                convos: '8',
                emails: '25',
                calls: '25',
                free: '120',
                month_rate: '$45',
                min_rate: '$0.33',
            },
            Pro: {
                title: 'Pro',
                id: 'price_1PjUYZKvqwA8qoBXAlwZIddn',
                cost: '$14.99/mo',
                convos: '8',
                emails: '100',
                calls: '100',
                free: '500',
                month_rate: '$195',
                min_rate: '$0.25',
            },
        },
    },
    {
        tabName: 'Buy Credits',
        bottomText: 'One-time Purchase',

        items: [
            {
                title: '40 Credits',
                id: 'price_1OYzKgKvqwA8qoBXXTCQrU36',
                rate: '$24',
                description:
                    'Equivalent to 20,000 tokens, or about 200 Agent tasks.',
            },
            {
                title: '10 Credits',
                id: 'price_1OYzKxKvqwA8qoBX69OgEJiV',
                rate: '$14',
                description: '',
            },
        ],
    },
];

const PaywallModal = ({ open = false }) => {
    const context = useContext(GeneralContext);
    useEffect(() => {
        mixpanel.track('Paywall opened', {});
    }, []);
    return (
        <BaseModal
            onClose={() => {
                context.setOpenModal('settings');
            }}
            backgroundColor={'black'}
            width={'440px'}
            open={open || context.openModal === 'paywall'}
            paywall={true}
        >
            <div
                style={{
                    flexGrow: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '30px',
                    alignItems: 'center',
                }}
            >
                <div className="text-white font-semibold text-2xl select-none text-center">
                    {'Oh no!'}
                    {context.width < 768 && <br />}
                    {'Your Subi free trial has ended.'}
                </div>
            </div>
            <Page1 />
        </BaseModal>
    );
};

export default PaywallModal;

const Page1 = () => {
    const { user, subscription } = useUser();
    const [callLength, setCallLength] = useState(3);
    const [callsPerDay, setCallsPerDay] = useState(30);
    const context = useContext(GeneralContext);
    const navigate = useNavigate();
    // const current = subscription.subscription_tier
    //     ? subscription.subscription_tier
    //     : 'Pay-As-You-Go';
    const current = 'Trial';
    const [activePurchase, setActivePurchase] = useState(current);

    const [activeTab, setActiveTab] = useState(0);

    const submitPurchase = () => {
        // console.log('activePurchase', activePurchase);
        mixpanel.track('Stripe checkout screen reached', {});
        const priceId = tabs[activeTab].items[activePurchase].id;

        // Construct the URL with the optional customer_id parameter
        const url = `/payment/create-checkout-session?price_id=${priceId}`;
        axiosWithRetry
            .post(url)
            .then(res => {
                // console.log(res);
                // navigate(res.data.url);
                window.location.replace(res.data.url);
                // setClientSecret(res.data.clientSecret);
            })
            .catch(err => console.error(err));
    };
    return (
        <>
            <div
                style={{
                    borderRadius: '40px 40px 10px 10px',
                }}
                className="text-black pb-10 px-5 pt-8 bg-white flex flex-col items-center gap-5"
            >
                <div className={'text-4xl font-semibold'}>{'$5 per month'}</div>
                <div
                    style={{ lineHeight: '100%' }}
                    className={'text-sm opacity-70'}
                >
                    60 minutes /mo
                    <br />
                    No cost for spam calls
                    <br />
                    Overage: $0.09 /min only
                    <br />
                    when autopay is turned on
                </div>
                <div className="text-sm text-black text-opacity-60 inline-flex">
                    {'By using Subi, you agree to our'}
                    <div
                        className="font-bold cursor-pointer contents"
                        onClick={() => {
                            context.setOpenModal('');
                            navigate('/terms');
                        }}
                    >
                        &nbsp;{`Terms & Conditions.`}
                    </div>
                </div>
                <div
                    style={{
                        borderRadius: '20px',

                        width: context.width >= 768 ? '350px' : '100%',
                    }}
                    className={
                        'bg-accent hover:bg-accentHover cursor-pointer text-white text-center py-2.5 font-medium w-full'
                    }
                    onClick={submitPurchase}
                >
                    Subscribe
                </div>
            </div>
        </>
    );
};

const PurchaseOptions = ({ options, active, setActivePurchase, current }) => {
    return (
        <div className="flex flex-row gap-2.5">
            {Object.keys(options).map(option => {
                return (
                    <div
                        key={options[option].id}
                        onClick={() => setActivePurchase(options[option].title)}
                        style={{
                            width: '114px',
                            color:
                                options[option].title === current
                                    ? 'rgba(20, 23, 24, 0.60)'
                                    : 'white',
                        }}
                        className={`rounded-xl select-none cursor-pointer text-xs font-medium ${options[option].title === current ? 'bg-lightGraySelection hover:bg-lightGraySelectionHover' : options[option].title === 'Pro' ? 'bg-accent hover:bg-accentHover' : 'bg-white'} flex flex-col text-center pt-1.5`}
                    >
                        <div>
                            {options[option].title === 'Pro'
                                ? 'BEST VALUE'
                                : 'YOUR PLAN'}
                        </div>
                        <div
                            className={`flex flex-col gap-2.5 p-3 pt-1 pr-1 bg-white rounded-xl ${
                                options[option].title !== 'Pro'
                                    ? 'border-2 border-black border-opacity-10 hover:border-opacity-20 mt-0.5'
                                    : 'm-0.5 mt-1'
                            } `}
                        >
                            <div>
                                <div className="flex flex-row justify-end">
                                    {active === options[option].title ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM7.53044 11.9697C7.23755 11.6768 6.76268 11.6768 6.46978 11.9697C6.17689 12.2626 6.17689 12.7374 6.46978 13.0303L9.46978 16.0303C9.76268 16.3232 10.2376 16.3232 10.5304 16.0303L17.5304 9.03033C17.8233 8.73744 17.8233 8.26256 17.5304 7.96967C17.2375 7.67678 16.7627 7.67678 16.4698 7.96967L10.0001 14.4393L7.53044 11.9697Z"
                                                fill="#3EA515"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="9.75"
                                                stroke="#141718"
                                                strokeOpacity="0.1"
                                                strokeWidth="2"
                                            />
                                        </svg>
                                    )}
                                </div>
                                <div
                                    style={{ lineHeight: '100%' }}
                                    className="text-lg font-semibold text-black pr-2"
                                >
                                    {capitalizeFirstLetter(
                                        options[option].title,
                                    )}
                                </div>
                                <div className="text-base font-normal text-black opacity-70 pr-2 text-left">
                                    {options[option].month_rate + '/month'}
                                </div>
                                <div className="text-base font-normal text-black opacity-70 pr-2 text-left">
                                    {options[option].calls + ' calls'}
                                </div>
                                <div className="text-base font-normal text-black opacity-70 pr-2 text-left">
                                    {options[option].min_rate + '/min*'}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
