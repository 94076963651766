import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { XmarkCircle } from 'iconoir-react';
import { useDropzone } from 'react-dropzone';
import axiosWithRetry from '../../util/axiosRetry';
import styled from 'styled-components';
import { GeneralContext } from '../../context/GeneralContext';
import { createOptimisticMessage, getChatIdFromUrl } from '../../util/utils';
import { useConversation } from '@/hooks/useConversation';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/context/useUser';
import { v4 as uuid } from 'uuid';
import loadingAnimation from '@/assets/loadingAnimation.json';

const ChatUpload = ({
    setActiveInput = () => {},
    activeInput = 'file',
    onboarding = 'false',
}) => {
    const uploadRef = useRef(null);
    const context = useContext(GeneralContext);
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { user } = useUser();

    const { createConversationMutation } = useConversation();

    const handleOutsideClick = event => {
        if (uploadRef.current && !uploadRef.current.contains(event.target)) {
            setActiveInput('text');
            setFiles([]);
            setErrorMessage('');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const onDrop = useCallback(
        acceptedFiles => {
            let totalSize =
                acceptedFiles.reduce((total, file) => total + file.size, 0) +
                files.reduce((total, file) => total + file.size, 0);
            if (totalSize > 50 * 1024 * 1024) {
                // 50MB
                setErrorMessage('Total file size exceeds 50MB');
            } else {
                setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
                setErrorMessage('');
            }
        },
        [files],
    );

    const removeFile = index => {
        setFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
        setErrorMessage('');
    };

    const sendFiles = async () => {
        if (files.length === 0) {
            setErrorMessage('At least one file must be uploaded');
            return;
        }

        setLoading(true);
        const formData = new FormData();

        files.forEach(file => {
            formData.append('files', file);
        });

        let conversationId = getChatIdFromUrl();

        if (!conversationId) {
            conversationId = uuid();
            const optimisticMessage = createOptimisticMessage(
                conversationId,
                user,
                'Uploading some files',
            );

            createConversationMutation.mutate(
                {
                    ...optimisticMessage,
                    conversation_id: conversationId,
                },
                {
                    onSuccess: () => {
                        axiosWithRetry
                            .post(
                                `/conversation/${conversationId}/upload`,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    },
                                },
                            )
                            .then(response => {
                                console.debug('sendFiles response', response);
                                setLoading(false);
                                setActiveInput('text');
                                setFiles([]);
                            });
                    },
                },
            );
        } else {
            await axiosWithRetry
                .post(`/conversation/${conversationId}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    console.log('sendFiles response', response);
                    setLoading(false);
                    setActiveInput('text');
                    setFiles([]);
                });
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <div className="w-full">
            {activeInput && (
                <DropzoneWrapper>
                    {!loading ? (
                        <DropzoneBody width={context.width} ref={uploadRef}>
                            {/*<Header>*/}
                            {/*    <Xmark*/}
                            {/*        style={{ cursor: 'pointer' }}*/}
                            {/*        onClick={() => setActiveInput(false)}*/}
                            {/*    />*/}
                            {/*</Header>*/}
                            <Wrapper width={context.width}>
                                <div className="flex flex-col gap-5">
                                    <Dropzone
                                        dark={context.dark}
                                        width={context.width}
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <DropText>
                                            Drop files here or tap to select
                                            files.
                                        </DropText>
                                    </Dropzone>
                                    {errorMessage && (
                                        <p style={{ color: 'red' }}>
                                            {errorMessage}
                                        </p>
                                    )}
                                    {files.length > 0 && (
                                        <Files dark={context.dark}>
                                            {onboarding === 'false' && (
                                                <ReadyText>
                                                    Ready to send
                                                </ReadyText>
                                            )}

                                            {files.map((file, index) => (
                                                <FileText key={file.path}>
                                                    <FileTextOverflow>
                                                        {file.path}
                                                        {/*- {file.size}{' '}*/}
                                                        {/*bytes*/}
                                                    </FileTextOverflow>

                                                    <XmarkCircle
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                            removeFile(index)
                                                        }
                                                        color={'#DE5C18'}
                                                        width={20}
                                                        height={20}
                                                    />
                                                </FileText>
                                            ))}
                                        </Files>
                                    )}

                                    {files.length > 0 &&
                                        onboarding === 'false' && (
                                            <div className="flex flex-row justify-center">
                                                <button
                                                    className="bg-accent text-white px-2.5 py-1.5 font-medium rounded-2xl"
                                                    onClick={sendFiles}
                                                >
                                                    Send Files
                                                </button>
                                            </div>
                                        )}
                                </div>
                            </Wrapper>
                        </DropzoneBody>
                    ) : (
                        <Lottie
                            style={{ width: '75px' }}
                            animationData={loadingAnimation}
                        />
                        // <UploadScreen ref={uploadRef}>
                        //     <p>Uploading files...</p>
                        //     <BotStyles.LoadingWrapper>
                        //         <TypingIndicator />
                        //     </BotStyles.LoadingWrapper>
                        // </UploadScreen>
                    )}
                </DropzoneWrapper>
            )}
        </div>
    );
};

export default ChatUpload;

const Files = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${props => (props.dark ? '#ffffff' : '#141718')};
`;
const Dropzone = styled.div`
    border-radius: 10px;
    cursor: pointer;
    background: ${props =>
        props.dark ? 'rgba(255, 255, 255, 0.1)' : '#F3F3F3'};
    height: 200px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: ${props =>
        props.dark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper = styled.div`
    padding: ${props =>
        props.width < 769 ? '30px 20px 0px 20px' : '30px 40px 0px 40px'};
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: ${props =>
        props.width < 769 ? 'space-between' : 'initial'};
`;
const DropzoneBody = styled.div`
    position: relative;
    border-radius: ${props => (props.width < 768 ? '1em' : 'none')};
    overflow: auto;
    cursor: auto;
    width: 100%;
`;

const FileTextOverflow = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: no-wrap;
`;

const DropText = styled.div`
    text-align: center;
    /* Placeholder */
    font-family: Indivisible;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
`;

const ReadyText = styled.div`
    overflow: hidden;
    opacity: 0.5;
    text-overflow: ellipsis;
    /* 15 Regular */
    font-family: Indivisible;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 15px */
`;

const FileText = styled.div`
    font-family: Indivisible;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const DropzoneWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 99999999999;
`;
