import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import axiosWithRetry from '../../util/axiosRetry';
import styled from 'styled-components';
import styleConfig from '../../styles/styleConfig';
import { Button, Input, ModalTitle } from '../../styles/StyledComponents';
import { validatePassword } from '../../util/utils';
import generalContext from '@/context/GeneralContext';

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        setToken(queryString.parse(location.search));
    }, [location.search]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const context = useContext(generalContext);
    const onSuccess = async () => {
        setIsLoading(true);
        if (token) {
            axiosWithRetry
                .post(`/auth/reset-password`, {
                    token: token['token'],
                    password: password,
                })
                .then(response => {
                    setError('Reset successful. Redirecting to home page.');
                    setIsLoading(false);
                    setSuccess(true);
                    setTimeout(() => navigate('/'), 2000);
                })
                .catch(error => {
                    console.log('resetPassword error', error);
                    setIsLoading(false);
                    if (
                        error.response.data.detail ===
                        'RESET_PASSWORD_BAD_TOKEN'
                    ) {
                        setError(
                            'Password reset token has expired. Please request a new reset.',
                        );
                    }
                    // handle error here, show a message to the user
                });
        }
        setIsLoading(false);
    };

    return (
        <Wrapper>
            <ModalContentWrapper>
                <ModalTitle>Create a new password</ModalTitle>
                <Form>
                    <SubsectionSubtext>
                        Password{' '}
                        {error ? (
                            <div
                                style={{
                                    color: success
                                        ? 'green'
                                        : styleConfig.colors.dangerLabel,
                                    paddingTop: '5px',
                                }}
                            >
                                {error}
                            </div>
                        ) : (
                            <SubsectionSubtext
                                style={{ opacity: '0.5', paddingTop: '5px' }}
                            >
                                Must be at least 8 characters
                            </SubsectionSubtext>
                        )}
                    </SubsectionSubtext>
                    <Input
                        error={error}
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <SubsectionSubtext>Confirm Password</SubsectionSubtext>
                    <Input
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        color={'black'}
                        size={'large'}
                        width={'100%'}
                        type="submit"
                        onClick={() =>
                            validatePassword(
                                password,
                                confirmPassword,
                                setError,
                                onSuccess,
                            )
                        }
                        disabled={isLoading}
                    >
                        Confirm
                    </Button>
                </Form>
            </ModalContentWrapper>
        </Wrapper>
    );
};

export default ResetPassword;

const ModalContentWrapper = styled.div`
    padding: 70px 50px 50px 50px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background: #fefefe;
    border-radius: 10px;

    @media (max-width: 768px) {
        width: calc(100% - 80px);
        padding: 50px 20px 40px 20px;
    }
`;

const SubsectionSubtext = styled.div`
    color: #141718;
    font-family: Indivisible, sans-serif;
    font-size: 15px;
    opacity: 0.8;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    letter-spacing: -0.3px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 62px);
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 15px;
    @media (max-width: 768px) {
        gap: 10px;
    }
`;
