import { axiosDefault } from '@/util/axiosRetry';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useCallHistory = phone_number => {
    const {
        data: calls,
        fetchNextPage,
        hasNextPage,
        isLoading: callsLoading,
    } = useInfiniteQuery({
        queryKey: ['calls', phone_number],
        queryFn: async k => {
            console.log('fetch');
            const response = await axiosDefault.get(
                `/phone_line/${k.queryKey[1]}/history?page=${k.pageParam}`,
            );
            return response.data;
        },
        getNextPageParam: (lastPage, pages) => {
            return pages ? pages.length + 1 : 1;
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        initialPageParam: 1,
    });

    return { calls, fetchNextPage, hasNextPage, callsLoading };
};

const test = [
    {
        id: '5aa4bffa-85d1-49f4-84b7-64201468098e',
        created_at: '2024-09-18T23:51:57.171285Z',
        updated_at: '2024-09-18T23:52:55.503679Z',
        started_at: '2024-09-18T16:51:58.354000',
        ended_at: '2024-09-18T16:52:53.457000',
        from_number: '+13104971307',
        to_number: '+14158799778',
        duration: '2m 30s',
        call_summary: null,
        transcript:
            "Agent: How can I help you?\nUser: Hi. Uh, who if Are you my civby?\nAgent: Hello! I'm here to assist you with any questions or tasks you might have. How can I help you today?\nUser: Are you Maisumi?\nAgent: No, I'm not Maisumi. I'm your assistant from Party Planning. How can I assist you today?\nUser: Okay. Thank you.\nAgent: You're welcome! If you have any questions or need assistance with anything, feel free to let me know.\n",
        user_sentiment: null,
        task_completion_rating: null,
        task_completion_rating_reason: null,
        call_completion_rating: null,
        call_completion_rating_reason: null,
    },
    {
        id: '5aa4bffa-85d1-49f4-87b7-64251468098e',
        created_at: '2024-09-17T23:51:57.171285Z',
        updated_at: '2024-09-17T23:52:55.503679Z',
        started_at: '2024-09-17T16:51:58.354000',
        ended_at: '2024-09-17T16:52:53.457000',
        from_number: '+13104971307',
        to_number: '+14158799778',
        duration: '2m 30s',
        call_summary: null,
        transcript:
            "Agent: How can I help you?\nUser: Hi. Uh, who if Are you my civby?\nAgent: Hello! I'm here to assist you with any questions or tasks you might have. How can I help you today?\nUser: Are you Maisumi?\nAgent: No, I'm not Maisumi. I'm your assistant from Party Planning. How can I assist you today?\nUser: Okay. Thank you.\nAgent: You're welcome! If you have any questions or need assistance with anything, feel free to let me know.\n",
        user_sentiment: null,
        task_completion_rating: null,
        task_completion_rating_reason: null,
        call_completion_rating: null,
        call_completion_rating_reason: null,
    },
    {
        id: '5aa4bffa-85d1-49f4-87b7-64201668098e',
        created_at: '2024-09-16T23:51:57.171285Z',
        updated_at: '2024-09-16T23:52:55.503679Z',
        started_at: '2024-09-16T16:51:58.354000',
        ended_at: '2024-09-16T16:52:53.457000',
        from_number: '+13104971307',
        to_number: '+14158799778',
        duration: '2m 30s',
        call_summary: null,
        transcript:
            "Agent: How can I help you?\nUser: Hi. Uh, who if Are you my civby?\nAgent: Hello! I'm here to assist you with any questions or tasks you might have. How can I help you today?\nUser: Are you Maisumi?\nAgent: No, I'm not Maisumi. I'm your assistant from Party Planning. How can I assist you today?\nUser: Okay. Thank you.\nAgent: You're welcome! If you have any questions or need assistance with anything, feel free to let me know.\n",
        user_sentiment: null,
        task_completion_rating: null,
        task_completion_rating_reason: null,
        call_completion_rating: null,
        call_completion_rating_reason: null,
    },
    {
        id: '5aa4bffa-85d1-49f4-87b7-64201467098e',
        created_at: '2024-09-15T23:51:57.171285Z',
        updated_at: '2024-09-15T23:52:55.503679Z',
        started_at: '2024-09-15T16:51:58.354000',
        ended_at: '2024-09-15T16:52:53.457000',
        from_number: '+13104971307',
        to_number: '+14158799778',
        duration: '2m 30s',
        call_summary: null,
        transcript:
            "Agent: How can I help you?\nUser: Hi. Uh, who if Are you my civby?\nAgent: Hello! I'm here to assist you with any questions or tasks you might have. How can I help you today?\nUser: Are you Maisumi?\nAgent: No, I'm not Maisumi. I'm your assistant from Party Planning. How can I assist you today?\nUser: Okay. Thank you.\nAgent: You're welcome! If you have any questions or need assistance with anything, feel free to let me know.\n",
        user_sentiment: null,
        task_completion_rating: null,
        task_completion_rating_reason: null,
        call_completion_rating: null,
        call_completion_rating_reason: null,
    },
];
